export default {
    'delivery': {
        'created': "Создан",
        'filled': "Заполнен",
        'purchaserConfirmed': "Подтвержден клиентом, ждет подтверждения от водителя",
        "courierFillConfirmed": "Подтвержден водителем, возврат едет до исполнителя",
        'transferredToProvider': "Доставлен до исполнителя, ждет подтверждения от исполнителя",
        'providerConfirmed': "Подтвержден исполнителем, ждет подтверждения от водителя",
        'received': "Выполнен",
        'canceled': "Отменен",
    },
    'pickup': {
        'created': "Создан",
        'filled': "Заполнен",
        'purchaserConfirmed': "Подтвержден клиентом, ждет подтверждения от исполнителя",
        'received': "Выполнен",
        'canceled': "Отменен",
    },
};
