import React, { Component } from "react";
import {
    Backdrop,
    CircularProgress,
    Container
} from "@mui/material";
import {
    Filter as FilterComponent,
    DialogFormProduct as DialogFormProductComponent,
    Table as TableComponent
} from "./components";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import {
    DialogConfirmAction
} from "../../../../components";
import queryString from "query-string";
import agent from "../../../../agent/agent";

const initialFilter = {
    "sortInfo": "",
};

const visibleColumns = [
    {
        id: "id",
        label: "№ товара",
        sortable: true,
        width: 80,
        align: 'left',
    },
    {
        id: "name",
        label: "Наименование",
        sortable: false,
        width: 'auto',
        align: 'left',
    },
    {
        id: "categoryId",
        label: "Категория",
        sortable: false,
        width: 'auto',
        align: 'left',
    },
    {
        id: "piecePrice",
        label: "Цена за 1шт.",
        sortable: true,
        width: 'auto',
        align: 'left',
    },
    {
        id: "cbmPrice",
        label: "Цена за 1м³",
        sortable: true,
        width: 'auto',
        align: 'left',
    },
    {
        id: "cbmStep",
        label: "Шаг в 1м³",
        sortable: true,
        width: 140,
        align: 'left',
    },
    {
        id: "pieceToCbmConversionFactor",
        label: "Количество шт. 1м³",
        sortable: true,
        width: 140,
        align: 'left',
    },
    {
        id: "hidden",
        label: "Скрыть товар",
        sortable: false,
        width: 140,
        align: 'left',
    },
    {
        id: "actions",
        label: "",
        sortable: false,
        width: 'auto',
        align: 'left',
    },
];

class Products extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            categories: [],

            filter: {
                ...initialFilter
            },
            pagination: {
                page: 1,
                totalPage: 1
            },


            isLoading: true,
            isShowBackdrop: false,
        };


        this.refDialogFormProduct = React.createRef();
        this.refDialogConfirmAction = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getProducts();
        await this.getCategories();
    }
    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }


    // Логика получения списка товаров
    getProducts = async () => {

        this.setState({ isLoading: true });

        const filter = this.getFilters();
        const {
            products,
            headers
        } = await agent.get(`/products${filter}&limit=20`).then((res) => {
            return {
                products: res.data.products,
                headers: res.headers
            }
        }).catch(() => {
            return {
                products: []
            }
        });

        const pagination = {
            ...this.state.pagination,
            totalPage: headers?.["x-pagination-page-count"] || 1
        };

        this.setState({
            isLoading: false,
            products,
            pagination
        });

    }

    // Получение списка категорий
    getCategories = async () => {
        const categories = await agent.get(`/categories?page=1&limit=50`).then((res) => {
            return res.data.categories || []
        }).catch(() => {
            return []
        });

        this.setState({
            categories
        })
    }


    // Логика работы фильтра
    getFilters = () => {
        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};

        let string = [
            `page=${pagination.page}`
        ];
        Object.keys(filter).map((key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push(`${key}=${value}`);
            }
        });

        window.history.replaceState(null, null, `/products?${string.join("&")}`);

        return `?${string.join("&")}`
    }
    changeFilter = async (filter, isFastStart) => {

        await this.setState({filter});

        if (!isFastStart) {
            return null
        }

        await this.getProducts();

    }
    resetFilter = async () => {
        await this.setState({filter: {...initialFilter}});
        await this.getProducts();
    }

    changePagination = async (pagination) => {
        await this.setState({pagination});
        await this.getProducts();
    }


    // Логика создания товара
    createProduct = async (product, isCreated = false) => {

        // Открываем форму создания товара
        if (!isCreated) {
            this.refDialogFormProduct.current.open({
               form: {},
               event: this.createProduct.bind(this)
            });

            return null
        }

        this.setState({ isShowBackdrop: true });

        const responseCreateProduct = await agent.post(`/products`, {
            ...product,

            cbmPrice: Number(product.cbmPrice),
            cbmQuantity: +product.cbmQuantity,
            cbmStep: +product.cbmStep,

            piecePrice: Number(product.piecePrice),
            pieceQuantity: Number(product.pieceQuantity),

            pieceToCbmConversionFactor: +product.pieceToCbmConversionFactor,

            length: Number(product.length),
            width: Number(product.width),
            height: Number(product.height),
        }).then((res) => {
            return res.data
        }).catch((err) => {
            this.setState({ isShowBackdrop: false });
            return { error: err.response }
        });
        if (responseCreateProduct.error) {
            Notification({
               type: NotificationTypes.error,
               message: "При создании товара возникла ошибка, попробуйте позже"
            });

            return null
        }

        await this.getProducts();

        this.refDialogFormProduct.current.close();

        this.setState({ isShowBackdrop: false });

        Notification({
            type: NotificationTypes.success,
            message: "Товар успешно создан"
        });

    }


    // Логика редактирование товара
    editProduct = async (product, isEdit = false) => {

        // Открываем форму редактирования товара
        if (!isEdit) {
            this.refDialogFormProduct.current.open({
                form: {...product},
                event: this.editProduct.bind(this)
            });

            return null
        }

        this.setState({ isShowBackdrop: true });

        const responseCreateProduct = await agent.put(`/products/${ product.id }`, {
            ...product,

            cbmPrice: Number(product.cbmPrice),
            cbmQuantity: product.cbmQuantity,
            cbmStep: product.cbmStep,

            piecePrice: Number(product.piecePrice),
            pieceQuantity: Number(product.pieceQuantity),

            pieceToCbmConversionFactor: product.pieceToCbmConversionFactor,
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return { error: err.response }
        });
        if (responseCreateProduct.error) {
            Notification({
                type: NotificationTypes.error,
                message: "При редактировании товара возникла ошибка, попробуйте позже"
            });

            return null
        }

        await this.getProducts();

        this.refDialogFormProduct.current.close();

        this.setState({ isShowBackdrop: false });

        Notification({
            type: NotificationTypes.success,
            message: "Товар успешно изменен"
        });

    }

    changeHidden = async (product, hidden) => {

        this.setState({ isShowBackdrop: true });

        const responseChangeHidden = await agent.put(`/products/${ product.id }`, {
            ...product,
            hidden
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return { error: err.response }
        });

        if (responseChangeHidden.error) {
            Notification({
                type: NotificationTypes.error,
                message: responseChangeHidden.error?.data?.message || 'Ошибка сервера',
            });

            return null
        }

        this.setState({ isShowBackdrop: false });

        Notification({
            type: NotificationTypes.success,
            message: "Товар успешно изменен",
        });

        let products = [...this.state.products];
        products.find((t) => t.id === product.id).hidden = responseChangeHidden.product.hidden;
        this.setState({ products });

    }


    // Логика удаление товара
    deleteProduct = async (product, isConfirm = false) => {

        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: `Вы действительно хотите удалить товар "${product.name}"?`,
                message: "После удаления нельзя будет вернуть товар",
                labelSuccess: "Да, удалить",
                onSuccess: this.deleteProduct.bind(this, product, true)
            });

            return null
        }

        this.setState({ isShowBackdrop: true })

        const responseDeleteProduct = await agent.delete(`/products/${ product.id }`).then((res) => {
            return res.data
        }).catch((err) => {
            return {error: err.response}
        });
        if (responseDeleteProduct.error) {
            this.setState({
                isShowBackdrop: false,
            })
            Notification({
               type: NotificationTypes.error,
               message: "Возникла ошибка удаления товара, попробуйте позже"
            });

            return null
        }

        await this.getProducts();

        this.setState({ isShowBackdrop: false })

        Notification({
            type: NotificationTypes.success,
            message: "Товар успешно удален"
        });

    }

    render() {
        const {
            products,
            categories,

            filter,
            pagination,


            isShowBackdrop,
            isLoading
        } = this.state;

        return (
            <Container maxWidth="xl">

                <FilterComponent
                    filter={filter}

                    onCreateProduct={this.createProduct}
                />

                <TableComponent
                    data={products}
                    categories={categories}
                    isLoading={isLoading}

                    visibleColumns={visibleColumns}

                    filter={filter}
                    pagination={pagination}

                    onEditProduct={this.editProduct}
                    onDeleteProduct={this.deleteProduct}
                    onChangeFilter={this.changeFilter}
                    onChangePagination={this.changePagination}
                    onChangeHidden={this.changeHidden}
                />

                <DialogFormProductComponent
                    ref={this.refDialogFormProduct}
                />

                <DialogConfirmAction
                    ref={this.refDialogConfirmAction}
                />

                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="inherit"/>
                </Backdrop>

            </Container>
        );
    }
}

export default Products
