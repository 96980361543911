import React, {Component} from 'react';
import {
    Backdrop,
    CircularProgress,

    Box,
    Container,
    Typography
} from "@mui/material";
import {
    TableInvoices as TableInvoicesComponent,
    DialogEditPaymentStatus as DialogEditPaymentStatusComponent
} from './components';
import {
    DialogConfirmAction, DialogUserCart
} from "../../../../components";
import {
    Notification,
    NotificationTypes
} from '../../../../common/Notification';
import agent from "../../../../agent/agent";
import queryString from "query-string";

const initialFilter = {
    "sortInfo": "",
    "limit": 10,
};

class Invoices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoices: [],

            editInvoice: {},

            filter: {
                ...initialFilter
            },
            pagination: {
                page: 1,
                totalPage: 1
            },

            isOpenEditPaymentStatus: false,

            isShowBackdrop: false,

            isLoading: true,
        };

        this.refDialogConfirmAction = React.createRef();
        this.refDialogUserCart = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getInvoices();
    };

    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }

    getInvoices = async () => {
        this.setState({isLoading: true})

        const filter = this.getFilters();

        const {invoices, count} = await agent.get(`/payments/invoices${filter}`)
            .then(res => res.data)
            .catch(err => {
                return {invoices: [], count: 0};
            });

        let newInvoices = [];

        if (invoices.length > 0) {
            newInvoices = [...invoices.map(item => {
                return {
                    ...item,
                    payment: {
                        ...item.payment,
                        order: {
                            ...item.payment.order,
                            purchaserUser: {
                                ...item.payment.order.purchaserUser,
                                organization: item?.payment?.order?.purchaserUser?.ownOrganization || null,
                            },
                        }
                    }
                };
            })];
        }

        let pagination = {...this.state.pagination};
        pagination.totalPage = Math.ceil(count / this.state.filter.limit) || 1;

        this.setState({
            invoices: newInvoices,
            pagination,
            isLoading: false,
        })
    };

    //Функция для открытия карточки пользователя
    openUserCart = (userRow, isOpen = false) => {
        if (!isOpen) {
            this.refDialogUserCart.current.open(userRow, this.openUserCart.bind(this));
            return null;
        }

        this.setState({isShowBackdrop: true});
    };

    // Логика изменения статуса счета
    setPaidStatus = async (status, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Подтверждение",
                message: `Вы действительно хотите изменить статус счета ${this.state.editInvoice.paymentId}?`,
                labelSuccess: "Да, изменить",
                onSuccess: this.setPaidStatus.bind(this, status, true)
            });

            return null
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.put(`/payments/${this.state.editInvoice.id}/set-paid-status`, {newStatus: status})
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false})

            Notification({
                message: response.error?.data?.message || "Ошибка при изменении статуса счета",
                type: NotificationTypes.error,
            });

            this.handleCloseIsOpenEditPaymentStatus();

            return null;
        }

        this.setState({isShowBackdrop: false});
        await this.getInvoices();

        this.handleCloseIsOpenEditPaymentStatus();
    };

    // Логика работы с фильтром
    getFilters = () => {
        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};

        let string = [
            `page=${pagination.page}`
        ];
        Object.keys(filter).map((key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push(`${key}=${value}`);
            }
        });

        window.history.replaceState(null, null, `/invoices?${string.join("&")}`);

        return `?${string.join("&")}`
    }

    changeFilter = async (filter, isFastStart) => {

        await this.setState({filter});

        if (!isFastStart) {
            return null
        }

        await this.getInvoices();

    }

    changePagination = async (pagination) => {
        await this.setState({pagination});
        await this.getInvoices();
    }

    // Открытие формы редактирования статуса счета
    setIsOpenEditPaymentStatus = (isOpen, editInvoice = {}) => {
        this.setState({
            isOpenEditPaymentStatus: isOpen,
            editInvoice,
        });
    };

    // Закрытие формы редактирования статуса счета
    handleCloseIsOpenEditPaymentStatus = () => {
        this.setIsOpenEditPaymentStatus(false);
    };

    _routeOrganization = (organizationId) => {
        this.props.history.replace(`/organizations/${organizationId}`);
    };

    render() {
        const {
            invoices,
            editInvoice,

            filter,
            pagination,

            isOpenEditPaymentStatus,

            isShowBackdrop,

            isLoading,
        } = this.state;
        return (
            <Container maxWidth="xl">
                <Box mb={5}>
                    <Typography variant="h2">Счета на оплату</Typography>
                </Box>

                <Box>
                    <TableInvoicesComponent
                        invoices={invoices}
                        filter={filter}
                        pagination={pagination}
                        isLoading={isLoading}

                        onRouteOrganization={this._routeOrganization}
                        onOpenUserCart={this.openUserCart}
                        onChangePagination={this.changePagination}
                        onChangeFilter={this.changeFilter}
                        onOpenEditPaymentStatus={this.setIsOpenEditPaymentStatus}
                    />
                </Box>

                <DialogEditPaymentStatusComponent
                    editInvoice={editInvoice}
                    isOpen={isOpenEditPaymentStatus}
                    onClose={this.handleCloseIsOpenEditPaymentStatus}
                    onSetPaidStatus={this.setPaidStatus}
                />

                <DialogConfirmAction
                    ref={this.refDialogConfirmAction}
                />

                <DialogUserCart
                    ref={this.refDialogUserCart}
                    refDialogConfirmation={this.refDialogConfirmAction}
                    history={this.props.history}
                />

                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </Container>
        );
    }
}

export default Invoices;