import React from 'react';
import {
    Box,

    Dialog,
    DialogContent,
    DialogTitle,

    Link,

    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";

const DialogExecutingOrders = (props) => {
    const {
        executingOrders,
        isOpen,
        onClose
    } = props;

    const classes = useStyles();

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth="md"
            onClose={onClose}
        >
            <DialogTitle>
                <Typography variant="h3">
                    Выполняющиеся заказы организации
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant="h4" mb={1}>
                    Заказы:
                </Typography>
                <Box className={classes.rowContent}>
                    {executingOrders.map(order => (
                        <Link className={classes.link} key={`executing-order-${order}`} href={`/orders/${order}`} target="_blank">
                            №{order}
                        </Link>
                    ))}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles({
    rowContent: {
        width: "100%",
        height: "100%",
        maxHeight: "600px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        gap: 16,
    },

    link: {
        display: "block",
        width: "max-content",
    },
})

export default DialogExecutingOrders;