import React from 'react';
import { useSelector } from "react-redux";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { deliveryTime } from "../../../../../../helper/deliveryTime";
import { Formik } from "formik";
import moment from "moment/moment";
import * as Yup from "yup";

const DialogTimeChange = (props) => {
    const {
        order,
        isOpen,
        onClose,
        onChange,
    } = props;

    const refFormik = React.useRef(null);
    const settings = useSelector(state => state?.global?.settings || null);
    const deliveryTimes = deliveryTime(settings);
    const minDeliveryDate = moment().add(settings.deliveryMinDays, "days").format("YYYY-MM-DD");
    const maxDeliveryDate = moment().add(settings.deliveryMaxDays, "days").format("YYYY-MM-DD");
    const [ initialValues, setInitialValues ] = React.useState({
        deliveryDate: '',
        deliveryTimespan: '',
    });

    React.useEffect(() => {
        if (Object.keys(order).length > 0 && order !== null) {
            setInitialValues({
                deliveryDate: moment(order.deliveryDate).format('YYYY-MM-DD'),
                deliveryTimespan: order.deliveryTimespan,
            });
        }
    }, [ isOpen ]);

    const onSubmit = (form) => {
        const newForm = {
            delivery: {
                deliveryDate: moment(form.deliveryDate).format('YYYY-MM-DD'),
                deliveryTimespan: form.deliveryTimespan
            },
        };

        onChange(newForm);
        handleCloseModal();
    };

    const handleChange = ({ target }) => {
        const { name, value } = target;
        const newForm = refFormik.current.values;

        newForm[name] = value;

        refFormik.current.setValues(newForm);
    };

    const handleCloseModal = () => {
        onClose();
    };

    return (
        <Dialog
            open={ isOpen }
            fullWidth
            maxWidth="md"
            onClose={ handleCloseModal }
        >
            <DialogTitle>
                <Typography variant="h3">Дата и время доставки</Typography>
            </DialogTitle>

            <DialogContent>
                <Formik
                    innerRef={ refFormik }
                    initialValues={ initialValues }
                    validationSchema={ validationSchema }
                    onSubmit={ onSubmit }
                >
                    { (props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit,
                        } = props;

                        console.log("values: ", values);

                        return (
                            <>
                                <Box pt={ 1 }>
                                    <Box mb={ 2 }>
                                        <TextField
                                            fullWidth
                                            type="date"
                                            name="deliveryDate"
                                            label="Дата доставки"
                                            InputLabelProps={ {
                                                shrink: true,
                                            } }
                                            InputProps={ {
                                                inputProps: {
                                                    min: minDeliveryDate,
                                                    max: maxDeliveryDate
                                                }
                                            } }
                                            value={ values.deliveryDate }
                                            error={ touched.deliveryDate && Boolean(errors.deliveryDate) }
                                            helperText={ touched.deliveryDate && errors.deliveryDate }

                                            onChange={ handleChange }
                                        />
                                    </Box>
                                    <Box>
                                        <FormControl
                                            fullWidth
                                            error={ touched.deliveryTimespan && Boolean(errors.deliveryTimespan) }
                                        >
                                            <InputLabel>Время доставки</InputLabel>
                                            <Select
                                                name="deliveryTimespan"
                                                label="Время доставки"
                                                value={ values.deliveryTimespan }

                                                onChange={ handleChange }
                                            >
                                                { deliveryTimes.map((time) => (
                                                    <MenuItem key={ time.value } value={ time.value }>
                                                        { time.label }
                                                    </MenuItem>
                                                )) }
                                            </Select>
                                        </FormControl>
                                        { Boolean(touched.deliveryTime && Boolean(errors.deliveryTimespan)) && (
                                            <FormHelperText error variant="filled">
                                                { touched.deliveryTimespan && errors.deliveryTimespan }
                                            </FormHelperText>
                                        ) }
                                    </Box>
                                </Box>

                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        sx={ { borderRadius: "4px", textTransform: "initial" } }

                                        onClick={ handleCloseModal }
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={ { borderRadius: "4px", textTransform: "initial" } }

                                        onClick={ handleSubmit }
                                    >
                                        Изменить
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    } }
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const validationSchema = Yup.object().shape({
    deliveryDate: Yup.string().required('Выберите дату'),
    deliveryTimespan: Yup.string().required('Выберите время'),
})

export default React.memo(DialogTimeChange);