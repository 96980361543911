import React, {Component} from "react";
import {
    Container,
    Backdrop,
    CircularProgress
} from "@mui/material";
import {
    Filter as FilterComponent,
    Table as TableComponent
} from "./components";
import {
    DialogChangeStatusPayment
} from "../../../../components";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";
import queryString from "query-string";


const initialFilter = {
    "sortInfo": "-id",

    //---------------------
    "type": "current",
    //---------------------

    'approximateId': '',
};

const visibleColumns = [
    {
        id: "id",
        label: "№ заказа",
        sortable: true,
        width: 80,
        align: 'center',
    },
    {
        id: "deliveryDate",
        label: "Срок выполнения",
        sortable: true,
        width: 'auto',
        align: 'center',
    },
    {
        id: "deliveryAddress",
        label: "Адрес доставки",
        sortable: false,
        width: 'auto',
        align: 'center',
    },
    {
        id: "statusOrderReady",
        label: "Статус готовности",
        sortable: false,
        width: 'auto',
        align: 'center',
    },
    {
        id: "deliveryMethod",
        label: "Статус доставки",
        sortable: false,
        width: 'auto',
        align: 'center',
    },
    {
        id: "statusOrderReceived",
        label: "Статус выполнения",
        sortable: false,
        width: 140,
        align: 'center',
    },
    {
        id: "paymentStatus",
        label: "Статус оплаты",
        sortable: false,
        width: 140,
        align: 'center',
    },
    {
        id: "storeStatus",
        label: "Статус склада",
        sortable: false,
        width: 'auto',
        align: 'center',
    },
    {
        id: "provider",
        label: "Исполнитель",
        sortable: false,
        width: 140,
        align: 'center',
    },
];

class Orders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],

            filter: {
                ...initialFilter
            },
            pagination: {
                page: 1,
                totalPage: 1
            },

            settings: {},

            isLoading: true,
            isShowBackdrop: false,
        };


        this.refDialogChangeStatusPayment = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getSettings();
        await this.getOrders();
    }

    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const locationPrevSearch = prevProps.location.search || "";
        let parsePrevSearch = queryString.parse(locationPrevSearch, {
            arrayFormat: "bracket"
        });
        if (parseSearch.type !== parsePrevSearch.type) {
            await this.initFilter();
            await this.getSettings();
            await this.getOrders();
        }
    };

    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }

    // Логика получения заказов
    getOrders = async () => {
        this.setState({isLoading: true});

        const filter = this.getFilters();
        const {
            orders,
            totalCount
        } = await agent.get(`/orders${filter}&limit=20`).then((res) => {
            return {
                orders: res.data.orders || [],
                totalCount: res.data.count
            }
        }).catch((err) => {
            return {
                orders: [],
                totalCount: 0
            }
        });


        let pagination = {...this.state.pagination};
        pagination.totalPage = Math.ceil(totalCount / 20) || 1;

        this.setState({
            isLoading: false,
            pagination,
            orders
        });
    }

    getSettings = async () => {
        const settings = await agent.get(`/settings`)
            .then((res) => res.data.settings)
            .catch((err) => {
            });

        this.setState({settings});
    }

    // Логика работы с фильтром
    getFilters = () => {
        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};

        let string = [
            `page=${pagination.page}`
        ];
        Object.keys(filter).map((key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push(`${key}=${value}`);
            }
        });

        window.history.replaceState(null, null, `/orders?${string.join("&")}`);

        return `?${string.join("&")}`
    }

    changeFilter = async (filter, isFastStart) => {

        await this.setState({
            filter: {
                ...filter,
            },
        });

        if (!isFastStart) {
            return null
        }

        await this.getOrders();

    }

    changePagination = async (pagination) => {
        await this.setState({pagination});
        await this.getOrders();
    }

    resetFilter = async () => {
        await this.setState({filter: {...initialFilter}});
        await this.getOrders();
    }

    // Логика изменения статуса оплаты
    changeStatusPayment = async (order, newStatus) => {

        if (!newStatus) {
            this.refDialogChangeStatusPayment.current.open({
                order: order,
                onSuccess: this.changeStatusPayment.bind(this)
            })

            return
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.put(`/orders/${order.id}/set-status`, {
            orderId: order.id,
            paymentStatus: newStatus
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return {error: err.response}
        })
        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                type: NotificationTypes.error,
                message: response.error?.data?.message || "Ошибка сервера"
            })

            return
        }

        await this.getOrders();

        this.setState({isShowBackdrop: false});

        Notification({
            type: NotificationTypes.success,
            message: "Статус оплаты успешно изменен"
        });
    }

    _routeOrderPage = (orderId) => {
        this.props.history.push(`/orders/${orderId}`)
    }

    render() {
        const {
            orders,

            filter,
            pagination,

            settings,

            isLoading,
            isShowBackdrop
        } = this.state;

        return (
            <Container maxWidth="xl">


                <FilterComponent
                    filter={filter}
                    onChangeFilter={this.changeFilter}
                    onResetFilter={this.resetFilter}
                    onSearch={this.getOrders}
                />

                <TableComponent
                    data={orders}
                    filter={filter}
                    pagination={pagination}
                    isLoading={isLoading}
                    visibleColumns={visibleColumns}

                    settings={settings}


                    onChangePagination={this.changePagination}
                    onChangeFilter={this.changeFilter}
                    onChangeStatusPayment={this.changeStatusPayment}

                    routeOrderPage={this._routeOrderPage}
                />

                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="inherit"/>
                </Backdrop>


                <DialogChangeStatusPayment
                    ref={this.refDialogChangeStatusPayment}
                />

            </Container>
        );
    }
}

export default Orders
