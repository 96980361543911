import React from 'react';
import {
    Box,
    Button,

    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,

    TextField,
    Typography
} from "@mui/material";
import {
    Formik
} from "formik";
import {
    AddressSuggestionsComponent,
    AutocompleteUser_1
} from "../../../../../../components";
import * as Yup from "yup";

const DialogAddStore = (props) => {
    const {
        isOpen,
        onClose,
        onCreate,
    } = props;

    const refFormik = React.useRef(null);
    const [initialValues, setInitialValues] = React.useState({
        ownerUser: '',
        ownerUserId: '',
        name: '',
        description: '',
        coords: [null, null],
        latitude: '',
        longitude: '',
        address: {
            value: '',
        },
    });

    React.useEffect(() => {
        return () => {
            setInitialValues({
                ownerUser: '',
                ownerUserId: '',
                name: '',
                description: '',
                coords: [null, null],
                latitude: '',
                longitude: '',
                address: {
                    value: '',
                },
            });
        };
    }, [isOpen]);

    const onSubmit = (form) => {
        const newForm = {
            ...form,
            address: form?.address?.value || "",
        }
        onCreate(newForm);
        handleCloseModal();
    };

    const handleChange = ({target}) => {
        const {name, value} = target;
        const newForm = refFormik.current.values;

        newForm[name] = value;

        refFormik.current.setValues(newForm);
    };

    const handleCloseModal = () => {
        onClose();
    };

    const handleChangeAddress = (value, coords) => {
        const newForm = refFormik.current.values;

        newForm.address = {...value};
        newForm.coords = coords || [null, null];
        newForm.latitude = String(coords?.[0]) || "0";
        newForm.longitude = String(coords?.[1]) || "0";

        refFormik.current.setValues(newForm);
    };

    const resetAddress = () => {
        const newForm = refFormik.current.values;

        newForm.coords = [null, null];
        newForm.latitude = '';
        newForm.longitude = '';
        newForm.address = {value: ''};

        refFormik.current.setValues(newForm);
    };

    const handleChangeAutocomplete = (value) => {
        const newForm = refFormik.current.values;

        newForm.ownerUser = value;
        newForm.ownerUserId = value.id;

        refFormik.current.setValues(newForm);
    };

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth="md"
            onClose={handleCloseModal}
        >
            <DialogTitle>
                <Typography variant='h3'>
                    Создание склада
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box pt={1} mb={2}>
                                    <Box mb={2}>
                                        <AutocompleteUser_1
                                            name='ownerUser'
                                            value={values.ownerUser}
                                            error={errors.ownerUserId}
                                            touched={touched.ownerUserId}
                                            limit="0"
                                            title="Владелец склада"
                                            roles="provider"
                                            showSearchFields
                                            onChangeAutocomplete={handleChangeAutocomplete}
                                        />
                                    </Box>
                                    <Box mb={2}>
                                        <TextField
                                            fullWidth
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name && errors.name}
                                            name='name'
                                            value={values.name}
                                            label='Название склада'

                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box mb={2}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            error={touched.description && Boolean(errors.description)}
                                            helperText={touched.description && errors.description}
                                            name='description'
                                            value={values.description}
                                            label='Описание склада'
                                            rows={4}

                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box>
                                        <AddressSuggestionsComponent
                                            addressValue={values.address}
                                            coordsValue={values.coords}
                                            latitudeValue={values.latitude}
                                            longitudeValue={values.longitude}
                                            touched={touched}
                                            errors={errors}

                                            onChangeAddress={handleChangeAddress}
                                            onResetAddress={resetAddress}
                                        />
                                    </Box>
                                </Box>

                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        sx={{borderRadius: "4px", textTransform: "initial"}}

                                        onClick={handleCloseModal}
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{borderRadius: "4px", textTransform: "initial"}}

                                        onClick={handleSubmit}
                                    >
                                        Создать
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

Yup.addMethod(Yup.array, "tuple", function (schema) {
    if (!this.isType(schema)) Yup.ValidationError();
    return Yup.object({
        tuple: Yup.array().min(schema.length).max(schema.length), ...Object.fromEntries(Object.entries(schema)),
    }).transform((value, originalValue) => {
        if (!this.isType(originalValue)) Yup.ValidationError();
        return {
            tuple: originalValue, ...Object.fromEntries(Object.entries(originalValue)),
        };
    });
});

const validationSchema = Yup.object().shape({
    ownerUserId: Yup.number().required('Обязательное поле'),
    name: Yup.string().required('Обязательное поле'),
    description: Yup.string().required('Обязательное поле'),
    address: Yup.object({
        value: Yup.string().required('Введите адрес'),
    }).required('Введите адрес'),
    latitude: Yup.string().required('Введите широту').min(-90, "Минимальное значение -90").max(90, "Максимальное значение 90"),
    longitude: Yup.string().required('Введите долготу').min(-180, "Минимальное значение -180").max(180, "Максимальное значение 180"),
    coords: Yup.array().tuple([Yup.number()
        .required("Обязательное поле")
        .typeError("Обязательное поле")
        .min(-90, "Минимальное значение -90")
        .max(90, "Максимальное значение 90"), Yup.number()
        .required("Обязательное поле")
        .typeError("Обязательное поле")
        .min(-180, "Минимальное значение -180")
        .max(180, "Максимальное значение 180"),]),
});

export default React.memo(DialogAddStore);