// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Order from './Order';

export default compose(
    connect(
        state => ({
          user: state.global?.user || {},
        }),
        dispatch => ({}),
    ),
)(Order);
