import React from "react";
import {
    Box,
    Button,
    Collapse,
    Grid,
    TextField
} from "@mui/material";
import {
    SearchRounded as SearchIcon,
    Clear as ClearIcon,
} from "@mui/icons-material";
import {
    makeStyles
} from "@mui/styles";

let timeoutSearch = null;

const Filter = (props) => {
    const {
        filter,
        onChangeFilter,
        onResetFilter,
        onSearch
    } = props;

    const classes = useStyles();
    const [isOpenSearch, setIsOpenSearch] = React.useState(false);

    const handleChangeFilter = ({target}) => {
        clearTimeout(timeoutSearch);

        const {value, name} = target;
        const newFilter = filter;

        newFilter[name] = value;

        if (name === 'approximateId') {
            newFilter.type = '';
        }

        onChangeFilter(newFilter);

        timeoutSearch = setTimeout(() => {
            onSearch();
        }, 1000);
    }

    return (
        <Box mb={1}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item></Grid>
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                onClick={onResetFilter}
                                endIcon={<ClearIcon/>}
                            >
                                Сбросить
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    textTransform: "initial",
                                    fontSize: 12,
                                    height: "auto",
                                    borderRadius: 1,
                                }}
                                endIcon={<SearchIcon/>}

                                onClick={setIsOpenSearch.bind(this, !isOpenSearch)}
                            >
                                Поиск
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box my={1}/>

            <Collapse in={isOpenSearch} timeout="auto" unmountOnExit>
                <Box className={classes.searchCollapseWrapper} px={2} py={2} bgcolor="white" borderRadius={4}>
                    <TextField
                        size="small"
                        value={filter['approximateId']}
                        name="approximateId"
                        label="№ заказа"
                        variant="outlined"

                        onChange={handleChangeFilter}
                    />
                </Box>
            </Collapse>
        </Box>
    );
};

const useStyles = makeStyles({
    searchCollapseWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        gap: 15,
    }
})

export default Filter;