import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,

    Box,
    TextField,
    Tooltip,
    Typography,
    Button
} from "@mui/material";
import {
    HelpOutlineRounded as HelpIcon
} from '@mui/icons-material';
import {
    makeStyles
} from "@mui/styles";
import {
    Formik
} from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";

const DialogAcceptOrder = (props) => {
    const {
        isOpen,

        onClose,
        onAccept
    } = props;

    const classes = useStyles();
    const refFormik = React.useRef(null);
    const [initialValues, setInitialValues] = React.useState({
        code: '',
    });

    React.useEffect(() => {
        if (isOpen) {
            setInitialValues({
                code: '',
            });
        }
    }, [isOpen]);

    const onSubmit = (form) => {
        onAccept(form.code);
        handleCloseModal();
    };

    const handleCloseModal = () => {
        onClose();
    };

    const handleChange = ({target}) => {
        const {name, value} = target;
        const newForm = refFormik.current.values;

        newForm[name] = value;

        refFormik.current.setValues(newForm);
    };

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth="sm"
            onClose={handleCloseModal}
        >
            <DialogTitle>
                <Typography variant="h3">
                    Передача груза заказчику
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box pt={1}>
                                    <Box className={classes.rowContent}>
                                        <InputMask
                                            mask="999999"
                                            value={values.code}
                                            maskChar=""
                                            onChange={handleChange}
                                        >
                                            {() => (
                                                <TextField
                                                    value={values.code}
                                                    error={Boolean(touched.code && errors.code)}
                                                    helperText={touched.code && errors.code}
                                                    name="code"
                                                    label="Код от заказчика"
                                                    placeholder="000000"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        </InputMask>
                                        <Tooltip title="Введите код, который Вам сообщит заказчик">
                                            <HelpIcon
                                                fontSize="small"
                                                color={Boolean(touched.code && errors.code) ? "error" : "primary"}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Box>

                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            borderRadius: "5px",
                                            textTransform: "none",
                                        }}

                                        onClick={handleCloseModal}
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            borderRadius: "5px",
                                            textTransform: "none",
                                        }}

                                        onClick={handleSubmit}
                                    >
                                        Подтвердить
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles({
    rowContent: {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },
})

const validationSchema = Yup.object().shape({
    code: Yup.string()
        .matches(/^[0-9]{6}$/, "Код состоит из 6-ти символов")
        .required('Введите код, который Вам сообщит заказчик'),
});

export default DialogAcceptOrder;