import React from 'react';
import {
    Box,
    Button,

    Grid, IconButton,

    Table,
    TableBody,
    TableCell,
    TableRow,

    Typography
} from "@mui/material";
import {
    EditRounded as EditIcon
} from '@mui/icons-material';
import {
    makeStyles
} from "@mui/styles";

const PartnerInformation = (props) => {
    const {
        store,
        onOpenChangeProvider
    } = props;
    const classes = useStyles();
    const user = store?.ownerUser || [];

    return (
        <Box className={classes.root}>
            <Box className={classes.rowContent}>
                <Typography
                    variant="h3"
                    sx={{fontWeight: "600"}}
                >
                    Информация об исполнителе
                </Typography>
                <IconButton
                    onClick={onOpenChangeProvider.bind(this, true)}
                >
                    <EditIcon/>
                </IconButton>
            </Box>
            <Table className={classes.table}>
                <TableBody>
                    <TableRow>
                        <TableCell width="25%" className={classes.tableLabel}>
                            Имя
                        </TableCell>
                        <TableCell className={classes.tableMessage}>
                            {user?.firstName || "—"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={classes.tableLabel}>
                            Телефон
                        </TableCell>
                        <TableCell className={classes.tableMessage}>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>{user?.phone || "—"}</Grid>
                                <Grid item>
                                    <Button
                                        className={classes.tableButton}
                                        href={`https://t.me/+${user?.phone || "-"}`}
                                        target="_blank"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Написать в телеграмм
                                    </Button>
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={classes.tableLabel}>
                            Email
                        </TableCell>
                        <TableCell className={classes.tableMessage}>
                            {user?.email || "—"}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        border: "1px solid #EAEAEA",
        borderRadius: 8,
        padding: "16px 32px 24px",
    },

    rowContent: {
        display: "flex",
        alignItems: 'center',
        gap: 16,
    },

    table: {
        boxShadow: "none",
        borderRadius: 0,
    },
    tableLabel: {
        "&.MuiTableCell-root": {
            padding: "8px 0 0 0",
            backgroundColor: "transparent!important",

            fontSize: 18,
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "#464646",
        },
    },
    tableMessage: {
        "&.MuiTableCell-root": {
            padding: "8px 0 0 0",
            backgroundColor: "transparent!important",

            fontSize: 18,
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "black",
            fontWeight: "600",
        },
    },

    tableButton: {
        height: 30,
        fontSize: 12,
        padding: "0 12px",
        borderRadius: 4
    },
}));

export default PartnerInformation;