import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,

    Box,
    Grid,
    Button,
    Typography,
} from "@mui/material";
import {
    Notification,
    NotificationTypes
} from "../../../../../../common/Notification";
import Tree from "./Tree";
import agent from "../../../../../../agent/agent";

class DialogAccreditation extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            accreditations: [],
            treeItems: [],

            user: {},

            isOpen: false,
            isLoading: true
        };
    }


    open = async ({ user }) => {
        await this.setState({
            user: user,
            isOpen: true
        });

        await this.initTree();
        await this.getAccreditations();
    }
    close = () => {
        this.setState({
            isOpen: false,
            isLoading: true
        })
    }

    initTree = async () => {
        const categories = this.props.categories;

        const getChildrens = function (parentId) {
            let items = (categories || []).filter((t) => +t.parentId === +parentId);

            if (items.length <= 0) {
                return null
            }

            items = items.map((item) => {
                let category = {...item};
                let childrens = getChildrens(item.id);

                if ((childrens || []).length > 0) {
                    category['childrens'] = childrens;
                }

                return category
            });

            return items
        }
        let newWood = categories
            .filter((t) => !t.parentId)
            .map((item) => {
                let category = {...item};
                let childrens = getChildrens(item.id);

                if ((childrens || []).length > 0) {
                    category['childrens'] = childrens;
                }

                return category
            });

        await this.setState({
            treeItems: newWood
        })
    }

    // Логика работы с акредициями пользователя
    getAccreditations = async () => {

        const accreditations = await agent.get(`/accreditations/${ this.state.user.id }`).then((res) => {
            return res.data?.accreditations || []
        }).catch((err) => {
            return []
        });

        this.setState({
            accreditations: accreditations,
            isLoading: false
        })

    }
    changeAccreditations = async (category, checked) => {

        // Если мы добавленияем аккредитация
        if ( checked ) {
            const body = {
                userId: this.state.user.id,
                categoryId: category.id
            };
            const responseCreate = await agent.post(`/accreditations`, body).then((res) => {
                return res.data
            }).catch((err) => {
                return { error: err.response }
            });
            if ( responseCreate.error ) {
                Notification({
                   type: NotificationTypes.error,
                   message: "При создании акредитации возникла ошибка"
                });

                return null
            }

            Notification({
                type: NotificationTypes.success,
                message: "Аккредатация успешно создана"
            })
        }


        // Если мы удалеяем аккредитацию
        if ( !checked ) {
            const accreditationId = this.state.accreditations.find(( t ) => t.categoryId === category.id)?.id;

            const responseDelete = await agent.delete(`/accreditations/${ accreditationId }`).then((res) => {
                return res.data
            }).catch((err) => {
                return { error: err.response }
            });
            if ( responseDelete.error ) {
                Notification({
                   type: NotificationTypes.error,
                   message: "При удалении акредации возникла ошибка"
                });

                return null
            }

            Notification({
                type: NotificationTypes.success,
                message: "Аккредитация удалена"
            });
        }

        await this.initTree();
        await this.getAccreditations();
    }

    render() {
        const {
            treeItems,
            accreditations,

            isOpen,
            isLoading
        } = this.state;

        return (
            <Dialog
                open={isOpen}

                maxWidth="md"

                fullWidth

                onClose={this.close}
            >

                <DialogTitle>
                    <Typography variant="h3">
                        Редактирование аккредитации
                    </Typography>
                </DialogTitle>

                <DialogContent>

                    {/* Загрузка списка аккредитация */}
                    {Boolean(isLoading) && (
                        <>

                            <Typography textAlign="center">
                                Идет загрузка...
                            </Typography>

                        </>
                    )}


                    {/* Список аккредитаций */}
                    {Boolean(!isLoading) && (
                        <>

                            {(treeItems || []).map(( item, index ) => (
                                <Tree
                                    key={`tree-${0}-${ index }`}
                                    item={item}
                                    level={0}
                                    accreditations={accreditations}
                                    onTap={this.changeAccreditations}
                                />
                            ))}

                        </>
                    )}

                </DialogContent>

                <DialogActions>
                    <Box px={2}>
                        <Button variant="contained" color="primary" size="small" onClick={this.close}>
                            Закрыть
                        </Button>
                    </Box>
                </DialogActions>

            </Dialog>
        );
    }
}

export default DialogAccreditation
