import {
    Box,
    Button,
    Typography
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import React from "react";
import {
    useDispatch
} from "react-redux";
import {
    setUser
} from "../../../../states/global";
import {useHistory} from "react-router-dom";

const Error403 = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const authReset = () => {
        localStorage.removeItem('jwt');
        dispatch(setUser(null));
        history.push('/');
    };
    return (
        <div className={classes.root}>
            <Box>
                <Box className={classes.box}>
                    <Typography className={classes.title} variant="h2">Доступ Запрещен</Typography>
                </Box>
                <Button
                    fullWidth
                    size="large"
                    variant="contained"

                    onClick={authReset}
                >
                    Авторизоваться
                </Button>
            </Box>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100vw',
        height: '100vh',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
    },
    box: {
        border: "1px solid #EAEAEA",
        borderRadius: 8,
        padding: "40px 50px",
        backgroundColor: theme.palette.error.main,
        marginBottom: 24,
    },
    title: {
        color: theme.palette.getContrastText(theme.palette.error.main)
    },

}));

export default Error403;