import React from "react";
import {
    Box,
    Grid,

    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

    Skeleton,

    Tooltip,
    IconButton,
    Avatar,
    Switch
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Edit as EditIcon,
    Delete as DeleteIcon
} from "@mui/icons-material";
import {
    TableHeadSortCell
} from "../../../../../../components";
import moment from "moment";

const TableCustom = (props) => {
    const {
        data,
        categories,

        isLoading,

        onEditProduct,
        onDeleteProduct
    } = props;

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Наименование</TableCell>
                        <TableCell>Категория</TableCell>

                        <TableCell>Цена за <br/>1шт.</TableCell>
                        <TableCell>Количество в <br/>1 шт.</TableCell>
                        <TableCell>Шаг в <br/>1шт.</TableCell>

                        <TableCell>Цена за <br/>1м³</TableCell>
                        <TableCell>Количество шт. <br/>в 1м³</TableCell>
                        <TableCell>Шаг в <br/>1м³</TableCell>

                        <TableCell>Коэффициент <br/>1шт в 1м³</TableCell>

                        <TableCell>Дата<br/> обновления</TableCell>
                        <TableCell>Дата<br/> создания</TableCell>

                        <TableCell/>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {

                        Boolean(isLoading) ? (

                            <>

                                {
                                    [0, 1, 2, 3, 4, 5].map((item) => (

                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>

                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>

                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>

                                            <TableCell><Skeleton/></TableCell>

                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>

                                            <TableCell><Skeleton/></TableCell>
                                        </TableRow>

                                    ))
                                }

                            </>

                        ) : (

                            <>

                                {

                                    data.map((row, index) => (

                                        <TableRow key={`row-item-${index}`}>
                                            <TableCell>{ row.id }</TableCell>
                                            <TableCell>{ row.name }</TableCell>
                                            <TableCell>{ (categories || []).find((t) => t.id === row.categoryId)?.name }</TableCell>

                                            <TableCell>{ row.piecePrice }</TableCell>
                                            <TableCell>{ row.pieceQuantity }</TableCell>
                                            <TableCell>{ row.pieceStep }</TableCell>

                                            <TableCell>{ row.cbmPrice }</TableCell>
                                            <TableCell>{ row.cbmQuantity }</TableCell>
                                            <TableCell>{ row.cbmStep }</TableCell>

                                            <TableCell>{ row.pieceToCbmConversionFactor }</TableCell>

                                            <TableCell>
                                                { moment(row.updatedAt).format("DD.MM.YYYY") }<br/>
                                                { moment(row.updatedAt).format("HH:mm:ss") }
                                            </TableCell>
                                            <TableCell>
                                                { moment(row.createdAt).format("DD.MM.YYYY") }<br/>
                                                { moment(row.createdAt).format("HH:mm:ss") }
                                            </TableCell>

                                            <TableCell>
                                                <Grid container spacing={1} justifyContent="flex-end" wrap="nowrap">
                                                    <Grid item>
                                                        <Tooltip title="Редактировать" arrow>
                                                            <IconButton onClick={() => onEditProduct(row)}>
                                                                <EditIcon color="primary"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title="Удалить" arrow>
                                                            <IconButton onClick={() => onDeleteProduct(row)}>
                                                                <DeleteIcon color="error"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>

                                    ))

                                }

                            </>

                        )

                    }

                </TableBody>

            </Table>

        </>
    )
}

const useStyles = makeStyles((theme) => ({

    buttonAction: {}

}));

export default TableCustom
