import React from 'react';
import {
    Box,
    Button,

    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,

    TextField,
    Typography
} from "@mui/material";
import {
    Formik
} from "formik";
import {
    IMaskInput
} from "react-imask";
import * as Yup from "yup";

const DialogCreateBankAccount = (props) => {
    const {
        isOpen,
        onClose,
        onCreate,
    } = props;

    const refFormik = React.useRef(null);
    const [initialValues, setInitialValues] = React.useState({
        bankName: "",
        account: "",
        bic: "",
        corAccount: "",
    });

    React.useEffect(() => {
        if (isOpen) {
            setInitialValues({
                bankName: "",
                account: "",
                bic: "",
                corAccount: "",
            });
        }
    }, [isOpen]);

    const onSubmit = (form) => {
        onCreate(form);
        handleCloseModal();
    };

    const handleChange = ({target}) => {
        const {name, value} = target;
        const newForm = refFormik.current.values;

        newForm[name] = value;

        refFormik.current.setValues(newForm);
    };

    const handleCloseModal = () => {
        onClose();
    };

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth='md'
            onClose={handleCloseModal}
        >
            <DialogTitle>
                <Typography variant="h3">
                    Добавить банковские реквизиты
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box pt={1}>
                                    <Box mb={2}>
                                        <TextField
                                            fullWidth
                                            name="bankName"
                                            label="Название"
                                            placeholder="Введите название банка"
                                            value={values.bankName}
                                            onChange={handleChange}
                                            error={
                                                touched.bankName &&
                                                Boolean(errors.bankName)
                                            }
                                            helperText={
                                                touched.bankName &&
                                                errors.bankName
                                            }
                                        />
                                    </Box>
                                    <Box mb={2}>
                                        <TextField
                                            fullWidth
                                            name="account"
                                            label="Расчетный счет"
                                            placeholder="Введите расчетный счет"
                                            value={values.account}
                                            onChange={handleChange}
                                            InputProps={{
                                                inputComponent: CustomInputAmount
                                            }}
                                            error={
                                                touched.account &&
                                                Boolean(errors.account)
                                            }
                                            helperText={
                                                touched.account &&
                                                errors.account
                                            }
                                        />
                                    </Box>
                                    <Box mb={2}>
                                        <TextField
                                            fullWidth
                                            name="bic"
                                            label="БИК"
                                            placeholder="Введите БИК"
                                            value={values.bic}
                                            onChange={handleChange}
                                            InputProps={{
                                                inputComponent: CustomInputAmount
                                            }}
                                            error={
                                                touched.bic &&
                                                Boolean(errors.bic)
                                            }
                                            helperText={
                                                touched.bic && errors.bic
                                            }
                                        />
                                    </Box>
                                    <Box mb={2}>
                                        <TextField
                                            fullWidth
                                            name="corAccount"
                                            label="Корреспондентский счет"
                                            placeholder="Введите корреспондентский счет"
                                            value={values.corAccount}
                                            onChange={handleChange}
                                            InputProps={{
                                                inputComponent: CustomInputAmount
                                            }}
                                            error={
                                                touched.corAccount &&
                                                Boolean(errors.corAccount)
                                            }
                                            helperText={
                                                touched.corAccount &&
                                                errors.corAccount
                                            }
                                        />
                                    </Box>
                                </Box>

                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        onClick={handleCloseModal}
                                        sx={{
                                            textTransform: "initial",
                                            borderRadius: "4px",
                                            padding: "4px 24px",
                                        }}
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleSubmit}
                                        sx={{
                                            textTransform: "initial",
                                            borderRadius: "4px",
                                            padding: "4px 24px",
                                        }}
                                    >
                                        Добавить
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const CustomInputAmount = ({inputRef, ...rest}) => (
    <IMaskInput
        ref={inputRef}
        {...rest}

        mask={/^[0-9]*$/}
        thousandsSeparator=""
        radix="."
        mapToRadix={[',']}
        scale={rest?.scale || 0}
        unmask={true}
        padFractionalZeros={true}
    />
);

const validationSchema = Yup.object().shape({
    bankName: Yup.string().required("Обязательное поле"),
    account: Yup.string().required("Обязательное поле").min(20, "Минимальная длина 20 символов").max(20, "Максимальная длина 20 символов"),
    bic: Yup.string().required("Обязательное поле").min(9, "Минимальная длина 9 символов").max(9, "Максимальная длина 9 символов"),
    corAccount: Yup.string().required("Обязательное поле").min(20, "Минимальная длина 20 символов").max(20, "Максимальная длина 20 символов"),
});

export default React.memo(DialogCreateBankAccount);