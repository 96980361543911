import React, {Component} from "react";
import {
    Alert,
    Backdrop,
    CircularProgress,
    Container,
    Fade,
    Snackbar,
    Typography
} from "@mui/material";
import {
    AddOrganizationModal as AddOrganizationModalCompoonent,
    Filter as FilterComponent,
    Table as TableComponent,
    DialogExecutingOrders as DialogExecutingOrdersComponent
} from "./components";
import {
    DialogConfirmAction
} from "../../../../components";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import queryString from "query-string";
import agent from "../../../../agent/agent";

const initialFilter = {
    sortInfo: "",
};

const visibleColumns = [
    {
        id: "id",
        label: "ID",
        sortable: true,
    },
    {
        id: "type",
        label: "Тип",
        sortable: true,
    },
    {
        id: "name",
        label: "Название",
        sortable: false,
    },
    {
        id: "inn",
        label: "ИНН",
        sortable: false,
    },
    {
        id: "kpp",
        label: "КПП",
        sortable: false,
    },
    {
        id: "ogrn",
        label: "ОГРН / ОГРН ИП",
        sortable: false,
    },
    {
        id: "email",
        label: "Почта",
        sortable: false,
    },
    {
        id: "phone",
        label: "Телефон",
        sortable: false,
        width: "100%",
    },
    {
        id: "address",
        label: "Адрес",
        sortable: false,
    },
    {
        id: "ceoName",
        label: "Директор",
        sortable: false,
    },
    {
        id: 'deleteAction',
        label: "",
        sortable: false,
    }
];

class MyOrganizations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            organizations: [],
            executingOrders: [],

            filter: {
                ...initialFilter,
            },
            pagination: {
                page: 1,
                totalPage: 1,
            },

            isOpenModal: false,
            isOpenExecutingOrders: false,
            isOpenAlert: false,

            isLoading: true,
            isShowBackdrop: false,
            alertMessage: "",
        };

        this.refPopUpDetailProduct = React.createRef();
        this.refDialogConfirmation = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getOrganizations();
    };

    closeAlert = async () => {
        await this.setState({isOpenAlert: false});
    };

    openAlert = async (err) => {
        await this.setState({
            isOpenAlert: true,
            alertMessage:
                typeof err === "string" || Array.isArray(err)
                    ? err
                    : "Произошла ошибка при отправке формы",
        });
    };

    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket",
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch,
        };
        let pagination = {
            ...this.state.pagination,
            page: page,
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0,
        });
    };

    // Логика получения организаций
    getOrganizations = async () => {
        this.setState({isLoading: true});

        const filter = this.getFilters();
        const {organizations, totalCount} = await agent
            .get(`/organizations${filter}&limit=20&listing=true`)
            .then((res) => {
                return {
                    organizations: res.data.organizations || [],
                    totalCount: res.data.count,
                };
            })
            .catch((err) => {
                return {
                    organizations: [],
                    totalCount: 0,
                };
            });

        let pagination = {...this.state.pagination};
        pagination.totalPage = Math.ceil(totalCount / 20) || 1;

        this.setState({
            isLoading: false,
            pagination,
            organizations,
        });
    };

    // Удаление организации
    deleteOrganization = async (id, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.open({
                message: "Вы действительно хотите удалить организацию?",
                labelSuccess: "Да, удалить",
                onSuccess: this.deleteOrganization.bind(this, id, true),
            })

            return null;
        }

        this.setState({isShowBackdrop: true,});

        const response = await agent.delete(`/organizations/${id}`)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({
                isShowBackdrop: false,
            });

            Notification({
                message: response.error.data.message || "Ошибка сервера",
                type: NotificationTypes.error,
            });

            return null;
        }

        if (!response.status) {
            const executingOrders = response.orders.map(order => order.id);
            this.setState({
                executingOrders,
                isOpenExecutingOrders: true,
                isShowBackdrop: false,
            });

            const message = `Организацию нельзя удалить, на складе выполняются заказы`;

            Notification({
                message: message,
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrganizations();
        this.setState({
            isShowBackdrop: false,
        });
        Notification({
            message: "Организация успешно удалена",
            type: NotificationTypes.success,
        })
    };

    // Логика создания организации
    createOrganization = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.open({
                message: "Вы действительно хотите добавить организацию?",
                labelSuccess: "Да, добавить",
                onSuccess: this.createOrganization.bind(this, form, true),
            });

            return null;
        }

        this.setState({
            isShowBackdrop: true,
        })

        const body = {
            ...form,
            phone: (form.phone || "").replace(/\D+/g, ""),
            ownerId: form.ownerUserId,
        };

        const response = await agent.post(`/organizations`, body)
            .then((res) => res.data)
            .catch((err) => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({
                isShowBackdrop: false,
            });

            Notification({
                message: response.error?.data?.message || "Ошибка при создании организации",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrganizations();

        this.setState({
            isShowBackdrop: false,
        });

        Notification({
            message: "Организация успешно добавлена",
            type: NotificationTypes.success,
        });
    };

    setIsOpenModal = async (isOpen) => {
        await this.setState({isOpenModal: isOpen});
    };

    handleClose = () => {
        this.setIsOpenModal(false);
    };

    // Логика работы с фильтром
    getFilters = () => {
        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};

        let string = [`page=${pagination.page}`];
        Object.keys(filter).map((key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push(`${key}=${value}`);
            }
        });

        window.history.replaceState(
            null,
            null,
            `/organizations?${string.join("&")}`
        );

        return `?${string.join("&")}`;
    };
    changeFilter = async (filter, isFastStart) => {
        await this.setState({filter});

        if (!isFastStart) {
            return null;
        }

        await this.getOrganizations();
    };
    changePagination = async (pagination) => {
        await this.setState({pagination});
        await this.getOrganizations();
    };
    resetFilter = async () => {
        await this.setState({filter: {...initialFilter}});
        await this.getUsers();
    };

    _routeOrganization = (order) => {
        this.props.history.push(`/organizations/${order.id}`);
    };


    // Открытие модалки с уведомлением о выполняющихся заказов
    setIsOpenExecutingOrders = (isOpen) => {
        this.setState({isOpenExecutingOrders: isOpen});
    };
    // Закрытие модалки с уведомлением о выполняющихся заказов
    handleCloseExecutingOrders = () => {
        this.setIsOpenExecutingOrders(false);
    };

    render() {
        const {
            organizations,
            executingOrders,
            filter,
            pagination,

            isOpenModal,
            isOpenAlert,
            isOpenExecutingOrders,

            isShowBackdrop,
            isLoading,
            alertMessage,
        } = this.state;

        return (
            <>
                <Container maxWidth="xl">
                    <FilterComponent
                        openModal={() => this.setIsOpenModal(true)}
                        filter={filter}
                        onChangeFilter={this.changeFilter}
                        onSearch={this.getOrganizations}
                    />
                    <TableComponent
                        visibleColumns={visibleColumns}
                        isLoading={isLoading}
                        data={organizations}
                        filter={filter}
                        pagination={pagination}
                        onChangePagination={this.changePagination}
                        onOpenDetailProducts={this.openDetailProducts}
                        routeOrganization={this._routeOrganization}
                        onChangeFilter={this.changeFilter}
                        onDeleteOrganization={this.deleteOrganization}
                    />
                    <AddOrganizationModalCompoonent
                        createOrChangeOrganization={this.createOrganization}
                        handleClose={this.handleClose}
                        isOpen={isOpenModal}
                    />
                    <Snackbar
                        TransitionComponent={Fade}
                        open={isOpenAlert}
                        autoHideDuration={6000}
                        onClose={this.closeAlert}
                    >
                        <Alert
                            onClose={this.closeAlert}
                            severity="error"
                            sx={{width: "100%"}}
                        >
                            {Array.isArray(alertMessage)
                                ? alertMessage.map((el) => (
                                    <Typography>{el}</Typography>
                                ))
                                : alertMessage}
                        </Alert>
                    </Snackbar>
                </Container>

                <DialogExecutingOrdersComponent
                    executingOrders={executingOrders}
                    isOpen={isOpenExecutingOrders}
                    onClose={this.handleCloseExecutingOrders}
                />

                <DialogConfirmAction
                    ref={this.refDialogConfirmation}
                />

                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </>
        );
    }
}

export default MyOrganizations;
