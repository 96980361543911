import React, {Component} from "react";
import {
    Backdrop,
    Box,
    CircularProgress,
    Container,
    Grid,
    Typography,
} from "@mui/material";
import {
    DialogConfirmAction, DialogConfirmActionWithCode
} from "../../../../components";
import {
    Filter as FilterComponent,
    InfoMessage as InfoMessageComponent,
    Information as InformationComponent,
    DialogFormAddEmployee as DialogFormAddEmployeeComponent,
    DialogEditOrganization as DialogEditOrganizationComponent,
    TableEmployees as TableEmployeesComponent,
    DialogCreateBankAccount as DialogCreateBankAccountComponent,
    DialogEditBankAccount as DialogEditBankAccountComponent,
} from "./components";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";

class Organization extends Component {
    constructor(props) {
        super(props);

        this.state = {
            organization: {},
            employees: [],

            organizationId: props?.match?.params?.id || null,

            isOpenAddEmployee: false,
            isOpenCreateBankAccount: false,
            isOpenEditBankAccount: false,
            isOpenEditOrganization: false,

            isLoading: true,
            isNotFound: false,
            alertMessage: "",

            isShowBackdrop: false,
        };

        this.refDialogConfirmAction = React.createRef();
        this.refDialogConfirmActionWithCode = React.createRef();
    }

    componentDidMount = async () => {
        if (!this.state.organizationId) {
            this.props.history.push(`/organizations`);
            return;
        }
        await this.getEmployees();
        await this.getOrganization();
    };

    // Логика получения организации
    getOrganization = async () => {
        const organizationId = this.state.organizationId;

        const organization = await agent
            .get(`/organizations/${organizationId}`)
            .then((res) => {
                return res.data?.organization || null;
            })
            .catch(() => {
                return null;
            });
        if (!organization) {
            this.setState({
                isNotFound: true,
                isLoading: false,
            });

            return null;
        }

        this.setState({
            isLoading: false,
            organization,
        });
    };

    // Логика редактирования организации
    editOrganization = async (form, isConfirm, isConfirmWithCode) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: `Подтверждение`,
                message: `Вы действительно отредактировать организацию?`,
                labelSuccess: "Да, редактировать",
                onSuccess: this.editOrganization.bind(this, form, true, false),
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        if (!isConfirmWithCode) {
            await this.refDialogConfirmActionWithCode.current.open({
                onSuccess: this.editOrganization.bind(this, form, true, true),
            });

            this.setState({isShowBackdrop: false});

            return null;
        }

        this.setState({
            isShowBackdrop: true,
        });

        const response = await agent.put(`/organizations/${ this.state.organizationId }`, {
            ...form,
            phone: (form?.phone || "").replace(/\D+/g, ""),
        })
            .then(res => res.data)
            .catch(err => {
                return { error: err.response }
            });

        if (response.error) {
            this.setState({
                isShowBackdrop: false,
            });

            Notification({
                message: response.error?.data?.message || "Ошибка при редактировании организации",
                type: NotificationTypes.error,
            });

            return null;
        }

        this.setState({
            isShowBackdrop: false,
        });

        await this.getOrganization();

        Notification({
            message: "Организация успешно отредактирована",
            type: NotificationTypes.success,
        });
    };

    //Логика получения участников организации
    getEmployees = async () => {
        const response = await agent.get(`/organizations/${this.state.organizationId}/employee`)
            .then(res => res.data.users)
            .catch(err => []);

        this.setState({
            employees: response,
        });
    };

    // // Логика создания банковского аккаунта
    // createBankAccount = (onSuccess) => async (values) => {
    //     const organizationId = this.state.organizationId;
    //
    //     await agent
    //         .post(`/organizations/${organizationId}/bank-accounts`, values)
    //         .then((res) => {
    //             onSuccess();
    //         })
    //         .catch((err) => {
    //             this.openAlert(
    //                 (err.response?.data?.message && formatErrors(err.response?.data?.message)) ||
    //                 err.message ||
    //                 err
    //             );
    //         });
    // };

    // Логика добавления банковских реквизитов
    createBankAccount = async (form, isConfirm, isConfirmWithCode) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: `Подтверждение`,
                message: `Вы действительно добавить банковские реквизиты?`,
                labelSuccess: "Да, добавить",
                onSuccess: this.createBankAccount.bind(this, form, true, false),
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        if (!isConfirmWithCode) {
            await this.refDialogConfirmActionWithCode.current.open({
                onSuccess: this.createBankAccount.bind(this, form, true, true),
            });

            this.setState({isShowBackdrop: false});

            return null;
        }

        this.setState({
            isShowBackdrop: true,
        });

        const response = await agent.post(`/organizations/${this.state.organizationId}/bank-accounts`, form)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при добавлении банковских реквизитов",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrganization();
        await this.getEmployees();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Банковские реквизиты успешно добавлены",
            type: NotificationTypes.success,
        });
    };
    // Логика изменения банковского реквизита
    editBankAccount = async (form, isConfirm, isConfirmWithCode) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: `Подтверждение`,
                message: `Вы действительно изменить банковские реквизиты?`,
                labelSuccess: "Да, изменить",
                onSuccess: this.editBankAccount.bind(this, form, true, false),
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        if (!isConfirmWithCode) {
            await this.refDialogConfirmActionWithCode.current.open({
                onSuccess: this.editBankAccount.bind(this, form, true, true),
            });

            this.setState({isShowBackdrop: false});

            return null;
        }

        this.setState({
            isShowBackdrop: true,
        });

        const response = await agent.put(`/organizations/${this.state.organizationId}/bank-accounts?id=${this.state.organization.bankAccount.id}`, form)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при редактировании банковских реквизитов",
                type: NotificationTypes.error,
            });

            return null;
        }

        this.setState({isShowBackdrop: false});

        await this.getOrganization();

        Notification({
            message: "Банковские реквизиты успешно изменены",
            type: NotificationTypes.success,
        });
    };

    // Логика добавления участников в организацию
    addEmployeeToOrganization = async (userId, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: `Подтверждение`,
                message: `Вы действительно хотите добавить участника?`,
                labelSuccess: "Да, добавить",
                onSuccess: this.addEmployeeToOrganization.bind(this, userId, true),
            });

            return null;
        }

        this.setState({
            isShowBackdrop: true,
        })

        const response = await agent.post(`/organizations/${this.state.organizationId}/add-employee`, {
            userId,
        })
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({
                isShowBackdrop: false,
            });

            Notification({
                message: response.error?.data?.message || "Ошибка при добавлении участника",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getEmployees();
        await this.getOrganization();

        this.setState({isShowBackdrop: false});
    };
    // Логика удаления участника организации
    deleteEmployee = async (userId, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: `Подтверждение`,
                message: `Вы действительно хотите удалить участника?`,
                labelSuccess: "Да, удалить",
                onSuccess: this.deleteEmployee.bind(this, userId, true),
            });

            return null;
        }

        this.setState({
            isShowBackdrop: true,
        })

        const response = await agent.delete(`/organizations/${this.state.organizationId}/remove-employee`, {
            data: {
                userId,
            }
        })
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({
                isShowBackdrop: false,
            });

            Notification({
                message: response.error?.data?.message || "Ошибка при удалении участника",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getEmployees();
        await this.getOrganization();

        this.setState({
            isShowBackdrop: false,
        });

        Notification({
            message: "Пользователь успешно удален из списка участников",
            type: NotificationTypes.success,
        });
    };

    // Открытие формы добавления участника
    setIsOpenAddEmployee = (isOpen) => {
        this.setState({
            isOpenAddEmployee: isOpen,
        });
    };
    // Закрытие формы добавления участника
    handleCloseAddEmployee = () => {
        this.setIsOpenAddEmployee(false);
    };

    // Открытие формы редактирования организации
    setIsOpenEditOrganization = (isOpen) => {
        this.setState({
            isOpenEditOrganization: isOpen,
        })
    };
    // Закрытие формы редактирования организации
    handleCloseEditOrganization = () => {
        this.setIsOpenEditOrganization(false);
    };

    // Открытие формы добавления реквизитов
    setIsOpenCreateBankAccount = (isOpen) => {
        this.setState({
            isOpenCreateBankAccount: isOpen,
        });
    };
    // Закрытие формы добавления реквизитов
    handleCloseCreateBankAccount = () => {
        this.setIsOpenCreateBankAccount(false);
    };

    // Открытие формы редактирование реквизитов
    setIsOpenEditBankAccount = (isOpen) => {
        this.setState({
            isOpenEditBankAccount: isOpen,
        });
    };
    // Закрытие формы редактирования реквизитов
    handleCloseEditBankAccount = () => {
        this.setIsOpenEditBankAccount(false);
    };

    render() {
        const {
            activeTab,
            organization,
            employees,

            organizationId,

            isOpenAddEmployee,
            isOpenEditOrganization,
            isOpenCreateBankAccount,
            isOpenEditBankAccount,

            isLoading,
            isNotFound,

            isShowBackdrop,
        } = this.state;

        return (
            <>
                <Container maxWidth="xl">
                    <Box mb={2}>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="flex-end"
                        >
                            <Grid item>
                                <Grid container alignItems="flex-end" spacing={1}>
                                    <Grid item>
                                        <Typography variant="h1" textAlign="left">
                                            Организация № {organizationId}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                    {Boolean(!isLoading && !isNotFound) && (
                        <>
                            <Box mb={2}>
                                <InformationComponent
                                    organization={organization}
                                    setIsOpenEditOrganization={this.setIsOpenEditOrganization}
                                    setIsOpenCreateBankAccount={this.setIsOpenCreateBankAccount}
                                    setIsOpenEditBankAccount={this.setIsOpenEditBankAccount}
                                />
                            </Box>
                            <FilterComponent
                                activeTab={activeTab}
                                openModal={() => this.setIsOpenModal(true)}
                                setIsOpenAddEmployee={this.setIsOpenAddEmployee}
                            />
                            <Box mb={2}>
                                <Typography variant="h3" mb={2}>Участники</Typography>
                                <TableEmployeesComponent
                                    employees={employees}
                                    onDeleteEmployee={this.deleteEmployee}
                                />
                            </Box>
                        </>
                    )}

                    {Boolean(isLoading) && (
                        <InfoMessageComponent
                            message="Загружаем организацию"
                            type="loading"
                        />
                    )}

                    {Boolean(isNotFound) && (
                        <InfoMessageComponent
                            message="Организация не найдена"
                            type="warn"
                        />
                    )}

                    <DialogCreateBankAccountComponent
                        isOpen={isOpenCreateBankAccount}
                        onClose={this.handleCloseCreateBankAccount}
                        onCreate={this.createBankAccount}
                    />

                    {organization.bankAccount && (
                        <DialogEditBankAccountComponent
                            bankAccount={organization.bankAccount}
                            isOpen={isOpenEditBankAccount}
                            onClose={this.handleCloseEditBankAccount}
                            onEdit={this.editBankAccount}
                        />
                    )}

                    <DialogEditOrganizationComponent
                        organization={organization}
                        isOpen={isOpenEditOrganization}
                        onClose={this.handleCloseEditOrganization}
                        onEdit={this.editOrganization}
                    />

                    <DialogFormAddEmployeeComponent
                        isOpen={isOpenAddEmployee}
                        onClose={this.handleCloseAddEmployee}
                        onAddEmployee={this.addEmployeeToOrganization}
                    />

                    <DialogConfirmAction ref={this.refDialogConfirmAction}/>

                    <DialogConfirmActionWithCode ref={this.refDialogConfirmActionWithCode}/>
                </Container>
                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </>
        );
    }
}

export default Organization;
