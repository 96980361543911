import { RouteWithLayout } from "../components";
import { Default as DefaultLayout } from "../layouts";
import {
    Accreditation as AccreditationPage,
    Categories as CategoriesPage,
    Order as OrderPage,
    Orders as OrdersPage,
    Organization as OrganizationPage,
    Organizations as OrganizationsPage,
    Products as ProductsPage,
    Store as StorePage,
    Stores as StoresPage,
    Users as UsersPage,
    Settings as SettingsPage,
    Wallet as WalletPage,
    RegistryPhysical as RegistryPhysicalPage,
    RegistryLegal as RegistryLegalPage,
    Invoices as InvoicesPage,
    Wallets as WalletsPage
} from "../pages";
import React from "react";
import { Redirect, Switch } from "react-router-dom";

const pages = [
    {
        path: "/users",
        component: UsersPage,
        layout: DefaultLayout,
        rules: ["admin"],
        exact: true,
    },
    {
        path: "/products",
        component: ProductsPage,
        layout: DefaultLayout,
        rules: ["admin"],
        exact: true,
    },
    {
        path: "/categories",
        component: CategoriesPage,
        layout: DefaultLayout,
        rules: ["admin"],
        exact: true,
    },
    {
        path: "/orders",
        component: OrdersPage,
        layout: DefaultLayout,
        rules: ["admin"],
        exact: true,
    },
    {
        path: "/orders/:id",
        component: OrderPage,
        layout: DefaultLayout,
        rules: ["admin"],
        exact: true,
    },
    {
        path: "/accreditation",
        component: AccreditationPage,
        layout: DefaultLayout,
        rules: ["admin"],
        exact: true,
    },
    {
        path: "/organizations",
        component: OrganizationsPage,
        layout: DefaultLayout,
        rules: ["admin"],
        exact: true,
    },
    {
        path: "/organizations/:id",
        component: OrganizationPage,
        layout: DefaultLayout,
        rules: ["admin"],
        exact: true,
    },
    {
        path: "/stores",
        component: StoresPage,
        layout: DefaultLayout,
        rules: ["admin"],
        exact: true,
    },
    {
        path: "/stores/:id",
        component: StorePage,
        layout: DefaultLayout,
        rules: ["admin"],
        exact: true,
    },
    {
        path: "/settings",
        component: SettingsPage,
        layout: DefaultLayout,
        rules: ["admin"],
        exact: true,
    },
    {
        path: "/wallet",
        component: WalletPage,
        layout: DefaultLayout,
        rules: ["admin"],
        exact: true,
    },
    {
        path: "/registry/withdrawal/physical",
        component: RegistryPhysicalPage,
        layout: DefaultLayout,
        rules: ["admin"],
        exact: true,
    },
    {
        path: "/registry/withdrawal/legal",
        component: RegistryLegalPage,
        layout: DefaultLayout,
        rules: ["admin"],
        exact: true,
    },
    {
        path: "/invoices",
        component: InvoicesPage,
        layout: DefaultLayout,
        rules: ["admin"],
        exact: true,
    },
    {
        path: "/wallets",
        component: WalletsPage,
        layout: DefaultLayout,
        rules: ["admin"],
        exact: true,
    },
];

const MainRoutes = () => {
    return (
        <Switch>
            {pages.map((page, idx) => (
                <RouteWithLayout key={"page-" + idx} {...page} />
            ))}

            <Redirect to="/orders" />
        </Switch>
    );
};

export default MainRoutes;
