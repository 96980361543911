import React from 'react';
import {
    Box,
    Button,

    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,

    IconButton,

    Step,
    StepContent,
    StepLabel,
    Stepper,

    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,

    Tooltip,
    Typography
} from "@mui/material";
import {
    LoadingButton
} from "@mui/lab";
import {
    AttachFileOutlined as AttachFileIcon,
    Delete as DeleteIcon,
    Upload as UploadIcon
} from "@mui/icons-material";
import {
    numberFormat
} from "../../../../../../common/Formater";
import {
    convertorToPiece, getBase64
} from "../../../../../../helper/convertor";
import {
    Formik
} from "formik";
import {
    Notification,
    NotificationTypes
} from "../../../../../../common/Notification";
import {makeStyles} from "@mui/styles";

const DialogFullReturnFill = (props) => {
    const {
        order,
        isOpen,
        onClose,
        onReturnFill
    } = props;

    const classes = useStyles();
    const [isLoadingImage, setIsLoadingImage] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const refFormik = React.useRef(null);
    const [initialValues, setInitialValues] = React.useState({
        photo: [],
        orderItems: order.orderItems,
    });

    React.useEffect(() => {
        if (isOpen) {
            setIsLoadingImage(false);
            setActiveStep(0);
            setInitialValues({
                photo: [],
                orderItems: order.orderItems,
            });
        }
    }, [isOpen]);

    const onSubmit = async (form) => {
        const returnItems = form.orderItems.filter(item => item.quantity > 0);

        if (returnItems.length > 0) {
            if (form.photo.length > 0) {
                let photosBase64 = [];
                for await (let photo of form.photo) {
                    const photoBase64 = await getBase64(photo.file).then(res => res);
                    photosBase64.push(photoBase64);
                }
                const newForm = {
                    productsPhotosInBase64: photosBase64,
                    returnItems: returnItems.map(item => {
                        return {
                            orderItemId: item.id,
                            quantity: item.quantity,
                        }
                    }),
                };
                onReturnFill(newForm);
                onClose();
            } else {
                Notification({
                    type: NotificationTypes.error,
                    message: "Приложите фотографии товаров",
                });
            }
        } else {
            Notification({
                type: NotificationTypes.error,
                message: "Введите количество возвращаемого товара",
            });
        }
    };

    const handleCloseModal = () => {
        onClose();
    }

    const handleChangeImage = (event) => {
        setIsLoadingImage(true);
        const file = event?.target.files?.[0] || null;

        let newForm = refFormik.current?.values || {};
        let image_id = {
            file: '',
            source: '',
        };
        image_id.file = file;
        image_id.source = URL.createObjectURL(file);
        newForm.photo = [...newForm.photo, image_id];
        refFormik.current.setValues(newForm);
        setIsLoadingImage(false);
    };
    const handleDeleteImage = (index) => {
        const newForm = refFormik.current.values;
        newForm.photo.splice(index, 1);

        refFormik.current.setValues(newForm);
    };

    const renderQuantity = (orderItem) => {
        const piece = convertorToPiece(orderItem.quantity, orderItem?.product?.pieceToCbmConversionFactor || 0);
        return (
            <>
                <Typography>{piece} шт.</Typography>
                <Typography>{numberFormat(orderItem.quantity, 4)} м³</Typography>
            </>
        );
    };

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth="md"
            onClose={handleCloseModal}
        >
            <DialogTitle>
                <Typography variant="h3">Полный возврат</Typography>
            </DialogTitle>

            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    innerRef={refFormik}

                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            handleSubmit
                        } = props;
                        return (
                            <>
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    <Step>
                                        <StepLabel>
                                            Выберите товары которые подлежат возврату
                                        </StepLabel>
                                        <StepContent>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell width="40%">Наименование</TableCell>
                                                        <TableCell>Кол-во</TableCell>
                                                        <TableCell>Кол-во к возврату</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {values.orderItems.map((val, index) => (
                                                        <TableRow key={`full-return-fill-item-${val.id}`}>
                                                            <TableCell>{val.productName}</TableCell>
                                                            <TableCell>{renderQuantity(val)}</TableCell>
                                                            <TableCell>
                                                                {renderQuantity(val)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </StepContent>
                                    </Step>
                                    <Step>
                                        <StepLabel>
                                            Приложите фотографию товаров
                                        </StepLabel>
                                        <StepContent>
                                            {values.photo.length > 0 &&
                                                <>
                                                    {values.photo.map((photo, index) => (
                                                        <Box
                                                            key={`full-return-fill-image-${photo?.file?.name || 'fileName'}-${index}`}
                                                        >
                                                            <ReturnFillImage
                                                                imageId={photo}
                                                                indexPhoto={index}
                                                                viewImage
                                                            />
                                                            <ReturnFillImage
                                                                imageId={photo}
                                                                indexPhoto={index}
                                                                viewFile
                                                                handleDeleteImage={handleDeleteImage}
                                                            />
                                                        </Box>
                                                    ))}
                                                </>
                                            }

                                            <LoadingButton
                                                className={classes.uploadButton}
                                                loading={isLoadingImage}
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                            >
                                                <label style={{
                                                    width: "100%",
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}>
                                                    <UploadIcon sx={{marginRight: "5px"}}/>
                                                    Выберите файл
                                                    <input
                                                        width="100%"
                                                        type="file"
                                                        name="image_id"
                                                        accept="image/*"
                                                        hidden
                                                        onChange={(event) => handleChangeImage(event)}
                                                    />
                                                </label>
                                            </LoadingButton>
                                        </StepContent>
                                    </Step>
                                </Stepper>

                                <DialogActions>
                                    {activeStep === 1 && (
                                        <Button
                                            variant="outlined"
                                            onClick={() => setActiveStep(prev => prev - 1)}
                                        >
                                            Назад
                                        </Button>
                                    )}
                                    <Button variant="outlined" onClick={handleCloseModal}>Отменить</Button>
                                    {activeStep === 0
                                        ? (
                                            <Button
                                                variant="contained"
                                                onClick={() => setActiveStep(prev => prev + 1)}
                                            >
                                                Дальше
                                            </Button>
                                        )
                                        : (
                                            <Button variant="contained"
                                                    onClick={handleSubmit}>Возврат</Button>
                                        )
                                    }
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const ReturnFillImage = React.memo((props) => {
    const {
        imageId,
        indexPhoto,
        viewImage,
        viewFile,

        handleDeleteImage
    } = props;

    return (<>
        {Boolean(viewImage)
            && (
                <Box
                    width="100%"
                    height={500}
                    sx={{
                        backgroundColor: "rgba(132,185,44, .2)",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 10,
                        boxSizing: "border-box",
                    }}
                    mb={2}
                >
                    <img src={imageId.source} style={{
                        display: "block", width: "100%", height: "100%", objectFit: "contain", borderRadius: 5,
                    }} alt=""/>
                </Box>
            )
        }
        {Boolean(viewFile)
            && (
                <Box display="flex" mb={2}>
                    <Box
                        sx={{
                            flex: 8,
                            display: "flex",
                            alignItems: "center",
                            padding: "5px",
                            background: "#84b92c",
                            borderRadius: "4px",
                            color: "#fff",
                            fontSize: "14px",
                            justifyContent: "center",
                            marginRight: "5px"
                        }}
                    >
                        <AttachFileIcon
                            sx={{
                                marginRight: "5px",
                            }}
                        />
                        {imageId?.file?.name}
                    </Box>
                    <Tooltip title="Удалить изображение">
                        <IconButton
                            color="error"

                            onClick={() => handleDeleteImage(indexPhoto)}
                        >
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                </Box>
            )
        }
    </>);
});

const useStyles = makeStyles({
    uploadButton: {
        '&.MuiButton-root': {
            padding: 0,

            "& > label": {
                padding: 0,
                height: "100%",
            },
        },
    },
});

export default DialogFullReturnFill;