import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import { AutocompleteCategory } from "../../../../../../components";
import { Formik } from "formik";
import * as Yup from "yup";


const initForm = {
    name: "",
    categoryId: "",

    piecePrice: null,
    pieceQuantity: null,

    cbmPrice: null,
    cbmQuantity: null,
    cbmStep: null,

    pieceToCbmConversionFactor: null,

    hidden: false,
};

class DialogFormProduct extends React.PureComponent {
    constructor(props) {
        super( props );

        this.state = {
            initUserName: "",

            isOpen: false,
            isCreated: false,


            onEvent: null
        };

        this.refFormik = React.createRef();
    }


    open = ({ form, event }) => {

        this.setState( {
            isOpen: true,
            isCreated: Boolean( Object.keys( form ).length <= 0 ),
            initUserName: form.name,
            onEvent: event
        }, () => {
            setTimeout( () => {
                this.refFormik.current.setValues(
                    Boolean( Object.keys( form ).length <= 0 ) ? { ...initForm } : form
                );
            }, 100 );
        } );

    }
    close = () => {
        this.refFormik.current.setValues( {} );
        this.setState( {
            isOpen: false
        } )
    }

    // Обработчик формы
    onSubmit = (form) => {
        this.state.onEvent( form, true );
    }

    // Изменение формы
    changeForm = ({ target }) => {
        const {
            value,
            name
        } = target;

        let newValues = this.refFormik.current.values;
        newValues[name] = value;

        this.refFormik.current.setValues( newValues );
    }

    changeBooleanForm = ({ target }, additionalValue) => {
        const { checked, name } = target;

        let newValues = this.refFormik.current.values;
        newValues[name] = checked || additionalValue;

        this.refFormik.current.setValues( newValues );
    }

    // Клик по кнопке в управлении
    clickCreate = () => {
        this.refFormik.current.handleSubmit();
    }

    render() {
        const {
            initUserName,

            isOpen,
            isCreated
        } = this.state;

        return (
            <>

                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={ isOpen }

                    onClose={ this.close }
                >

                    <DialogTitle>
                        <Typography variant="h3">
                            { Boolean( isCreated ) ? "Создание товара" : `Редактирование товара: "${ initUserName }"` }
                        </Typography>
                    </DialogTitle>

                    <DialogContent>
                        <Formik
                            innerRef={ this.refFormik }
                            initialValues={ {} }
                            validationSchema={ validationSchema }
                            onSubmit={ this.onSubmit }
                        >
                            { (props) => {
                                const {
                                    values,
                                    errors,
                                    touched
                                } = props;

                                if (Boolean( !isCreated && Object.keys( values || {} ).length <= 0 )) {
                                    return null
                                }

                                return (
                                    <Box pt={ 1 }>

                                        <Box mb={ 2 }>
                                            <TextField
                                                value={ values.name }
                                                error={ Boolean( touched.name && errors.name ) }
                                                helperText={ touched.name && errors.name }
                                                name="name"
                                                label="Наименование товара"
                                                placeholder="Введите наименование товара"
                                                variant="outlined"
                                                fullWidth

                                                onChange={ this.changeForm }
                                            />
                                        </Box>
                                        <Box mb={ 2 }>
                                            <AutocompleteCategory
                                                value={ values.categoryId }
                                                error={ Boolean( touched.categoryId && errors.categoryId ) }
                                                helperText={ touched.categoryId && errors.categoryId }
                                                name="categoryId"
                                                label="Категория товара"
                                                placeholder="Выберите категорию товара"
                                                variant="outlined"
                                                fullWidth

                                                onChange={ this.changeForm }
                                            />
                                        </Box>


                                        <Box mt={ 5 } mb={ 2 }>
                                            <TextField
                                                value={ values.piecePrice }
                                                error={ Boolean( touched.piecePrice && errors.piecePrice ) }
                                                helperText={ touched.piecePrice && errors.piecePrice }
                                                name="piecePrice"
                                                label="Цена за 1шт."
                                                placeholder="Введите цену"
                                                variant="outlined"
                                                fullWidth

                                                onChange={ this.changeForm }
                                            />
                                        </Box>

                                        <Box mt={ 5 } mb={ 2 }>
                                            <TextField
                                                value={ values.cbmPrice }
                                                error={ Boolean( touched.cbmPrice && errors.cbmPrice ) }
                                                helperText={ touched.cbmPrice && errors.cbmPrice }
                                                name="cbmPrice"
                                                label="Цена за 1м³"
                                                placeholder="Введите цену"
                                                variant="outlined"
                                                fullWidth

                                                onChange={ this.changeForm }
                                            />
                                        </Box>

                                        <Box mb={ 2 }>
                                            <TextField
                                                value={ values.cbmStep }
                                                error={ Boolean( touched.cbmStep && errors.cbmStep ) }
                                                helperText={ touched.cbmStep && errors.cbmStep }
                                                name="cbmStep"
                                                label="Шаг в 1м³"
                                                placeholder="Введите шаг"
                                                variant="outlined"
                                                fullWidth

                                                onChange={ this.changeForm }
                                            />
                                        </Box>

                                        <Box mt={ 5 }>
                                            <TextField
                                                value={ values.pieceToCbmConversionFactor }
                                                error={ Boolean( touched.pieceToCbmConversionFactor && errors.pieceToCbmConversionFactor ) }
                                                helperText={ touched.pieceToCbmConversionFactor && errors.pieceToCbmConversionFactor }
                                                name="pieceToCbmConversionFactor"
                                                label="Коэффициент 1шт в 1м³"
                                                placeholder="Введите коэффициент"
                                                variant="outlined"
                                                fullWidth

                                                onChange={ this.changeForm }
                                            />
                                        </Box>

                                        <Box mt={ 3 }>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name="hidden"
                                                        value={ values.hidden }
                                                        checked={ values.hidden }
                                                        onChange={ this.changeBooleanForm }
                                                    />
                                                }
                                                label="Скрыть товар"
                                                labelPlacement="start"
                                            />
                                        </Box>
                                    </Box>
                                )
                            } }
                        </Formik>
                    </DialogContent>

                    <DialogActions>
                        <Box px={ 2 }>
                            <Grid container justifyContent="flex-end" spacing={ 1 }>
                                <Grid item>
                                    <Button variant="outlined" onClick={ this.close }
                                            sx={ {
                                                textTransform: "initial",
                                                borderRadius: "4px",
                                                padding: "4px 24px"
                                            } }>
                                        Отменить
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={ this.clickCreate }
                                            sx={ {
                                                textTransform: "initial",
                                                borderRadius: "4px",
                                                padding: "4px 24px"
                                            } }>
                                        { Boolean( isCreated ) ? "Создать" : "Редактировать" }
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogActions>

                </Dialog>

            </>
        );
    }
}


const validationSchema = Yup.object().shape( {
    name: Yup.string().required( "Обязательно к заполнению" ),
    categoryId: Yup.string().required( "Обязательно к заполнению" ),

    piecePrice: Yup.number().required( "Обязательно к заполнению" ).nullable(),
    // pieceQuantity: Yup.number().required("Обязательно к заполнению").nullable(),

    cbmPrice: Yup.number().required( "Обязательно к заполнению" ).nullable(),
    // cbmQuantity: Yup.number().required("Обязательно к заполнению").nullable(),
    cbmStep: Yup.number().required( "Обязательно к заполнению" ).nullable(),

    pieceToCbmConversionFactor: Yup.string().required( "Обязательно к заполнению" ).nullable(),
} );

export default DialogFormProduct
