import React, {Component} from "react";
import {
    InfoMessage as InfoMessageComponent,
    Information as InformationComponent,
    PartnerInformation as PartnerInformationComponent,
    DialogEditStore as DialogEditStoreComponent,
    DialogChangeProvider as DialogChangeProviderComponent,
} from "./components";
import {
    Backdrop,
    Box,
    CircularProgress,
    Container,
    Grid,
    Typography
} from "@mui/material";
import {
    DialogConfirmAction
} from "../../../../components";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";

class MyStore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            store: {},

            storeId: props?.match?.params?.id || null,

            isOpenEditStore: false,
            isOpenChangeProvider: false,

            isLoading: true,
            isNotFound: false,
            isShowBackdrop: false,
        };

        this.refDialogConfirmAction = React.createRef();
    }

    componentDidMount = async () => {
        if (!this.state.storeId) {
            this.props.history.push(`/stores`);
            return;
        }
        await this.getStore();
    };

    // Логика получения склада
    getStore = async () => {
        const storeId = this.state.storeId;

        const store = await agent
            .get(`/stores/${storeId}`)
            .then((res) => {
                return res.data?.store || null;
            })
            .catch(() => {
                return null;
            });
        if (!store) {
            this.setState({
                isNotFound: true,
                isLoading: false,
            });

            return null;
        }

        this.setState({
            isLoading: false,
            store,
        });
    };

    // Логика изменения резерва скалада
    editReserveStatusStore = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Изменить статус резерва",
                message: `Вы действительно хотите изменить статус резерва склада?"`,
                labelSuccess: "Да, изменить",
                onSuccess: this.editReserveStatusStore.bind(this, form, true)
            })

            return null
        }

        this.setState({
            isShowBackdrop: true,
        })

        const response = await agent.put(`/stores/${this.state.storeId}`, form)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            })

        if (response.error) {
            this.setState({isShowBackdrop: false})

            Notification({
                message: "Ошибка изменения резерва статуса",
                type: NotificationTypes.error
            });

            return null
        }

        await this.getStore();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Статус резерва успешно изменен",
            type: NotificationTypes.success
        });
    };

    // Логика изменения данных склада
    editStore = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Подтверждение",
                message: `Вы действительно хотите изменить данные склада?"`,
                labelSuccess: "Да, изменить",
                onSuccess: this.editStore.bind(this, form, true)
            })

            return null
        }

        this.setState({
            isShowBackdrop: true,
        })

        const response = await agent.put(`/stores/${this.state.storeId}`, form)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            })

        if (response.error) {
            this.setState({isShowBackdrop: false})

            Notification({
                message: response.error?.data?.message || "Ошибка при изменении данных склада",
                type: NotificationTypes.error
            });

            return null
        }

        await this.getStore();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Данные склада успешно изменены",
            type: NotificationTypes.success
        });
    };

    // Открытие формы редактирования склада
    setIsOpenEditStore = (isOpen) => {
        this.setState({isOpenEditStore: isOpen});
    };
    // Закрытие формы редактирования организации
    handleCloseEditStore = () => {
        this.setIsOpenEditStore(false);
    };

    // Открытие формы смены исполнителя
    setIsOpenChangeProvider = (isOpen) => {
        this.setState({isOpenChangeProvider: isOpen});
    };
    // Закрытие формы смены исполнителя
    handleCloseChangeProvider = () => {
        this.setIsOpenChangeProvider(false);
    };

    render() {
        const {
            store,
            storeId,

            isOpenEditStore,
            isOpenChangeProvider,

            isNotFound,
            isLoading,
            isShowBackdrop
        } = this.state;

        return (
            <Container maxWidth="xl">
                <Box mb={2}>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                    >
                        <Grid item>
                            <Grid container alignItems="flex-end" spacing={1}>
                                <Grid item>
                                    <Typography variant="h1" textAlign="left">
                                        Склад № {storeId}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item>
                            <ActionsComponent/>
                        </Grid> */}
                    </Grid>
                </Box>

                {Boolean(!isLoading && !isNotFound) && (
                    <Box mb={2}>
                        <InformationComponent
                            store={store}
                            onEditReserveStatus={this.editReserveStatusStore}

                            onOpenEditStore={this.setIsOpenEditStore}
                        />
                    </Box>
                )}

                {Boolean(!isLoading && !isNotFound) && (
                    <Box mb={2}>
                        <PartnerInformationComponent
                            store={store}

                            onOpenChangeProvider={this.setIsOpenChangeProvider}
                        />
                    </Box>
                )}

                {Boolean(isLoading) && (
                    <InfoMessageComponent
                        message="Загружаем склад"
                        type="loading"
                    />
                )}

                {Boolean(isNotFound) && (
                    <InfoMessageComponent
                        message="Склад не найден"
                        type="warn"
                    />
                )}

                <DialogEditStoreComponent
                    store={store}
                    isOpen={isOpenEditStore}
                    onClose={this.handleCloseEditStore}
                    onEdit={this.editStore}
                />

                <DialogChangeProviderComponent
                    store={store}
                    isOpen={isOpenChangeProvider}
                    onClose={this.handleCloseChangeProvider}
                    onChange={this.editStore}
                />

                <DialogConfirmAction
                    ref={this.refDialogConfirmAction}
                />


                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </Container>
        );
    }
}

export default MyStore;
