export default {
    "new": "Не оплачено",
    "fullPrepayment": "Полная предоплата",
    "partialPrepayment": "Частичная предоплата",
    "loanFullPrepayment": "Кредит полная предоплата",
    "billIssued": "Счет выставлен",
    "billPaid": "Счет оплачен",
    "fullRefund": "Полный возврат",
    "partitialRefund": "Частичный возврат",
    "surchargeMade": "Доплата",
    'processing': "В обработке",
    'canceled': "Ошибка оплаты",
}
