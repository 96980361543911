const numberFormat = function (amount, decimalCount = 0, decimal = ',', thousands = ' ') {
    if (!amount) {
        return amount
    }

    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
    let j = (i.length > 3) ? i.length % 3 : 0

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
}
const phoneFormat = (phone) => {
    if (!phone) {
        return ''
    }

    const cleaned = phoneFormatClean(phone);
    let matchEight = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

    if (!matchEight) {
        return phone
    }

    return `+${ matchEight[1] } (${ matchEight[2] }) ${ matchEight[3] } - ${ matchEight[4] } - ${ matchEight[5] }`
};

const phoneFormatClean = (phone) => {
    if (!phone) {
        return '';
    }

    return (phone).replace(/\D/g, '');
};

export {
    numberFormat,
    phoneFormat,
    phoneFormatClean
};
