import {
    Box,
    Grid, IconButton,
    Pagination,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel, Tooltip,
} from "@mui/material";
import {
    Delete as DeleteIcon
} from "@mui/icons-material";
import React from "react";
import {
    formatPhone
} from "../../../../../../helper/formatPhone";
import {makeStyles} from "@mui/styles";
import organizationTypes from "../../../../../../constants/organizationTypes";

const TableCustom = (props) => {
    const {
        data,
        filter,
        visibleColumns,
        pagination,
        isLoading,
        routeOrganization,
        onEditCategory,
        onChangeHidden,
        onDeleteCategory,
        onChangePagination,
        onChangeFilter,
        onDeleteOrganization,
    } = props;
    const classes = useStyles();

    const isEmpty = !data.length;

    const handleChangeHidden = (row, newValue) => {
        onChangeHidden(row, newValue);
    };

    const handleChangeFilter = (name, value) => {
        const newFilter = {...filter};
        newFilter[name] = value;

        onChangeFilter(newFilter, true);
    };

    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination);
    };

    return (
        <>
            <TableContainer>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <EnhancedTableHead
                                visibleColumns={visibleColumns}
                                filter={filter}
                                handleChangeFilter={handleChangeFilter}
                            />
                        </TableRow>
                    </TableHead>

                    <TableBody className={classes.tableBody}>
                        {Boolean(isLoading) ? (
                            <>
                                {[0, 1, 2, 3, 4, 5].map((item) => (
                                    <TableRow key={`row-item-load-${item}`}>
                                        <TableCell>
                                            <Skeleton/>
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton/>
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton/>
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton/>
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton/>
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton/>
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton/>
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton/>
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton/>
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton/>
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton/>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>
                        ) : (
                            <>
                                {isEmpty ? (
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                borderRadius: "0 0 10px 10px",
                                            }}
                                            align="center"
                                            colspan={10}
                                        >
                                            Ничего не найдено
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    data.map((row, index) => (
                                        <TableRow
                                            key={`row-item-${index}`}
                                            hover
                                            onClick={() => routeOrganization(row)}
                                        >
                                            <TableCell>{row.id}</TableCell>
                                            <TableCell>{organizationTypes.find(item => item.value === row.type).label || "—"}</TableCell>
                                            <TableCell>{row.name || "—"}</TableCell>
                                            <TableCell>{row.inn || "-"}</TableCell>
                                            <TableCell>{row.kpp || "—"}</TableCell>
                                            <TableCell>{row.ogrn || "—"}</TableCell>
                                            <TableCell>
                                                {row.email || "—"}
                                            </TableCell>
                                            <TableCell>
                                                {row.phone ? formatPhone(row.phone) : "—"}
                                            </TableCell>
                                            <TableCell>
                                                {row.address || "—"}
                                            </TableCell>
                                            <TableCell>
                                                {row.ceoName || "—"}
                                            </TableCell>
                                            <TableCell onClick={e => e.stopPropagation()}>
                                                <Tooltip title="Удалить организацию">
                                                    <IconButton
                                                        color="error"
                                                        onClick={onDeleteOrganization.bind(this, +row.id, false)}
                                                    >
                                                        <DeleteIcon color="error"/>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box mt={3}/>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Pagination
                        page={Number(pagination.page)}
                        count={Number(pagination.totalPage)}
                        onChange={handleChangePage}
                    />
                </Grid>
                <Grid item></Grid>
            </Grid>
        </>
    );
};

const EnhancedTableHead = (props) => {
    const {
        visibleColumns,
        filter,

        handleChangeFilter
    } = props;
    const classes = useStyles();

    const handleChangeSort = (col) => {
        if (filter.sortInfo === `-${col}`) {
            handleChangeFilter("sortInfo", col);
        } else {
            handleChangeFilter("sortInfo", `-${col}`);
        }
    };
    return (
        <>
            {visibleColumns.map(column => (
                <TableCell
                    key={column.id}
                    {...column}
                >
                    {column.sortable
                        ? (
                            <TableSortLabel
                                classes={{
                                    active: classes.activeSort,
                                    root: classes.sort,
                                }}
                                active={filter.sortInfo === column.id || filter.sortInfo === `-${column.id}`}
                                direction={filter.sortInfo === column.id ? "desc" : "asc"}

                                onClick={handleChangeSort.bind(this, column.id)}
                            >
                                {column.label}
                            </TableSortLabel>
                        )
                        : column.label
                    }
                </TableCell>
            ))}
        </>
    );
};

const useStyles = makeStyles({
    table: {
        "& .MuiTableHead-root": {
            "& > .MuiTableRow-root": {
                height: "100%",
                "& > *:last-child": {
                    position: "sticky",
                    top: 0,
                    right: 0,
                    zIndex: 20,
                    background: "#84b92c",
                    width: 60,
                    minWidth: 60,
                }
            }
        },

        "& .MuiTableBody-root": {
            "& .MuiTableRow-root": {
                "& > *:last-child": {
                    filter: "drop-shadow(0px 5px 24px rgba(149, 157, 165, 0.25))",
                    position: "sticky",
                    right: 0,
                    zIndex: 20,
                    width: 60,
                    minWidth: 60,
                }
            },
            "& .MuiTableRow-root:nth-child(2n)": {
                "& > *:last-child": {
                    background: "#F6F6F7"
                }
            }
        },
    },
    tableBody: {
        "&.MuiTableBody-root .MuiTableCell-root": {
            overflowWrap: "normal",
            whiteSpace: "nowrap",
        },
    },
    sort: {
        "&.MuiButtonBase-root": {
            transition: "all .2s linear",
            "&:hover": {
                color: "rgba(255,255,255, .5)",
                "& .MuiSvgIcon-root": {
                    fill: "#FFFFFF",
                }
            }
        }
    },
    activeSort: {
        '&.MuiButtonBase-root.Mui-active': {
            color: "#FFFFFF",

            "& .MuiSvgIcon-root": {
                "& path": {
                    fill: "#FFFFFF",
                }
            }
        },
    },
})

export default TableCustom;
