import React from "react";
import {
    Box,
    Grid,

    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

    Skeleton,

    Tooltip,
    IconButton,
    Avatar,
    Switch
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Edit as EditIcon,
    Delete as DeleteIcon
} from "@mui/icons-material";
import {
    TableHeadSortCell
} from "../../../../../../components";
import moment from "moment";

const TableCustom = (props) => {
    const {
        data,

        isLoading,

        onEditCategory,
        onChangeHidden,
        onDeleteCategory
    } = props;



    const handleChangeHidden = (row, newValue) => {
        onChangeHidden(row, newValue);
    }

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Наименование</TableCell>
                        <TableCell>Родительская категория</TableCell>
                        <TableCell>Выключена</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {

                        Boolean(isLoading) ? (

                            <>

                                {
                                    [0, 1, 2, 3, 4, 5].map((item) => (

                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell>
                                                <Grid container spacing={1} justifyContent="flex-end">
                                                    <Grid item><Skeleton width={24} height={40}/></Grid>
                                                    <Grid item><Skeleton width={24} height={40}/></Grid>
                                                    <Grid item><Skeleton width={24} height={40}/></Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>

                                    ))
                                }

                            </>

                        ) : (

                            <>

                                {

                                    data.map((row, index) => (

                                        <TableRow key={`row-item-${index}`}>
                                            <TableCell>
                                                { row.id }
                                            </TableCell>
                                            <TableCell>
                                                { row.name }
                                            </TableCell>
                                            <TableCell>
                                                { (data || []).find((t) => t.id === row.parentId)?.name || "—" }
                                            </TableCell>
                                            <TableCell>
                                                <Switch
                                                    value={row.hidden}
                                                    checked={row.hidden}
                                                    onChange={(event, value) => handleChangeHidden(row, value)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Grid container spacing={1} justifyContent="flex-end">
                                                    <Grid item>
                                                        <Tooltip title="Редактировать" arrow>
                                                            <IconButton onClick={() => onEditCategory(row, false)}>
                                                                <EditIcon color="primary"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title="Удалить" arrow>
                                                            <IconButton onClick={() => onDeleteCategory(row, false)}>
                                                                <DeleteIcon color="error"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>

                                    ))

                                }

                            </>

                        )

                    }

                </TableBody>

            </Table>

        </>
    )
}

const useStyles = makeStyles((theme) => ({

    buttonAction: {}

}));

export default TableCustom
