import React from "react";
import {
    Grid,

    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

    Skeleton,

    Tooltip,
    IconButton, TableSortLabel, Switch,
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Edit as EditIcon,
    Delete as DeleteIcon
} from "@mui/icons-material";

const TableCustom = (props) => {
    const {
        data,
        categories,
        filter,
        pagination,
        visibleColumns,

        isLoading,

        onChangePagination,
        onChangeFilter,
        onChangeHidden,
        onEditProduct,
        onDeleteProduct
    } = props;

    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination)
    }

    const handleChangeFilter = (name, value) => {
        const newFilter = {...filter};
        newFilter[name] = value;

        onChangeFilter(newFilter, true);
    };

    const handleChangeHidden = (row, newValue) => {
        onChangeHidden(row, newValue);
    }

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>
                        <EnhancedTableHead
                            visibleColumns={ visibleColumns }
                            filter={ filter }
                            handleChangeFilter={ handleChangeFilter }
                        />
                    </TableRow>
                </TableHead>

                <TableBody>

                    {

                        Boolean( isLoading ) ? (

                            <>

                                {
                                    [ 0, 1, 2, 3, 4, 5 ].map( (item) => (

                                        <TableRow key={ `row-item-load-${ item }` }>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>

                                            <TableCell><Skeleton/></TableCell>

                                            <TableCell><Skeleton/></TableCell>

                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>

                                            <TableCell><Skeleton/></TableCell>
                                        </TableRow>

                                    ) )
                                }

                            </>

                        ) : (

                            <>

                                {

                                    data.map( (row, index) => (

                                        <TableRow key={ `row-item-${ index }` }>
                                            <TableCell>{ row.id }</TableCell>
                                            <TableCell>{ row.name }</TableCell>
                                            <TableCell>{ ( categories || [] ).find( (t) => t.id === row.categoryId )?.name }</TableCell>

                                            <TableCell>{ row.piecePrice }</TableCell>

                                            <TableCell>{ row.cbmPrice }</TableCell>
                                            <TableCell>{ row.cbmStep }</TableCell>
                                            <TableCell>{ row.pieceToCbmConversionFactor }</TableCell>
                                            <TableCell>
                                                <Switch
                                                    value={ row.hidden }
                                                    checked={ row.hidden }
                                                    onChange={ (event, value) => handleChangeHidden( row, value ) }
                                                />
                                            </TableCell>

                                            <TableCell>
                                                <Grid container spacing={ 1 } justifyContent="flex-end" wrap="nowrap">
                                                    <Grid item>
                                                        <Tooltip title="Редактировать" arrow>
                                                            <IconButton onClick={ () => onEditProduct( row ) }>
                                                                <EditIcon color="primary"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title="Удалить" arrow>
                                                            <IconButton onClick={ () => onDeleteProduct( row ) }>
                                                                <DeleteIcon color="error"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>

                                    ) )

                                }

                            </>

                        )

                    }

                </TableBody>

            </Table>

        </>
    );
}

const EnhancedTableHead = (props) => {
    const {
        visibleColumns,
        filter,

        handleChangeFilter
    } = props;
    const classes = useStyles();

    const handleChangeSort = (col) => {
        if (filter.sortInfo === `-${col}`) {
            handleChangeFilter("sortInfo", col);
        } else {
            handleChangeFilter("sortInfo", `-${col}`);
        }
    };
    return (
        <>
            {visibleColumns.map(column => (
                <TableCell
                    key={column.id}
                    width={column.width}
                    {...column}
                >
                    {column.sortable
                        ? (
                            <TableSortLabel
                                classes={{
                                    active: classes.activeSort,
                                    root: classes.sort,
                                }}
                                active={filter.sortInfo === column.id || filter.sortInfo === `-${column.id}`}
                                direction={filter.sortInfo === column.id ? "desc" : "asc"}

                                onClick={handleChangeSort.bind(this, column.id)}
                            >
                                {column.label}
                            </TableSortLabel>
                        )
                        : column.label
                    }
                </TableCell>
            ))}
        </>
    );
};

const useStyles = makeStyles((theme) => ({

    buttonAction: {},

    sort: {
        "&.MuiButtonBase-root": {
            transition: "all .2s linear",
            "&:hover": {
                color: "rgba(255,255,255, .5)",
                "& .MuiSvgIcon-root": {
                    fill: "#FFFFFF",
                }
            }
        }
    },
    activeSort: {
        '&.MuiButtonBase-root.Mui-active': {
            color: "#FFFFFF",

            "& .MuiSvgIcon-root": {
                "& path": {
                    fill: "#FFFFFF",
                }
            }
        },
    },

}));

export default TableCustom
