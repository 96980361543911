import {PlusOne as PlusOneIcon} from "@mui/icons-material";
import {Box, Button, Grid} from "@mui/material";
import React from "react";

const Filter = (props) => {
    const {
        openModal,
        setIsOpenAddEmployee
    } = props;

    return (
        <Box mb={1}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item></Grid>
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{
                                    textTransform: "initial",
                                    fontSize: 12,
                                    height: "auto",
                                    borderRadius: 1,
                                }}
                                endIcon={<PlusOneIcon/>}

                                onClick={setIsOpenAddEmployee.bind(this, true)}
                            >
                                Добавить участника
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box my={1}/>
        </Box>
    );
};

export default Filter;
