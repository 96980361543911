import React from 'react';
import {
    Box,
    Button,

    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,

    TextField,
    Typography
} from "@mui/material";
import {
    Formik
} from "formik";
import {
    IMaskInput
} from "react-imask";
import {
    convertorNumber
} from "../../../../../../helper/convertor";
import {
    Notification,
    NotificationTypes
} from "../../../../../../common/Notification";
import * as Yup from "yup";

const DialogSetSale = (props) => {
    const {
        orderPrice,
        isOpen,
        onClose,
        onSet,
    } = props;

    const refFormik = React.useRef(null);
    const [initialValues, setInitialValues] = React.useState({
        discountValue: '',
    });

    React.useEffect(() => {
        setInitialValues({
            discountValue: '',
        });
    }, [isOpen]);

    const onSubmit = (form) => {
        onSet(form);
        handleCloseModal();
    };

    const handleChange = ({target}) => {
        const {name, value} = target;
        const newForm = refFormik.current.values;

        if (+value < +orderPrice) {
            newForm[name] = value + '';
        } else {
            newForm[name] = "";

            const message = `Сумма скидки не должна превышать стоимость заказа. Стоимость заказа: ${convertorNumber(orderPrice, 2, '.')} ₽`;

            Notification({
                message,
                type: NotificationTypes.error,
            });
        }

        refFormik.current.setValues(newForm)
    };

    const handleCloseModal = () => {
        onClose();
    };

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth="md"
            onClose={handleCloseModal}
        >
            <DialogTitle>
                <Typography variant="h3">Скидка</Typography>
            </DialogTitle>

            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    validationSchema={validateScheme}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box py={2}>
                                    <TextField
                                        value={values.discountValue}
                                        fullWidth
                                        name="discountValue"
                                        label="Скидка"
                                        InputProps={{
                                            inputComponent: CustomInputAmount
                                        }}
                                        inputProps={{
                                            scale: 2,
                                        }}
                                        error={touched.discountValue && Boolean(errors.discountValue)}
                                        helperText={touched.discountValue && errors.discountValue}
                                        placeholder="Введите скидку"

                                        onChange={handleChange}
                                    />
                                </Box>
                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            textTransform: "initial",
                                            borderRadius: "4px",
                                            padding: "4px 24px"
                                        }}

                                        onClick={handleCloseModal}
                                    >
                                        Отменить
                                    </Button>
                                    <Button variant="contained" onClick={handleSubmit}>
                                        Применить
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const CustomInputAmount = ({inputRef, ...rest}) => (
    <IMaskInput
        ref={inputRef}
        {...rest}

        mask={Number}
        thousandsSeparator=""
        radix="."
        mapToRadix={[',']}
        scale={rest?.scale || 0}
        unmask={true}
    />
)

const validateScheme = Yup.object().shape({
    discountValue: Yup.string().required('Введите скидку'),
})

export default DialogSetSale;