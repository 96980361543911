import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import regexp from "../../../../../../constants/regexp";
import InputMask from "react-input-mask";
import userRoles from "../../../../../../constants/userRoles";
import organizationTypes from "../../../../../../constants/organizationTypes";
import AutocompleteOrganization from "../../../../../../components/autocomplete/AutocompleteOrganization";
import agent from "../../../../../../agent/agent";

class DialogFormUser extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            form: {},
            initUserName: "",
            organizations: [],

            isOpen: false,
            isCreated: false,


            onEvent: null
        };

        this.refFormik = React.createRef();
    }


    open = (form = {}, onEvent) => {

        this.setState({
            isOpen: true,
            isCreated: Boolean(Object.keys(form).length <= 0),
            initUserName: `${form.firstName} ${form.lastName}`,
            onEvent: onEvent,
            form,
        }, () => {
            setTimeout(() => {
                this.refFormik.current.setValues(
                    Boolean(Object.keys(form).length <= 0)
                        ? {
                            firstName: "",
                            lastName: "",
                            username: "",
                            email: "",
                            role: "",
                            phone: "",
                            organizationConnectType: "",

                            expeditorId: "",

                            organization: '',

                            organizationType: '',
                            organizationName: "",
                            organizationInn: "",
                            organizationKpp: "",
                            organizationOgrn: "",
                            organizationEmail: "",
                            organizationPhone: "",
                            organizationAddress: "",
                            organizationCeoName: "",
                        }
                        : {
                            ...form,
                            expeditorId: form?.expeditorId || '',
                        }
                );
            }, 100);
        });
    }

    close = () => {
        this.setState({
            isOpen: false
        })
    }

    // Обработчик формы
    onSubmit = (form) => {
        const newForm = {...form};
        this.state.onEvent(newForm, true);
        this.close();
    }

    // Изменение формы
    changeForm = ({target}) => {
        const {
            value,
            name
        } = target;

        let newValues = this.refFormik.current.values;
        newValues[name] = value;

        this.refFormik.current.setValues(newValues);
    }

    // Клик по кнопке в управлении
    clickCreate = () => {
        this.refFormik.current.handleSubmit();
    }

    getOrganizations = async (value) => {
        this.setState({
            organizations: [],
        })
        const {organizations} = await agent.get(`/organizations?inn=${value}&limit=0`)
            .then(res => res.data)
            .catch(err => []);
        this.setState({
            organizations,
        });
    };

    resetOrganizations = () => {
        this.setState({
            organizations: [],
        })
    };

    handleChangeAutocomplete = (value) => {
        let newValues = this.refFormik.current.values;
        newValues.organization = value;

        this.refFormik.current.setValues(newValues);
    };

    render() {
        const {
            initUserName,
            organizations,
            isOpen,
            isCreated
        } = this.state;
        const {
            expeditors,
        } = this.props;
        return (
            <>

                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={isOpen}

                    onClose={this.close}
                >

                    <DialogTitle>
                        <Typography variant="h3">
                            {Boolean(isCreated) ? "Создание пользователя" : `Редактирование пользователя: "${initUserName}"`}
                        </Typography>
                    </DialogTitle>

                    <DialogContent>
                        <Formik
                            innerRef={this.refFormik}
                            initialValues={{}}
                            validationSchema={isCreated ? validationSchemaCreate : validationSchemaEdit}
                            onSubmit={this.onSubmit}
                        >
                            {(props) => {
                                const {
                                    values,
                                    errors,
                                    touched
                                } = props;

                                if (Boolean(!isCreated && Object.keys(values || {}).length <= 0)) {
                                    return null
                                }

                                return (
                                    <Box pt={1}>

                                        <Box mb={2}>
                                            <TextField
                                                value={values.firstName}
                                                error={Boolean(touched.firstName && errors.firstName)}
                                                helperText={touched.firstName && errors.firstName}
                                                name="firstName"
                                                label="Имя пользователя"
                                                placeholder="Введите имя пользователя"
                                                variant="outlined"
                                                fullWidth

                                                onChange={this.changeForm}
                                            />
                                        </Box>
                                        <Box mb={2}>
                                            <TextField
                                                value={values.lastName}
                                                error={Boolean(touched.lastName && errors.lastName)}
                                                helperText={touched.lastName && errors.lastName}
                                                name="lastName"
                                                label="Фамилия пользователя"
                                                placeholder="Введите фамилию пользователя"
                                                variant="outlined"
                                                fullWidth

                                                onChange={this.changeForm}
                                            />
                                        </Box>
                                        <Box mb={2}>
                                            <TextField
                                                value={values.username}
                                                error={Boolean(touched.username && errors.username)}
                                                helperText={touched.username && errors.username}
                                                name="username"
                                                label="Никнейм пользователя"
                                                placeholder="Введите никнейм пользователя"
                                                variant="outlined"
                                                fullWidth

                                                onChange={this.changeForm}
                                            />
                                        </Box>
                                        <Box mb={2}>
                                            <TextField
                                                value={values.email}
                                                error={Boolean(touched.email && errors.email)}
                                                helperText={touched.email && errors.email}
                                                name="email"
                                                label="Email пользователя"
                                                placeholder="Введите Email пользователя"
                                                variant="outlined"
                                                fullWidth

                                                onChange={this.changeForm}
                                            />
                                        </Box>

                                        <Box mb={2}>
                                            <InputMask
                                                mask="+7 (099) 999 - 99 - 99"
                                                value={values.phone}
                                                maskChar=" "
                                                onChange={this.changeForm}
                                                formatChars={{
                                                    '0': '[489]',
                                                    '9': '[0-9]',
                                                }}
                                            >
                                                {() => (
                                                    <TextField
                                                        error={Boolean(touched.phone && errors.phone)}
                                                        helperText={touched.phone && errors.phone}
                                                        name="phone"
                                                        label="Номер телефона"
                                                        placeholder="+7 (999) 999 - 99 - 99"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                            </InputMask>
                                        </Box>

                                        <Box mb={2}>
                                            <FormControl fullWidth error={Boolean(touched.role && errors.role)}>
                                                <InputLabel>Роль пользователя</InputLabel>
                                                <Select
                                                    name="role"
                                                    value={values.role}
                                                    label="Роль пользователя"
                                                    onChange={this.changeForm}

                                                    error={Boolean(touched.role && errors.role)}
                                                    helperText={touched.role && errors.role}
                                                >
                                                    {userRoles.map((role, index) => (
                                                        <MenuItem value={role.value} key={`role-${index}`}>
                                                            {role.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {Boolean(touched.role && errors.role) && (
                                                    <FormHelperText>{touched.role && errors.role}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Box>

                                        {values.role === 'driver' && (
                                            <Box mb={2}>
                                                <FormControl fullWidth
                                                             error={Boolean(touched.expeditorId && errors.expeditorId)}>
                                                    <InputLabel>Экспедитор</InputLabel>
                                                    <Select
                                                        name="expeditorId"
                                                        value={values.expeditorId}
                                                        label="Экспедитор"
                                                        onChange={this.changeForm}

                                                        error={Boolean(touched.expeditorId && errors.expeditorId)}
                                                        helperText={touched.expeditorId && errors.expeditorId}
                                                    >
                                                        {expeditors.map((expeditor, index) => (
                                                            <MenuItem value={expeditor.id}
                                                                      key={`expeditor-${expeditor.id}`}>
                                                                {expeditor.id} | {expeditor.firstName} {expeditor.lastName}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {Boolean(touched.expeditorId && errors.expeditorId) && (
                                                        <FormHelperText>{touched.expeditorId && errors.expeditorId}</FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Box>
                                        )}

                                        {((!['admin'].includes(values.role)) && Boolean(isCreated)) && (
                                            <>
                                                <Box mb={2}>
                                                    <FormControl fullWidth
                                                                 error={Boolean(touched.organizationConnectType && errors.organizationConnectType)}>
                                                        <InputLabel>Организация</InputLabel>
                                                        <Select
                                                            name="organizationConnectType"
                                                            value={values.organizationConnectType}
                                                            label="Организация"
                                                            onChange={this.changeForm}
                                                            defaultValue=""

                                                            error={Boolean(touched.organizationConnectType && errors.organizationConnectType)}
                                                            helperText={touched.organizationConnectType && errors.organizationConnectType}
                                                        >
                                                            {['client', 'driver', 'admin'].includes(values.role) && (
                                                                <MenuItem value='none'>
                                                                    Без организации
                                                                </MenuItem>
                                                            )}
                                                            <MenuItem value='joinAnOrganization'>
                                                                Присоединить к организации
                                                            </MenuItem>
                                                            <MenuItem value='createNewOrganization'>
                                                                Создать новую организацию
                                                            </MenuItem>
                                                        </Select>
                                                        {Boolean(touched.organizationConnectType && errors.organizationConnectType) && (
                                                            <FormHelperText>{touched.organizationConnectType && errors.organizationConnectType}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Box>
                                                {values.organizationConnectType === 'joinAnOrganization' && (
                                                    <Box mb={2}>
                                                        <AutocompleteOrganization
                                                            organizations={organizations}
                                                            value={values.organization}
                                                            errors={errors}
                                                            touched={touched}
                                                            onChangeValue={this.handleChangeAutocomplete}
                                                            getOrganizations={this.getOrganizations}
                                                            resetOrganizations={this.resetOrganizations}
                                                        />
                                                    </Box>
                                                )}
                                            </>
                                        )}

                                        {(((values.userType === 'legal') && Boolean(!isCreated)) || values.organizationConnectType === 'createNewOrganization') && (
                                            <>
                                                <Box mt={5} mb={2}>
                                                    <FormControl fullWidth
                                                                 error={Boolean(touched.organizationType && errors.organizationType)}>
                                                        <InputLabel>Тип организации</InputLabel>
                                                        <Select
                                                            name="organizationType"
                                                            value={values.organizationType}
                                                            label="Тип организации"
                                                            onChange={this.changeForm}
                                                            disabled={Boolean(!isCreated)}

                                                            error={Boolean(touched.organizationType && errors.organizationType)}
                                                            helperText={touched.organizationType && errors.organizationType}
                                                        >
                                                            {organizationTypes.map(type => (
                                                                <MenuItem value={type.value}
                                                                          key={`organization-type-${type.value}`}>
                                                                    {type.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {Boolean(touched.organizationType && errors.organizationType) && (
                                                            <FormHelperText>{touched.organizationType && errors.organizationType}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Box>
                                                <Box mb={2}>
                                                    <TextField
                                                        value={values.organizationName}
                                                        error={Boolean(touched.organizationName && errors.organizationName)}
                                                        helperText={touched.organizationName && errors.organizationName}
                                                        name="organizationName"
                                                        label="Наименование организации"
                                                        placeholder="Введите наименование организации"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={Boolean(!isCreated)}

                                                        onChange={this.changeForm}
                                                    />
                                                </Box>
                                                <Box mb={2}>
                                                    <TextField
                                                        value={values.organizationInn}
                                                        error={Boolean(touched.organizationInn && errors.organizationInn)}
                                                        helperText={touched.organizationInn && errors.organizationInn}
                                                        name="organizationInn"
                                                        label="ИНН организации"
                                                        placeholder="Введите ИНН организации"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={Boolean(!isCreated)}

                                                        onChange={this.changeForm}
                                                    />
                                                </Box>
                                                <Box mb={2}>
                                                    <TextField
                                                        value={values.organizationKpp}
                                                        error={Boolean(touched.organizationKpp && errors.organizationKpp)}
                                                        helperText={touched.organizationKpp && errors.organizationKpp}
                                                        name="organizationKpp"
                                                        label="КПП организации"
                                                        placeholder="Введите КПП организации"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={Boolean(!isCreated)}

                                                        onChange={this.changeForm}
                                                    />
                                                </Box>
                                                <Box mb={2}>
                                                    <TextField
                                                        value={values.organizationOgrn}
                                                        error={Boolean(touched.organizationOgrn && errors.organizationOgrn)}
                                                        helperText={touched.organizationOgrn && errors.organizationOgrn}
                                                        name="organizationOgrn"
                                                        label="ОГРН организации"
                                                        placeholder="Введите ОГРН организации"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={Boolean(!isCreated)}

                                                        onChange={this.changeForm}
                                                    />
                                                </Box>
                                                <Box mb={2}>
                                                    <TextField
                                                        value={values.organizationEmail}
                                                        error={Boolean(touched.organizationEmail && errors.organizationEmail)}
                                                        helperText={touched.organizationEmail && errors.organizationEmail}
                                                        name="organizationEmail"
                                                        label="Email организации"
                                                        placeholder="Введите email организации"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={Boolean(!isCreated)}

                                                        onChange={this.changeForm}
                                                    />
                                                </Box>
                                                <Box mb={2}>
                                                    <InputMask
                                                        mask="+7 (099) 999 - 99 - 99"
                                                        value={values.organizationPhone}
                                                        maskChar=""
                                                        onChange={this.changeForm}
                                                        formatChars={{
                                                            '0': '[489]',
                                                            '9': '[0-9]',
                                                        }}
                                                        disabled={Boolean(!isCreated)}
                                                    >
                                                        {() => (
                                                            <TextField
                                                                error={Boolean(touched.organizationPhone && errors.organizationPhone)}
                                                                helperText={touched.organizationPhone && errors.organizationPhone}
                                                                name="organizationPhone"
                                                                label="Номер телефона организации"
                                                                placeholder="+7 (999) 999 - 99 - 99"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    </InputMask>
                                                </Box>
                                                <Box mb={2}>
                                                    <TextField
                                                        value={values.organizationAddress}
                                                        error={Boolean(touched.organizationAddress && errors.organizationAddress)}
                                                        helperText={touched.organizationAddress && errors.organizationAddress}
                                                        name="organizationAddress"
                                                        label="Адрес организации"
                                                        placeholder="Введите адрес организации"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={Boolean(!isCreated)}

                                                        onChange={this.changeForm}
                                                    />
                                                </Box>
                                                <Box>
                                                    <TextField
                                                        value={values.organizationCeoName}
                                                        error={Boolean(touched.organizationCeoName && errors.organizationCeoName)}
                                                        helperText={touched.organizationCeoName && errors.organizationCeoName}
                                                        name="organizationCeoName"
                                                        label="Директор организации"
                                                        placeholder="Введите директора организации"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={Boolean(!isCreated)}

                                                        onChange={this.changeForm}
                                                    />
                                                </Box>

                                                {Boolean(!isCreated) && (
                                                    <Box mt={1}>
                                                        <Typography variant="h6">Для редактирования организации
                                                            перейдите <Link href="#"
                                                                            onClick={() => this.props.history.replace(`/organizations/${values.organizationId}`)}>сюда</Link></Typography>
                                                    </Box>
                                                )}
                                            </>
                                        )}

                                        {(values.userType === 'physical'&& Boolean(!isCreated)) && (
                                            <>
                                                <Box mt={1}>
                                                    <Typography variant="h6">Для добавления организации перейдите <Link
                                                        href="#"
                                                        onClick={() => this.props.history.replace(`/organizations`)}>сюда</Link></Typography>
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                                );
                            }}
                        </Formik>
                    </DialogContent>

                    <DialogActions>
                        <Box px={2}>
                            <Grid container justifyContent="flex-end" spacing={1}>
                                <Grid item>
                                    <Button variant="outlined" onClick={this.close}
                                            sx={{textTransform: "initial", borderRadius: "4px", padding: "4px 24px"}}>
                                        Отменить
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={this.clickCreate}
                                            sx={{textTransform: "initial", borderRadius: "4px", padding: "4px 24px"}}>
                                        {Boolean(isCreated) ? "Создать" : "Редактировать"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogActions>

                </Dialog>

            </>
        );
    }
}

const validationSchemaCreate = Yup.object().shape({
    firstName: Yup.string().required("Введите Имя пользователя"),
    // lastName: Yup.string().required("Введите Фамилию пользователя"),
    // username: Yup.string().required("Введите Никнейм пользователя"),
    // email: Yup.string().email("Некорректный ввод Email адреса").required("Введите Email пользователя"),
    role: Yup.string().required("Выберите роль пользователя"),
    phone: Yup.string().matches(regexp.phone, "Введите номер телефона в формате - +7 (999) 999 - 99 - 99").required('Введите номер телефона'),

    // Для водителя
    expeditorId: Yup.number().when("role", {
        is: "driver",
        then: Yup.number().required('Выберите экспедитора'),
    }),

    // Организация
    organizationConnectType: Yup.string().when(['role'], {
        is: (role) => role !== 'admin',
        then: Yup.string().required('Выберите способ добавления организации'),
    }),

    organization: Yup.object().when(['organizationConnectType'], {
        is: (organizationConnectType) => organizationConnectType === 'joinAnOrganization',
        then: Yup.object().required('Выберите организацию'),
    }),

    organizationType: Yup.string().when(['organizationConnectType'], {
        is: (organizationConnectType) => organizationConnectType === 'createNewOrganization',
        then: Yup.string().required('Выберите тип организации'),
    }),
    organizationName: Yup.string().when(['organizationConnectType'], {
        is: (organizationConnectType) => organizationConnectType === 'createNewOrganization',
        then: Yup.string().required('Введите название организации'),
    }),
    organizationInn: Yup.string().when(['organizationConnectType'], {
        is: (organizationConnectType) => organizationConnectType === 'createNewOrganization',
        then: Yup.string()
            .required("Обязательное поле")
            .min(9, "Минимальная длина 9 символов")
            .max(12, "Максимальная длина 12 символов"),
    }),
    organizationKpp: Yup.string().when(['organizationConnectType'], {
        is: (organizationConnectType) => organizationConnectType === 'createNewOrganization',
        then: Yup.string()
            .required("Обязательное поле")
            .min(9, "Минимальная длина 9 символов")
            .max(9, "Максимальная длина 9 символов"),
    }),
    organizationOgrn: Yup.string().when(['organizationConnectType'], {
        is: (organizationConnectType) => organizationConnectType === 'createNewOrganization',
        then: Yup.string()
            .required("Обязательное поле")
            .min(13, "Минимальная длина 13 символов")
            .max(15, "Максимальная длина 15 символов"),
    }),
    organizationEmail: Yup.string().when(['organizationConnectType'], {
        is: (organizationConnectType) => organizationConnectType === 'createNewOrganization',
        then: Yup.string().required('Выберите email организации'),
    }),
    organizationPhone: Yup.string().when(['organizationConnectType'], {
        is: (organizationConnectType) => organizationConnectType === 'createNewOrganization',
        then: Yup.string().required('Выберите номер телефона организации'),
    }),
    organizationAddress: Yup.string().when(['organizationConnectType'], {
        is: (organizationConnectType) => organizationConnectType === 'createNewOrganization',
        then: Yup.string().required('Выберите адрес организации'),
    }),
    organizationCeoName: Yup.string().when(['organizationConnectType'], {
        is: (organizationConnectType) => organizationConnectType === 'createNewOrganization',
        then: Yup.string().required('Выберите директора организации'),
    }),
});

const validationSchemaEdit = Yup.object().shape({
    firstName: Yup.string().required("Введите Имя пользователя"),
    // lastName: Yup.string().required("Введите Фамилию пользователя"),
    // username: Yup.string().required("Введите Никнейм пользователя"),
    // email: Yup.string().email("Некорректный ввод Email адреса").required("Введите Email пользователя"),
    role: Yup.string().required("Выберите роль пользователя"),
    phone: Yup.string().required("Введите номер телефона"),

    // Для водителя
    expeditorId: Yup.number().when("role", {
        is: "driver",
        then: Yup.number().required('Выберите экспедитора'),
    }),
});
export default DialogFormUser
