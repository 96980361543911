import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { Formik } from "formik";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import regexp from "../../../../../../constants/regexp";
import organizationTypes from "../../../../../../constants/organizationTypes";
import { AutocompleteUser_1 } from "../../../../../../components";

const initialValues = {
    ownerUser: "",
    ownerUserId: "",
    type: "",
    name: "",
    inn: "",
    kpp: "",
    ogrn: "",
    email: "",
    phone: "",
    address: "",
    ceoName: "",
};

const AddOrganizationModal = (props) => {
    const {
        organization,
        createOrChangeOrganization,
        isOpen,
        handleClose
    } = props;
    const refFormik = React.useRef(null);

    const onSubmit = (form) => {
        createOrChangeOrganization(form);
        handleCloseModal();
    };

    const handleCloseModal = () => {
        handleClose();
        refFormik.current.resetForm();
    };

    const handleChange = ({ target }) => {
        const { name, value } = target;
        const newForm = refFormik.current.values;

        newForm[name] = value;

        refFormik.current.setValues(newForm);
    }

    const handleChangeAutocomplete = (value) => {
        const newForm = refFormik.current.values;

        newForm.ownerUser = value;
        newForm.ownerUserId = value.id;

        refFormik.current.setValues(newForm);
    };

    return (<>
        <Dialog
            open={ isOpen }
            onClose={ handleCloseModal }
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>
                <Typography
                    variant="h3">{ organization ? `Редактирование организации: ${ organization.name }` : 'Создание организации' }</Typography>
            </DialogTitle>
            <DialogContent>
                <Box pt={ 1 }>
                    <Formik
                        innerRef={ refFormik }
                        initialValues={ initialValues }
                        validationSchema={ ValidationSchema }
                        onSubmit={ onSubmit }
                    >
                        { (props) => {
                            const {
                                values,
                                errors,
                                touched,
                                handleSubmit
                            } = props;

                            return (
                                <>
                                    <Box mb={ 2 }>
                                        <AutocompleteUser_1
                                            name='ownerUser'
                                            value={ values.ownerUser }
                                            error={ errors.ownerUserId }
                                            touched={ touched.ownerUserId }
                                            limit="0"
                                            roles=""
                                            title="Владелец организации"
                                            showSearchFields
                                            onChangeAutocomplete={ handleChangeAutocomplete }
                                        />
                                    </Box>
                                    <Box mb={ 2 }>
                                        <FormControl fullWidth error={ touched.type && Boolean(errors.type) }>
                                            <InputLabel>Тип</InputLabel>
                                            <Select
                                                name="type"
                                                label="Тип"
                                                value={ organization ? organization.type : values.type }
                                                onChange={ handleChange }
                                            >
                                                { organizationTypes.map((type) => (<MenuItem value={ type.value }>
                                                    { type.label }
                                                </MenuItem>)) }
                                            </Select>
                                            { Boolean(touched.type && Boolean(errors.type)) && (
                                                <FormHelperText error variant="filled">
                                                    { touched.type && errors.type }
                                                </FormHelperText>) }
                                        </FormControl>
                                    </Box>
                                    <Box mb={ 2 }>
                                        <TextField
                                            fullWidth
                                            name="name"
                                            label="Название"
                                            value={ organization ? organization.name : values.name }
                                            onChange={ handleChange }
                                            error={ touched.name && Boolean(errors.name) }
                                            helperText={ touched.name && errors.name }
                                        />
                                    </Box>
                                    <Box mb={ 2 }>
                                        <TextField
                                            fullWidth
                                            name="inn"
                                            label="ИНН"
                                            value={ organization ? organization.inn : values.inn }
                                            onChange={ handleChange }
                                            error={ touched.inn && Boolean(errors.inn) }
                                            helperText={ touched.inn && errors.inn }
                                        />
                                    </Box>
                                    <Box mb={ 2 }>
                                        <TextField
                                            fullWidth
                                            name="kpp"
                                            label="КПП"
                                            value={ organization ? organization.kpp : values.kpp }
                                            onChange={ handleChange }
                                            error={ touched.kpp && Boolean(errors.kpp) }
                                            helperText={ touched.kpp && errors.kpp }
                                        />
                                    </Box>
                                    <Box mb={ 2 }>
                                        <TextField
                                            fullWidth
                                            name="ogrn"
                                            label="ОГРН"
                                            value={ organization ? organization.ogrn : values.ogrn }
                                            onChange={ handleChange }
                                            error={ touched.ogrn && Boolean(errors.ogrn) }
                                            helperText={ touched.ogrn && errors.ogrn }
                                        />
                                    </Box>
                                    <Box mb={ 2 }>
                                        <TextField
                                            fullWidth
                                            name="email"
                                            label="Почта"
                                            value={ organization ? organization.email : values.email }
                                            onChange={ handleChange }
                                            error={ touched.email && Boolean(errors.email) }
                                            helperText={ touched.email && errors.email }
                                        />
                                    </Box>
                                    <Box mb={ 2 }>
                                        <InputMask
                                            mask="+7 (099) 999 - 99 - 99"
                                            value={ organization ? organization.phone : values.phone }
                                            maskChar=" "
                                            onChange={ handleChange }
                                            formatChars={ {
                                                0: "[489]", 9: "[0-9]",
                                            } }
                                        >
                                            { () => (<TextField
                                                fullWidth
                                                name="phone"
                                                label="Номер телефона"
                                                error={ touched.phone && Boolean(errors.phone) }
                                                helperText={ touched.phone && errors.phone }
                                            />) }
                                        </InputMask>
                                    </Box>
                                    <Box mb={ 2 }>
                                        <TextField
                                            fullWidth
                                            name="address"
                                            label="Адрес"
                                            value={ organization ? organization.address : values.address }
                                            onChange={ handleChange }
                                            error={ touched.address && Boolean(errors.address) }
                                            helperText={ touched.address && errors.address }
                                        />
                                    </Box>
                                    <Box mb={ 2 }>
                                        <TextField
                                            fullWidth
                                            name="ceoName"
                                            label="Директор"
                                            value={ organization ? organization.ceoName : values.ceoName }
                                            onChange={ handleChange }
                                            error={ touched.ceoName && Boolean(errors.ceoName) }
                                            helperText={ touched.ceoName && errors.ceoName }
                                        />
                                    </Box>

                                    <DialogActions>
                                        <Box px={ 2 }>
                                            <Grid container justifyContent="flex-end" spacing={ 1 }>
                                                <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={ handleCloseModal }
                                                        sx={ {
                                                            textTransform: "initial",
                                                            borderRadius: "4px",
                                                            padding: "4px 24px",
                                                        } }
                                                    >
                                                        Отменить
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        onClick={ handleSubmit }
                                                        sx={ {
                                                            textTransform: "initial",
                                                            borderRadius: "4px",
                                                            padding: "4px 24px",
                                                        } }
                                                    >
                                                        { organization ? "Редактировать" : "Создать" }
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </DialogActions>
                                </>
                            );
                        } }
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    </>);
};

const ValidationSchema = Yup.object().shape({
    type: Yup.string()
        .required("Обязательное поле")
        .matches(regexp.organizationType, "Невалидный тип"),
    name: Yup.string().required("Обязательное поле"),
    inn: Yup.string()
        .required("Обязательное поле")
        .min(9, "Минимальная длина 9 символов")
        .max(12, "Максимальная длина 12 символов"),
    kpp: Yup.string().when([ "type" ], {
        is: (type) => type === 'jp',
        then: Yup.string()
            .required("Обязательное поле")
            .min(9, "Минимальная длина 9 символов")
            .max(9, "Максимальная длина 9 символов"),
    }),
    ogrn: Yup.string()
        .required("Обязательное поле")
        .min(13, "Минимальная длина 13 символов")
        .max(15, "Максимальная длина 15 символов"),
    email: Yup.string()
        .required("Обязательное поле")
        .email("Невалидный адрес почты"),
    phone: Yup.string()
        .required("Обязательное поле")
        .matches(regexp.phone, "Введите номер телефона в формате - +7 (999) 999 - 99 - 99"),
    address: Yup.string().required("Обязательное поле"),
    ceoName: Yup.string().required("Обязательное поле"),
    ownerUserId: Yup.number().required('Выберите владельца организации'),
});

export default AddOrganizationModal;
