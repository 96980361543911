import React, {
    useEffect,
    useState
} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,

    Box,
    Grid,
    Pagination,
    Skeleton,
    Switch,
    Typography,
    IconButton,
    Tooltip
} from "@mui/material";
import {
    DeleteRounded as DeleteIcon
} from "@mui/icons-material";
import agent from "../../../../../../agent/agent";
import {
    makeStyles
} from "@mui/styles";

const TableCustom = (props) => {
    const {
        data,
        pagination,
        isLoading,
        routeStore,
        onEditCategory,
        onChangeHidden,
        onDeleteCategory,
        onChangePagination,
        changeStore,
        onDeleteStore,
    } = props;

    const handleChangeHidden = (row, newValue) => {
        onChangeHidden(row, newValue);
    }

    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination)
    }

    const isEmpty = !data.length

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>
                        <TableCell>
                            ID
                        </TableCell>
                        <TableCell>
                            Название склада
                        </TableCell>
                        <TableCell>
                            Описание склада
                        </TableCell>
                        <TableCell>
                            Резерв
                        </TableCell>
                        <TableCell>
                            Адрес
                        </TableCell>
                        <TableCell>
                            Исполнитель
                        </TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {

                        Boolean(isLoading) ? (

                            <>

                                {
                                    [0, 1, 2, 3, 4, 5].map((item) => (

                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                        </TableRow>

                                    ))
                                }

                            </>

                        ) : (

                            <>

                                {
                                    isEmpty
                                        ? <TableRow>
                                            <TableCell style={{borderRadius: '0 0 10px 10px'}} align="center" colspan={7}>
                                                Ничего не найдено
                                            </TableCell>
                                        </TableRow>
                                        : data.map((row, index) => (

                                            <TableRowComponent
                                                row={row}
                                                index={index}
                                                routeStore={routeStore}
                                                changeStore={changeStore}
                                                onDeleteStore={onDeleteStore}
                                            />
                                            // <TableRow
                                            //     key={`row-item-${index}`}
                                            //     hover
                                            //     onClick={() => routeStore(row)}
                                            // >
                                            //     <TableCell>{row.id}</TableCell>
                                            //     <TableCell>{row.name || "—"}</TableCell>
                                            //     <TableCell>{row.description || "—"}</TableCell>
                                            //     {/*<TableCell>{moment(row.createdAt).format("DD.MM.YYYY HH:mm")}</TableCell>*/}
                                            //     <TableCell>{row.reserve ? 'да' : 'нет'}</TableCell>
                                            //     <TableCell>{row.address || "—"}</TableCell>
                                            // </TableRow>

                                        ))

                                }

                            </>

                        )

                    }

                </TableBody>

            </Table>

            <Box mt={3}/>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Pagination
                        page={Number(pagination.page)}
                        count={Number(pagination.totalPage)}

                        onChange={handleChangePage}
                    />
                </Grid>
                <Grid item></Grid>
            </Grid>

        </>
    );
}

const TableRowComponent = (props) => {
    const {
        row,
        index,
        routeStore,
        changeStore,
        onDeleteStore
    } = props;
    const [user, setUser] = useState({});
    const classes = useStyles();

    useEffect(() => {
        (async () => {
            await getPartner();
        })();
    }, []);

    //Логика получения партнера
    const getPartner = async () => {
        const url = `/users/${row.ownerUserId}`;
        const user = await agent.get(url)
            .then((res) => res.data.user)
            .catch(() => {
                return {};
            })
        setUser(user);
    }

    const handleChangeReserve = ({target}) => {
        const {checked} = target;
        const newForm = {
            name: row.name,
            description: row.description,
            address: row.address,
            addressLat: row.addressLat,
            addressLong: row.addressLong,
            reserve: checked,
        };

        changeStore(row, newForm);
    }

    const handleDeleteStore = (e, id) => {
        e.stopPropagation();
        onDeleteStore(id);
    };

    return (
        <TableRow
            key={`row-item-${index}`}
            hover
            onClick={() => routeStore(row)}
        >
            <TableCell>{row.id}</TableCell>
            <TableCell>{row.name || "—"}</TableCell>
            <TableCell>{row.description || "—"}</TableCell>
            {/*<TableCell>{moment(row.createdAt).format("DD.MM.YYYY HH:mm")}</TableCell>*/}
            <TableCell onClick={e => e.stopPropagation()}>
                <Switch
                    checked={row.reserve}
                    onChange={handleChangeReserve}
                />
            </TableCell>
            <TableCell>{row.address || "—"}</TableCell>
            <TableCell>{
                <>
                    <Typography>Имя: {user.firstName}</Typography>
                    <Typography>Телефон: {user.phone}</Typography>
                    <Typography>Email: {user.email}</Typography>
                </>
            }</TableCell>
            <TableCell>
                <Tooltip
                    classes={{
                        tooltip: classes.tooltipError,
                    }}
                    title="Удалить склад"
                >
                    <IconButton
                        color="error"

                        onClick={e => handleDeleteStore(e, row.id)}
                    >
                        <DeleteIcon color="error"/>
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

const useStyles = makeStyles({
    tooltipError: {
        background: "#e53935",
    },
});

export default TableCustom
