import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,

    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";
import paymentStatus from "../../constants/paymentStatus";

class DialogChangeStatusPayment extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            order: {},

            selectPaymentStatus: "",

            onSuccess: null,


            open: false
        }
    }


    open = ({order, onSuccess}) => {
        this.setState({
            order,
            selectPaymentStatus: order.paymentStatus,

            onSuccess: onSuccess || null,

            open: true
        })
    }
    close = () => {
        this.setState({
            open: false
        })
    }

    changeSelectPaymentStatus = ({target}) => {
        this.setState({
            selectPaymentStatus: target.value
        })
    }

    onSuccess = () => {
        this.onCancel();

        this.state?.onSuccess(this.state.order, this.state.selectPaymentStatus);
    }
    onCancel = () => {
        this.setState({
            open: false
        })
    }

    render() {
        const {
            order,

            selectPaymentStatus,

            open
        } = this.state;

        return (
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}


                onClose={this.onCancel}
            >

                <DialogTitle sx={{backgroundColor: "#84b92c"}}>
                    <Typography variant="h3" sx={{color: "white"}}>
                        Изменение статуса оплаты
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Box py={3}>

                        <Box mb={2}>
                            <Typography variant="h4">
                                Текущий статус оплаты: <span
                                style={{color: "#84b92c"}}>{paymentStatus[order.paymentStatus]}</span>
                            </Typography>
                        </Box>

                        <Box>

                            <FormControl fullWidth>
                                <InputLabel>Статус оплаты</InputLabel>
                                <Select
                                    value={selectPaymentStatus}
                                    label="Статус оплаты"
                                    onChange={this.changeSelectPaymentStatus}
                                >
                                    {Object.keys(paymentStatus).map((key) => (
                                        <MenuItem value={key}>
                                            { paymentStatus[key] }
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Box>

                    </Box>
                </DialogContent>

                <DialogActions>
                    <Box px={2}>
                        <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                            <Grid item>
                                <Button sx={{borderRadius: "4px", textTransform: "initial"}} variant="outlined"
                                        onClick={this.onCancel}>
                                    Отмена
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button sx={{borderRadius: "4px", textTransform: "initial"}} variant="contained"
                                        onClick={this.onSuccess}>
                                    Да, изменить
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogActions>

            </Dialog>
        )
    }
}

export default DialogChangeStatusPayment
