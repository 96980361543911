import Store from './Store';
import { connect } from 'react-redux';
// @flow
import { compose } from 'recompose';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(Store);
