import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {
    Box, Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, Grid,
    InputLabel, ListSubheader,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import {Formik} from "formik";
import {makeStyles} from "@mui/styles";
import {formatPhone} from "../../../../../../helper/formatPhone";
import * as Yup from "yup";

const initialValue = {
    store: '',
}

const DialogFormSetProvider = (props) => {
    const {
        stores,
        isOpen,
        onClose,
        setProvider
    } = props;
    const classes = useStyles();
    const refFormik = useRef(null);
    const [groupStores, setGroupStores] = useState([]);

    useEffect(() => {
        for (const store of stores) {
            setGroupStores(prev => {
                const existingStores = prev.filter(item => item.ownerUser.id === store.ownerUser.id);
                if (existingStores.length === 0) {
                    return [...prev, {
                        ownerUser: store.ownerUser,
                        stores: stores.filter(item => item.ownerUser.id === store.ownerUser.id),
                    }];
                } else {
                    return prev;
                }
            })
        }
    }, []);

    const onSubmit = (form) => {
        onClose();
        const newForm = {
            storeId: form.store.id,
            providerId: form.store.ownerUser.id,
        };
        setProvider(newForm);
    };
    const handleChange = ({target}) => {
        const {name, value} = target;
        const newForm = refFormik.current.values;
        newForm[name] = value;

        refFormik.current.setValues(newForm);
    }
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={isOpen}
            onClose={onClose}
        >
            <DialogTitle>
                <Typography variant="h3">Назначение исполнителя</Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValue}
                    validationSchema={validateSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;
                        return (
                            <>
                                <Box>
                                    <FormControl
                                        fullWidth
                                    >
                                        <InputLabel error={touched.store && Boolean(errors.store)}
                                                    id="provider-select-label">Склад</InputLabel>
                                        <Select
                                            labelId="provider-select-label"
                                            value={values.store}
                                            name="store"
                                            label="Склад"
                                            error={touched.store && Boolean(errors.store)}

                                            onChange={handleChange}
                                        >
                                            {groupStores.map(groupStore => (
                                                [
                                                    <ListSubheader key={groupStore.id} className={classes.menuHeader}>
                                                        <Box className={classes.rowContent}>
                                                            <Typography className={classes.title}>Исполнитель:</Typography>
                                                            <Typography className={classes.value}>
                                                                {groupStore?.ownerUser?.firstName || "-"} {groupStore?.ownerUser?.lastName || "-"}
                                                            </Typography>
                                                        </Box>
                                                        <Box className={classes.rowContent}>
                                                            <Typography className={classes.title}>Телефон:</Typography>
                                                            <Typography className={classes.value}>
                                                                {formatPhone(groupStore?.ownerUser?.phone) || '-'}
                                                            </Typography>
                                                        </Box>
                                                        <Typography className={classes.title}>Склады:</Typography>
                                                    </ListSubheader>,
                                                    groupStore.stores.map(store => (
                                                        <MenuItem className={classes.menuItem} key={store.id}
                                                                  value={store}>{store.name}</MenuItem>
                                                    ))
                                                ]
                                            ))}
                                        </Select>
                                        {errors.store && touched.store
                                            ? (
                                                <Typography mt={0.5} ml={0.5}
                                                            color={errors.store && touched.store ? "error" : null}>{errors.store}</Typography>
                                            )
                                            : null
                                        }
                                    </FormControl>
                                </Box>
                                <DialogActions>
                                    <Box>
                                        <Grid container justifyContent="flex-end" spacing={1}>
                                            <Grid item>
                                                <Button variant="outlined" onClick={onClose}
                                                        sx={{
                                                            textTransform: "initial",
                                                            borderRadius: "4px",
                                                            padding: "4px 24px"
                                                        }}>
                                                    Отменить
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" onClick={handleSubmit}>
                                                    Назначить
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const validateSchema = Yup.object().shape({
    store: Yup.object().required('Выберите склад'),
})

const useStyles = makeStyles({
    dialogContent: {
        '&.MuiDialogContent-root': {
            paddingTop: 8,
        }
    },
    menuHeader: {
        '&.MuiListSubheader-root': {
            background: 'rgba(132, 185, 44)',
            paddingTop: 8,
            paddingBottom: 8,
        }
    },

    rowContent: {
        display: "flex",
        alignItems: 'center',
        marginBottom: 4,
    },

    title: {
        "&.MuiTypography-root": {
            fontSize: 18,
            fontWeight: 500,
            color: "#FFFFFF",
        }
    },
    value: {
        "&.MuiTypography-root": {
            fontWeight: 700,
            fontSize: 18,
            color: "#FFFFFF",
            marginLeft: 8,
        }
    },

    menuItem: {
        '&.MuiMenuItem-root': {
            fontWeight: 400,
            fontSize: 20,
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 24,
        }
    }
})

export default DialogFormSetProvider;