import React from 'react';
import {
    Box,
    Button,

    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,

    IconButton,

    Tooltip,
    Typography
} from "@mui/material";
import {
    AttachFileOutlined as AttachFileIcon,
    Delete as DeleteIcon,
    Upload as UploadIcon
} from "@mui/icons-material";
import {
    Formik
} from "formik";
import {
    makeStyles
} from "@mui/styles";
import {
    Notification,
    NotificationTypes
} from "../../../../../../common/Notification";
import {
    getBase64
} from "../../../../../../helper/convertor";

const DialogBillOfLadingPhotos = (props) => {
    const {
        isOpen,
        onAttach,
        onClose
    } = props;

    const classes = useStyles();
    const refFormik = React.useRef(null);
    const [initialValues, setInitialValues] = React.useState({
        photos: [],
    });

    React.useEffect(() => {
        if (isOpen) {
            setInitialValues({photos: []});
        }
    }, [isOpen]);

    const onSubmit = async (form) => {
        const photos = form.photos;

        if (photos.length > 0) {
            let photosBase64 = [];

            for await (let photo of photos) {
                let newPhotoBase64 = await getBase64(photo.file).then(res => res);

                photosBase64.push(newPhotoBase64);
            }

            onAttach(photosBase64);
            handleCloseModal();

            return;
        }

        return Notification({
            message: "Прикрепите фотографии",
            type: NotificationTypes.error,
        });
    };

    const handleCloseModal = () => {
        onClose();
    };

    const handleChangeImage = (event) => {
        const file = event?.target.files?.[0] || null;

        let newForm = refFormik.current?.values || {};
        let image_id = {
            file: '',
            source: '',
        };
        image_id.file = file;
        image_id.source = URL.createObjectURL(file);
        newForm.photos = [...newForm.photos, image_id];
        refFormik.current.setValues(newForm);
    };
    const handleDeleteImage = (index) => {
        const newForm = refFormik.current.values;
        newForm.photos.splice(index, 1);

        refFormik.current.setValues(newForm);
    };

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth="md"
            onClose={handleCloseModal}
        >
            <DialogTitle>
                <Typography variant='h3'>Добавить накладные</Typography>
                <Typography variant='h4' mt={2}>
                    Прикрепите фотографии накладных на которых поставлены печати
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box pt={1} mb={1}>
                                    {values.photos.length > 0 &&
                                        <>
                                            {values.photos.map((photo, index) => (
                                                <Box
                                                    key={`bill-of-lading-image-${photo?.file?.name || 'fileName'}-${index}`}
                                                >
                                                    <ReturnFillImage
                                                        imageId={photo}
                                                        indexPhoto={index}
                                                        viewImage
                                                    />
                                                    <ReturnFillImage
                                                        imageId={photo}
                                                        indexPhoto={index}
                                                        viewFile
                                                        handleDeleteImage={handleDeleteImage}
                                                    />
                                                </Box>
                                            ))}
                                        </>
                                    }

                                    {values.photos.length < 4 && (
                                        <Button
                                            className={classes.uploadButton}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                        >
                                            <label style={{
                                                width: "100%",
                                                cursor: "pointer",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}>
                                                <UploadIcon sx={{marginRight: "5px"}}/>
                                                Выберите файл
                                                <input
                                                    width="100%"
                                                    type="file"
                                                    name="image_id"
                                                    accept="image/*"
                                                    hidden
                                                    onChange={(event) => handleChangeImage(event)}
                                                />
                                            </label>
                                        </Button>
                                    )}
                                </Box>

                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            textTransform: "initial",
                                            borderRadius: "4px",
                                            padding: "4px 24px"
                                        }}

                                        onClick={handleCloseModal}
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            textTransform: "initial",
                                            borderRadius: "4px",
                                            padding: "4px 24px"
                                        }}

                                        onClick={handleSubmit}
                                    >
                                        Прикрепить
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const ReturnFillImage = React.memo((props) => {
    const {
        imageId,
        indexPhoto,
        viewImage,
        viewFile,

        handleDeleteImage
    } = props;

    return (<>
        {Boolean(viewImage)
            && (
                <Box
                    width="100%"
                    height={500}
                    sx={{
                        backgroundColor: "rgba(132,185,44, .2)",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 10,
                        boxSizing: "border-box",
                    }}
                    mb={2}
                >
                    <img src={imageId.source} style={{
                        display: "block", width: "100%", height: "100%", objectFit: "contain", borderRadius: 5,
                    }} alt=""/>
                </Box>
            )
        }
        {Boolean(viewFile)
            && (
                <Box display="flex" mb={2}>
                    <Box
                        sx={{
                            flex: 8,
                            display: "flex",
                            alignItems: "center",
                            padding: "5px",
                            background: "#84b92c",
                            borderRadius: "4px",
                            color: "#fff",
                            fontSize: "14px",
                            justifyContent: "center",
                            marginRight: "5px"
                        }}
                    >
                        <AttachFileIcon
                            sx={{
                                marginRight: "5px",
                            }}
                        />
                        {imageId?.file?.name}
                    </Box>
                    <Tooltip title="Удалить изображение">
                        <IconButton
                            color="error"

                            onClick={() => handleDeleteImage(indexPhoto)}
                        >
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                </Box>
            )
        }
    </>);
});

const useStyles = makeStyles({
    uploadButton: {
        '&.MuiButton-root': {
            padding: 0,

            "& > label": {
                padding: 0,
                height: "100%",
            },
        },
    },
});

export default DialogBillOfLadingPhotos;