import React from "react";
import {
    Autocomplete,
    TextField
} from "@mui/material";
import agent from "../../agent/agent";

class AutocompleteCategory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],

            isLoad: true
        };
    }

    componentDidMount = async () => {

        if (this.props.value) {
            await this.getCategories()
        }

    }

    getCategories = async () => {

        if (this.state.users.length > 0) {
            return null
        }

        const users = await agent.get(`/users?limit=50&page=1`).then((res) => {
            return res.data.users || []
        }).catch(() => {
            return []
        });

        this.setState({
            users,
            isLoad: false
        })

    }

    onChange = (value) => {

        let object = {
            target: {
                name: this.props.name,
                value: value?.id || undefined
            }
        };

        this.props.onChange(object)

    }

    _getOptionLabel = (row) => {
        const options = this.state.users || [];

        if (typeof row !== 'object') {
            const item = options.find((t) => String(t.id) === String(row));

            if (!item) {
                return ""
            }

            return `${ item?.firstName } ${ item?.lastName } (${ item?.id })`
        }

        return `${ row?.firstName } ${ row?.lastName } (${ row?.id })`
    }
    _filterOptions = (list, params) => {
        const inputValue = params?.inputValue || "";

        return [...list].filter((t) => {

            if (!inputValue) {
                return true
            }

            const nameLowerCase = t.name.toLowerCase();
            const inputValueLowerCase = inputValue.toLowerCase();

            return Boolean(nameLowerCase.indexOf(inputValueLowerCase) > -1)

        })
    }

    render() {
        const {
            value,
            name
        } = this.props;
        const {
            users,
            isLoad
        } = this.state;

        return (
            <>

                <Autocomplete
                    {...this.props}
                    value={value}
                    disablePortal
                    options={users}
                    onChange={(event, value) => this.onChange(value)}
                    name={name}
                    variant="outlined"
                    fullWidth
                    disableClearable
                    autoComplete={false}
                    loading={isLoad}
                    onOpen={this.getCategories}
                    limitTags={1}

                    getOptionLabel={this._getOptionLabel}
                    filterOptions={this._filterOptions}

                    renderInput={(params) => <TextField
                        {...this.props}
                        {...params}
                        fullWidth
                    />}
                />

            </>
        );
    }
}

export default AutocompleteCategory
