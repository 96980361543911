import React, { Component } from 'react';
import { Backdrop, Box, CircularProgress, Container, Typography } from "@mui/material";
import {
    DialogFormEditBalance as DialogFormEditBalanceComponent,
    Filter as FilterComponent,
    TableCustom as TableCustomComponent
} from "./components";
import queryString from "query-string";
import agent from "../../../../agent/agent";
import { DialogConfirmAction } from "../../../../components";
import { Notification, NotificationTypes } from "../../../../common/Notification";

const initialFilter = {
    "sortInfo": "-id",
    'approximateId': '',
};

const visibleColumns = [
    {
        id: "id",
        label: "№ кошелька",
        sortable: true,
        width: 120,
        align: 'center',
    },
    {
        id: 'user',
        label: "Пользователь",
        sortable: false,
        width: 'auto',
        align: 'center'
    },
    {
        id: "balance",
        label: "Баланс",
        sortable: true,
        width: 'auto',
        align: 'center',
    },
    {
        id: 'balanceFrozen',
        label: 'Забронировано',
        sortable: true,
        width: 'auto',
        align: 'center',
    },
    {
        id: 'actions',
        label: '',
        sortable: false,
        width: 60,
        align: 'center',
    }
];

class Wallets extends Component {
    constructor(props) {
        super( props );
        this.state = {
            wallets: [],
            openedWalletId: null,

            filter: {
                ...initialFilter
            },
            pagination: {
                page: 1,
                totalPage: 1
            },

            isOpenEditBalance: false,

            isLoadingWallets: true,
            isShowBackdrop: false,
        };

        this.refDialogConfirmAction = React.createRef();
    }

    componentDidMount = async () => {
        if (this.props.user.phone !== '79827185290') {
            this.props.history.push('/');

            Notification({
                message: 'У вас нет доступа к этой странице',
                type: NotificationTypes.warning,
            });

            return;
        }

        await this.initFilter();
        await this.getWallets();
    };

    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse( locationSearch, {
            arrayFormat: "bracket"
        } );
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState( {
            filter,
            pagination,

            initOpenFilter: Object.keys( parseSearch || {} ).length > 0
        } );
    }

    // Логика получения кошельков
    getWallets = async () => {
        this.setState( {
            isLoadingWallets: true,
        } );

        const filter = this.getFilters();
        const {
            wallets,
            totalCount
        } = await agent.get( `/finance/wallets${ filter }&limit=20` )
            .then( (res) => {
                return {
                    wallets: res?.data?.wallets || [],
                    totalCount: res?.data?.count || 0,
                }
            } )
            .catch( () => {
                return {
                    wallets: [],
                    totalCount: 0,
                }
            } );

        let pagination = { ...this.state.pagination };
        pagination.totalPage = Math.ceil( totalCount / 20 ) || 1;

        const newWallets = [];

        wallets.forEach( (wallet) => {
            const walletUsers = [];

            wallet.users.forEach( (user) => {
                walletUsers.push( `${ user.firstName } ${ user.lastName }` );
            } );

            newWallets.push( {
                ...wallet,
                users: walletUsers,
            } );
        } );

        this.setState( {
            isLoadingWallets: false,
            pagination,
            wallets: newWallets,
        } );
    };

    // Логика редактирования баланса
    onEditBalance = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open( {
                title: "Подтверждение",
                message: "Вы действительно хотите изменить баланс?",
                labelSuccess: "Да, изменить",
                onSuccess: this.onEditBalance.bind( this, form, true ),
            } );

            return
        }

        this.setState( { isShowBackdrop: true } );

        const body = {
            balance: +form?.balance || 0,
            balanceFrozen: +form?.balanceFrozen || 0,
        }

        const response = await agent.patch( `/finance/wallets/${ form.walletId }/update-balance`, body )
            .then( res => res.data )
            .catch( err => {
                return {
                    error: err.response,
                };
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            Notification( {
                message: response.error?.data?.message || "Ошибка при изменении баланса",
                type: NotificationTypes.error,
            } );

            return;
        }

        await this.getWallets();
        this.setState( { isShowBackdrop: false } );
        Notification( {
            message: "Баланс кошелька успешно изменен",
            type: NotificationTypes.success,
        } );
    };

    // Логика работы с фильтром
    getFilters = () => {
        const filter = { ...this.state.filter };
        const pagination = { ...this.state.pagination };

        let string = [
            `page=${ pagination.page }`
        ];
        Object.keys( filter ).map( (key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push( `${ key }=${ value }` );
            }
        } );

        window.history.replaceState( null, null, `/wallets?${ string.join( "&" ) }` );

        return `?${ string.join( "&" ) }`
    }

    changeFilter = async (filter, isFastStart) => {
        await this.setState( {
            filter: {
                ...filter,
            },
        } );

        if (!isFastStart) {
            return null
        }

        await this.getWallets();

    }

    changePagination = async (pagination) => {
        await this.setState( { pagination } );
        await this.getWallets();
    }

    resetFilter = async () => {
        await this.setState( { filter: { ...initialFilter } } );
        await this.getWallets();
    }

    // Открытие формы редактирования баланса
    setIsOpenEditBalance = (isOpen, id = null) => {
        this.setState( {
            isOpenEditBalance: isOpen,
            openedWalletId: id,
        } );
    };
    // Закрытие формы редактирования баланса
    handleCloseEditBalance = () => {
        this.setIsOpenEditBalance( false );
    };

    render() {
        const {
            filter,
            wallets,
            openedWalletId,
            pagination,

            isOpenEditBalance,

            isLoadingWallets,
            isShowBackdrop
        } = this.state;
        return (
            <Container maxWidth='xl'>
                <Box mb={ 5 }>
                    <Typography variant="h2">Кошельки пользователей</Typography>
                </Box>

                <FilterComponent
                    filter={ filter }
                    onChangeFilter={ this.changeFilter }
                    onResetFilter={ this.resetFilter }
                    onSearch={ this.getWallets }
                />

                <TableCustomComponent
                    data={ wallets }
                    filter={ filter }
                    pagination={ pagination }
                    isLoading={ isLoadingWallets }
                    visibleColumns={ visibleColumns }

                    onChangePagination={ this.changePagination }
                    onChangeFilter={ this.changeFilter }
                    onOpenEditBalance={ this.setIsOpenEditBalance }
                />

                <DialogFormEditBalanceComponent
                    walletId={ openedWalletId }
                    isOpen={ isOpenEditBalance }
                    onClose={ this.handleCloseEditBalance }
                    onEdit={ this.onEditBalance }
                />

                <DialogConfirmAction
                    ref={ this.refDialogConfirmAction }
                />

                <Backdrop open={ isShowBackdrop }>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </Container>
        );
    }
}

export default Wallets;
