import React, {Component} from "react";
import {
    Container,

    Backdrop,
    CircularProgress, Button, Box
} from "@mui/material";
import {
    Form as FormComponent
} from "./components";
import {
    Logout as LogoutIcon
} from "@mui/icons-material";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification"
import agent from "../../../../agent/agent";
import {convertorNumber} from "../../../../helper/convertor";
import {withStyles} from "@mui/styles";
import {findByLabelText} from "@testing-library/react";

class Settings extends Component {
    constructor(props) {
        super(props);

        this.state = {

            settings: {},

            isLoading: true,
            isShowBackdrop: false
        };
    }

    componentDidMount = async () => {
        await this.getSettings();
    }

    logOutUser = () => {
        localStorage.removeItem('jwt');

        this.props.setUser(null);
    }

    getSettings = async () => {
        let settings = await agent.get(`/settings`).then((res) => {
            return res.data?.settings || {}
        }).catch(() => {
            return {}
        })
        settings.newOrderLifetime = settings.newOrderLifetime / 60 / 60;

        this.setState({
            settings,
            isLoading: false
        })
    }

    changeSettings = (settings) => {
        this.setState({
            settings
        })
    }

    saveSettings = async () => {

        const errors = this._isErrorsForm();
        if (!!errors.length) {
            Notification({
                message: errors.join("; "),
                type: NotificationTypes.error
            })

            return
        }

        this.setState({isShowBackdrop: true})

        let form = {...this.state.settings};
        delete form.id;
        delete form.createdAt;
        delete form.updatedAt;
        form.deliveryMinDays = Number(form.deliveryMinDays);
        form.deliveryMaxDays = Number(form.deliveryMaxDays);
        form.centerLat = Number(form.centerLat);
        form.centerLong = Number(form.centerLong);
        form.systemPercent = Number(form.systemPercent);
        form.driverBonus = Number(form.driverBonus);
        form.newOrderLifetime = Number(form.newOrderLifetime) * 60 * 60;
        form.deliveryTariffs = form.deliveryTariffs.map((item) => {
            return {
                from: Number.parseFloat(item.from),
                to: Number.parseFloat(item.to),
                price: Number.parseFloat(item.price),
            }
        })
        form.expeditorDeliveryTariffs = form.expeditorDeliveryTariffs.map(item => {
            return {
                from: Number.parseFloat(item.from),
                to: Number.parseFloat(item.to),
                price: Number.parseFloat(item.price),
            }
        });
        form.countCbmInOneOrder = +form.countCbmInOneOrder;

        const response = await agent.put(`/settings`, form).then((res) => {
            return res.data
        }).catch((err) => {
            return {error: err.response}
        })

        if (response.error) {
            Notification({
                type: NotificationTypes.error,
                message: response.error?.data?.message || "Ошибка при изменении настроек",
            });

            return
        }

        await this.getSettings();

        this.setState({isShowBackdrop: false})

        Notification({
            type: NotificationTypes.success,
            message: "Настройки успешно изменены"
        })

    }

    _isErrorsForm = () => {
        const settings = {...this.state.settings};

        let errors = [];

        if (!settings.newOrderLifetime) {
            errors.push("Заполните \"Срок службы заказа\"")
        }

        (settings.deliveryTariffs || []).map((item, index) => {
            let error = [];

            if (item.from === null) {
                error.push(`Дистанция (от)`)
            }
            if (item.to === null) {
                error.push(`Дистанция (до)`)
            }
            if (item.price === null) {
                error.push(`Стоимость`)
            }

            if (error.length > 0) {
                errors.push(`Обнаружены ошибки в "Тарифы для доставки" в строке ${index + 1}: ${error.join(',')}`)
            }
        })


        return errors
    }

    render() {
        const {
            settings,

            isLoading
        } = this.state;
        const {
            classes
        } = this.props;

        return (
            <Container maxWidth="xl">

                {Boolean(!isLoading) && (
                    <>
                        <Box className={classes.buttonWrapper}>
                            <Button
                                endIcon={<LogoutIcon/>}
                                color="secondary"
                                variant="contained"
                                className={classes.buttonLogOut}

                                onClick={this.logOutUser}
                            >
                                Выход
                            </Button>
                        </Box>
                        <FormComponent
                            data={settings}

                            onChange={this.changeSettings}
                            onSave={this.saveSettings}
                        />
                    </>
                )}

            </Container>
        );
    }
}

const styles = {
    buttonWrapper: {
        width: "100%",
        display: 'flex',
        justifyContent: "flex-end",
    },
    buttonLogOut: {
        height: 40,
        padding: "0 32px",
        background: "#000000",
        borderRadius: 4,
        marginBottom: 32,
        marginTop: 8,

        "&:hover": {
            background: "#000000",
        }
    }
}

export default withStyles(styles)(Settings);
