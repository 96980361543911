import { WarningAmber as WarningAmberIcon } from "@mui/icons-material";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const InfoMessage = (props) => {
    const { message, type } = props;
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Grid
                container
                spacing={2}
                flexDirection="column"
                alignItems="center"
            >
                <Grid item>
                    <Box className={classes.iconContainer}>
                        {Boolean(type === "loading") && (
                            <CircularProgress
                                sx={{ color: "rgba(0,0,0,0.9)" }}
                            />
                        )}
                        {Boolean(type === "warn") && (
                            <WarningAmberIcon
                                sx={{ color: "rgba(0,0,0,0.9)", fontSize: 32 }}
                            />
                        )}
                    </Box>
                </Grid>
                <Grid item>
                    <Typography className={classes.message}>
                        {message}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        borderRadius: 10,
        backgroundColor: "rgba(132, 185, 44, 0.15)",

        padding: 24,
    },
    iconContainer: {
        width: 44,
        height: 44,
    },
    message: {
        fontSize: 24,
        lineHeight: "28px",
        letterSpacing: "-0.2px",
        color: "black",
        opacity: 0.9,
        textAlign: "center",
    },
}));

export default InfoMessage;
