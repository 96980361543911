import {palette} from "../common";

export default {
    styleOverrides: {
        root: {
            boxShadow: "0px 4px 8px rgb(0 0 0 / 5%)",
            borderRadius: 10
        }
    }
}
