import React from 'react';
import {
    Box, Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@mui/material";
import {
    Formik
} from "formik";
import {
    AddressSuggestionsComponent,
    AutocompleteUser_1
} from "../../../../../../components";
import * as Yup from "yup";

const DialogEditStore = (props) => {
    const {
        store,
        isOpen,
        onClose,
        onEdit,
    } = props;

    const refFormik = React.useRef(null);

    const initialValues = {
        ...store,
        address: {
            value: store.address,
        },
        coords: [store.addressLat, store.addressLong],
        latitude: store.addressLat + "",
        longitude: store.addressLong + "",
    }

    const onSubmit = (form) => {
        const newForm = {
            name: form.name,
            description: form.description,
            address: form?.address?.value || '',
            addressLat: form?.coords[0] || 0,
            addressLong: form?.coords[1] || 0,
            reserve: form.reserve,
            ownerUserId: form.ownerUserId,
        };

        onEdit(newForm);
        onClose();
    };

    const handleChange = ({target}) => {
        const {name, value} = target;
        const newForm = refFormik.current.values;

        newForm[name] = value;

        refFormik.current.setValues(newForm);
    };

    const handleChangeAddress = (value, coords) => {
        const newForm = refFormik.current.values;

        newForm.address = {...value};
        newForm.coords = coords || [null, null];
        newForm.latitude = String(coords?.[0]) || "0";
        newForm.longitude = String(coords?.[1]) || "0";

        refFormik.current.setValues(newForm);
    };

    const resetAddress = () => {
        const newForm = refFormik.current.values;

        newForm.coords = [null, null];
        newForm.latitude = '';
        newForm.longitude = '';
        newForm.address = {value: ''};

        refFormik.current.setValues(newForm);
    };

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth='md'
            onClose={onClose}
        >
            <DialogTitle>
                Редактирование склада
            </DialogTitle>

            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box pt={1}>
                                    <Box mb={2}>
                                        <TextField
                                            fullWidth
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name && errors.name}
                                            name='name'
                                            value={values.name}
                                            label='Название склада'

                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box mb={2}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            error={touched.description && Boolean(errors.description)}
                                            helperText={touched.description && errors.description}
                                            name='description'
                                            value={values.description}
                                            label='Описание склада'
                                            rows={4}

                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box mb={2}>
                                        <AddressSuggestionsComponent
                                            addressValue={values.address}
                                            coordsValue={values.coords}
                                            latitudeValue={values.latitude}
                                            longitudeValue={values.longitude}
                                            touched={touched}
                                            errors={errors}

                                            onChangeAddress={handleChangeAddress}
                                            onResetAddress={resetAddress}
                                        />
                                    </Box>
                                </Box>
                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        sx={{borderRadius: "4px", textTransform: "initial"}}

                                        onClick={onClose}
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{borderRadius: "4px", textTransform: "initial"}}

                                        onClick={handleSubmit}
                                    >
                                        Изменить
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

Yup.addMethod(Yup.array, "tuple", function (schema) {
    if (!this.isType(schema)) Yup.ValidationError();
    return Yup.object({
        tuple: Yup.array().min(schema.length).max(schema.length), ...Object.fromEntries(Object.entries(schema)),
    }).transform((value, originalValue) => {
        if (!this.isType(originalValue)) Yup.ValidationError();
        return {
            tuple: originalValue, ...Object.fromEntries(Object.entries(originalValue)),
        };
    });
});

const validationSchema = Yup.object().shape({
    coords: Yup.array().tuple([Yup.number()
        .required("Обязательное поле")
        .typeError("Обязательное поле")
        .min(-90, "Минимальное значение -90")
        .max(90, "Максимальное значение 90"), Yup.number()
        .required("Обязательное поле")
        .typeError("Обязательное поле")
        .min(-180, "Минимальное значение -180")
        .max(180, "Максимальное значение 180"),]),

    latitude: Yup.string().required('Введите широту').min(-90, "Минимальное значение -90").max(90, "Максимальное значение 90"),
    longitude: Yup.string().required('Введите долготу').min(-180, "Минимальное значение -180").max(180, "Максимальное значение 180"),
    name: Yup.string().required('Введите название'),
    description: Yup.string().required('Введите описание'),
    address: Yup.object({
        value: Yup.string().required('Введите адрес'),
    }).required('Введите адрес'),
});

export default React.memo(DialogEditStore);