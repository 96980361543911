import React from 'react';
import {
    Box, Button,
    IconButton,

    Pagination,

    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,

    Tooltip, Typography
} from "@mui/material";
import {
    Skeleton
} from "@mui/lab";
import {
    TuneRounded as SettingsIcon,
    FileDownloadRounded as DownloadIcon
} from '@mui/icons-material';
import moment from "moment/moment";
import {
    makeStyles
} from "@mui/styles";
import {convertorNumber} from "../../../../../../helper/convertor";
import invoicePaymentStatus from "../../../../../../constants/invoicePaymentStatus";

const TableInvoices = (props) => {
    const {
        invoices,
        isLoading,
        filter,
        pagination,

        onRouteOrganization,
        onOpenUserCart,
        onOpenEditPaymentStatus,
        onChangePagination,
        onChangeFilter,
    } = props;

    const classes = useStyles();

    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination)
    }

    const handleChangeFilter = (name, value) => {
        const newFilter = {...filter};
        newFilter[name] = value;

        onChangeFilter(newFilter, true);
    };

    const routeToDownloadInvoice = (url) => {
        window.open(url, '_blank');
    };

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            ID
                        </TableCell>
                        <TableCell>
                            Дата
                        </TableCell>
                        <TableCell align="center">
                            Номер счета
                        </TableCell>
                        <TableCell align="center">
                            Номер заказа
                        </TableCell>
                        <TableCell>Заказчик</TableCell>
                        <TableCell>Общая стоимость</TableCell>
                        <TableCell>Статус оплаты</TableCell>
                        <TableCell width="90px"/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading
                        ? <>
                            {[1, 2, 3, 4, 5].map(item => (
                                <TableRow key={`invoices-skeleton-${item}`}>
                                    <TableCell><Skeleton/></TableCell>
                                    <TableCell><Skeleton/></TableCell>
                                    <TableCell><Skeleton/></TableCell>
                                    <TableCell><Skeleton/></TableCell>
                                    <TableCell><Skeleton/></TableCell>
                                    <TableCell><Skeleton/></TableCell>
                                    <TableCell><Skeleton/></TableCell>
                                    <TableCell><Skeleton/></TableCell>
                                </TableRow>
                            ))}
                        </>
                        : <>
                            {invoices.map(invoice => (
                                <TableRow key={`invoice-item-${invoice.id}`}>
                                    <TableCell>{invoice?.id || "-"}</TableCell>
                                    <TableCell>{moment(invoice?.createdAt).format("DD.MM.YYYY HH:mm") || "-"}</TableCell>
                                    <TableCell align="center">{invoice?.paymentId || '-'}</TableCell>
                                    <TableCell align="center">{invoice?.payment?.orderId || "-"}</TableCell>
                                    <TableCell align="center">
                                        <Box className={classes.rowContent}>
                                            <Typography>Заказчик:</Typography>
                                            <Button
                                                className={classes.userNameButton}
                                                variant="text"

                                                onClick={onOpenUserCart.bind(this, invoice?.payment?.order?.purchaserUser || {}, false)}
                                            >
                                                {invoice?.payment?.order?.purchaserUser?.firstName || "-"} {invoice?.payment?.order?.purchaserUser?.lastName || "-"}
                                            </Button>
                                        </Box>
                                        <Box className={classes.rowContent}>
                                            <Typography>Организация:</Typography>
                                            <Button
                                                className={classes.userNameButton}
                                                variant="text"

                                                onClick={onRouteOrganization.bind(this, invoice?.payment?.order?.purchaserUser?.organization?.id || '')}
                                            >
                                                {invoice?.payment?.order?.purchaserUser?.organization?.name || "-"}
                                            </Button>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        {convertorNumber(invoice?.payment?.order?.totalPrice || 0, 2)} ₽
                                    </TableCell>
                                    <TableCell>
                                        {invoicePaymentStatus[invoice?.payment?.status] || '-'}
                                    </TableCell>
                                    <TableCell>
                                        <Box className={classes.rowContent}>
                                            <Tooltip title="Изменить статус оплаты">
                                                <IconButton
                                                    color="primary"

                                                    onClick={onOpenEditPaymentStatus.bind(this, true, invoice)}
                                                >
                                                    <SettingsIcon color="primary"/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Скачать счет">
                                                <IconButton
                                                    color="primary"

                                                    onClick={routeToDownloadInvoice.bind(this, invoice?.documentLink || '')}
                                                >
                                                    <DownloadIcon color="primary"/>
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    }
                </TableBody>
            </Table>

            <Box mt={3}/>

            {invoices.length > 0 && (
                <Pagination
                    page={Number(pagination.page)}
                    count={Number(pagination.totalPage)}

                    onChange={handleChangePage}
                />
            )}
        </>
    );
};

const useStyles = makeStyles({
    rowContent: {
        display: "flex",
        alignItems: "center",
    },

    userNameButton: {
        '&.MuiButton-root': {
            padding: 0,
            margin: "0 0 0 4px",
            display: 'flex',
            justifyContent: "flex-start",
            color: "#84b92c",
            textTransform: "none",
            height: "auto",
            fontSize: 14,
            letterSpacing: "-0.05px",
            lineHeight: "21px",
            fontWeight: 400,
            borderRadius: 0,
            textAlign: "left",
        },
    },
})

export default TableInvoices;