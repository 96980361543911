import React, {Component} from 'react';
import agent from "../../../../agent/agent";
import {
    MainInformation as MainInformationComponent,
    TableComponent
} from './components';
import {
    Backdrop,
    Box,
    CircularProgress,
    Container
} from "@mui/material";
import {
    DialogConfirmAction,
    DialogUserCart
} from "../../../../components";
import queryString from "query-string";
import { Notification, NotificationTypes } from "../../../../common/Notification";

const initialFilter = {
    'sortInfo': "-createdAt",
    'personType': "physical",
    'type': 'current',
    'limit': 10,
};

const visibleColumns = [
    {
        id: "createdAt",
        label: "Дата",
        sortable: true,
        width: "auto",
        align: 'left',
    },
    {
        id: "id",
        label: "№ ПП",
        sortable: false,
        width: 'auto',
        align: 'left',
    },
    {
        id: "user",
        label: "Пользователь",
        sortable: false,
        width: 'auto',
        align: 'left',
    },
    {
        id: "amount",
        label: "Сумма",
        sortable: true,
        width: 'auto',
        align: 'right',
    },
    {
        id: "actions",
        label: "",
        sortable: false,
        width: 'auto',
        align: 'right',
    },
];

class RegistryPhysical extends Component {
    constructor(props) {
        super(props);
        this.state = {
            registries: [],
            filter: {
                ...initialFilter
            },
            pagination: {
                page: 1,
                totalPage: 1
            },
            resultSum: 0,

            isLoading: true,

            isShowBackdrop: false,
        };

        this.refDialogConfirmAction = React.createRef();
        this.refDialogUserCart = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getRegistries();
    };

    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }

    getRegistries = async () => {
        this.setState({
            isLoading: true,
        })

        const filter = this.getFilters();
        const {requests, resultSum, count} = await agent.get(`/request-for-withdrawal-funds/registry${filter}`)
            .then(res => res.data)
            .catch(err => {
                return {requests: [], resultSum: 0, count: 0};
            });

        let registries = [];

        if (requests.length > 0) {
            registries = [...requests.map(item => {
                return {
                    ...item,
                }
            })];
        }

        let pagination = {...this.state.pagination};
        pagination.totalPage = Math.ceil(count / this.state.filter.limit) || 1;

        this.setState({
            registries,
            resultSum,
            pagination,
            isLoading: false,
        });
    };

    //Функция для открытия карточки пользователя
    openUserCart = (userRow, isOpen = false) => {
        if (!isOpen) {
            this.refDialogUserCart.current.open(userRow, this.openUserCart.bind(this));
            return null;
        }

        this.setState({isShowBackdrop: true});
    };

    // Логика ручной выплаты
    manualPayout = async ( id, isConfirm ) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open( {
                message: "Вы действительно хотите провести выплату?",
                labelSuccess: "Да, провести",
                onSuccess: this.manualPayout.bind( this, id, true ),
            } );

            return null;
        }

        this.setState( { isShowBackdrop: true } );

        const response = await agent.put( `/request-for-withdrawal-funds/manually-withdrawal/${ id }` )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            Notification( {
                message: response.error?.data?.message || "Ошибка при проведении выплаты, попробуйте позже",
                type: NotificationTypes.error,
            } );

            return;
        }

        await this.getRegistries();
        this.setState( { isShowBackdrop: false } );

        Notification( {
            message: "Выплата успешно проведена",
            type: NotificationTypes.success,
        } );
    };

    // Логика отмены выплаты
    cancelPayout = async ( id, isConfirm ) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open( {
                message: "Вы действительно хотите отменить выплату?",
                labelSuccess: "Да, отменить",
                onSuccess: this.cancelPayout.bind( this, id, true ),
            } );

            return null;
        }

        this.setState( { isShowBackdrop: true } );

        const response = await agent.delete( `request-for-withdrawal-funds/${ id }` )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response };
            } );


        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            Notification( {
                message: response.error?.data?.message || "Ошибка при отмене выплаты, попробуйте позже",
                type: NotificationTypes.error,
            } );

            return;
        }

        await this.getRegistries();
        this.setState( { isShowBackdrop: false } );

        Notification( {
            message: "Выплата успешно отменена",
            type: NotificationTypes.success,
        } );
    };

    // Логика работы с фильтром
    getFilters = () => {
        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};

        let string = [
            `page=${pagination.page}`
        ];
        Object.keys(filter).map((key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push(`${key}=${value}`);
            }
        });

        window.history.replaceState(null, null, `/registry/withdrawal/physical?${string.join("&")}`);

        return `?${string.join("&")}`
    }

    changeFilter = async (filter, isFastStart) => {
        await this.setState({filter});

        if (!isFastStart) {
            return null
        }

        await this.getRegistries();

    }

    changePagination = async (pagination) => {
        await this.setState({pagination});
        await this.getRegistries();
    }

    render() {
        const {
            registries,
            filter,
            pagination,
            resultSum,
            isLoading,
            isShowBackdrop
        } = this.state;

        return (
            <>
                <Container maxWidth="xl">
                    <Box mb={ 5 }>
                        <MainInformationComponent
                            totalSum={ resultSum }
                        />
                    </Box>
                    <Box>
                        <TableComponent
                            registries={ registries || [] }
                            filter={ filter }
                            pagination={ pagination }
                            visibleColumns={ visibleColumns }
                            isLoading={ isLoading }
                            onOpenUserCart={ this.openUserCart }
                            onChangePagination={ this.changePagination }
                            onChangeFilter={ this.changeFilter }
                            onManualPayout={ this.manualPayout }
                            onCancelPayout={ this.cancelPayout }
                        />
                    </Box>
                </Container>

                <DialogConfirmAction
                    ref={ this.refDialogConfirmAction }
                />

                <DialogUserCart
                    ref={ this.refDialogUserCart }
                    refDialogConfirmation={ this.refDialogConfirmAction }
                    history={ this.props.history }
                />

                <Backdrop open={ isShowBackdrop }>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </>
        );
    }
}

export default RegistryPhysical;