// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import RegistryLegal from './RegistryLegal';

export default compose(
    connect(
        state => ({
            user: state?.global?.user || [],
        }),
        dispatch => ({}),
    ),
)(RegistryLegal);