import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,

    Box,
    Grid,
    Button,
    TextField,
    Typography,

    FormControlLabel,
    Switch
} from "@mui/material";
import {} from "@mui/icons-material";
import {
    Formik
} from "formik";
import {
    AutocompleteCategory
} from "../../../../../../components";
import * as Yup from "yup";


const initForm = {
    name: "",
    parentId: null,
    hidden: false,
};

class DialogFormUser extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initUserName: "",

            isOpen: false,
            isCreated: false,


            onEvent: null
        };

        this.refFormik = React.createRef();
    }


    open = ({form, event}) => {

        this.setState({
            isOpen: true,
            isCreated: Boolean(Object.keys(form).length <= 0),
            initUserName: form.name,
            onEvent: event
        }, () => {
            setTimeout(() => {
                this.refFormik.current.setValues(
                    Boolean(Object.keys(form).length <= 0) ? {...initForm} : form
                );
            }, 100);
        });

    }
    close = () => {
        this.refFormik.current.setValues({});
        this.setState({
            isOpen: false
        })
    }

    // Обработчик формы
    onSubmit = (form) => {
        this.state.onEvent(form, true);
    }

    // Изменение формы
    changeForm = ({target}, additionalValue) => {
        const {
            checked,
            value,
            name
        } = target;

        let newValues = this.refFormik.current.values;
        newValues[name] = checked || additionalValue || value;

        this.refFormik.current.setValues(newValues);
    }
    changeBooleanForm = ({target}, additionalValue) => {
        const {checked, name} = target;

        let newValues = this.refFormik.current.values;
        newValues[name] = checked || additionalValue;

        this.refFormik.current.setValues(newValues);
    }

    // Клик по кнопке в управлении
    clickCreate = () => {
        this.refFormik.current.handleSubmit();
    }

    render() {
        const {
            initUserName,

            isOpen,
            isCreated
        } = this.state;

        return (
            <>

                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={isOpen}

                    onClose={this.close}
                >

                    <DialogTitle>
                        <Typography variant="h3">
                            {Boolean(isCreated) ? "Создание категории" : `Редактирование категории: "${initUserName}"`}
                        </Typography>
                    </DialogTitle>

                    <DialogContent>
                        <Formik
                            innerRef={this.refFormik}
                            initialValues={{}}
                            validationSchema={validationSchema}
                            onSubmit={this.onSubmit}
                        >
                            {(props) => {
                                const {
                                    values,
                                    errors,
                                    touched
                                } = props;

                                if (Boolean(!isCreated && Object.keys(values || {}).length <= 0)) {
                                    return null
                                }

                                return (
                                    <Box pt={1}>

                                        <Box mb={2}>
                                            <TextField
                                                value={values.name}
                                                error={Boolean(touched.name && errors.name)}
                                                helperText={touched.name && errors.name}
                                                name="name"
                                                label="Наименование категории"
                                                placeholder="Введите наименование категории"
                                                variant="outlined"
                                                fullWidth

                                                onChange={this.changeForm}
                                            />
                                        </Box>
                                        <Box mb={2}>
                                            <AutocompleteCategory
                                                value={values.parentId}
                                                error={Boolean(touched.parentId && errors.parentId)}
                                                helperText={touched.parentId && errors.parentId}
                                                name="parentId"
                                                label="Родительская категория"
                                                placeholder="Выберите родительскую категорию"
                                                variant="outlined"
                                                fullWidth

                                                onChange={this.changeForm}
                                            />
                                        </Box>
                                        <Box>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name="hidden"
                                                        value={values.hidden}
                                                        checked={values.hidden}
                                                        onChange={this.changeBooleanForm}
                                                    />
                                                }
                                                label="Выключена"/>
                                        </Box>


                                    </Box>
                                )
                            }}
                        </Formik>
                    </DialogContent>

                    <DialogActions>
                        <Box px={2}>
                            <Grid container justifyContent="flex-end" spacing={1}>
                                <Grid item>
                                    <Button variant="outlined" onClick={this.close}
                                            sx={{textTransform: "initial", borderRadius: "4px", padding: "4px 24px"}}>
                                        Отменить
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={this.clickCreate}
                                            sx={{textTransform: "initial", borderRadius: "4px", padding: "4px 24px"}}>
                                        {Boolean(isCreated) ? "Создать" : "Сохранить"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogActions>

                </Dialog>

            </>
        );
    }
}


const validationSchema = Yup.object().shape({
    name: Yup.string().required("Введите наименование категории"),
    parentId: Yup.number().nullable(),
    hidden: Yup.boolean(),
});

export default DialogFormUser
