import React, { Component } from "react";
import {
    Container,
    Backdrop,
    CircularProgress
} from "@mui/material";
import {
    Table as TableComponent,
    Filter as FilterComponent,
    DialogFormCategory as DialogFormCategoryComponent
} from "./components";
import {
    DialogConfirmAction
} from "../../../../components";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";

class Categories extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: [],

            isLoading: true,
            isShowBackdrop: false,
        };

        this.refDialogFormCategory = React.createRef();
        this.refDialogConfirmAction = React.createRef();
    }

    componentDidMount = async () => {
        await this.getCategories();
    }

    // Логика получения списка категорий
    getCategories = async () => {
        this.setState({ isLoading: true })

        const categories = await agent.get(`/categories?limit=50&page=1`).then((res) => {
            return res.data.categories || []
        }).catch(() => {
            return []
        });

        this.setState({
            isLoading: false,
            categories
        })
    }


    // Логика создания категории
    createCategory = async (category, isCreate = false) => {

        if ( !isCreate ) {
            this.refDialogFormCategory.current.open({
               form: {},
               event: this.createCategory.bind(this)
            });

            return null
        }


        this.setState({ isShowBackdrop: true });

        const responseCreate = await agent.post(`/categories`, category).then((res) => {
            return res.data
        }).catch((err) => {
            return { error: err.response }
        });
        if (responseCreate.error) {
            this.setState({ isShowBackdrop: false });

            Notification({
               type: NotificationTypes.error,
               message: "Возникла ошибка, попробуйте позже"
            });

            return null
        }

        await this.getCategories();

        this.setState({ isShowBackdrop: false });

        this.refDialogFormCategory.current.close();

        Notification({
            type: NotificationTypes.success,
            message: "Категория успешно создана"
        });

    }


    // Логика редактирование категории
    editCategory = async (category, isEdit = false) => {

        if (!isEdit) {
            this.refDialogFormCategory.current.open({
                form: {...category},
                event: this.editCategory.bind(this)
            });

            return null
        }

        this.setState({ isShowBackdrop: true });

        const responseEditCategory = await agent.put(`/categories/${ category.id }`, {
            ...category
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return { error: err.response }
        });
        if (responseEditCategory.error) {
            this.setState({ isShowBackdrop: false });

            Notification({
                type: NotificationTypes.error,
                message: "Возникла ошибка при редактировании, попробуйте позже"
            });

            return null
        }

        await this.getCategories();

        this.refDialogFormCategory.current.close();

        this.setState({ isShowBackdrop: false });

        Notification({
            type: NotificationTypes.success,
            message: "Категория успешно изменена"
        });

    }
    changeHidden = async (category, hidden) => {

        this.setState({ isShowBackdrop: true });

        const responseChangeHidden = await agent.put(`/categories/${ category.id }`, {
            ...category,
            hidden
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return { error: err.response }
        });
        if (responseChangeHidden.error) {
            Notification({
               type: NotificationTypes.error,
               message: "Возникла ошибка изменения, попробуйте позже"
            });

            return null
        }

        this.setState({ isShowBackdrop: false });

        Notification({
            type: NotificationTypes.success,
            message: "Категория успешно изменена"
        });

        let categories = [...this.state.categories];
        categories.find((t) => t.id === category.id).hidden = responseChangeHidden.category.hidden;
        this.setState({ categories });

    }


    // Логика удаления категории
    deleteCategory = async (category, isConfirm) => {

        // Запрос на подветреждение удаления категории
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: `Подтверждение удаления категории "${ category.name }"`,
                message: `Вы действительно хотите удалить категорию "${ category.name }"?`,
                labelSuccess: "Да, удалить",
                onSuccess: this.deleteCategory.bind(this, category, true)
            });

            return null
        }


        // Удаление категории
        this.setState({ isShowBackdrop: true });

        const responseDeleteCategory = await agent.delete(`/categories/${ category.id }`).then((res) => {
            return res.data
        }).catch((err) => {
            return { error: err.response }
        });
        if (responseDeleteCategory.error) {
            this.setState({ isShowBackdrop: false });

            Notification({
                type: NotificationTypes.error,
                message: "Возникла ошибка удаления, попробуйте позже"
            });

            return null
        }

        Notification({
            type: NotificationTypes.success,
            message: "Категория успешно удалена"
        });

        await this.getCategories();

        this.setState({ isShowBackdrop: false });

    }


    render() {
        const {
            categories,

            isLoading,
            isShowBackdrop
        } = this.state;

        return (
            <Container maxWidth="xl">

                <FilterComponent
                    onCreateCategory={this.createCategory}
                />

                <TableComponent
                    data={categories}
                    isLoading={isLoading}

                    onEditCategory={this.editCategory}
                    onChangeHidden={this.changeHidden}
                    onDeleteCategory={this.deleteCategory}
                />


                <DialogFormCategoryComponent
                    ref={this.refDialogFormCategory}
                />

                <DialogConfirmAction
                    ref={this.refDialogConfirmAction}
                />

                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="inherit"/>
                </Backdrop>

            </Container>
        );
    }
}

export default Categories
