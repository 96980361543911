import React from "react";
import {
    Box,
    Grid,
    Button,
    Collapse,
    Typography
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Clear as ClearIcon,
    Search as SearchIcon,
    PlusOne as PlusOneIcon,
    FilterAlt as FilterAltIcon,
    HighlightOff as HighlightOffIcon
} from "@mui/icons-material";
import {

} from "../../../../../../components";

const Filter = (props) => {
    const {
        onCreateCategory
    } = props;

    return (
        <Box mb={1}>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item></Grid>
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                onClick={onCreateCategory}
                                endIcon={<PlusOneIcon/>}
                            >
                                Добавить
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box my={1}/>

        </Box>
    )
}

const useStyles = makeStyles(() => ({

    active: {
        display: "flex",
        alignItems: "center",
        padding: "2px 12px",
        borderRadius: 6,
        backgroundColor: "#FB7800",
        cursor: "pointer",

        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600"
    },
    activeLabel: {
        opacity: 0.6,
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white"
    },
    activeValue: {
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white",
        marginLeft: 6
    },

}));

export default Filter
