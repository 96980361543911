import React, { Component } from "react";
import {
    Backdrop,
    CircularProgress,
    Container
} from "@mui/material";
import {
    Filter as FilterComponent,
    Table as TableComponent,
    DialogExecutingOrders as DialogExecutingOrdersComponent,
    DialogAddStore as DialogAddStoreComponent,
} from "./components";
import {
    DialogConfirmAction
} from "../../../../components";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";
import queryString from "query-string";

const initialFilter = {
    sort: "",
};

class MyStores extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stores: [],
            executingOrders: [],

            filter: {
                ...initialFilter,
            },
            pagination: {
                page: 1,
                totalPage: 1,
            },

            isOpenAddStore: false,
            isOpenExecutingOrders: false,

            isLoading: true,

            isShowBackdrop: false,
        };

        this.refPopUpDetailProduct = React.createRef();
        this.refDialogConfirmAction = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getStores();
    };

    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket",
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch,
        };
        let pagination = {
            ...this.state.pagination,
            page: page,
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0,
        });
    };

    // Логика получения складов
    getStores = async () => {
        this.setState({isLoading: true});

        const filter = this.getFilters();
        const {stores, totalCount} = await agent
            .get(`/stores${filter}&limit=20&listing=true`)
            .then((res) => {
                return {
                    stores: res.data.stores || [],
                    totalCount: res.data.count,
                };
            })
            .catch((err) => {
                return {
                    stores: [],
                    totalCount: 0,
                };
            });

        let pagination = {...this.state.pagination};
        pagination.totalPage = Math.ceil(totalCount / 20) || 1;

        this.setState({
            isLoading: false,
            pagination,
            stores,
        });
    };

    //Создание склада
    createStore = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Создать склад",
                message: `Вы действительно хотите создать склад?"`,
                labelSuccess: "Да, создать",
                onSuccess: this.createStore.bind(this, form, true)
            })

            return null
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.post(`/stores`, form)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            })

        if (response.error) {
            this.setState({
                isShowBackdrop: false,
            });

            Notification({
                type: NotificationTypes.error,
                message: response.error.data.message || "Ошибка сервера",
            });

            return null;
        }

        await this.getStores();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Склад успешно создан",
            type: NotificationTypes.success
        });
    };

    // Удаление склада
    deleteStore = async (id, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Создать склад",
                message: `Вы действительно хотите удалить склад?"`,
                labelSuccess: "Да, удалить",
                onSuccess: this.deleteStore.bind(this, id, true)
            });

            return null
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.delete(`/stores/${id}`)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при удалении склада",
                type: NotificationTypes.error,
            });

            return null;
        }

        if (!response.success) {
            const executingOrders = response.executingOrders.map(order => order.id);
            this.setState({
                executingOrders,
                isOpenExecutingOrders: true,
                isShowBackdrop: false,
            });

            const message = `Склад нельзя удалить, на складе выполняются заказы`;

            Notification({
                message: message,
                type: NotificationTypes.error,
            });

            return null;
        }

        this.setState({isShowBackdrop: false});
        await this.getStores();

        Notification({
            message: "Склад успешно удален",
            type: NotificationTypes.success,
        });
    };

    changeStore = async (store, form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Изменить статус резерва",
                message: `Вы действительно хотите изменить статус резерва склада?"`,
                labelSuccess: "Да, изменить",
                onSuccess: this.changeStore.bind(this, store, form, true)
            })

            return null
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.put(`/stores/${store.id}`, form)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            })

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: "Ошибка изменения резерва статуса",
                type: NotificationTypes.error
            });

            return null
        }

        await this.getStores();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Статус резерва успешно изменен",
            type: NotificationTypes.success
        });
    };

    // Логика работы с фильтром
    getFilters = () => {
        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};

        let string = [`page=${pagination.page}`];
        Object.keys(filter).map((key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push(`${key}=${value}`);
            }
        });

        window.history.replaceState(null, null, `/stores?${string.join("&")}`);

        return `?${string.join("&")}`;
    };
    changeFilter = async (filter, isFastStart) => {
        await this.setState({filter});

        if (!isFastStart) {
            return null;
        }

        await this.getUsers();
    };
    changePagination = async (pagination) => {
        await this.setState({pagination});
        await this.getStores();
    };
    resetFilter = async () => {
        await this.setState({filter: {...initialFilter}});
        await this.getUsers();
    };

    // Открытие детализации товаров в заказе
    openDetailProducts = (order) => {
        this.refPopUpDetailProduct.current.open(order);
    };

    _routeStore = (order) => {
        this.props.history.push(`/stores/${order.id}`);
    };

    // Открытие формы создания склада
    setIsOpenAddStore = (isOpen) => {
        this.setState({isOpenAddStore: isOpen});
    };
    // Закрытие формы создания склада
    handleCloseAddStore = () => {
        this.setIsOpenAddStore(false);
    };

    // Открытие модалки с уведомлением о выполняющихся заказов
    setIsOpenExecutingOrders = (isOpen) => {
        this.setState({isOpenExecutingOrders: isOpen});
    };
    // Закрытие модалки с уведомлением о выполняющихся заказов
    handleCloseExecutingOrders = () => {
        this.setIsOpenExecutingOrders(false);
    };

    render() {
        const {
            stores,
            executingOrders,
            filter,
            pagination,

            isOpenAddStore,
            isOpenExecutingOrders,

            isLoading,
            isShowBackdrop
        } = this.state;

        return (
            <Container maxWidth="xl">
                <FilterComponent
                    onOpenAddStore={this.setIsOpenAddStore.bind(this, true)}
                />
                <TableComponent
                    isLoading={isLoading}
                    data={stores}
                    filter={filter}
                    pagination={pagination}
                    onChangePagination={this.changePagination}
                    onOpenDetailProducts={this.openDetailProducts}
                    routeStore={this._routeStore}
                    changeStore={this.changeStore}
                    onDeleteStore={this.deleteStore}
                />

                <DialogAddStoreComponent
                    isOpen={isOpenAddStore}
                    onClose={this.handleCloseAddStore}
                    onCreate={this.createStore}
                />

                <DialogExecutingOrdersComponent
                    executingOrders={executingOrders}
                    isOpen={isOpenExecutingOrders}
                    onClose={this.handleCloseExecutingOrders}
                />

                <DialogConfirmAction
                    ref={this.refDialogConfirmAction}
                />


                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </Container>
        );
    }
}

export default MyStores;
