import React from "react";
import {
    Box,
    Button,

    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,

    Typography
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    convertorNumber,
    convertorToPiece
} from "../../../../../../helper/convertor";
import {
    numberFormat
} from "../../../../../../common/Formater";

const OrderItems = (props) => {
    const {
        order,
        returnItems,
        shortageItems,
        onOpenChangeOrderItems
    } = props;
    const classes = useStyles();

    const renderProductQuantity = (product) => {
        const piece = convertorToPiece(product.quantity, product?.product?.pieceToCbmConversionFactor || 0);
        return (
            <>
                <Typography>{piece} шт.</Typography>
                <Typography>{numberFormat(product.quantity, 4)} м³</Typography>
            </>
        );
    };

    const renderProductPrices = (product) => {
        return (
            <>
                <Typography>{product.product.piecePrice}  ₽/шт.</Typography>
                <Typography>{product.product.cbmPrice}  ₽/м³</Typography>
            </>
        )
    };

    const getOrderSum = () => {
        if (['partialReturn'].includes(order.deliveryStatus)) {
            const returnItemsSum = returnItems.reduce((acc, curr) => +acc + +curr.price, 0);
            const newSum = +order.sum - +returnItemsSum;

            return `${convertorNumber(newSum, 2)} ₽`
        }
        if (['fullReturn'].includes(order.deliveryStatus)) {
            return `${convertorNumber(0, 2)} ₽`;
        }

        return `${convertorNumber(order.sum, 2)} ₽`
    };

    const getOrderTotalPrice = () => {
        if (['partialReturn'].includes(order.deliveryStatus)) {
            const returnItemsSum = returnItems.reduce((acc, curr) => +acc + +curr.price, 0);
            const newTotalPrice = +order?.totalPrice - +returnItemsSum;

            return `${convertorNumber(newTotalPrice, 2)} ₽`
        }
        if (['fullReturn'].includes(order.deliveryStatus)) {
            const deliveryPrice = order?.finalDeliveryPrice || 0;
            const newTotalPrice = 0 + deliveryPrice;

            return `${convertorNumber(newTotalPrice, 2)} ₽`;
        }

        return `${convertorNumber(order?.totalPrice, 2)} ₽`
    };

    const renderShortageItemsPrice = () => {
        const totalPrice = shortageItems.reduce((acc, curr) => +acc + +curr.sum, 0);

        return (
            <Typography fontWeight="600" fontSize={18}>
                {convertorNumber(totalPrice, 2)} ₽
            </Typography>
        );
    };

    return (
        <Box className={classes.root}>

            <Box mb={2} className={classes.rowContent}>
                <Typography variant="h3">Состав заказа</Typography>
                <Button
                    className={classes.button}
                    size='small'
                    variant="outlined"

                    onClick={onOpenChangeOrderItems.bind(this, true)}
                >
                    Изменить состав
                </Button>
            </Box>

            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Наименование</TableCell>
                        <TableCell align="right">Кол-во</TableCell>
                        <TableCell align="right">Цена</TableCell>
                        <TableCell align="right">Стоимость</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(order.orderItems || []).map((product, index) => {
                        return (
                            <TableRow>
                                <TableCell>{product.productName}</TableCell>
                                <TableCell
                                    align="right">{renderProductQuantity(product)}</TableCell>
                                <TableCell align="right">{renderProductPrices(product)}</TableCell>
                                <TableCell align="right">{convertorNumber(product.price, 2)} ₽</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>

            {returnItems.length > 0 && (
                <>
                    <Table className={classes.tableInfoPrice}>
                        <TableBody>
                            <TableRow>
                                <TableCell width="70%"/>
                                <TableCell>
                                    <Typography fontWeight="700" fontSize={21}>Стоимость заказа:</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography fontWeight="700" fontSize={21}>
                                        {convertorNumber(order.sum, 2)} р.
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Typography variant="h3" pl={1} mt={2}>Возврат</Typography>

                    <Table className={classes.table}>

                        <TableHead>
                            <TableRow>
                                <TableCell>Наименование</TableCell>
                                <TableCell align="right">Кол-во возврата</TableCell>
                                <TableCell align="right">Цена</TableCell>
                                <TableCell align="right">Стоимость</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(returnItems || []).map((product, index) => {
                                return (
                                    <TableRow>
                                        <TableCell>{product.product.name}</TableCell>
                                        <TableCell align="right">
                                            {renderProductQuantity(product)}
                                        </TableCell>
                                        <TableCell align="right">
                                            {renderProductPrices(product)}
                                        </TableCell>
                                        <TableCell align="right">
                                            {convertorNumber(product.price, 2)} р.
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </>
            )}

            <Table className={classes.tableInfoPrice}>
                <TableBody>
                    <TableRow>
                        <TableCell width={returnItems.length > 0 ? "50%" : "70%"}/>
                        <TableCell>
                            <Typography fontWeight="600" fontSize={18}>
                                Стоимость заказа{returnItems.length > 0 && " после возврата"}:
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography fontWeight="600" fontSize={18}>
                                {getOrderSum()}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width={returnItems.length > 0 ? "50%" : "70%"}/>
                        <TableCell>
                            <Typography fontWeight="600" fontSize={18}>
                                Стоимость доставки {returnItems.length > 0 && "после возврата"} (клиент):
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography fontWeight="600" fontSize={18}>
                                {convertorNumber(order?.initialDeliveryPrice, 2)} ₽
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width={returnItems.length > 0 ? "50%" : "70%"}/>
                        <TableCell>
                            <Typography fontWeight="600" fontSize={18}>
                                Стоимость доставки {returnItems.length > 0 && " после возврата"} (экспедитор):
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography fontWeight="600" fontSize={18}>
                                {convertorNumber(order?.finalDeliveryPrice, 2)} ₽
                            </Typography>
                        </TableCell>
                    </TableRow>
                    {order.discount > 0 && (
                        <TableRow>
                            <TableCell width={returnItems.length > 0 ? "50%" : "70%"}/>
                            <TableCell>
                                <Typography fontWeight="600" fontSize={18}>
                                    Скидка исполнителя:
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography fontWeight="600" fontSize={18}>
                                    {convertorNumber(order?.discount, 2)} ₽
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {order.adminDiscount > 0 && (
                        <TableRow>
                            <TableCell width={returnItems.length > 0 ? "50%" : "70%"}/>
                            <TableCell>
                                <Typography fontWeight="600" fontSize={18}>
                                    Скидка администратора:
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography fontWeight="600" fontSize={18}>
                                    {convertorNumber(order?.adminDiscount, 2)} ₽
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell width={returnItems.length > 0 ? "50%" : "70%"}/>
                        <TableCell>
                            <Typography fontWeight="700" fontSize={21}>
                                Общая стоимость{returnItems.length > 0 && " после возврата"}:
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography fontWeight="700" fontSize={21}>
                                {getOrderTotalPrice()}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            {shortageItems.length > 0 && (
                <>

                    <Typography variant="h3" pl={1} mt={2}>Недовоз</Typography>

                    <Table className={classes.table}>

                        <TableHead>
                            <TableRow>
                                <TableCell>Наименование</TableCell>
                                <TableCell align="right">Кол-во недовоза</TableCell>
                                <TableCell align="right">Цена</TableCell>
                                <TableCell align="right">Стоимость</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(shortageItems || []).map((product, index) => {
                                return (
                                    <TableRow>
                                        <TableCell>{product.product.name}</TableCell>
                                        <TableCell
                                            align="right">{renderProductQuantity(product)}</TableCell>
                                        <TableCell align="right">{renderProductPrices(product)}</TableCell>
                                        <TableCell
                                            align="right">{convertorNumber(product.sum, 2)} р.</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>

                    <Table className={classes.tableInfoPrice}>
                        <TableBody>
                            <TableRow>
                                <TableCell width="65%"/>
                                <TableCell>
                                    <Typography fontWeight="700" fontSize={21}>
                                        Стоимость недовоза:
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography fontWeight="700" fontSize={21}>
                                        {renderShortageItemsPrice()}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </>
            )}

        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        borderRadius: 10,
        backgroundColor: "rgba(132, 185, 44, 0.04)",

        padding: "24px 32px"
    },

    rowContent: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
    },

    button: {
        height: 30,
        fontSize: 12,
        padding: "0 12px",
        borderRadius: 4
    },

    table: {
        boxShadow: "none",

        "& thead": {
            boxShadow: "none"
        },
        "& tr th": {
            fontSize: 12,
            opacity: 0.8,
            color: "black",
            fontWeight: "400",
            backgroundColor: "transparent",
            border: "none"
        },
        "& tr td": {
            fontSize: 14,
            color: "black",
            borderBottom: "8px solid #fafcf6",
            fontWeight: "400",
            backgroundColor: "white",

            "&:first-child": {
                borderRadius: "12px 0 0 12px!important"
            },
            "&:last-child": {
                borderRadius: "0 12px 12px 0!important"
            },
        },
    },

    tableInfoPrice: {
        "& tr td": {
            fontSize: 14,
            color: "black",
            fontWeight: "400",
            padding: "10px",
            backgroundColor: "white",
        },
    },
}));

export default OrderItems
