import React from "react";
import { Button, Grid, Link, Menu, MenuItem, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { userIsLegal } from "../../../../../../helper/userType";

const Actions = (props) => {
    const {
        order,
        orderId,
        orderReceipts,
        billOfLadings,
        paymentStatus,
        deliveryStatus,
        deliveryMethod,
        returnStatus,
        driverAndClientActionsButtons,

        isLoading,
        isLoadingBillOfLading,
        isLoadingReceipts,
        isLoadingSendLinkToAccount,

        setIsOpenSetProvider,
        setIsOpenSetExpeditor,
        setIsOpenSetSale,
        setIsOpenAddBillOfLadingPhotos,
        onCancelOrder,
        onGetBillOfLading,
        onGetReceipts,
        onSendLinkToAccount
    } = props;

    const classes = useStyles();
    const [ receiptsAnchorEl, setReceiptsAnchorEl ] = React.useState(null);
    const [ billOfLadingAnchorEl, setBillOfLadingAnchorEl ] = React.useState(null);
    const [ isOpenReceipts, setIsOpenReceipts ] = React.useState(false);
    const [ isOpenBillOfLading, setIsOpenBillOfLading ] = React.useState(null);

    const handleGetReceipts = async (event) => {
        setReceiptsAnchorEl(event.currentTarget);

        await onGetReceipts();

        setIsOpenReceipts(true);
    };

    const handleCloseReceipts = () => {
        setReceiptsAnchorEl(null);
        setIsOpenReceipts(false);
    };

    const handleGetBillOfLading = async (event) => {
        setBillOfLadingAnchorEl(event.currentTarget);

        await onGetBillOfLading();

        setIsOpenBillOfLading(true);
    };

    const handleCloseBillOfLading = () => {
        setBillOfLadingAnchorEl(null);
        setIsOpenBillOfLading(false);
    };

    const disabledSetExpeditorButton = () => {
        if (deliveryStatus !== 'canceled') {
            if (deliveryMethod === 'pickup') {
                return true;
            }

            if (deliveryMethod === 'delivery') {
                return deliveryStatus !== 'readyForShipment';
            }
        }

        return true;
    };

    return (
        <Grid width="100%" container rowSpacing={ 1.5 } columnSpacing={ 1.5 }>
            <Grid xs={ 2 } item>
                <LoadingButton
                    fullWidth
                    disabled={ isLoading || [ 'canceled' ].includes(deliveryStatus) }
                    variant="contained"
                    color="primary"
                    className={ classes.button }
                    loading={ isLoadingSendLinkToAccount }

                    onClick={ onSendLinkToAccount }
                >
                    Выслать ссылку на ЛК
                </LoadingButton>
            </Grid>

            { (paymentStatus !== 'new') && (
                <Grid xs={ 2 } item>
                    <LoadingButton
                        fullWidth
                        className={ classes.button }
                        disabled={ isLoading || [ 'canceled' ].includes(deliveryStatus) || userIsLegal(order.purchaserUser) }
                        variant="contained"
                        color="primary"
                        sx={ {
                            padding: "0 8px",
                            minHeight: "initial",
                            height: 30,
                            borderRadius: 1,
                        } }
                        loading={ isLoadingReceipts }
                        id="receipts-basic-button"
                        aria-controls={ isOpenReceipts ? 'receipts-basic-menu' : undefined }
                        aria-haspopup="true"
                        aria-expanded={ isOpenReceipts ? 'true' : undefined }
                        onClick={ handleGetReceipts }
                    >
                        Скачать чеки
                    </LoadingButton>
                    <Menu
                        id="receipts-basic-menu"
                        anchorEl={ receiptsAnchorEl }
                        open={ isOpenReceipts }
                        onClose={ handleCloseReceipts }
                        MenuListProps={ {
                            'aria-labelledby': 'receipts-basic-button',
                        } }
                    >
                        { orderReceipts.length > 0 && (
                            <>
                                { orderReceipts.map((receipt, index) => (
                                    <MenuItem key={ `order-receipt-index-${ index }` }>
                                        <Button
                                            disableRipple
                                            disableFocusRipple
                                            disableTouchRipple
                                            disableElevation
                                            className={ classes.menuLinkButton }
                                            component={ Link }
                                            href={ receipt }
                                            download={ `ZAKAZ-№${ orderId }-CHECK-${ index + 1 }.pdf` }
                                        >
                                            Чек №{ index + 1 }
                                        </Button>
                                    </MenuItem>
                                )) }
                            </>
                        ) }
                        { orderReceipts.length <= 0 && (
                            <MenuItem disabled>
                                Чеков по этому заказу нет. Попробуйте позже или обратитесь к администратору
                            </MenuItem>
                        ) }
                    </Menu>
                </Grid>
            ) }

            <Grid xs={ 2 } item>
                <LoadingButton
                    fullWidth
                    className={ classes.button }
                    disabled={ isLoading || [ 'new', 'inProgress', 'partiallyReady', 'readyForShipment', 'courierInstalled', 'loaded', 'canceled' ].includes(deliveryStatus) }
                    variant="contained"
                    color="primary"
                    sx={ {
                        padding: "0 8px",
                        minHeight: "initial",
                        height: 30,
                        borderRadius: 1,
                    } }
                    loading={ isLoadingBillOfLading }
                    id="bill-of-ladings-basic-button"
                    aria-controls={ isOpenBillOfLading ? 'bill-of-ladings-basic-menu' : undefined }
                    aria-haspopup="true"
                    aria-expanded={ isOpenBillOfLading ? 'true' : undefined }
                    onClick={ handleGetBillOfLading }
                >
                    Скачать накладные
                </LoadingButton>
                <Menu
                    id="bill-of-ladings-basic-menu"
                    anchorEl={ billOfLadingAnchorEl }
                    open={ isOpenBillOfLading }
                    onClose={ handleCloseBillOfLading }
                    MenuListProps={ {
                        'aria-labelledby': 'bill-of-ladings-basic-button',
                    } }
                >
                    { billOfLadings.length > 0 && (
                        <>
                            { billOfLadings.map((lading, index) => (
                                <MenuItem key={ `order-bill-of-lading-index-${ index }` }>
                                    <Button
                                        disableRipple
                                        disableFocusRipple
                                        disableTouchRipple
                                        disableElevation
                                        className={ classes.menuLinkButton }
                                        component={ Link }
                                        href={ lading }
                                        download={ `ZAKAZ-№${ orderId }-NAKLADNAYA-${ index + 1 }.pdf` }
                                    >
                                        Накладная №{ index + 1 }
                                    </Button>
                                </MenuItem>
                            )) }
                        </>
                    ) }
                    { billOfLadings.length <= 0 && (
                        <MenuItem disabled>
                            Накладных по этому заказу нет. Попробуйте позже или обратитесь к администратору
                        </MenuItem>
                    ) }
                </Menu>
            </Grid>

            <Grid xs={ 2 } item>
                <Button
                    fullWidth
                    disabled={
                        isLoading ||
                        order?.attachedPhotosFlags?.billOfLadingPhotos ||
                        [ 'new', 'inProgress', 'partiallyReady', 'readyForShipment', 'courierInstalled', 'loaded', 'canceled' ].includes(deliveryStatus)
                    }
                    variant="contained"
                    color="primary"
                    className={ classes.button }

                    onClick={ setIsOpenAddBillOfLadingPhotos.bind(this, true) }
                >
                    Прикрепить накладные
                </Button>
            </Grid>

            <Grid xs={ 2 } item>
                <Tooltip title="Назначить исполнителя" arrow>
                    <Button
                        fullWidth
                        disabled={
                            isLoading || !(deliveryStatus === "new" && paymentStatus === 'fullPrepayment')
                        }
                        variant="contained"
                        color="primary"
                        className={ classes.button }

                        onClick={ setIsOpenSetProvider.bind(this, true) }
                    >
                        Принять в работу
                    </Button>
                </Tooltip>
            </Grid>

            <Grid xs={ 2 } item>
                <Tooltip title="Назначить экспедитора" arrow>
                    <Button
                        fullWidth
                        disabled={ isLoading || disabledSetExpeditorButton() }
                        variant="contained"
                        color="primary"
                        className={ classes.button }

                        onClick={ setIsOpenSetExpeditor.bind(this, true) }
                    >
                        Назначить экспедитора
                    </Button>
                </Tooltip>
            </Grid>

            <Grid xs={ 2 } item>
                <Tooltip title="Установить скидку" arrow>
                    <Button
                        fullWidth
                        disabled={ isLoading || [ 'canceled' ].includes(deliveryStatus) }
                        variant="contained"
                        color="primary"
                        className={ classes.button }

                        onClick={ setIsOpenSetSale.bind(this, true) }
                    >
                        Установить скидку
                    </Button>
                </Tooltip>
            </Grid>

            <Grid xs={ 2 } item>
                <Button
                    fullWidth
                    disabled={ isLoading || ![ "new", "inProgress", "partiallyReady", 'readyForShipment', 'notReadyForShipment', 'loaded' ].includes(deliveryStatus) }
                    variant="contained"
                    color="error"
                    className={ classes.button }

                    onClick={ onCancelOrder.bind(this, false) }
                >
                    Отменить заказ
                </Button>
            </Grid>

            { driverAndClientActionsButtons.length > 0 && (
                <>
                    { driverAndClientActionsButtons.map((button, index) => (
                        <Grid xs="auto" item key={ `driver-client-action-button-${ index }` }>
                            <Tooltip title={ button.tooltip } arrow>
                                <Button
                                    fullWidth
                                    disabled={ isLoading }
                                    variant="contained"
                                    color="primary"
                                    className={ classes.button }

                                    onClick={ button.onClick }
                                >
                                    { button.label }
                                </Button>
                            </Tooltip>
                        </Grid>
                    )) }
                </>
            ) }
        </Grid>
    );
}

const useStyles = makeStyles(() => ({
    button: {
        height: 30,
        padding: "0 24px",
        borderRadius: 4,

        textTransform: "initial",
        fontSize: 16,

        whiteSpace: "nowrap",
    },

    menuLinkButton: {
        fontWeight: 400,
        padding: "0 8px",
        minHeight: "initial",
        height: "auto",
        borderRadius: 1,
        color: "#000000",
        textTransform: "none",
    },
}));

export default Actions