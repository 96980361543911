import React from "react";
import {
    Box,
    Grid,
    IconButton,

    Table,
    TableBody,
    TableCell,
    TableRow,

    Tooltip,
    Typography,
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Edit as EditIcon,
    AddCircleRounded as AddIcon
} from "@mui/icons-material";
import organizationTypes from "../../../../../../constants/organizationTypes";

const Information = (props) => {
    const {
        organization,
        setIsOpenEditOrganization,
        setIsOpenCreateBankAccount,
        setIsOpenEditBankAccount,
    } = props;
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Grid container spacing={3} wrap="nowrap" alignItems="center">
                <Grid item>
                    <Typography
                        variant="h3"
                        sx={{fontWeight: "600"}}
                    >
                        Информация об организации
                    </Typography>
                </Grid>
                <Grid item>
                    <Tooltip title="Редактировать организацию" arrow>
                        <IconButton onClick={setIsOpenEditOrganization.bind(this, true)}>
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Table className={classes.table}>
                <TableBody>
                    <TableRow>
                        <TableCell width="25%" className={classes.tableLabel}>
                            Форма
                        </TableCell>
                        <TableCell className={classes.tableMessage}>
                            {organizationTypes.find(item => item.value === organization.type).label || '-'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={classes.tableLabel}>
                            Название
                        </TableCell>
                        <TableCell className={classes.tableMessage}>
                            {organization.name || "—"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={classes.tableLabel}>
                            ИНН
                        </TableCell>
                        <TableCell className={classes.tableMessage}>
                            {organization.inn || "—"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={classes.tableLabel}>
                            КПП
                        </TableCell>
                        <TableCell className={classes.tableMessage}>
                            {organization.kpp || "—"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={classes.tableLabel}>
                            {organization.type === 'ip'
                                ? "ОГРН ИП"
                                : "ОГРН"
                            }
                        </TableCell>
                        <TableCell className={classes.tableMessage}>
                            {organization.ogrn || "—"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={classes.tableLabel}>
                            Почта
                        </TableCell>
                        <TableCell className={classes.tableMessage}>
                            {organization.email || "—"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={classes.tableLabel}>
                            Телефон
                        </TableCell>
                        <TableCell className={classes.tableMessage}>
                            {organization.phone || "—"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={classes.tableLabel}>
                            Адрес
                        </TableCell>
                        <TableCell className={classes.tableMessage}>
                            {organization.address || "—"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={classes.tableLabel}>
                            Директор
                        </TableCell>
                        <TableCell className={classes.tableMessage}>
                            {organization.ceoName || "—"}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Grid mt={2} container spacing={1} wrap="nowrap" alignItems="center">
                <Grid item>
                    <Typography
                        variant="h3"
                        sx={{fontWeight: "600"}}
                    >
                        Реквизиты
                    </Typography>
                </Grid>
                {!organization.bankAccount
                    ? (
                        <Grid item>
                            <Tooltip title="Добавить реквизиты" arrow>
                                <IconButton
                                    color="primary"

                                    onClick={setIsOpenCreateBankAccount.bind(this, true)}
                                >
                                    <AddIcon color="primary"/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )
                    : (
                        <Grid item>
                            <Tooltip title="Изменить реквизиты" arrow>
                                <IconButton
                                    onClick={setIsOpenEditBankAccount.bind(this, true)}
                                >
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )
                }
            </Grid>
            {organization.bankAccount && (
                <Table className={classes.table}>
                    <TableBody>
                        <TableRow>
                            <TableCell width="25%" className={classes.tableLabel}>
                                Название банка
                            </TableCell>
                            <TableCell className={classes.tableMessage}>
                                {organization.bankAccount.bankName || "—"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width="25%" className={classes.tableLabel}>
                                БИК
                            </TableCell>
                            <TableCell className={classes.tableMessage}>
                                {organization.bankAccount.bic || "—"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width="25%" className={classes.tableLabel}>
                                Расч. счет
                            </TableCell>
                            <TableCell className={classes.tableMessage}>
                                {organization.bankAccount.account || "—"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width="25%" className={classes.tableLabel}>
                                Корр. счет
                            </TableCell>
                            <TableCell className={classes.tableMessage}>
                                {organization.bankAccount.corAccount || "—"}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )}
        </Box>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        border: "1px solid #EAEAEA",
        borderRadius: 8,
        padding: "16px 32px 24px",
    },
    table: {
        boxShadow: "none",
        borderRadius: 0,
    },
    tableLabel: {
        "&.MuiTableCell-root": {
            padding: "8px 0 0 0",
            backgroundColor: "transparent!important",

            fontSize: 18,
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "#464646",
        },
    },
    tableMessage: {
        "&.MuiTableCell-root": {
            padding: "8px 0 0 0",
            backgroundColor: "transparent!important",

            fontSize: 18,
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "black",
            fontWeight: "600",
        },
    },
}));

export default Information;