import React, {useCallback} from 'react';
import {
    Box,
    Button,

    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,

    Typography
} from "@mui/material";
import {
    Formik
} from "formik";
import {
    AutocompleteUser_1
} from "../../../../../../components";
import * as Yup from "yup";

const DialogChangeProvider = (props) => {
    const {
        store,
        isOpen,
        onClose,
        onChange
    } = props;

    const refFormik = React.useRef(null);
    const initialValues = {...store};
    const handleChangeAutocomplete = (value) => {
        const newForm = refFormik.current.values;

        newForm.ownerUser = value;
        newForm.ownerUserId = value.id;

        refFormik.current.setValues(newForm);
    };

    const onSubmit = (form) => {
        const newForm = {
            name: form.name,
            description: form.description,
            address: form?.address || '',
            addressLat: form?.addressLat || 0,
            addressLong: form?.addressLat || 0,
            reserve: form?.reserve || false,
            newOwnerId: form.ownerUserId,
        };

        onChange(newForm);
        handleCloseModal();
    };

    const handleCloseModal = () => {
        refFormik.current.resetForm();
        onClose();
    };

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth="md"
            onClose={handleCloseModal}
        >
            <DialogTitle>
                <Typography>Смена исполнителя</Typography>
            </DialogTitle>

            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box pt={1}>
                                    <Box mb={2}>
                                        <AutocompleteUser_1
                                            name='ownerUser'
                                            value={values.ownerUser}
                                            error={errors.ownerUserId}
                                            touched={touched.ownerUserId}
                                            title="Исполнитель"
                                            limit="0"
                                            roles="provider"
                                            showSearchFields
                                            onChangeAutocomplete={handleChangeAutocomplete}
                                        />
                                    </Box>
                                </Box>
                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        sx={{borderRadius: "4px", textTransform: "initial"}}

                                        onClick={onClose}
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{borderRadius: "4px", textTransform: "initial"}}

                                        onClick={handleSubmit}
                                    >
                                        Изменить
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const validationSchema = Yup.object().shape({
    ownerUserId: Yup.number().required('Выберите владельца склада'),
});

export default React.memo(DialogChangeProvider);