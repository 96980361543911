import React, {Component} from 'react';
import {
    Backdrop,
    Box,
    CircularProgress,
    Container
} from "@mui/material";
import {
    MainInformationComponent,
    TableInformation,
    DialogWithdrawalFunds as DialogWithdrawalFundsComponent,
} from "./components";
import {
    Notification,
    NotificationTypes
} from '../../../../common/Notification';
import {
    DialogConfirmAction,
    DialogConfirmActionWithCode
} from "../../../../components";
import queryString from "query-string";
import agent from "../../../../agent/agent";

const initialFilter = {
    "sortInfo": "",
};

class Wallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'all',

            transactions: [],
            allTransactions: [],
            wallet: {},
            walletsInfo: {},
            balance: null,
            income: null,
            purchaseIncome: null,
            expense: null,

            regularRequest: {},
            withdrawalRequest: [],
            isRegularRequest: true,

            withdrawalType: "oneTime",

            filter: {...initialFilter},
            pagination: {
                page: 1,
                totalPage: 1,
            },

            isOpenWithdrawalFunds: false,

            isLoading: true,
            isLoadingWithdrawalFunds: true,
            isShowBackdrop: false,
        };

        this.refDialogConfirmation = React.createRef();
        this.refDialogConfirmActionWithCode = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getWithdrawalFunds();
        await this.getWallet();
        await this.getWalletsInfo();
        await this.getWalletInformation();
        await this.getAllTransactions();
    }

    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }

    getWallet = async () => {
        const {wallet} = await agent.get(`/finance/wallets/${this.props.user.id}`)
            .then(res => res.data)
            .catch(err => {
                return {};
            });
        this.setState({
            wallet,
        })
    }

    getWalletsInfo = async () => {
        const response = await agent.get(`/finance/wallets/info`)
            .then(res => res.data)
            .catch(err => []);

        this.setState({
            walletsInfo: response,
        });
    };

    getTransactions = async () => {
        const filter = this.getFilters();
        this.setState({
            isLoading: true,
        })
        const {transactions, count} = await agent.get(`finance/transactions/wallets/${this.state.wallet.id}${filter}&limit=20`)
            .then(res => res.data)
            .catch(err => {
                return {
                    transactions: [],
                    count: 0,
                }
            });

        let pagination = {...this.state.pagination};
        pagination.totalPage = Math.ceil(count / 20) || 1;

        this.setState({
            transactions,
            pagination,
            isLoading: false,
        })

        this.setState({
            isLoading: false,
        })
    }

    getWalletInformation = async () => {
        const balance = await agent.get(`finance/wallets/${this.state.wallet.id}/balance`)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response.data.message}
            })
        const income = await agent.get(`finance/wallets/${this.state.wallet.id}/active-role-income`)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response.data.message}
            })
        const purchaseIncome = await agent.get(`finance/wallets/${this.state.wallet.id}/purchaser-income`)
            .then(res => res.data.expense)
            .catch(err => {
                return {error: err.response.data.message}
            })
        const expense = await agent.get(`finance/wallets/${this.state.wallet.id}/expense`)
            .then(res => res.data.expense)
            .catch(err => {
                return {error: err.response.data.message}
            });

        this.setState({
            balance,
            income: income.income,
            purchaseIncome: purchaseIncome,
            expense
        });
    }

    getAllTransactions = async () => {
        const filter = this.getFilters();

        this.setState({
            isLoading: true,
        })

        const {transactions, count} = await agent.get(`/finance/transactions${filter}&limit=20`)
            .then(res => res.data)
            .catch(err => {
                return {
                    transactions: [],
                    count: 0,
                }
            });

        let pagination = {...this.state.pagination};
        pagination.totalPage = Math.ceil(count / 20) || 1;

        this.setState({
            pagination,
            allTransactions: transactions,
            isLoading: false,
        })
    }

    // Логика получения списка выплат
    getWithdrawalFunds = async () => {
        this.setState({
            isLoadingWithdrawalFunds: true,
        });
        const response = await agent.get(`request-for-withdrawal-funds`)
            .then(res => res.data.requests)
            .catch(err => []);

        let regularRequest = {};
        let withdrawalRequest = [];

        if (response.length > 0) {
            regularRequest = response.filter(item => item.withdrawalType === 'regular');
            withdrawalRequest = response.filter(item => item.withdrawalType !== 'regular');
        }

        if (regularRequest.length > 0) {
            regularRequest = regularRequest[regularRequest.length - 1];
        } else {
            regularRequest = {};
        }

        this.setState({
            withdrawalRequest,
            regularRequest: regularRequest,
            isRegularRequest: Object.keys(regularRequest).length > 0,
            isLoadingWithdrawalFunds: false,
        });
    };

    // Логика выплаты
    withdrawalFunds = async (form, isConfirm, isConfirmWithCode) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.open({
                title: "Подтверждение",
                message: `Вы действительно хотите получить оплату?`,
                labelSuccess: "Да, получить",
                onSuccess: this.withdrawalFunds.bind(this, form, true, false),
            });

            return
        }

        this.setState({isShowBackdrop: true});

        if (!isConfirmWithCode) {
            await this.refDialogConfirmActionWithCode.current.open({
                onSuccess: this.withdrawalFunds.bind(this, form, true, true),
            });

            this.setState({isShowBackdrop: false});

            return null;
        }

        this.setState({isShowBackdrop: true});

        const body = {
            ...form,
            amount: +form.amount,
            withdrawalType: this.state.withdrawalType,
        };

        if (form.withdrawWholeAvailableSum) {
            delete body.amount;
        }

        const response = await agent.post(`/request-for-withdrawal-funds`, body)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при получении оплаты",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getWithdrawalFunds();
        await this.getWallet();
        await this.getWalletInformation();
        await this.getTransactions();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Оплата успешно встала в очередь на выплату",
            type: NotificationTypes.success,
        });
    };

    // Логика отмены регулярной выплаты
    canceledRegularFunds = async (isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите отменить регулярные выплаты?`,
                labelSuccess: "Да, отменить",
                onSuccess: this.canceledRegularFunds.bind(this, true),
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        const {
            regularRequest
        } = this.state;

        const response = await agent.delete(`/request-for-withdrawal-funds/${regularRequest.id}`)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при отмене регулярной выплаты",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getWithdrawalFunds();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Регулярная выплата успешно отменена",
            type: NotificationTypes.success,
        });
    };

    // Логика работы с фильтром
    getFilters = () => {
        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};

        let string = [
            `page=${pagination.page}`
        ];
        Object.keys(filter).map((key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push(`${key}=${value}`);
            }
        });

        window.history.replaceState(null, null, `/wallet?${string.join("&")}`);

        return `?${string.join("&")}`
    }

    changeFilter = async (filter, isFastStart) => {

        await this.setState({filter});

        if (!isFastStart) {
            return null
        }

        if (this.state.activeTab === 'all') {
            await this.getAllTransactions();
        }
        if (this.state.activeTab === 'my') {
            await this.getTransactions();
        }
    }

    changePagination = async (pagination) => {
        await this.setState({pagination});

        if (this.state.activeTab === 'all') {
            await this.getAllTransactions();
        }
        if (this.state.activeTab === 'my') {
            await this.getTransactions();
        }
    }

    changeActiveTab = async (value) => {
        await this.setState({
            activeTab: value,
            filter: {...initialFilter},
            pagination: {
                page: 1,
                totalPage: 1,
            },
        });
        if (value === "my") {
            await this.getTransactions();
        } else {
            await this.getAllTransactions();
        }
    };

    // Открытие формы получения оплаты
    setIsOpenWithdrawalFunds = (isOpen, withdrawalType = this?.state?.withdrawalType || "oneTime") => {
        this.setState({
            isOpenWithdrawalFunds: isOpen,
            withdrawalType,
        });
    };
    // Закрытие формы получения оплаты
    handleCloseWithdrawalFunds = () => {
        this.setIsOpenWithdrawalFunds(false);
    };

    render() {
        const {
            activeTab,
            transactions,
            allTransactions,
            wallet,
            walletsInfo,
            balance,
            income,
            purchaseIncome,
            expense,

            regularRequest,
            withdrawalRequest,
            isRegularRequest,

            filter,
            pagination,

            isOpenWithdrawalFunds,

            isLoading,
            isLoadingWithdrawalFunds,
            isShowBackdrop
        } = this.state;
        return (
            <>
                <Container maxWidth="xl">
                    <Box mb={5}>
                        <MainInformationComponent
                            wallet={wallet}
                            walletsInfo={walletsInfo}
                            isRegularRequest={isRegularRequest}
                            onOpenWithdrawalFunds={this.setIsOpenWithdrawalFunds}
                            onCanceledRegularFunds={this.canceledRegularFunds}
                        />
                    </Box>
                    <Box>
                        <TableInformation
                            wallet={wallet}
                            activeTab={activeTab}
                            transactions={transactions}
                            filter={filter}
                            pagination={pagination}
                            allTransactions={allTransactions}
                            isLoading={isLoading}

                            onChangePagination={this.changePagination}
                            onChangeFilter={this.changeFilter}
                            onChangeTab={this.changeActiveTab}
                        />
                    </Box>
                </Container>

                {!isLoadingWithdrawalFunds && (
                    <DialogWithdrawalFundsComponent
                        withdrawalRequest={withdrawalRequest}
                        isOpen={isOpenWithdrawalFunds}
                        onClose={this.handleCloseWithdrawalFunds}
                        onWithdrawal={this.withdrawalFunds}
                    />
                )}

                <DialogConfirmAction
                    ref={this.refDialogConfirmation}
                />

                <DialogConfirmActionWithCode ref={this.refDialogConfirmActionWithCode}/>

                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="white"/>
                </Backdrop>
            </>
        );
    }
}

export default Wallet;