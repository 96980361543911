import React from "react";
import {
    Box,
    Grid,
    FormControlLabel,
    Checkbox,
} from "@mui/material";

const Tree = (props) => {
    const {
        level,
        item,
        accreditations,

        onTap
    } = props;


    const handleChange = (event, checked) => {
        onTap(item, checked)
    }

    return (
        <Box pl={level}>

            <FormControlLabel
                control={<Checkbox
                    value={Boolean( accreditations.find((t) => t.categoryId === item.id) )}
                    checked={Boolean( accreditations.find((t) => t.categoryId === item.id) )}
                />}
                label={ item.name }
                onChange={handleChange}
            />

            {Boolean( (item.childrens || []).length > 0 ) && (
                <>
                    {item.childrens.map(( children, index ) => (
                        <Tree
                            key={`tree-${level}-${ index }`}
                            item={children}
                            level={level + 1}
                            accreditations={accreditations}
                            onTap={onTap}
                        />
                    ))}
                </>
            )}

        </Box>
    )
}

export default Tree
