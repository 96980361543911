import React from "react";
import {
    Box,
    IconButton,
    Pagination,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { EditRounded as EditIcon } from '@mui/icons-material';
import { numberFormat } from "../../../../../../common/Formater";

const TableCustom = (props) => {
    const {
        data,
        filter,
        pagination,
        visibleColumns,

        isLoading,

        onChangePagination,
        onChangeFilter,
        onOpenEditBalance,
    } = props;

    const classes = useStyles();

    const handleChangePage = (event, page) => {
        let newPagination = { ...pagination };
        newPagination.page = page;

        onChangePagination( newPagination )
    }

    const handleChangeFilter = (name, value) => {
        const newFilter = { ...filter };
        newFilter[name] = value;

        onChangeFilter( newFilter, true );
    };

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>
                        <EnhancedTableHead
                            visibleColumns={ visibleColumns }
                            filter={ filter }
                            handleChangeFilter={ handleChangeFilter }
                        />
                    </TableRow>
                </TableHead>

                <TableBody>

                    {
                        Boolean( isLoading ) ? (
                            <>

                                {
                                    [ 0, 1, 2, 3, 4, 5 ].map( (item) => (

                                        <TableRow key={ `row-item-load-${ item }` }>
                                            {
                                                visibleColumns.map( (item, index) => (
                                                    <TableCell
                                                        key={ `row-item-skeleton-${ index + 1 }` }
                                                    >
                                                        <Skeleton/>
                                                    </TableCell>
                                                ) )
                                            }
                                        </TableRow>

                                    ) )
                                }

                            </>
                        ) : (
                            <>
                                {
                                    data.length > 0
                                        ? data.map( (row, index) => (
                                            <TableRow
                                                key={ `row-item-${ index }` }
                                            >
                                                <TableCell align='center'>{ row.id }</TableCell>
                                                <TableCell align='center'>
                                                    { row.users.map( (user, index) => (
                                                        <Typography
                                                            variant=''
                                                            key={ `wallet-user-name-${ index }` }
                                                        >
                                                            { user }
                                                        </Typography>
                                                    ) ) }
                                                </TableCell>
                                                <TableCell
                                                    align='center'>{ numberFormat( row?.balance || 0 ) } ₽</TableCell>
                                                <TableCell
                                                    align='center'>{ numberFormat( row?.balanceFrozen || 0 ) } ₽</TableCell>
                                                <TableCell>
                                                    <Tooltip title='Редактировать баланс кошелька'>
                                                        <IconButton
                                                            color='primary'
                                                            onClick={ () => onOpenEditBalance( true, row.id ) }
                                                        >
                                                            <EditIcon color='primary'/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ) )
                                        : <>
                                            <TableRow>
                                                <TableCell align='center' colSpan={ visibleColumns.length }>
                                                    Нет кошельков
                                                </TableCell>
                                            </TableRow>
                                        </>
                                }
                            </>
                        )
                    }
                </TableBody>
            </Table>

            <Box mt={ 3 }/>

            <Pagination
                page={ Number( pagination.page ) }
                count={ Number( pagination.totalPage ) }

                onChange={ handleChangePage }
            />

        </>
    );
};

const EnhancedTableHead = (props) => {
    const {
        visibleColumns,
        filter,

        handleChangeFilter
    } = props;
    const classes = useStyles();

    const handleChangeSort = (col) => {
        if (filter.sortInfo === `-${ col }`) {
            handleChangeFilter( "sortInfo", col );
        } else {
            handleChangeFilter( "sortInfo", `-${ col }` );
        }
    };
    return (
        <>
            { visibleColumns.map( column => (
                <TableCell
                    key={ column.id }
                    width={ column.width }
                    { ...column }
                >
                    { column.sortable
                        ? (
                            <TableSortLabel
                                classes={ {
                                    active: classes.activeSort,
                                    root: classes.sort,
                                } }
                                active={ filter.sortInfo === column.id || filter.sortInfo === `-${ column.id }` }
                                direction={ filter.sortInfo === column.id ? "desc" : "asc" }

                                onClick={ handleChangeSort.bind( this, column.id ) }
                            >
                                { column.label }
                            </TableSortLabel>
                        )
                        : column.label
                    }
                </TableCell>
            ) ) }
        </>
    );
};

const useStyles = makeStyles( (theme) => ( {
    buttonAction: {},
    userPhone: {
        fontWeight: 500,
        color: "#84b92c",
    },

    tableSmall: {
        "& tr td": {
            padding: "2px 20px",
            "&:first-child": {
                paddingLeft: 0,
            },
            "&:last-child": {
                paddingRight: 0,
            }
        }
    },

    sort: {
        "&.MuiButtonBase-root": {
            transition: "all .2s linear",
            "&:hover": {
                color: "rgba(255,255,255, .5)",
                "& .MuiSvgIcon-root": {
                    fill: "#FFFFFF",
                }
            }
        }
    },
    activeSort: {
        '&.MuiButtonBase-root.Mui-active': {
            color: "#FFFFFF",

            "& .MuiSvgIcon-root": {
                "& path": {
                    fill: "#FFFFFF",
                }
            }
        },
    },
} ) );

export default TableCustom
