import React from 'react';
import {
    Box,
    Button,

    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,

    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import {
    Formik
} from "formik";
import * as Yup from 'yup';

const DialogEditPaymentStatus = (props) => {
    const {
        editInvoice,
        isOpen,
        onClose,
        onSetPaidStatus
    } = props;

    const refFormik = React.useRef(null);

    const initialValues = {
        status: editInvoice?.payment?.status || '',
    };

    const onSubmit = (form) => {
        onSetPaidStatus(form.status);
    };

    const handleChange = ({target}) => {
        const {name, value} = target;

        const newForm = refFormik.current.values;
        newForm[name] = value;

        refFormik.current.setValues(newForm);
    };

    const handleCloseModal = () => {
        onClose();
    };

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={isOpen}

            onClose={handleCloseModal}
        >
            <DialogTitle>
                Изменения статусы оплаты
            </DialogTitle>
            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box pt={1}>
                                    <FormControl fullWidth error={Boolean(touched.status && errors.status)}>
                                        <InputLabel id="select-payment-status-label">Статус</InputLabel>
                                        <Select
                                            labelId="select-payment-status-label"
                                            id="select-payment-status"
                                            value={values.status}
                                            name="status"
                                            label="Статус"
                                            error={Boolean(touched.status && errors.status)}
                                            helperText={touched.status && errors.status}

                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled value='processing'>В обработке</MenuItem>
                                            <MenuItem value="complete">Оплачен</MenuItem>
                                            <MenuItem value='canceled'>Отменен</MenuItem>
                                            <MenuItem value='error'>Ошибка</MenuItem>
                                        </Select>
                                        {Boolean(touched.status && errors.status) && (
                                            <FormHelperText>{touched.status && errors.status}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Box>

                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            borderRadius: "5px",
                                            textTransform: "none",
                                        }}
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            borderRadius: "5px",
                                            textTransform: "none",
                                        }}

                                        onClick={handleSubmit}
                                    >
                                        Изменить
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const validationSchema = Yup.object().shape({
    status: Yup.string().notOneOf(['processing'], 'Изменяемый статус не может быть "В обработке"').required('Выберите статус'),
})

export default React.memo(DialogEditPaymentStatus);