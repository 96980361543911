import React from 'react';
import {
    setSettings,
    setUser
} from '../states/global';
import {
    compose
} from 'recompose';
import {
    connect
} from 'react-redux';
import MainRoute from "./Main";
import NotAuth from "./NotAuth";
import agent from "../agent/agent";

class Router extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    componentDidMount = async () => {
        await this.getUser();
        await this.getSettings();
    }

    getUser = async () => {
        const jwt = localStorage.getItem('jwt');

        if (!jwt) {
            this.setState({isLoading: false});

            return null
        }

        const user = await agent.get("/auth/me").then((res) => {
            return res.data?.user
        }).catch((err) => {
            return null
        });

        if (!user) {
            localStorage.removeItem('jwt');
            this.setState({isLoading: false});

            return null
        }

        this.props.setUser(user);
    }

    getSettings = async () => {
        const response = await agent.get('/settings')
            .then(res => res.data.settings)
            .catch(err => null);

        this.props.setSettings(response);

        this.setState({isLoading: false});
    };

    render() {
        const {isLoading} = this.state;
        const {user} = this.props.global;

        if (isLoading) {
            return null
        }

        if (!user) {
            return (
                <NotAuth/>
            )
        }

        return (
            <>
                <MainRoute/>
            </>
        )
    }
}

export default compose(
    connect(
        state => ({
            global: state.global
        }),
        dispatch => ({
            setUser: (user) => dispatch(setUser(user)),
            setSettings: (settings) => dispatch(setSettings(settings)),
        }),
    ),
)(Router);
