import React, {useState} from 'react';
import {
    Box,
    CircularProgress,
    Collapse, IconButton,
    Typography
} from "@mui/material";
import {
    LoadingButton
} from "@mui/lab";
import {
    ExpandLessRounded as LessIcon,
    ExpandMoreRounded as MoreIcon,
    AddAPhotoRounded as AddPhotoIcon,
} from "@mui/icons-material";
import {
    makeStyles
} from "@mui/styles";
import clsx from "clsx";
import theme from "../../../../../../theme";

const OrderPhotos = (props) => {
    const {
        orderDeliveryStatus,
        attachedPhotoFlags,

        providerPhotos,
        driverPhotos,
        returnPhotos,
        billOfLadingPhotos,

        isLoadingProviderPhotos,
        isLoadingDriverPhotos,
        isLoadingReturnPhotos,
        isLoadingBillOfLadingPhotos,

        getPhotos,
        setIsOpenAddBillOfLadingPhotos
    } = props;
    const classes = useStyles();
    const [openPhotos, setOpenPhotos] = useState(''); // providerPhotos | driverPhotos | returnPhotos | billOfLadingPhotos

    const handleChangeOpenPhotos = async (type) => {
        if (openPhotos === type) {
            setOpenPhotos('');
        } else {
            if (
                (type === 'providerPhotos' && providerPhotos.length <= 0) ||
                (type === 'driverPhotos' && driverPhotos.length <= 0) ||
                (type === 'returnPhotos' && returnPhotos.length <= 0) ||
                (type === 'billOfLadingPhotos' && billOfLadingPhotos.length <= 0)
            ) {
                return await getPhotos(type, () => setOpenPhotos(type));
            }

            setOpenPhotos(type);
        }
    };

    const renderPhotos = (type) => {
        const isNotEmptyProviderPhotos = attachedPhotoFlags?.fromProvider || false;
        const isNotEmptyDriverPhotos = attachedPhotoFlags?.fromDriver || false;
        const isNotEmptyReturnPhotos = attachedPhotoFlags?.returnPhotos || false;
        const isNotEmptyBillOfLadingPhotos = attachedPhotoFlags?.billOfLadingPhotos || false;

        if (type === 'providerPhotos') {
            if (isNotEmptyProviderPhotos) {
                return (
                    <>
                        <Box mb={1} className={classes.columnContent}>
                            <LoadingButton
                                fullWidth
                                size='large'
                                loading={isLoadingProviderPhotos}
                                loadingIndicator={(
                                    <CircularProgress color="inherit" size={32}/>
                                )}
                                className={clsx({
                                    [classes.button]: true,
                                    [classes.buttonActive]: openPhotos === 'providerPhotos',
                                })}
                                endIcon={openPhotos === 'providerPhotos' ? <LessIcon/> : <MoreIcon/>}

                                onClick={handleChangeOpenPhotos.bind(this, 'providerPhotos')}
                            >
                                Отгрузка со склада
                            </LoadingButton>
                            <Collapse
                                in={openPhotos === 'providerPhotos'}
                                timeout="auto"
                            >
                                <Box className={classes.imageWrapper}>
                                    {providerPhotos.map((photo, i) => (
                                        <img src={photo || ''}
                                             alt={`Фото при передаче груза - ${i + 1}`}/>
                                    ))}
                                </Box>
                            </Collapse>
                        </Box>
                    </>
                );
            }
        }

        if (type === 'driverPhotos') {
            if (isNotEmptyDriverPhotos) {
                return (
                    <>
                        <Box mb={1} className={classes.columnContent}>
                            <LoadingButton
                                fullWidth
                                size='large'
                                loading={isLoadingDriverPhotos}
                                loadingIndicator={(
                                    <CircularProgress color="inherit" size={32}/>
                                )}
                                className={clsx({
                                    [classes.button]: true,
                                    [classes.buttonActive]: openPhotos === 'driverPhotos',
                                })}
                                endIcon={openPhotos === 'driverPhotos' ? <LessIcon/> : <MoreIcon/>}

                                onClick={handleChangeOpenPhotos.bind(this, 'driverPhotos')}
                            >
                                Доставка заказа до заказчика
                            </LoadingButton>
                            <Collapse
                                in={openPhotos === 'driverPhotos'}
                                timeout="auto"
                            >
                                <Box className={classes.imageWrapper}>
                                    {driverPhotos.map((photo, i) => (
                                        <img src={photo || ''}
                                             alt={`Фото при доставке груза до заказчика - ${i + 1}`}/>
                                    ))}
                                </Box>
                            </Collapse>
                        </Box>
                    </>
                );
            }
        }

        if (type === 'returnPhotos') {
            if (isNotEmptyReturnPhotos) {
                return (
                    <>
                        <Box mb={1} className={classes.columnContent}>
                            <LoadingButton
                                fullWidth
                                size='large'
                                loading={isLoadingReturnPhotos}
                                loadingIndicator={(
                                    <CircularProgress color="inherit" size={32}/>
                                )}
                                className={clsx({
                                    [classes.button]: true,
                                    [classes.buttonActive]: openPhotos === 'returnPhotos',
                                })}
                                endIcon={openPhotos === 'returnPhotos' ? <LessIcon/> : <MoreIcon/>}

                                onClick={handleChangeOpenPhotos.bind(this, 'returnPhotos')}
                            >
                                {orderDeliveryStatus === 'partialReturn' ? "Частичный " : "Полный "} возврат
                            </LoadingButton>
                            <Collapse
                                in={openPhotos === 'returnPhotos'}
                                timeout="auto"
                            >
                                <Box className={classes.imageWrapper}>
                                    {returnPhotos.map((photo, i) => (
                                        <img src={photo || ''}
                                             alt={`Фото при ${orderDeliveryStatus === 'partialReturn' ? "частичном " : "полном "} возврате - ${i + 1}`}/>
                                    ))}
                                </Box>
                            </Collapse>
                        </Box>
                    </>
                );
            }
        }

        if (type === 'billOfLadingPhotos') {
            if (isNotEmptyBillOfLadingPhotos) {
                return (
                    <>
                        <Box mb={1} className={classes.columnContent}>
                            <LoadingButton
                                fullWidth
                                size='large'
                                loading={isLoadingBillOfLadingPhotos}
                                loadingIndicator={(
                                    <CircularProgress color="inherit" size={32}/>
                                )}
                                className={clsx({
                                    [classes.button]: true,
                                    [classes.buttonActive]: openPhotos === 'billOfLadingPhotos',
                                })}
                                endIcon={openPhotos === 'billOfLadingPhotos' ? <LessIcon/> : <MoreIcon/>}

                                onClick={handleChangeOpenPhotos.bind(this, 'billOfLadingPhotos')}
                            >
                                Накладные
                            </LoadingButton>
                            <Collapse
                                in={openPhotos === 'billOfLadingPhotos'}
                                timeout="auto"
                            >
                                <Box className={classes.imageWrapper}>
                                    {billOfLadingPhotos.map((photo, i) => (
                                        <img src={photo || ''}
                                             alt={`Фото накладных - ${i + 1}`}/>
                                    ))}

                                    {billOfLadingPhotos.length < 4 && (
                                        <IconButton
                                            color="primary"
                                            className={classes.addPhotoButton}

                                            onClick={setIsOpenAddBillOfLadingPhotos.bind(this, true)}
                                        >
                                            <AddPhotoIcon color="primary" fontSize="large"/>
                                        </IconButton>
                                    )}
                                </Box>
                            </Collapse>
                        </Box>
                    </>
                );
            }
        }
    };

    return (
        <Box className={classes.root}>

            <Typography className={classes.title} variant="h3">Фотографии заказа</Typography>

            <Box className={classes.content}>
                {renderPhotos('providerPhotos')}
                {renderPhotos('driverPhotos')}
                {renderPhotos('returnPhotos')}
                {renderPhotos('billOfLadingPhotos')}
            </Box>
        </Box>
    );
};

const useStyles = makeStyles({
    root: {
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        borderRadius: 10,
        backgroundColor: "rgba(132, 185, 44, 0.04)",

        padding: "24px 32px"
    },

    head: {},
    title: {
        marginBottom: 18
    },
    content: {
        display: "flex",
        flexDirection: "column",
    },

    columnContent: {
        display: "flex",
        flexDirection: "column",
        width: '100%',
    },

    button: {
        "&.MuiLoadingButton-root": {
            background: "#84b92c1a",
            justifyContent: "space-between",
            fontWeight: 500,
            fontSize: '20px',
            letterSpacing: '0.02em',
            lineHeight: 'normal',
            textTransform: "none",
            color: "#000000",
            borderRadius: 0,
            border: "1px solid #84b92c1a",
        },

        "&:disabled": {
            background: "#c5c5c5",
            border: "1px solid #c5c5c5",
            color: "#c5c5c5",
        },
    },

    buttonActive: {
        "&.MuiLoadingButton-root": {
            borderColor: "#84b92c",
        }
    },

    imageWrapper: {
        maxWidth: "100%",
        padding: 16,
        display: "flex",
        alignItems: "flex-start",
        gap: 10,
        flexWrap: "wrap",
        background: "#84b92c1a",
        border: "1px solid #84b92c",
        borderTop: "none",
        "& > img": {
            maxWidth: "calc(100% / 3)",
            objectFit: "contain",
        }
    },

    addPhotoButton: {
        "&.MuiButtonBase-root": {
            width: "100%",
            height: "100%",
            borderRadius: 5,
            border: "1px solid #84b92c",
        },
    },
})

export default OrderPhotos;