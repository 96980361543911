import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { IMaskInput } from "react-imask";
import { Notification, NotificationTypes } from "../../../../../../common/Notification";

const DialogFormEditBalance = (props) => {
    const {
        walletId,
        isOpen,
        onClose,
        onEdit
    } = props;

    const [ balance, setBalance ] = React.useState( '' );
    const [ balanceFrozen, setBalanceFrozen ] = React.useState( '' );

    const handleCloseModal = () => {
        setBalanceFrozen( (prev) => '' );
        setBalance( (prev) => '' );
        onClose();
    };

    const handleChangeBalance = ({ target }) => {
        const { value } = target;

        setBalance( (prev) => value );
    };

    const handleChangeBalanceFrozen = ({ target }) => {
        const { value } = target;

        setBalanceFrozen( (prev) => value );
    };

    const onSubmit = () => {
        if (balanceFrozen.length <= 0 && balance.length <= 0) {
            Notification( {
                message: 'Одно из полей должно быть заполнено',
                type: NotificationTypes.error,
            } );

            return;
        }

        onEdit( { walletId, balance, balanceFrozen } );
        handleCloseModal();
    };

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={ isOpen }
            onClose={ handleCloseModal }
        >
            <DialogTitle>
                Редактировать баланс
            </DialogTitle>
            <DialogContent>
                <Box py={ 2 }>
                    <TextField
                        value={ balance }
                        fullWidth
                        name="balance"
                        label="Баланс"
                        InputProps={ {
                            inputComponent: CustomInputAmount
                        } }
                        inputProps={ {
                            scale: 2,
                        } }
                        placeholder="Введите баланс"

                        onChange={ handleChangeBalance }
                    />
                </Box>
                <Box py={ 2 }>
                    <TextField
                        value={ balanceFrozen }
                        fullWidth
                        name="balance"
                        label="Забронированный баланс"
                        InputProps={ {
                            inputComponent: CustomInputAmount
                        } }
                        inputProps={ {
                            scale: 2,
                        } }
                        placeholder="Введите забронированный баланс"

                        onChange={ handleChangeBalanceFrozen }
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    sx={ {
                        textTransform: "initial",
                        borderRadius: "4px",
                        padding: "4px 24px"
                    } }

                    onClick={ handleCloseModal }
                >
                    Отменить
                </Button>
                <Button variant="contained" onClick={ onSubmit }>
                    Редактировать
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const CustomInputAmount = ({ inputRef, ...rest }) => (
    <IMaskInput
        ref={ inputRef }
        { ...rest }

        mask={ Number }
        thousandsSeparator=""
        radix="."
        mapToRadix={ [ ',' ] }
        scale={ rest?.scale || 0 }
        unmask={ true }
    />
)

export default DialogFormEditBalance;
