import React, {useState} from "react";
import {
    Box,
    Button,

    Collapse,

    Grid,

    Link,

    Table,
    TableBody,
    TableCell,
    TableRow,

    Tooltip,
    Typography,
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    formatPhone
} from "../../../../../../helper/formatPhone";
import {
    deliveryTime
} from "../../../../../../helper/deliveryTime";
import moment from "moment";
import paymentStatus from "../../../../../../constants/paymentStatus";
import DeliveryPickup from "../../../../../../assets/svg/delivery/pickup.svg";
import DeliveryDelivery from "../../../../../../assets/svg/delivery/delivery.svg";
import delivery from "../../../../../../constants/delivery";
import Map from "../../../../../../components/Map";
import {useSelector} from "react-redux";
import {convertorNumber} from "../../../../../../helper/convertor";
import returnStatus from "../../../../../../constants/returnStatus";

const OrderInformation = (props) => {
    const {
        order,

        setIsOpenTimeChange,
        setIsOpenDeliveryChange,
        setIsOpenProviderCard,
        onChangeStatusPayment,
        onOrderPayment,
        onOrderPaymentAdditional,
    } = props;
    const settings = useSelector(state => state?.global?.settings || null);
    const classes = useStyles();
    const [isShowMap, setIsShowMap] = useState(false);

    const _deliveryDate = () => {
        const deliveryTimes = deliveryTime(settings);
        const date = moment(order?.deliveryDate).format("DD.MM.YYYY");
        const time = deliveryTimes.filter(time => time.value === order?.deliveryTimespan);
        return `${date} (${time[0]?.label || 'Время не указано'})`
    }
    const _deliveryMethod = () => {
        let pathIcon = "";
        if (order.deliveryMethod === "pickup") {
            pathIcon = DeliveryPickup;
        }
        if (order.deliveryMethod === "delivery") {
            pathIcon = DeliveryDelivery;
        }


        return (
            <Box className={classes.delivery}>
                <img src={pathIcon}/>
                {order.deliveryMethod === "pickup" ? "Самовывоз" : "Доставка"}
                {/*{order?.deliveryAddress || "-"}*/}
            </Box>
        )
    }

    const _getStatusOrderReady = () => {
        switch (order.deliveryStatus) {
            case "new":
                return "Новый";
            case "inProgress":
                return "В работе";
            case "partiallyReady":
                return "Частично готов";
            case "readyForShipment":
            case 'courierInstalled':
            case 'transferringToDelivery':
            case "loaded":
            case "enRoute":
            case 'transferringToClient':
            case "received":
            case "partialReturn":
            case "fullReturn":
            case "canceled":
                return "Готов";
            default:
                return "Статус не определен";
        }
    }

    const _getStatusOrderDelivery = () => {
        if (order.deliveryMethod === "pickup") {
            return "Самовывоз";
        } else {
            switch (order.deliveryStatus) {
                case "new":
                case "partiallyReady":
                case "inProgress":
                case "readyForShipment":
                    return "Новый";
                case "loaded":
                case 'courierInstalled':
                case 'transferringToDelivery':
                    return "Ожидает погрузки";
                case "enRoute":
                    return "Загружен";
                case "received":
                case "transferringToClient":
                case "partialReturn":
                case "fullReturn":
                case "canceled":
                    return "Доставлен";
                default:
                    return "Статус не определен";
            }
        }
    }

    const _getStatusOrderReceived = () => {
        switch (order.deliveryStatus) {
            case "new":
            case "partiallyReady":
            case "inProgress":
            case "readyForShipment":
            case "loaded":
            case "enRoute":
            case 'courierInstalled':
            case 'transferringToDelivery':
            case "transferringToClient":
                return "Новый";
            case "received":
                return "Выполнен";
            case "partialReturn":
                return "Частичный возврат";
            case "fullReturn":
                return "Полный возврат";
            case "canceled":
                return "Отменен";
            default:
                return "Статус не определен";
        }
    }

    return (
        <Box className={ classes.root }>

            <Box mb={ 2 }>
                <Typography variant="h3">Информация о заказе</Typography>
            </Box>

            <Table className={ classes.table }>
                <TableBody>
                    <TableRow>
                        <TableCell
                            width="20%">{ order.deliveryMethod === "pickup" ? "Дата/время отгрузки" : "Дата/время доставки" }</TableCell>
                        <TableCell className={ classes.tableValue }>
                            { _deliveryDate() }
                        </TableCell>
                        <TableCell width={ 150 } align="right">
                            <Button
                                variant="outlined"
                                className={ classes.tableButton }
                                onClick={ setIsOpenTimeChange.bind(this, true) }
                            >
                                Перенести время
                            </Button>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            width="20%">Способ доставки</TableCell>
                        <TableCell className={ classes.tableValue }>
                            { _deliveryMethod() }
                        </TableCell>
                        <TableCell width={ 150 } align="right">
                            <Button
                                variant="outlined"
                                disabled={ ![ 'new', 'inProgress', 'partiallyReady', 'readyForShipment', 'notReadyForShipment', 'loaded' ].includes(order.deliveryStatus) }
                                className={ classes.tableButton }
                                onClick={ () => setIsOpenDeliveryChange(true) }
                            >
                                Изменить адрес
                            </Button>
                        </TableCell>
                    </TableRow>
                    { order.deliveryMethod === "delivery" && (
                        <>
                            <TableRow>
                                <TableCell width="20%">Адрес доставки</TableCell>
                                <TableCell className={ classes.tableValue }>
                                    <Grid container alignItems='center' spacing={ 2 }>
                                        <Grid item>
                                            { order?.deliveryAddress || "Милицейский адрес не найден" }
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                className={ classes.tableButton }
                                                onClick={ () => setIsShowMap(!isShowMap) }
                                            >
                                                { isShowMap ? "Свернуть карту" : "Развернуть карту" }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell width={ 150 } align="right"/>
                            </TableRow>
                            <TableRow>
                                <TableCell width="20%">Широта</TableCell>
                                <TableCell className={ classes.tableValue }>
                                    { order?.deliveryAddressLat || "-" }
                                </TableCell>
                                <TableCell width={ 150 } align="right"/>
                            </TableRow>
                            <TableRow>
                                <TableCell width="20%">Долгота</TableCell>
                                <TableCell className={ classes.tableValue }>
                                    { order?.deliveryAddressLong || "-" }
                                </TableCell>
                                <TableCell width={ 150 } align="right"/>
                            </TableRow>
                        </>
                    ) }
                    <TableRow>
                        <TableCell width="20%">Статус готовности</TableCell>
                        <TableCell className={ classes.tableValue }>
                            { _getStatusOrderReady() }
                        </TableCell>
                        <TableCell width={ 150 } align="right"/>
                    </TableRow>
                    <TableRow>
                        <TableCell width="20%">Статус доставки</TableCell>
                        <TableCell className={ classes.tableValue }>
                            { _getStatusOrderDelivery() }
                        </TableCell>
                        <TableCell width={ 150 } align="right"/>
                    </TableRow>
                    <TableRow>
                        <TableCell width="20%">Статус выполнения</TableCell>
                        <TableCell className={ classes.tableValue }>
                            { _getStatusOrderReceived() }
                        </TableCell>
                        <TableCell width={ 150 } align="right"/>
                    </TableRow>
                    { [ 'partialReturn', 'fullReturn' ].includes(order.deliveryStatus) && (
                        <TableRow>
                            <TableCell width="20%">Статус возврата</TableCell>
                            <TableCell className={ classes.tableValue }>
                                { returnStatus[order.deliveryMethod][order.statusOfReturnOrder] }
                            </TableCell>
                            <TableCell width={ 150 } align="right"/>
                        </TableRow>
                    ) }

                    <TableRow>
                        <TableCell width="20%">Статус оплаты</TableCell>
                        <TableCell className={ classes.tableValue }>
                            { paymentStatus[order.paymentStatus] }
                        </TableCell>
                        <TableCell width="max-content" align="right">
                            <Box className={ classes.rowContent }>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    className={ classes.tableButton }
                                    onClick={ () => onChangeStatusPayment(order) }
                                >
                                    Изменить статус оплаты
                                </Button>
                                { [ 'new', 'processing' ].includes(order.paymentStatus) && (
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        className={ classes.tableButton }
                                        onClick={ onOrderPayment.bind(this, false) }
                                    >
                                        Сгенерировать ссылку на оплату
                                    </Button>
                                ) }

                                { order.paymentStatus === 'partialPrepayment' && (
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        className={ classes.tableButton }
                                        onClick={ onOrderPaymentAdditional.bind(this, true) }
                                    >
                                        Сгенерировать ссылку на доплату
                                    </Button>
                                ) }
                            </Box>
                        </TableCell>
                    </TableRow>

                    { order.discount > 0 && (
                        <TableRow>
                            <TableCell width="20%">Скидка исполнителя</TableCell>
                            <TableCell className={ classes.tableValue }>
                                { convertorNumber(order.discount, 2) } ₽
                            </TableCell>
                            <TableCell width={ 150 } align="right"/>
                        </TableRow>
                    ) }
                    { order.adminDiscount > 0 && (
                        <TableRow>
                            <TableCell width="20%">Скидка администратора</TableCell>
                            <TableCell className={ classes.tableValue }>
                                { convertorNumber(order.adminDiscount, 2) } ₽
                            </TableCell>
                            <TableCell width={ 150 } align="right"/>
                        </TableRow>
                    ) }

                    <TableRow>
                        <TableCell width="20%">Заказчик</TableCell>
                        <TableCell className={ classes.tableValue }>
                            <Grid container alignItems='center' spacing={ 2 }>
                                <Grid item>
                                    { order?.purchaserUser?.firstName || "-" }
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Позвонить" arrow>
                                        <Link href={ `tel:+${ order?.purchaserUser?.phone }` }>
                                            { formatPhone(order?.purchaserUser?.phone || "-") }
                                        </Link>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Button
                                        href={ `https://t.me/+${ order?.purchaserUser?.phone }` }
                                        target="_blank"
                                        variant="outlined"
                                        color="primary"
                                        className={ classes.tableButton }
                                    >
                                        Написать в телеграм
                                    </Button>
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell width={ 150 } align="right"/>
                    </TableRow>
                    <TableRow>
                        <TableCell width="20%">Исполнитель</TableCell>
                        <TableCell className={ classes.tableValue }>
                            <Grid container alignItems='center' spacing={ 2 }>
                                { order.providerUser
                                    ? (
                                        <>
                                            <Grid item>
                                                <Button
                                                    disableRipple
                                                    variant='text'
                                                    color="primary"
                                                    className={ classes.buttonName }

                                                    onClick={ setIsOpenProviderCard.bind(this, order?.providerUser || {}, false) }
                                                >
                                                    { order?.providerUser?.firstName }
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Позвонить" arrow>
                                                    <Link href={ `tel:+${ order?.providerUser?.phone }` }>
                                                        { formatPhone(order?.providerUser?.phone || "-") }
                                                    </Link>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    href={ `https://t.me/+${ order?.providerUser?.phone }` }
                                                    target="_blank"
                                                    variant="outlined"
                                                    color="primary"
                                                    className={ classes.tableButton }
                                                >
                                                    Написать в телеграм
                                                </Button>
                                            </Grid>
                                        </>
                                    )
                                    : (
                                        <Grid item>
                                            -
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </TableCell>
                        <TableCell width={ 150 } align="right"/>
                    </TableRow>

                    { order.deliveryMethod !== "pickup" && (
                        <>
                            <TableRow>
                                <TableCell width="20%">Экспедитор</TableCell>
                                <TableCell className={ classes.tableValue }>
                                    <Grid container alignItems='center' spacing={ 2 }>
                                        <Grid item>
                                            { order?.courierUser?.firstName || "-" }
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title="Позвонить" arrow>
                                                <Link href={ `tel:+${ order?.courierUser?.phone }` }>
                                                    { formatPhone(order?.courierUser?.phone || "-") }
                                                </Link>
                                            </Tooltip>
                                        </Grid>
                                        { order.courierUser && (
                                            <Grid item>
                                                <Button
                                                    href={ `https://t.me/+${ order?.courierUser?.phone }` }
                                                    target="_blank"
                                                    variant="outlined"
                                                    color="primary"
                                                    className={ classes.tableButton }
                                                >
                                                    Написать в телеграм
                                                </Button>
                                            </Grid>
                                        ) }
                                    </Grid>
                                </TableCell>
                                <TableCell width={ 150 } align="right"/>
                            </TableRow>
                            <TableRow>
                                <TableCell width="20%">Водитель</TableCell>
                                <TableCell className={ classes.tableValue }>
                                    <Grid container alignItems='center' spacing={ 2 }>
                                        <Grid item>
                                            { order?.driver?.firstName || "-" }
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title="Позвонить" arrow>
                                                <Link href={ `tel:+${ order?.driver?.phone }` }>
                                                    { formatPhone(order?.driver?.phone || "-") }
                                                </Link>
                                            </Tooltip>
                                        </Grid>
                                        { order.driver && (
                                            <Grid item>
                                                <Button
                                                    href={ `https://t.me/+${ order?.driver?.phone }` }
                                                    target="_blank"
                                                    variant="outlined"
                                                    color="primary"
                                                    className={ classes.tableButton }
                                                >
                                                    Написать в телеграм
                                                </Button>
                                            </Grid>
                                        ) }
                                    </Grid>
                                </TableCell>
                                <TableCell width={ 150 } align="right"/>
                            </TableRow>
                        </>
                    ) }

                    <TableRow>
                        <TableCell width="20%">Комментарий исполнителя</TableCell>
                        <TableCell className={ classes.tableValue }>
                            { order?.providerComment || "-" }
                        </TableCell>
                        <TableCell width={ 150 } align="right"/>
                    </TableRow>

                    { order.confirmationCode && (
                        <TableRow>
                            <TableCell width="20%">Код подтверждения</TableCell>
                            <TableCell className={ classes.tableValue }>
                                { order?.confirmationCode || "-" }
                            </TableCell>
                            <TableCell width={ 150 } align="right"/>
                        </TableRow>
                    ) }
                </TableBody>
            </Table>
            { order.deliveryMethod !== "pickup" && (
                <Collapse in={ isShowMap } timeout="auto">
                    <Box height={ 420 } mt={ 2 }>
                        <Map
                            center={ [ order.deliveryAddressLat, order.deliveryAddressLong ] }
                            coords={ [ order.deliveryAddressLat, order.deliveryAddressLong ] }
                        />
                    </Box>
                </Collapse>
            ) }
        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        borderRadius: 10,
        backgroundColor: "rgba(132, 185, 44, 0.04)",

        padding: "24px 32px"
    },

    rowContent: {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },

    table: {
        "&.MuiTable-root": {
            boxShadow: "none"
        },
        "& .MuiTableCell-root": {
            padding: "8px 0 0 0",
            backgroundColor: "transparent!important"
        },
    },
    tableValue: {
        fontSize: 14,
        fontWeight: "600"
    },
    tableButton: {
        height: "max-content",
        width: "max-content",
        whiteSpace: "nowrap",
        fontSize: 12,
        padding: "0 12px",
        borderRadius: 4
    },

    delivery: {
        display: "flex",
        alignItems: "center",

        "& > img": {
            marginRight: 8
        }
    },

    buttonName: {
        height: 30,
        fontSize: 12,
        padding: 0,
        borderRadius: 0,
    }
}));

export default OrderInformation
