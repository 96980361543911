import React, {Component} from "react";
import {
    Backdrop,
    Box,
    CircularProgress,
    Container,
    Grid,
    Typography
} from "@mui/material";
import {
    Actions as ActionsComponent,
    DepotInformation,
    DialogAcceptOrder as DialogAcceptOrderComponent,
    DialogClientConfirmReturn as DialogClientConfirmReturnComponent,
    DialogDeliveryAddressChange as DialogDeliveryAddressChangeComponent,
    DialogDriverConfirmReturn as DialogDriverConfirmReturnComponent,
    DialogFormChangeOrderItems,
    DialogFormDeliveredToClient as DialogFormDeliveredToClientComponent,
    DialogFormSetExpeditor,
    DialogFormSetProvider,
    DialogFullReturnFill as DialogFullReturnFillComponent,
    DialogPartialReturnFill as DialogPartialReturnFillComponent,
    DialogSetSale as DialogSetSaleComponent,
    DialogTimeChange as DialogTimeChangeComponent,
    DialogTransferReturnToProvider as DialogTransferReturnToProviderComponent,
    DialogTransferTheGoodsConfirm as DialogTransferTheGoodsConfirmComponent,
    InfoMessage as InfoMessageComponent,
    OrderInformation as OrderInformationComponent,
    OrderItems as OrderItemsComponent,
    OrderPhotos as OrderPhotosComponent,
    StoreInformation as StoreInformationComponent,
    DialogAddBillOfLadingPhotos as DialogAddBillOfLadingPhotosComponent,
} from "./components";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import {
    DialogChangeStatusPayment,
    DialogConfirmAction,
    DialogUserCart
} from "../../../../components";
import {
    userIsLegal
} from "../../../../helper/userType";
import agent from "../../../../agent/agent";

class Order extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: {},
            billOfLadings: [],

            returnItems: [],
            shortageItems: [],

            orderId: props?.match?.params?.id || null,

            orderReceipts: [],

            providerPhotos: [],
            driverPhotos: [],
            returnPhotos: [],
            billOfLadingPhotos: [],

            stores: [],

            isOpenTimeChange: false,
            isOpenSetProvider: false,
            isOpenSetExpeditor: false,
            isOpenSetSale: false,
            isOpenDeliveryAddressChange: false,
            isOpenChangeOrderItems: false,
            isOpenAddBillOfLadingPhotos: false,

            // Статусы открытия модальных окон клиента и водителя
            isOpenTransferTheGoodsConfirm: false,
            isOpenDeliveredToClient: false,
            isOpenAcceptOrder: false,
            isOpenPartialReturnFill: false,
            isOpenClientConfirmReturn: false,
            isOpenDriverConfirmReturn: false,
            isOpenTransferReturnToProvider: false,
            isOpenFullReturnFill: false,
            //---------------------------------------------------

            isShowBackdrop: false,

            isLoading: true,
            isLoadingReceipts: false,
            isLoadingBillOfLading: false,
            isLoadingSendLinkToAccount: false,
            isLoadingProviderPhotos: false,
            isLoadingDriverPhotos: false,
            isLoadingReturnPhotos: false,
            isLoadingBillOfLadingPhotos: false,

            isNotFound: false,
        };

        this.refDialogChangeStatusPayment = React.createRef();
        this.refDialogConfirmAction = React.createRef();
        this.refDialogUserCart = React.createRef();
    }

    componentDidMount = async () => {
        if (!this.state.orderId) {
            this.props.history.replace(`/orders`)
        }
        await this.getStores();
        await this.getReturnItems();
        await this.getShortageItems();
        await this.getOrder();
    }

    // Логика получения заказа
    getOrder = async () => {

        const orderId = this.state.orderId;

        const order = await agent.get(`/orders/${orderId}`).then((res) => {
            return res.data?.order || null
        }).catch(() => {
            return null
        });
        if (!order) {
            this.setState({
                isNotFound: true,
                isLoading: false
            })

            return null
        }


        this.setState({
            isLoading: false,
            order
        });
    }

    getStores = async () => {
        const {stores} = await agent.get(`/stores?needReserve=1&limit=0`)
            .then(res => res.data)
            .catch(err => {
                return [];
            });

        let newStores = [];

        if (stores.length > 0) {
            newStores = stores.filter(store => store.ownerUser.active);
        }

        this.setState({stores: newStores});
    }

    getReturnItems = async () => {
        const response = await agent.get(`returns/${this.state.orderId}/items`)
            .then(res => res.data.returnItems)
            .catch(err => []);

        this.setState({returnItems: response});
    };

    // Получение невозврат товаров
    getShortageItems = async () => {
        const response = await agent.get(`/returns/${this.state.orderId}/shortage-items`)
            .then(res => res.data.shortageItems)
            .catch(err => {
                return [];
            });

        this.setState({
            shortageItems: response,
        });
    };

    // Логика получения накладной заказа
    getBillOfLading = async () => {
        if (this.state.billOfLadings.length <= 0) {
            this.setState({isLoadingBillOfLading: true});

            const response = await agent.get(`orders/${this.state.orderId}/bill-of-lading`)
                .then(res => res.data.pdfFile)
                .catch(err => {
                    return {error: err.response};
                });

            if (response.error) {
                this.setState({
                    isLoadingReceipts: false,
                    orderReceipts: [],
                });

                Notification({
                    message: response.error?.data?.message || "Ошибка при загрузке чеков",
                    type: NotificationTypes.error,
                });

                return null;
            }

            let billOfLadingsUrls = [];

            if (Object.keys(response).length <= 0) return null;

            const billOfLadingUrl = window.URL.createObjectURL(new Blob([new Uint8Array(response.data).buffer]));

            billOfLadingsUrls.push(billOfLadingUrl);

            this.setState({
                isLoadingBillOfLading: false,
                billOfLadings: billOfLadingsUrls,
            });
        }

    };

    //  Логика получения чеков
    getOrderReceipts = async () => {
        if (this.state.orderReceipts.length <= 0) {
            this.setState({isLoadingReceipts: true});

            const response = await agent.get(`orders/${this.state.orderId}/receipts`)
                .then(res => res.data.receipts)
                .catch(err => {
                    return {error: err.response};
                });

            if (response.error) {
                this.setState({
                    isLoadingReceipts: false,
                    orderReceipts: [],
                });

                Notification({
                    message: response.error?.data?.message || "Ошибка при загрузке чеков",
                    type: NotificationTypes.error,
                });

                return null;
            }

            let receiptsUrls = [];

            if (Array.isArray(response) && response.length > 0) {
                receiptsUrls = [...response.map(receipt => window.URL.createObjectURL(new Blob([new Uint8Array(receipt.file.data).buffer])))];
            }

            this.setState({
                isLoadingReceipts: false,
                orderReceipts: receiptsUrls,
            });
        }
    };

    // Логика получения фотографий прикрепленных к заказу
    // TYPES - 'providerPhotos' | 'driverPhotos' | 'returnPhotos' | 'billOfLadingPhotos'
    getOrderPhotosUsingType = async (type = '', callback) => {
        this.setLoadingStatusOrderPhotos(type, true);

        const response = await agent.get(`/orders/${this.state.orderId}/photos?neededType=${type}`)
            .then(res => res.data.photos)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setLoadingStatusOrderPhotos(type, false);

            Notification({
                message: response.error?.data?.message || "Ошибка при загрузке фотографий",
                type: NotificationTypes.error,
            });

            return null;
        }

        let newPhotos = [];

        if (response.length > 0) {
            if (Array.isArray(response) && response.length > 0) {
                newPhotos = [...response.map(receipt => window.URL.createObjectURL(new Blob([new Uint8Array(receipt.photo.data).buffer])))];
            }
        }

        this.setState({[type]: newPhotos});

        this.setLoadingStatusOrderPhotos(type, false);

        if (newPhotos.length > 0) {
            callback();
        } else {
            Notification({
                message: 'Не удалось найти фотографии, попробуйте позже или обратитесь к администратору',
                type: NotificationTypes.error,
            });
        }
    };

    // Переключение лоадеров при подгрузке фотографий прикрепленных к заказу
    setLoadingStatusOrderPhotos = (type = '', status = false) => {
        type === 'providerPhotos' && this.setState({isLoadingProviderPhotos: status});
        type === 'driverPhotos' && this.setState({isLoadingDriverPhotos: status});
        type === 'returnPhotos' && this.setState({isLoadingReturnPhotos: status});
        type === 'billOfLadingPhotos' && this.setState({isLoadingBillOfLadingPhotos: status});
    };

    // Логика прикрепления фотографий накладных
    attachBillOfLadingPhotos = async (photos, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Подтверждение",
                message: "Вы действительно хотите прикрепить фотографии?",
                labelSuccess: "Да, прикрепить",
                onSuccess: this.attachBillOfLadingPhotos.bind(this, photos, true),
            });

            return
        }

        this.setState({isShowBackdrop: true});

        const body = {
            orderId: this.state.orderId,
            billOfLadingPhotoInBase64: photos,
        };

        const response = await agent.post(`/orders/${this.state.orderId}/attach-bill-of-lading`, body)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response};
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при добавлении фотографий",
                type: NotificationTypes.error,
            });

            return;
        }

        let newPhotos = [];

        if (Array.isArray(response.photos) && response.photos.length > 0 && this.state.billOfLadingPhotos.length > 0) {
            newPhotos = [...response.photos.map(photo => window.URL.createObjectURL(new Blob([new Uint8Array(photo.data).buffer])))];
        }

        await this.getOrder();

        this.setState({
            isShowBackdrop: false,
            billOfLadingPhotos: [...this.state.billOfLadingPhotos, ...newPhotos],
        });

        Notification({
            message: "Фотографии успешно прикреплены",
            type: NotificationTypes.success,
        });

    };

    // Логика отправки ссылки на личный кабинет пользователя
    sendLinkToAccount = async () => {
        this.setState({isLoadingSendLinkToAccount: true});

        const response = await agent.post('/messages', {
            userId: this.state.order.purchaserUserId,
        })
            .then(res => res.data)
            .catch(err => {
                return {error: err.response};
            });

        if (response.error) {
            this.setState({isLoadingSendLinkToAccount: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при отправке ссылки на личный кабинет заказчику",
                type: NotificationTypes.error,
            });

            return;
        }

        this.setState({isLoadingSendLinkToAccount: false});

        Notification({
            message: "Ссылка на личный кабинет успешно отправлена закачику",
            type: NotificationTypes.success,
        });
    };

    // Логика подтверждения принятия груза
    transferTheGoodsConfirm = async (code, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Подтверждение",
                message: `Вы действительно хотите подтвердить принятие груза?`,
                labelSuccess: "Да, подтвердить",
                onSuccess: this.transferTheGoodsConfirm.bind(this, code, true),
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.put(`/orders/${this.state.orderId}/confirm`, {
            code,
        })
            .then(res => res.data)
            .catch(err => {
                return {error: err.response};
            })

        if (response.error) {
            this.setState({isShowBackdrop: false});
            Notification({
                message: response.error?.data?.message || "Ошибка при подтверждении принятии заказа",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrder();
        await this.getReturnItems();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Груз успешно принят",
            type: NotificationTypes.success,
        });
    };

    // Логика доставки заказа до заказчика
    deliveredToClient = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Подтверждение",
                message: `Вы действительно хотите подтвердить доставку заказа?`,
                labelSuccess: "Да, подтвердить",
                onSuccess: this.deliveredToClient.bind(this, form, true),
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.put(`/orders/${this.state.orderId}/transfer`, {
            orderPhotosInBase64: form.photo,
        })
            .then(res => res.data)
            .catch(err => {
                return {error: err.response};
            })

        if (response.error) {
            this.setState({isShowBackdrop: false});
            Notification({
                message: response.error?.data?.message || "Ошибка при подтверждении доставки заказа",
                type: NotificationTypes.error,
            })

            return null;
        }

        await this.getOrder();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Заказ успешно доставлен",
            type: NotificationTypes.success,
        });
    };

    acceptOrder = async (code, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Подтверждение",
                message: `Вы действительно хотите передать груз заказчику?`,
                labelSuccess: "Да, передать",
                onSuccess: this.acceptOrder.bind(this, code, true),
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.put(`/orders/${this.state.orderId}/confirm`, {code})
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при передаче груза заказчику",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrder();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Груз успешно передан заказчику. Заказ считается выполненным",
            type: NotificationTypes.success,
        });
    };

    // Логика работы по статусам
    setOrderStatus = async (status, isConfirm) => {

        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Подтверждение",
                message: `Вы действительно хотите изменить статус готовности заказа?`,
                labelSuccess: "Да, изменить",
                onSuccess: this.setOrderStatus.bind(this, status, true),
            });

            return
        }

        this.setState({isShowBackdrop: true});

        let response = null;

        if (status === "partialReturn" || status === 'fullReturn') {
            response = await agent.put(`/returns/${this.state.order.id}`, {
                orderId: this.state.order.id,
                returnType: status,
            })
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    return {error: err.response}
                });
        } else {
            response = await agent.put(`/orders/${this.state.order.id}/set-status`, {
                orderId: this.state.order.id,
                deliveryStatus: status,
            })
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    return {error: err.response}
                });
        }

        if (response !== null) {
            if (response.error) {
                this.setState({isShowBackdrop: false});
                Notification({
                    type: NotificationTypes.error,
                    message: response.error?.data?.message || "Ошибка сервера"
                })
                return
            }

            await this.getOrder();
            await this.getReturnItems();
            this.setState({isShowBackdrop: false});

            Notification({
                type: NotificationTypes.success,
                message: "Статус заказа успешно изменен"
            });

            return;
        }

        this.setState({isShowBackdrop: true});

        Notification({
            type: NotificationTypes.error,
            message: "Непредвиденная ошибка",
        });
    }

    //Частичный возврат (return-fill)
    partialReturnFill = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Подтверждение",
                message: `Вы действительно хотите провести частичный возврат?`,
                labelSuccess: "Да, провести",
                onSuccess: this.partialReturnFill.bind(this, form, true),
            });

            return null;
        }

        this.setState({isShowBackdrop: true})

        const response = await agent.put(`/returns/${this.state.orderId}/return-fill`, form)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                type: NotificationTypes.error,
                message: response.error.data.message || "Ошибка сервера",
            });

            return null
        }

        await this.getOrder();
        await this.getReturnItems();

        this.setState({isShowBackdrop: false});

        Notification({
            type: NotificationTypes.success,
            message: "Частичный возврат успешно проведен, ожидайте подтверждения от заказчика",
        });
    };

    //Полный возврат (return-fill)
    fullReturnFill = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Подтверждение",
                message: `Вы действительно хотите провести полный возврат?`,
                labelSuccess: "Да, провести",
                onSuccess: this.fullReturnFill.bind(this, form, true),
            });

            return
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.put(`/returns/${this.state.orderId}/return-fill`, form)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                type: NotificationTypes.error,
                message: response.error.data.message || "Ошибка сервера",
            });

            return null
        }

        await this.getOrder();
        await this.getReturnItems();

        this.setState({isShowBackdrop: false});

        Notification({
            type: NotificationTypes.success,
            message: "Полный возврат успешно проведен, ожидайте подтверждения от заказчика",
        });
    };

    //Подтверждение возврата
    clientConfirmReturn = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Подтверждение",
                message: `Вы действительно хотите подтвердить ${this.state.order.deliveryStatus === 'partialReturn' ? "частичный" : "полный"} возврат?`,
                labelSuccess: "Да, подтвердить",
                onSuccess: this.clientConfirmReturn.bind(this, form, true),
            });

            return
        }

        this.setState({isShowBackdrop: true})

        const response = await agent.put(`/returns/${this.state.orderId}/return-confirmation`, {
            deliveredProductIds: [...form],
        })
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });
        if (response.error) {
            this.setState({isShowBackdrop: false})

            Notification({
                message: response.error.data.message || "Ошибка подтверждения возврата",
                type: NotificationTypes.error
            });

            return
        }

        await this.getOrder()
        await this.getReturnItems();

        this.setState({isShowBackdrop: false})

        Notification({
            message: "Возврат успешно подтвержден",
            type: NotificationTypes.success,
        });
    };

    // Подтверждение возврата
    driverConfirmReturn = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Подтверждение",
                message: `Вы действительно хотите подтвердить возврат?`,
                acceptButtonTitle: "Да, подтвердить",
                onSuccess: this.driverConfirmReturn.bind(this, form, true),
            });

            return null;
        }

        this.setState({isShowBackdrop: true})

        const response = await agent.put(`returns/${this.state.orderId}/return-confirmation`, {
            code: form.code,
        })
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false})

            Notification({
                message: response.error?.data?.message || "Ошибка при подтверждении возврата",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrder();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Возврат успешно подтвержден",
            type: NotificationTypes.success,
        });
    };

    // Трансфер возврата до провайдера
    transferReturnToProvider = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Подтверждение",
                message: `Вы действительно хотите подтвердить доставку возврата до провайдера?`,
                labelSuccess: "Да, подтвердить",
                onSuccess: this.transferReturnToProvider.bind(this, form, true),
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.put(`/returns/${this.state.orderId}/transfer-return-to-provider`, form)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response};
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при доставке возврата до исполнителя",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrder();
        await this.getReturnItems();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Доставка до исполнителя успешно подтверждено, дождитесь подтвеждения от исполнителя",
            type: NotificationTypes.success,
        });
    };

    // Логика назначения провайдера
    setProvider = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Назначение исполнителя",
                message: `Вы действительно хотите назначить исполнителя?"`,
                labelSuccess: "Да, назначить",
                onSuccess: this.setProvider.bind(this, form, true)
            })

            return null
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.put(`/orders/${this.state.orderId}/take-as-provider`, form)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            })

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при назначении исполнителя",
                type: NotificationTypes.error
            });

            return null
        }

        await this.getOrder();
        this.setState({isShowBackdrop: false});

        Notification({
            message: "Исполнитель успешно назначен",
            type: NotificationTypes.success
        });
    };

    // Логика назначения экспедитора
    setExpeditor = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Назначение исполнителя",
                message: `Вы действительно хотите назначить экспедитора?"`,
                labelSuccess: "Да, назначить",
                onSuccess: this.setExpeditor.bind(this, form, true)
            })

            return null
        }

        this.setState({isShowBackdrop: true})

        const response = await agent.put(`/orders/${this.state.orderId}/take-as-courier`, {
            courierId: form.expeditorId,
        })
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false})
            Notification({
                message: response.error?.data?.message || "Ошибка при назначении экспедитора",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrder();
        this.setState({isShowBackdrop: false})
        Notification({
            message: "Экспедитор успешно назначен",
            type: NotificationTypes.success,
        })
    };

    //Логика установки скидки
    setOrderSale = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Назначение исполнителя",
                message: `Вы действительно хотите установить скидку?"`,
                labelSuccess: "Да, установить",
                onSuccess: this.setOrderSale.bind(this, form, true)
            });

            return null
        }

        this.setState({isShowBackdrop: true});

        const body = {
            orderId: +this.state.orderId,
            discountValue: +form.discountValue,
            userId: this.props.user.id,
        }

        const response = await agent.put(`/orders/${this.state.orderId}/discount`, body)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});
            Notification({
                type: NotificationTypes.error,
                message: response.error?.data?.message || "Ошибка сервера"
            });

            return
        }

        await this.getOrder();

        this.setState({isShowBackdrop: false});

        Notification({
            type: NotificationTypes.success,
            message: "Скидка успешно применена",
        })
    };

    //Функция для открытия карточки пользователя
    openUserCart = (user, isOpen = false) => {
        if (!isOpen) {
            this.refDialogUserCart.current.open(user || [], this.openUserCart.bind(this));
            return null;
        }

        this.setState({isShowBackdrop: true});
    };

    // Логика отмены заказа
    cancelOrder = async (isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Назначение исполнителя",
                message: `Вы действительно хотите отменить заказ?"`,
                labelSuccess: "Да, отменить",
                onSuccess: this.cancelOrder.bind(this, true),
            })

            return null;
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.put(`/orders/${this.state.orderId}/cancel`)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при отмене заказа",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrder();

        this.setState( { isShowBackdrop: false } );

        Notification( {
            message: "Заказ успешно отменен",
            type: NotificationTypes.success,
        } );
    };

    // Логика изменения статуса оплаты
    changeStatusPayment = async (order = this.state.order, newStatus) => {
        if (!newStatus) {
            this.refDialogChangeStatusPayment.current.open({
                order: order,
                onSuccess: this.changeStatusPayment.bind(this)
            })

            return
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.put(`/orders/${order.id}/set-status`, {
            orderId: order.id,
            paymentStatus: newStatus
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return {error: err.response}
        })
        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                type: NotificationTypes.error,
                message: response.error?.data?.message || "Ошибка сервера"
            })

            return
        }

        await this.getOrder();

        this.setState({isShowBackdrop: false});

        Notification({
            type: NotificationTypes.success,
            message: "Статус оплаты успешно изменен"
        });
    }

    //Логика изменения адреса и способа доставки
    changeDeliveryAddress = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Изменение адреса доставки",
                message: `Вы действительно хотите изменить адрес доставки?"`,
                labelSuccess: "Да, изменить",
                onSuccess: this.changeDeliveryAddress.bind(this, form, true)
            })

            return null
        }

        this.setState({isShowBackdrop: true});

        const body = {
            ...form,
            purchaserId: this.state.order.purchaserUser.id,
        };

        const response = await agent.put(`/orders/${this.state.orderId}/delivery-method`, body)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка изменения адреса доставки",
                type: NotificationTypes.error
            });

            return null
        }

        await this.getOrder();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Адрес доставки успешно изменено",
            type: NotificationTypes.success
        });
    }

    //Логика изменения даты и времени доставки
    changeDeliveryTime = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Изменение времени доставки",
                message: `Вы действительно хотите изменить время доставки?"`,
                labelSuccess: "Да, изменить",
                onSuccess: this.changeDeliveryTime.bind(this, form, true)
            })

            return null
        }

        this.setState({isShowBackdrop: true});

        const body = {
            ...form,
            purchaserId: this.state.order.purchaserUser.id,
        };

        const response = await agent.put(`/orders/${this.state.orderId}/delivery-date`, body)
            .then((res) => res.data)
            .catch((err) => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: "Ошибка изменения времени доставки",
                type: NotificationTypes.error
            });

            return null
        }

        await this.getOrder();
        this.setState({isShowBackdrop: false});

        Notification({
            message: "Время доставки успешно изменено",
            type: NotificationTypes.success
        });
    };

    // Логика изменения состава заказа
    changeOrderItems = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Изменение состава заказа",
                message: `Вы действительно хотите изменить состав заказа?"`,
                labelSuccess: "Да, изменить",
                onSuccess: this.changeOrderItems.bind(this, form, true)
            })

            return null
        }

        this.setState({
            isShowBackdrop: true,
        })

        const body = {
            ...form,
            purchaserId: this.state.order.purchaserUser.id,
        };

        const response = await agent.put(`/orders/${this.state.orderId}/items`, body)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({
                isShowBackdrop: false,
            });

            Notification({
                message: response.error?.data?.message || "Ошибка при изменении состава заказа",
                type: NotificationTypes.error,
            });

            return null;
        }

        this.setState({
            isShowBackdrop: false,
        });

        await this.getOrder();

        Notification({
            message: "Состав заказа успешно изменен",
            type: NotificationTypes.success,
        });
    };

    // Логика генерации ссылки на оплату
    orderPayment = async (isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Подтверждение",
                message: `Вы действительно хотите сгенерировать ссылку на оплату?"`,
                labelSuccess: "Да, сгенерировать",
                onSuccess: this.orderPayment.bind(this, true),
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        const {
            orderId,
        } = this.state;

        const body = {
            orderId,
        };

        const response = await agent.post(`payments/online/send-link-to-client`, body)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response};
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибки при генерации ссылки на оплату",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrder();
        await this.getReturnItems();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Ссылка на оплату успешно сгенерирована и отправлена заказчику",
            type: NotificationTypes.success,
        });
    };

    // Логика генерации ссылки на доплату
    orderPaymentAdditional = async (isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Подтверждение",
                message: `Вы действительно хотите сгенерировать ссылку на доплату?"`,
                labelSuccess: "Да, сгенерировать",
                onSuccess: this.orderPaymentAddition.bind(this, true),
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        const {
            order,
            orderId,
        } = this.state;

        const body = {
            clientId: order.purchaserUserId,
            orderId,
        };

        const response = await agent.post(`/payments/additional`, body)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response};
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при генерации ссылки на доплату",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrder();
        await this.getReturnItems();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Ссылка на доплату успешно сгенерирована и отправлена заказчику",
            type: NotificationTypes.success,
        });
    };

    // Генерация кнопок управления заказом за клиента и за водителя
    _getDriverAndClientActionsButtons = () => {
        const order = this.state.order;
        const deliveryMethod = this.state.order.deliveryMethod;
        const deliveryStatus = this.state.order.deliveryStatus;
        const statusOfReturnOrder = this.state.order.statusOfReturnOrder;

        let buttons = [];

        if (deliveryMethod === 'delivery') {
            if (deliveryStatus === 'transferringToDelivery') {
                buttons.push({
                    label: "Принять груз",
                    tooltip: "Принять груз за водителя",
                    onClick: this.setIsOpenTransferTheGoodsConfirm.bind(this, true),
                });
            }

            if (deliveryStatus === 'enRoute') {
                buttons.push({
                    label: "Заказ доставлен",
                    tooltip: "Поменять статус на 'Заказ доставлен' за водителя",
                    onClick: this.setIsOpenDeliveredToClient.bind(this, true)
                });
            }


            // Завершение заказа (передача заказа заказчику)
            if ([
                "transferringToClient",
            ].includes(deliveryStatus) || (["partialReturn", "fullReturn"].includes(deliveryStatus) && (order.discount !== 0 || order.adminDiscount !== 0) && order.statusOfReturnOrder === 'created')) {
                buttons.push({
                        label: "Передать груз",
                        tooltip: "Передать груз за водителя",
                        onClick: this.setIsOpenAcceptOrder.bind(this, true)
                    }
                );
            }

            // Частичный возврат (установка статуса, активировать кнопку "Скидка" у исполнителя)
            if ([
                "transferringToClient",
            ].includes(deliveryStatus)) {
                buttons.push({
                    label: "Частичный возврат",
                    tooltip: "Сделать частичный возврат за водителя",
                    onClick: this.setOrderStatus.bind(this, 'partialReturn', false)
                });
            }

            //Частичный возврат (возврат товаров)
            if (deliveryStatus === 'partialReturn' && statusOfReturnOrder === 'created') {
                buttons.push({
                    label: "Возврат",
                    tooltip: "Заполнить частичный возврат за водителя",
                    onClick: this.setIsOpenPartialReturnFill.bind(this, true)
                });
            }

            // Полный возврат (установка статуса, активировать кнопку "Скидка" у исполнителя)
            if ([
                "transferringToClient",
            ].includes(deliveryStatus)) {
                buttons.push({
                    label: "Полный возврат",
                    tooltip: "Сделать полный возврат за водителя",
                    onClick: this.setOrderStatus.bind(this, 'fullReturn', false)
                });
            }

            // Полный возврат (возврат товаров)
            if (deliveryStatus === 'fullReturn' && order.statusOfReturnOrder === 'created') {
                buttons.push({
                    label: "Возврат",
                    onClick: this.setIsOpenFullReturnFill.bind(this, true)
                });
            }

            if (['partialReturn', 'fullReturn'].includes(deliveryStatus) && statusOfReturnOrder === 'filled') {
                buttons.push({
                    label: "Подтвердить возврат",
                    tooltip: "Подтвердить возврат за клиента",
                    onClick: this.setIsOpenClientConfirmReturn.bind(this, true),
                });
            }

            if (['partialReturn', 'fullReturn'].includes(deliveryStatus) && ['purchaserConfirmed', 'providerConfirmed'].includes(order.statusOfReturnOrder)) {
                buttons.push({
                    label: "Подтвердить возврат",
                    tooltip: "Подвердить возврат за водителя",
                    onClick: this.setIsOpenDriverConfirmReturn.bind(this, true)
                });
            }

            if (['partialReturn', 'fullReturn'].includes(deliveryStatus) && ['courierFillConfirmed'].includes(order.statusOfReturnOrder)) {
                buttons.push({
                    label: "Возврат доставлен исполнителю",
                    tooltip: "Доставить возврат за водителя",
                    onClick: this.setIsOpenTransferReturnToProvider.bind(this, true),
                });
            }
        }

        return buttons;
    };

    // Открытие формы подтверждения принятия груза
    setIsOpenTransferTheGoodsConfirm = (isOpen) => {
        this.setState({
            isOpenTransferTheGoodsConfirm: isOpen,
        });
    };
    // Закрытие формы подтверждения принятия заказа
    handleCloseTransferTheGoodsConfirm = () => {
        this.setIsOpenTransferTheGoodsConfirm(false);

    };

    // Открытие формы доставки до заказчика
    setIsOpenDeliveredToClient = (isOpen) => {
        this.setState({
            isOpenDeliveredToClient: isOpen,
        })
    };
    // Закрытие формы доставки до заказчика
    handleCloseDeliveredToClient = () => {
        this.setIsOpenDeliveredToClient(false);
    };

    // Открытие формы завершение заказа (передачи заказа заказчику)
    setIsOpenAcceptOrder = (isOpen) => {
        this.setState({
            isOpenAcceptOrder: isOpen,
        });
    };
    // Закрытие формы завершения заказа (передачи заказа заказчику)
    handleCloseAcceptOrder = () => {
        this.setIsOpenAcceptOrder(false);
    };

    // Открытие формы заполнения частичного возврата
    setIsOpenPartialReturnFill = (isOpen) => {
        this.setState({isOpenPartialReturnFill: isOpen});
    };
    // закрытие формы заполнения частичного возврата
    handleClosePartialReturnFill = () => {
        this.setIsOpenPartialReturnFill(false);
    }

    //Открытие формы подтверждения частичного возврата
    setIsOpenClientConfirmReturn = (isOpen) => {
        this.setState({
            isOpenClientConfirmReturn: isOpen,
        })
    };
    //Закрытие формы подтверждения частичного возврата
    handleCloseClientConfirmReturn = () => {
        this.setIsOpenClientConfirmReturn(false);
    }

    // Открытие формы подтверждения возврата
    setIsOpenDriverConfirmReturn = (isOpen) => {
        this.setState({
            isOpenDriverConfirmReturn: isOpen,
        });
    };
    // Закрытие формы подтверждения возврата
    handleCloseDriverConfirmReturn = () => {
        this.setIsOpenDriverConfirmReturn(false);
    };

    // Открытие формы трансфера возврата до провайдера
    setIsOpenTransferReturnToProvider = (isOpen) => {
        this.setState({isOpenTransferReturnToProvider: isOpen})
    };
    // Закрытие формы трансфера возврата до провайдера
    handleCloseTransferReturnToProvider = () => {
        this.setIsOpenTransferReturnToProvider(false);
    };

    // Открытие формы заполнения полного возврата
    setIsOpenFullReturnFill = (isOpen) => {
        this.setState({isOpenFullReturnFill: isOpen});
    };
    // Закрытие формы заполнения полного возврата
    handleCloseFullReturnFill = () => {
        this.setIsOpenFullReturnFill(false);
    };

    // Открытие формы изменения времени и даты
    setIsOpenTimeChange = (isOpen) => {
        this.setState({isOpenTimeChange: isOpen});
    };
    // Закрытие формы изменения времени и даты
    handleCloseTimeChange = () => {
        this.setIsOpenTimeChange(false);
    };

    //Открытие формы изменения адреса доставки
    setIsOpenDeliveryAddressChange = (isOpen) => {
        this.setState({isOpenDeliveryAddressChange: isOpen});
    }
    //Закрытие формы изменения адреса доставки
    handleCloseDeliveryAddressChange = () => {
        this.setIsOpenDeliveryAddressChange(false);
    };

    //Открытие формы для установки провайдера
    setIsOpenSetProvider = (isOpen) => {
        this.setState({
            isOpenSetProvider: isOpen,
        });
    }
    //Закрытие формы для установки провайдера
    handleCloseSetProvider = () => {
        this.setIsOpenSetProvider(false);
    }

    // Открытие формы установки скидки
    setIsOpenSetSale = (isOpen) => {
        this.setState({isOpenSetSale: isOpen});
    };
    // Закрытие формы установки скидки
    handleCloseSetSale = () => {
        this.setIsOpenSetSale(false);
    };

    // Открытие формы изменения состава заказа
    setIsOpenChangeOrderItems = (isOpen) => {
        this.setState({
            isOpenChangeOrderItems: isOpen,
        });
    };
    // закрытие формы изменения состава заказа
    handleCloseIsOpenChangeOrderItems = () => {
        this.setIsOpenChangeOrderItems(false);
    };

    // Логика открытия формы назначения экспедитора
    setIsOpenSetExpeditor = (isOpen) => {
        this.setState({
            isOpenSetExpeditor: isOpen,
        })
    };
    // Логика закрытия формы назначения экспедитора
    handleCloseIsOpenSetExpeditor = () => {
        this.setIsOpenSetExpeditor(false);
    };

    //Открытие формы добавления фотографии накладных
    setIsOpenAddBillOfLadingPhotos = (isOpen) => {
        this.setState({isOpenAddBillOfLadingPhotos: isOpen});
    };
    // Закрытие формы добавления фотографии накладных
    handleCloseAddBillOfLadingPhotos = () => {
        this.setIsOpenAddBillOfLadingPhotos(false);
    };

    render() {
        const {
            order,
            orderReceipts,
            billOfLadings,
            returnItems,
            shortageItems,
            orderId,
            stores,

            providerPhotos,
            driverPhotos,
            returnPhotos,
            billOfLadingPhotos,

            isOpenTimeChange,
            isOpenSetProvider,
            isOpenSetExpeditor,
            isOpenSetSale,
            isOpenDeliveryAddressChange,
            isOpenChangeOrderItems,
            isOpenAddBillOfLadingPhotos,

            isOpenTransferTheGoodsConfirm,
            isOpenDeliveredToClient,
            isOpenAcceptOrder,
            isOpenPartialReturnFill,
            isOpenClientConfirmReturn,
            isOpenDriverConfirmReturn,
            isOpenTransferReturnToProvider,
            isOpenFullReturnFill,

            isShowBackdrop,

            isLoading,
            isLoadingReceipts,
            isLoadingBillOfLading,
            isLoadingSendLinkToAccount,
            isLoadingProviderPhotos,
            isLoadingDriverPhotos,
            isLoadingReturnPhotos,
            isLoadingBillOfLadingPhotos,

            isNotFound
        } = this.state;

        return (
            <Container maxWidth="xl">

                <Box mb={2}>
                    <Grid container flexDirection="column" rowSpacing={2}>
                        <Grid item>
                            <Grid container alignItems="flex-end">
                                <Grid item>
                                    <Typography variant="h1" textAlign="left">
                                        Заказ № {orderId}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item width="100%">
                            <ActionsComponent
                                order={order}
                                orderId={orderId}
                                orderReceipts={orderReceipts}
                                billOfLadings={billOfLadings}
                                paymentStatus={order.paymentStatus}
                                deliveryStatus={order.deliveryStatus}
                                deliveryMethod={order.deliveryMethod}
                                returnStatus={order?.statusOfReturnOrder || ""}
                                driverAndClientActionsButtons={this._getDriverAndClientActionsButtons()}

                                isLoading={isLoading}
                                isLoadingReceipts={isLoadingReceipts}
                                isLoadingBillOfLading={isLoadingBillOfLading}
                                isLoadingSendLinkToAccount={isLoadingSendLinkToAccount}

                                setIsOpenSetProvider={this.setIsOpenSetProvider}
                                setIsOpenSetExpeditor={this.setIsOpenSetExpeditor}
                                setIsOpenSetSale={this.setIsOpenSetSale}
                                setIsOpenAddBillOfLadingPhotos={this.setIsOpenAddBillOfLadingPhotos}
                                onCancelOrder={this.cancelOrder}
                                onGetBillOfLading={this.getBillOfLading}
                                onGetReceipts={this.getOrderReceipts}
                                onSendLinkToAccount={this.sendLinkToAccount}
                            />
                        </Grid>
                    </Grid>
                </Box>


                {Boolean(!isLoading && !isNotFound) && (
                    <>

                        <Box mb={1}>
                            <OrderInformationComponent
                                order={order}

                                setIsOpenTimeChange={this.setIsOpenTimeChange}
                                setIsOpenDeliveryChange={this.setIsOpenDeliveryAddressChange}
                                setIsOpenProviderCard={this.openUserCart}
                                onChangeStatusPayment={this.changeStatusPayment}
                                onOrderPayment={this.orderPayment}
                                onOrderPaymentAdditional={this.orderPaymentAdditional}
                            />
                        </Box>

                        {order.store && (
                            <Box mb={1}>
                                <StoreInformationComponent
                                    store={order.store}
                                />
                            </Box>
                        )}

                        {order.depot && (
                            <Box mb={1}>
                                <DepotInformation depot={order.depot}/>
                            </Box>
                        )}

                        {(order.attachedPhotosFlags &&
                            (
                                order.attachedPhotosFlags.billOfLadingPhotos ||
                                order.attachedPhotosFlags.fromDriver ||
                                order.attachedPhotosFlags.fromProvider
                            )
                        ) && (
                            <Box mb={1}>
                                <OrderPhotosComponent
                                    orderDeliveryStatus={this.state.order?.deliveryStatus || ""}
                                    attachedPhotoFlags={order.attachedPhotosFlags}

                                    providerPhotos={providerPhotos}
                                    driverPhotos={driverPhotos}
                                    returnPhotos={returnPhotos}
                                    billOfLadingPhotos={billOfLadingPhotos}

                                    isLoadingProviderPhotos={isLoadingProviderPhotos}
                                    isLoadingDriverPhotos={isLoadingDriverPhotos}
                                    isLoadingReturnPhotos={isLoadingReturnPhotos}
                                    isLoadingBillOfLadingPhotos={isLoadingBillOfLadingPhotos}

                                    getPhotos={this.getOrderPhotosUsingType}
                                    setIsOpenAddBillOfLadingPhotos={this.setIsOpenAddBillOfLadingPhotos}
                                />
                            </Box>
                        )}

                        <OrderItemsComponent
                            order={order}
                            returnItems={returnItems}
                            shortageItems={shortageItems}
                            onOpenChangeOrderItems={this.setIsOpenChangeOrderItems}
                        />

                        <DialogTimeChangeComponent
                            order={order}
                            isOpen={isOpenTimeChange}
                            onClose={this.handleCloseTimeChange}
                            onChange={this.changeDeliveryTime}
                        />

                        {/*<DialogFormTimeChange*/}
                        {/*    isOpen={isOpen}*/}
                        {/*    handleClose={this.handleClose}*/}
                        {/*    onChangeTime={this.changeDeliveryTime}*/}
                        {/*/>*/}

                        <DialogDeliveryAddressChangeComponent
                            order={order}
                            isOpen={isOpenDeliveryAddressChange}
                            onClose={this.handleCloseDeliveryAddressChange}
                            onChange={this.changeDeliveryAddress}
                        />

                        <DialogFormSetProvider
                            stores={stores}
                            isOpen={isOpenSetProvider}
                            onClose={this.handleCloseSetProvider}
                            setProvider={this.setProvider}
                        />

                        <DialogFormChangeOrderItems
                            order={order}
                            isOpen={isOpenChangeOrderItems}
                            onClose={this.handleCloseIsOpenChangeOrderItems}
                            onChangeOrderItems={this.changeOrderItems}
                        />

                        <DialogFormSetExpeditor
                            isOpen={isOpenSetExpeditor}
                            onClose={this.handleCloseIsOpenSetExpeditor}
                            onSet={this.setExpeditor}
                        />

                        <DialogSetSaleComponent
                            orderPrice={order?.sum || 0}
                            isOpen={isOpenSetSale}
                            onClose={this.handleCloseSetSale}
                            onSet={this.setOrderSale}
                        />
                    </>
                )}


                {Boolean(isLoading) && (
                    <InfoMessageComponent
                        message="Загружаем заказ"
                        type="loading"
                    />
                )}

                {Boolean(isNotFound) && (
                    <InfoMessageComponent
                        message="Заказ не найден"
                        type="warn"
                    />
                )}

                <DialogTransferTheGoodsConfirmComponent
                    isOpen={isOpenTransferTheGoodsConfirm}
                    onClose={this.handleCloseTransferTheGoodsConfirm}
                    onConfirm={this.transferTheGoodsConfirm}
                />

                <DialogAcceptOrderComponent
                    isOpen={isOpenAcceptOrder}
                    onClose={this.handleCloseAcceptOrder}
                    onAccept={this.acceptOrder}
                />

                {(!isLoading) && (
                    <>
                        <DialogFormDeliveredToClientComponent
                            order={order}
                            isOpen={isOpenDeliveredToClient}
                            onClose={this.handleCloseDeliveredToClient}
                            onDelivered={this.deliveredToClient}
                        />

                        {['fullReturn', 'partialReturn'].includes(order.deliveryStatus) && (
                            <>
                                <DialogPartialReturnFillComponent
                                    order={order}
                                    isOpen={isOpenPartialReturnFill}
                                    onClose={this.handleClosePartialReturnFill}
                                    onReturnFill={this.partialReturnFill}
                                />

                                <DialogFullReturnFillComponent
                                    order={order}
                                    isOpen={isOpenFullReturnFill}
                                    onClose={this.handleCloseFullReturnFill}
                                    onReturnFill={this.fullReturnFill}
                                />
                            </>
                        )}

                        <DialogClientConfirmReturnComponent
                            order={order || []}
                            returnItems={returnItems || []}
                            isOpen={isOpenClientConfirmReturn}
                            onClose={this.handleCloseClientConfirmReturn}
                            onConfirm={this.clientConfirmReturn}
                        />

                        {['courierFillConfirmed'].includes(order.statusOfReturnOrder) && (
                            <DialogTransferReturnToProviderComponent
                                order={order}
                                returnItems={returnItems}
                                isOpen={isOpenTransferReturnToProvider}
                                onClose={this.handleCloseTransferReturnToProvider}
                                onTransfer={this.transferReturnToProvider}
                            />
                        )}
                    </>
                )}

                <DialogDriverConfirmReturnComponent
                    isOpen={isOpenDriverConfirmReturn}
                    onClose={this.handleCloseDriverConfirmReturn}
                    onConfirm={this.driverConfirmReturn}
                />

                <DialogUserCart
                    ref={this.refDialogUserCart}
                    refDialogConfirmation={this.refDialogConfirmAction}
                    history={this.props.history}
                />

                <DialogChangeStatusPayment
                    ref={this.refDialogChangeStatusPayment}
                />

                <DialogAddBillOfLadingPhotosComponent
                    isOpen={isOpenAddBillOfLadingPhotos}

                    onAttach={this.attachBillOfLadingPhotos}
                    onClose={this.handleCloseAddBillOfLadingPhotos}
                />

                <DialogConfirmAction
                    ref={this.refDialogConfirmAction}
                />


                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </Container>
        );
    }
}

export default Order