import { Box } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import agent from "../../agent/agent";
import {
    Header as HeaderComponent,
    Error403 as Error403Component,
} from "./components";

class Default extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasRights: false,
        };
    }

    componentDidMount = () => {
        this.checkUserPermissions();
    };


    // Проверка пользователя на доступ в раздел
    checkUserPermissions = () => {
        const hasRights = (this.props?.rules || []).includes(this.props.userRole);

        if (this.state.hasRights === hasRights) {
            return
        }

        this.setState({
            hasRights
        });
    }

    render() {
        const { hasRights } = this.state;

        // Если у пользователя не хватает прав
        if (!Boolean(hasRights)) {
            return <Error403Component />
        }

        return (
            <>
                <HeaderComponent />

                <Box
                    height="calc(100vh - 64px)"
                    overflow="auto"
                    px={2}
                    py={1}
                    boxSizing="border-box"
                >
                    {this.props.children}
                </Box>
            </>
        );
    }
}

export default compose(
    connect(
        (state) => ({
            userRole: state.global?.user?.role || ""
        }),
        (dispatch) => ({})
    )
)(Default);
