import React, { Component } from "react";
import {Container} from "@mui/material";
import {
    Table as TableComponent,
    Filter as FilterComponent,
    DialogFormAccreditation as DialogFormAccreditationComponent
} from "./components";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import queryString from "query-string";
import agent from "../../../../agent/agent";

const initialFilter = {
    "sort": ""
};

class Accreditation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accreditations: [],

            filter: {
                ...initialFilter
            },
            pagination: {
                page: 1,
                totalPage: 1
            },

            isLoading: true,
            isShowBackdrop: false,
        };


        this.refDialogFormAccreditation = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getAccreditations();
    }
    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }


    // Логику получения списка акредитация
    getAccreditations = async () => {

        this.setState({ isLoading: true });

        const {
            accreditations,
            totalCount
        } = await agent.get(`/accreditations`).then((res) => {
            return {
                accreditations: res.data.accreditations,
                totalCount: res.data.count
            }
        }).catch((err) => {
            return {
                accreditations: [],
                totalCount: 0
            }
        });

        let pagination = {...this.state.pagination};
        pagination.totalPage = Math.ceil(totalCount / 20) || 1;

        this.setState({
            accreditations,
            pagination,
            isLoading: false
        })

    }


    // Логика работы фильтра
    getFilters = () => {
        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};

        let string = [
            `page=${pagination.page}`
        ];
        Object.keys(filter).map((key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push(`${key}=${value}`);
            }
        });

        window.history.replaceState(null, null, `/accreditation?${string.join("&")}`);

        return `?${string.join("&")}`
    }
    changeFilter = async (filter, isFastStart) => {

        await this.setState({filter});

        if (!isFastStart) {
            return null
        }

        await this.getProducts();

    }
    resetFilter = async () => {
        await this.setState({filter: {...initialFilter}});
        await this.getProducts();
    }


    // Логика создания акредитации
    createAccreditation = async (accreditation, isCreate = false) => {

        if (!isCreate) {
            this.refDialogFormAccreditation.current.open({
                form: {},
                event: this.createAccreditation.bind(this)
            });

            return null
        }

        this.setState({ isShowBackdrop: true });

        const responseCreateAccreditation = await agent.post(`/accreditations`, accreditation).then((res) => {
            return res.data
        }).catch((err) => {
            return { error: err.response }
        });
        if ( responseCreateAccreditation.error ) {
            this.setState({ isShowBackdrop: false });

            Notification({
               type: NotificationTypes.error,
               message: "Ошибка создания аккредитации"
            });

            return null
        }

        await this.getAccreditations();

        this.setState({ isShowBackdrop: false });

        Notification({
           type: NotificationTypes.success,
           message: "Аккредитация успешно добавлена"
        });

    }

    render() {
        const {
            accreditations,

            filter,
            pagination,


            isShowBackdrop,
            isLoading
        } = this.state;

        return (
            <Container maxWidth="xl">

                <FilterComponent
                    filter={filter}

                    onCreateAccreditation={this.createAccreditation}
                />




                <DialogFormAccreditationComponent
                    ref={this.refDialogFormAccreditation}
                />

            </Container>
        );
    }
}

export default Accreditation
