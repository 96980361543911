import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";
import {Formik} from "formik";
import {AddressSuggestionsComponent} from "../../../../../../components";
import {useSelector} from "react-redux";
import * as Yup from "yup";

const DialogDeliveryAddressChange = (props) => {
    const {
        order,
        isOpen,
        onClose,
        onChange
    } = props;

    const refFormik = React.useRef(null);
    const {
        settings
    } = useSelector(state => state.global);
    const [initialValues, setInitialValues] = React.useState({
        method: '',
        address: {
            value: '',
        },
        coords: [null, null],
        latitude: '',
        longitude: '',
    });

    React.useEffect(() => {
        if (Object.keys(order).length > 0 && order !== null) {
            setInitialValues({
                coords: [(+order?.deliveryAddressLat || null) || (+order?.purchaserAddressLat || null)
                    , (+order?.deliveryAddressLong || null) || (+order?.purchaserAddressLong || null)],
                method: order?.deliveryMethod || '',
                address: {
                    value: order?.deliveryAddress || '',
                },
                latitude: (order?.deliveryAddressLat || "") || (order?.purchaserAddressLat || ""),
                longitude: (order?.deliveryAddressLong || "") || (order?.purchaserAddressLong || ""),
            });
        }
    }, [isOpen]);

    const onSubmit = (form) => {
        let newForm = {
            newDeliveryMethod: form.method,
            coords: form.coords,
        };

        if (newForm.newDeliveryMethod === 'pickup' && (newForm.coords[0] === null || newForm.coords[1] === null)) {
            delete newForm.coords;
        }

        onChange(newForm);
        handleCloseModal();
    };

    const handleChange = ({target}) => {
        const {name, value} = target;
        const newForm = refFormik.current.values;

        newForm[name] = value;

        refFormik.current.setValues(newForm);
    };

    const handleChangeAddress = (value, coords) => {
        const newForm = refFormik.current.values;

        newForm.address = {...value};
        newForm.coords = coords || [null, null];
        newForm.latitude = String(coords?.[0]) || "0";
        newForm.longitude = String(coords?.[1]) || "0";

        refFormik.current.setValues(newForm);
    };

    const handleCloseModal = () => {
        onClose();
    };

    const resetAddress = () => {
        const newForm = refFormik.current.values;

        newForm.coords = [null, null];
        newForm.latitude = '';
        newForm.longitude = '';
        newForm.address = {value: ''};

        refFormik.current.setValues(newForm);
    };

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth="md"
            onClose={handleCloseModal}
        >
            <DialogTitle>
                <Typography variant="h3">
                    Адрес доставки
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box pt={1} mb={2}>
                                    <Box mb={3}>
                                        {values.method === 'delivery' && (
                                            <>
                                                <Typography variant="h6" color="red" mb={1}>
                                                    При изменении адреса или метода доставки (на "Доставка") срок
                                                    выполнения заказа может
                                                    увеличиться!
                                                </Typography>
                                                <Typography variant="h6">
                                                    Минимальный срок выполнения
                                                    заказа <span
                                                    style={{
                                                        color: "#84b92c",
                                                        fontWeight: 700,
                                                    }}>{settings?.deliveryMinDays || 0}</span> дней
                                                    с момента оплаты
                                                </Typography>
                                            </>
                                        )}
                                    </Box>
                                    <Box mb={2}>
                                        <FormControl
                                            error={Boolean(touched?.method && errors?.method)}
                                        >
                                            <FormLabel
                                                id="delivery-method-radio-buttons-group"
                                                error={Boolean(touched?.method && errors?.method)}
                                            >
                                                Способ доставки
                                            </FormLabel>
                                            <RadioGroup
                                                aria-labelledby="delivery-method-radio-buttons-group"
                                                name="method"
                                                value={values.method}
                                                onChange={handleChange}
                                            >
                                                <FormControlLabel value="delivery" control={<Radio/>} label="Доставка"/>
                                                <FormControlLabel value="pickup" control={<Radio/>} label="Самовывоз"/>
                                            </RadioGroup>
                                        </FormControl>
                                        {Boolean(touched?.method && errors?.method) && (
                                            <FormHelperText variant="filled" error>
                                                {touched?.method && errors?.method}
                                            </FormHelperText>
                                        )}
                                    </Box>
                                    <Box>
                                        <AddressSuggestionsComponent
                                            notRequired={values.method === 'pickup'}
                                            addressValue={values.address}
                                            coordsValue={values.coords}
                                            latitudeValue={values.latitude}
                                            longitudeValue={values.longitude}
                                            touched={touched}
                                            errors={errors}

                                            onChangeAddress={handleChangeAddress}
                                            onResetAddress={resetAddress}
                                        />
                                    </Box>
                                </Box>

                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        sx={{borderRadius: "4px", textTransform: "initial"}}

                                        onClick={handleCloseModal}
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{borderRadius: "4px", textTransform: "initial"}}

                                        onClick={handleSubmit}
                                    >
                                        Изменить
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

Yup.addMethod(Yup.array, "tuple", function (schema) {
    if (!this.isType(schema)) Yup.ValidationError();
    return Yup.object({
        tuple: Yup.array().min(schema.length).max(schema.length), ...Object.fromEntries(Object.entries(schema)),
    }).transform((value, originalValue) => {
        if (!this.isType(originalValue)) Yup.ValidationError();
        return {
            tuple: originalValue, ...Object.fromEntries(Object.entries(originalValue)),
        };
    });
});

const validationSchema = Yup.object().shape({
    method: Yup.string().required('Выберите метод доставки'),
    coords: Yup.object().when('method', {
        is: "delivery",
        then: Yup.array().tuple([
            Yup.number().required("Обязательное поле")
                .typeError("Обязательное поле")
                .min(-90, "Минимальное значение -90")
                .max(90, "Максимальное значение 90"),
            Yup.number().required("Обязательное поле")
                .typeError("Обязательное поле")
                .min(-180, "Минимальное значение -180")
                .max(180, "Максимальное значение 180")
        ]),
    }).nullable(),
    latitude: Yup.string().when('method', {
        is: 'delivery',
        then: Yup.string().required('Введите широту').min(-90, "Минимальное значение -90").max(90, "Максимальное значение 90"),
    }),
    longitude: Yup.string().when('method', {
        is: 'delivery',
        then: Yup.string().required('Введите долготу').min(-180, "Минимальное значение -180").max(180, "Максимальное значение 180"),
    }),
    address: Yup.object().when('method', {
        is: 'delivery',
        then: Yup.object( {
            value: Yup.string().required( 'Введите адрес' ),
        } ),
    }),
});

export default React.memo(DialogDeliveryAddressChange);
