import React, {useState} from "react";
import {
    Box,
    Button,
    Collapse,
    Grid,
    TextField
} from "@mui/material";
import {
    PlusOne as PlusOneIcon,
    SearchRounded as SearchIcon
} from "@mui/icons-material";
import {
    makeStyles
} from "@mui/styles";

let timeoutSearch = null;

const Filter = (props) => {
    const {
        openModal,
        filter,
        onChangeFilter,
        onSearch
    } = props;
    const classes = useStyles();
    const [isOpenSearch, setIsOpenSearch] = useState(false);

    const handleChangeFilter = ({target}) => {
        clearTimeout(timeoutSearch);
        const {value, name} = target;
        const newFilter = filter;
        newFilter[name] = value;

        onChangeFilter(newFilter);

        timeoutSearch = setTimeout(() => {
            onSearch();
        }, 1000)
    }

    return (
        <Box mb={1}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item></Grid>
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{
                                    textTransform: "initial",
                                    fontSize: 12,
                                    height: "auto",
                                    borderRadius: 1,
                                }}
                                onClick={openModal}
                                endIcon={<PlusOneIcon/>}
                            >
                                Добавить
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    textTransform: "initial",
                                    fontSize: 12,
                                    height: "auto",
                                    borderRadius: 1,
                                }}
                                endIcon={<SearchIcon/>}

                                onClick={setIsOpenSearch.bind(this, !isOpenSearch)}
                            >
                                Поиск
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box my={1}/>

            <Collapse in={isOpenSearch} timeout="auto" unmountOnExit>
                <Box className={classes.searchCollapseWrapper} px={2} py={2} bgcolor="white" borderRadius={4}>
                    <TextField
                        size="small"
                        value={filter['ceoName']}
                        name="ceoName"
                        label="Введите директора"
                        variant="outlined"
                        fullWidth

                        onChange={handleChangeFilter}
                    />
                    <TextField
                        size="small"
                        value={filter['email']}
                        name="email"
                        label="Введите E-Mail"
                        variant="outlined"
                        fullWidth

                        onChange={handleChangeFilter}
                    />
                    <TextField
                        size="small"
                        value={filter['inn']}
                        name="inn"
                        label="Введите ИНН"
                        variant="outlined"
                        fullWidth

                        onChange={handleChangeFilter}
                    />
                    <TextField
                        size="small"
                        value={filter['kpp']}
                        name="kpp"
                        label="Введите КПП"
                        variant="outlined"
                        fullWidth

                        onChange={handleChangeFilter}
                    />
                    <TextField
                        size="small"
                        value={filter['name']}
                        name="name"
                        label="Введите название"
                        variant="outlined"
                        fullWidth

                        onChange={handleChangeFilter}
                    />
                </Box>
            </Collapse>
        </Box>
    );
};

const useStyles = makeStyles({
    searchCollapseWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        gap: 15,
    }
})

export default Filter;
