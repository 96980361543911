import React, { Component } from "react";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import {
    DialogAccreditation as DialogAccreditationComponent,
    DialogFormUser as DialogFormUserComponent,
    DialogFormVerificationCode,
    Filter as FilterComponent,
    Table as TableComponent
} from "./components";
import { DialogConfirmAction, DialogUserCart } from "../../../../components";
import { Notification, NotificationTypes } from "../../../../common/Notification";
import queryString from "query-string";
import agent from "../../../../agent/agent";
import errorAuth from "../../../../errors/messages/errorAuth";
import { userIsLegal } from "../../../../helper/userType";


const initialFilter = {
    "sort": "",
    "active": "1",
    "roles": "",
};

class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            categories: [],
            expeditors: [],

            filter: {
                ...initialFilter
            },
            pagination: {
                page: 1,
                totalPage: 1
            },

            verificationCode: '',
            createdUserId: '',

            isOpenVerificationForm: false,
            isLoading: true,
            isLoadingVerified: false,
            isVerified: false,
            isShowBackdrop: false,
        };

        this.refDialogFormUser = React.createRef();
        this.refDialogAccreditation = React.createRef();
        this.refDialogConfirmAction = React.createRef();
        this.refDialogUserCart = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getUsers();
        await this.getCategories();
        await this.getExpeditors();
    }
    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch
        };

        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }

    // Логика получения списка пользователей
    getUsers = async () => {
        const filter = this.getFilters();
        const {
            users,
            count
        } = await agent.get(`/users${ filter }&limit=20`).then((res) => {
            return {
                users: res.data.users,
                count: res.data.count
            }
        }).catch(() => {
            return {
                users: [],
                count: 0
            }
        });

        const pagination = {
            ...this.state.pagination,
            totalPage: Math.ceil(count / 20)
        };

        this.setState({
            users: users,
            pagination,
            isLoading: false
        });
    };
    getExpeditors = async () => {
        const { users } = await agent.get(`/users?roles=courier&limit=0`)
            .then(res => res.data)
            .catch(err => {
                return [];
            });
        this.setState({
            expeditors: users,
        })
    };

    // Получение списка категорий
    getCategories = async () => {
        const categories = await agent.get(`/categories?page=1&limit=50`).then((res) => {
            return res.data.categories || []
        }).catch(() => {
            return []
        });

        this.setState({
            categories
        })
    }

    // Логика работы с фильтром
    getFilters = () => {
        const filter = { ...this.state.filter };
        const pagination = { ...this.state.pagination };

        let string = [
            `page=${ pagination.page }`
        ];
        Object.keys(filter).map((key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push(`${ key }=${ value }`);
            }
        });

        window.history.replaceState(null, null, `/users?${ string.join("&") }`);

        return `?${ string.join("&") }`
    }
    changeFilter = async (filter, isFastStart) => {

        await this.setState({
            filter,
            pagination: {
                ...this.state.pagination,
                page: 1,
            }
        });

        if (!isFastStart) {
            return null
        }

        await this.getUsers();
    }
    resetFilter = async () => {
        await this.setState({ filter: { ...initialFilter } });
        await this.getUsers();
    }

    // Логика работы пагинации
    onChangePagination = async (pagination) => {
        await this.setState({ pagination });

        await this.getUsers();
    }

    // Логика создания пользователя
    createUser = async (user, isCreate = false, isConfirm) => {
        if (!isCreate) {
            this.refDialogFormUser.current.open(
                {},
                this.createUser.bind(this)
            );

            return null
        }

        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Подтверждения",
                message: `Вы действительно хотите создать пользователя`,
                labelSuccess: "Да, создать",
                onSuccess: this.createUser.bind(this, user, true, true),
            });

            return null
        }

        this.setState({ isShowBackdrop: true });

        const body = {
            firstName: user.firstName,
            lastName: user.lastName,
            patronymic: user.patronymic,
            username: user.username,
            email: user.email,
            role: user.role,
            organizationConnectType: user.organizationConnectType || '',
            phone: (user.phone || "").replace(/\D+/g, ""),
        };

        if (body.role === 'driver') {
            body.expeditorId = user.expeditorId;
        }

        if (body.organizationConnectType === 'createNewOrganization') {
            body.organization = {
                type: user.organizationType,
                name: user.organizationName,
                inn: user.organizationInn,
                kpp: user.organizationKpp,
                ogrn: user.organizationOgrn,
                email: user.organizationEmail,
                phone: (user?.organizationPhone || "").replace(/\D+/g, ""),
                address: user.organizationAddress,
                ceoName: user.organizationCeoName,
            }
        }

        if (body.organizationConnectType === 'joinAnOrganization') {
            body.organizationId = user.organization.id;
        }

        const responseCreate = await agent.post("/users", body)
            .then(async (res) => res.data)
            .catch((err) => {
                return { error: err.response }
            });

        if (responseCreate.error) {
            this.setState({ isShowBackdrop: false });

            Notification({
                message: responseCreate.error?.data?.message || 'Возникла ошибка, попробуйте позже',
                type: NotificationTypes.error
            });

            return null
        }

        await this.getUsers();

        this.setState({ isShowBackdrop: false });

        Notification({
            message: "Пользователь успешно создан",
            type: NotificationTypes.success
        });

    };

    //Функция для открытия карточки пользователя
    openUserCart = (userRow, isOpen = false) => {
        if (!isOpen) {
            this.refDialogUserCart.current.open(userRow, this.openUserCart.bind(this));
            return null;
        }

        this.setState({ isShowBackdrop: true });
    };

    // Логика редактирования пользователя
    editUser = async (user, isEdit = false, isConfirm) => {
        const editedUser = {
            id: user.id,
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            patronymic: user?.patronymic || '',
            username: user?.username || '',
            email: user?.email || '',
            role: user.role,
            phone: user.phone,
        };

        if (user.role === 'driver') {
            editedUser.expeditorId = user.expeditorId;
        }

        if (userIsLegal(user)) {
            editedUser.userType = 'legal';
            editedUser.organizationId = user.organization.id;
            editedUser.organizationType = user.organization.type;
            editedUser.organizationName = user.organization.name;
            editedUser.organizationInn = user.organization.inn;
            editedUser.organizationKpp = user.organization.kpp;
            editedUser.organizationOgrn = user.organization.ogrn;
            editedUser.organizationEmail = user.organization.email;
            editedUser.organizationPhone = user.organization.phone;
            editedUser.organizationAddress = user.organization.address;
            editedUser.organizationCeoName = user.organization.ceoName;
        } else {
            editedUser.userType = 'physical';
        }

        if (!isEdit) {
            this.refDialogFormUser.current.open(
                {
                    ...editedUser,
                },
                this.editUser.bind(this)
            );

            return null;
        }

        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Подтверждения",
                message: `Вы действительно хотите изменить пользователя "${ user.firstName } ${ user.lastName }"`,
                labelSuccess: "Да, изменить",
                onSuccess: this.editUser.bind(this, user, true, true),
            })

            return null
        }

        this.setState({ isShowBackdrop: true });

        const body = {
            firstName: user.firstName,
            lastName: user.lastName,
            patronymic: user?.patronymic || '',
            username: user.username,
            email: user.email,
            role: user.role,
            clientType: user.clientType || '',
            phone: (user.phone || "").replace(/\D+/g, ""),
        }

        if (body.role === 'driver') {
            body.expeditorId = user.expeditorId;
        }

        const responseEdit = await agent.put(`/users/${ user.id }`, body)
            .then(res => res.data)
            .catch((err) => {
                return { error: err.response }
            });

        if (responseEdit.error) {
            this.setState({ isShowBackdrop: false });

            Notification({
                message: responseEdit.error?.data?.message || 'Возникла ошибка, попробуйте позже',
                type: NotificationTypes.error
            });

            return null
        }

        await this.getUsers();

        this.setState({ isShowBackdrop: false });

        Notification({
            message: "Пользователь успешно изменен",
            type: NotificationTypes.success
        });

    };

    // Логика удаления пользователя
    deleteUser = async (user, isConfirm) => {

        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Удаление пользователя",
                message: `Вы действительно хотите удалить пользователя "${ user.firstName } ${ user.lastName }"`,
                labelSuccess: "Да, удалить",
                onSuccess: this.deleteUser.bind(this, user, true)
            })

            return null
        }

        this.setState({ isShowBackdrop: true });

        const responseDeleteUser = await agent.put(`/users/${ user.id }/set-active`, {
            active: false
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return { error: err.response }
        });
        if (responseDeleteUser.error) {
            this.setState({ isShowBackdrop: false });

            Notification({
                message: "Ошибка удаления пользователя",
                type: NotificationTypes.error
            });

            return null
        }

        await this.getUsers();

        this.setState({ isShowBackdrop: false });

        Notification({
            message: "Пользователь успешно удален",
            type: NotificationTypes.success
        });
    }

    // Редактирование аккредитаций пользователей
    editAccreditation = async (user, isSave = false) => {

        if (!isSave) {
            this.refDialogAccreditation.current.open({
                user: user
            });

            return null
        }

        this.setState({ isShowBackdrop: true });


    }

    // Подтверждении регистрации пользователя
    setActive = async (user, isConfirm) => {

        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Активация пользователя",
                message: `Вы действительно хотите активировать пользователя "${ user.firstName } ${ user.lastName }"`,
                labelSuccess: "Да, активировать",
                onSuccess: this.setActive.bind(this, user, true)
            })

            return null
        }

        this.setState({ isShowBackdrop: true });

        const responseActiveUser = await agent.put(`/users/${ user.id }/set-active`, {
            active: true
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return { error: err.response }
        });
        if (responseActiveUser.error) {
            this.setState({ isShowBackdrop: false });

            Notification({
                message: "Ошибка активации пользователя",
                type: NotificationTypes.error
            });

            return null
        }

        await this.getUsers();

        this.setState({ isShowBackdrop: false });

        Notification({
            message: "Пользователь успешно активирован",
            type: NotificationTypes.success
        });

    }

    // Подтверждение номера
    getVerificationCode = async (phone) => {
        const response = await agent.post("/auth/phone-confirmation", {
            phone: (phone || "").replace(/\D+/g, "")
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return {
                error: err.response,
                status: err.response.status,
            };
        });
        if (response.error) {

            let errorMessage = response.error?.data?.message || "Возникла ошибка, попробуйте позже";

            if (response.status === 429) {
                errorMessage = 'Превышен лимит запросов, попробуйте через 5 минут';
            }

            Notification({
                type: NotificationTypes.error,
                message: errorMessage
            })

            return null
        }

        this.setState({
            verificationCode: response?.confirmationId,
            isOpenVerificationForm: true,
        });

        Notification({
            type: NotificationTypes.success,
            message: "На ваш номер отправлен СМС с кодом"
        });
    }
    authUser = async (code) => {
        await this.setState({ isLoadingVerified: true });

        const body = {
            id: this.state.verificationCode,
            code,
        }
        // Подтверждение авторизации
        const responseConfirm = await agent.post(`/auth/phone-confirm`, body).then((res) => {
            return res.data
        }).catch((err) => {
            return {
                error: err.response,
                status: err.response.status
            }
        });
        if (responseConfirm.error) {
            await this.setState({
                isLoadingVerified: false,
                isOpenVerificationForm: false,
            });

            let errorMessage = responseConfirm.error?.data?.message || "Возникла ошибка, попробуйте позже";

            if (responseConfirm.status === 429) {
                errorMessage = 'Превышен лимит запросов, попробуйте через 5 минут';
            }

            Notification({
                type: NotificationTypes.error,
                message: errorMessage
            })

            return null
        }

        await this.setState({
            createdUserId: responseConfirm.userId,
            isLoadingVerified: false,
            isVerified: true,
            isOpenVerificationForm: false,
        });
    }

    // Сброс верификации
    resetVerified = () => {
        this.setState({
            isVerified: false,
        });
    };

    // Закрытие формы верификации кода подтверждения
    handleCloseIsOpenVerificationForm = () => {
        this.setState({ isOpenVerificationForm: false })
    };

    render() {
        const {
            users,
            expeditors,
            categories,

            filter,
            pagination,


            isShowBackdrop,
            isLoading,
            isLoadingVerified,
            isVerified,
            isOpenVerificationForm
        } = this.state;

        return (
            <Container maxWidth="xl">

                <FilterComponent
                    filter={ filter }

                    onCreateUser={ this.createUser }
                    onReset={ this.resetFilter }
                    onSearch={ this.getUsers }
                    onChange={ this.changeFilter }
                />

                <TableComponent
                    data={ users }
                    filter={ filter }
                    pagination={ pagination }

                    isLoading={ isLoading }

                    onEditUser={ this.editUser }
                    onEditAccreditation={ this.editAccreditation }
                    onSetActive={ this.setActive }
                    onChangePagination={ this.onChangePagination }
                    onDeleteUser={ this.deleteUser }
                    onOpenUserCart={ this.openUserCart }
                />


                <DialogFormUserComponent
                    ref={ this.refDialogFormUser }
                    history={ this.props.history }
                    expeditors={ expeditors }
                    isVerified={ isVerified }
                    onGetVerificationCode={ this.getVerificationCode }
                    onResetVerified={ this.resetVerified }
                />

                <DialogAccreditationComponent
                    ref={ this.refDialogAccreditation }
                    categories={ categories }
                />

                <DialogConfirmAction
                    ref={ this.refDialogConfirmAction }
                />

                <DialogUserCart
                    ref={ this.refDialogUserCart }
                    refDialogConfirmation={ this.refDialogConfirmAction }
                    history={ this.props.history }
                />

                <DialogFormVerificationCode
                    isOpen={ isOpenVerificationForm }
                    isLoading={ isLoadingVerified }
                    onClose={ this.handleCloseIsOpenVerificationForm }
                    onAuth={ this.authUser }
                />

                <Backdrop open={ isShowBackdrop }>
                    <CircularProgress color="inherit"/>
                </Backdrop>

            </Container>
        );
    }
}

export default Users
