import React, {useRef} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import {LoadingButton} from "@mui/lab";

const DialogFormVerificationCode = (props) => {
    const {
        isOpen,
        isLoading,
        onClose,
        onAuth,
    } = props;
    const refFormik = useRef(null);
    const initialValues = {
        code: '',
    };

    const onSubmit = (form) => {
        onAuth(form.code);
    };

    const handleChange = ({target}) => {
        const {name, value} = target;
        const newForm = refFormik.current.values;
        newForm[name] = value;

        refFormik.current.setValues(newForm);
    };

    return (
        <Dialog
            open={isOpen}
        >
            <DialogTitle>
                Подтверждение номера
            </DialogTitle>
            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box py={1}>
                                    <InputMask
                                        mask="9999"
                                        value={values.code}
                                        maskChar=" "
                                        onChange={handleChange}
                                    >
                                        {() =>
                                            <TextField
                                                fullWidth
                                                size="medium"
                                                value={values.code}
                                                error={Boolean(touched.code && errors.code)}
                                                helperText={touched.code && errors.code}
                                                name="code"
                                                placeholder="****"
                                                label="Код из СМС"

                                                onChange={handleChange}
                                            />
                                        }
                                    </InputMask>
                                </Box>
                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        fullWidth

                                        onClick={onClose}
                                    >
                                        Отменить
                                    </Button>
                                    <LoadingButton
                                        variant="contained"
                                        size="large"
                                        loading={isLoading}
                                        fullWidth

                                        onClick={handleSubmit}
                                    >
                                        Подтвердить
                                    </LoadingButton>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const validationSchema = Yup.object().shape({
    code: Yup.string()
        .matches(/^[0-9]{4}$/, "Код состоит 4х символов")
        .required('Введите код подтверждения из СМС'),
});

export default DialogFormVerificationCode;