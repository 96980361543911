import React from 'react';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    IconButton,
    Link,
    Pagination,
    Radio,
    RadioGroup,

    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,

    Typography
} from "@mui/material";
import {
    Skeleton
} from "@mui/lab";
import {
    CheckCircleRounded as CheckCircleIcon,
    CancelRounded as CancelIcon
} from '@mui/icons-material';
import {
    convertorNumber
} from "../../../../../../helper/convertor";
import {
    makeStyles
} from "@mui/styles";
import {
    formatPhone
} from "../../../../../../helper/formatPhone";
import moment from "moment/moment";

const TableComponent = ( props ) => {
    const {
        registries,
        filter,
        pagination,
        visibleColumns,
        isLoading,
        onOpenUserCart,
        onChangePagination,
        onChangeFilter,
        onManualPayout,
        onCancelPayout
    } = props;
    const classes = useStyles();

    const handleChangePage = ( event, page ) => {
        let newPagination = { ...pagination };
        newPagination.page = page;

        onChangePagination( newPagination )
    }

    const handleChangeFilter = ( name, value ) => {
        const newFilter = { ...filter };
        newFilter[name] = value;

        onChangeFilter( newFilter, true );
    };

    const handleChangeRadio = ( e ) => {
        const {
            name, value
        } = e.target;

        handleChangeFilter( name, value );
    };

    return (
        <>
            <Box mb={ 2 }>
                <Typography variant="h2">Выплаты</Typography>
            </Box>
            <Box mb={ 2 }>
                <FormControl>
                    <RadioGroup
                        name="type"
                        value={ filter.type }
                        onChange={ handleChangeRadio }
                    >
                        <FormControlLabel value="current" control={ <Radio/> } label="Текущие"/>
                        <FormControlLabel value="archive" control={ <Radio/> } label="Архив"/>
                    </RadioGroup>
                </FormControl>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <EnhancedTableHead
                            visibleColumns={ visibleColumns }
                            filter={ filter }
                            handleChangeFilter={ handleChangeFilter }
                        />
                    </TableRow>
                </TableHead>
                <TableBody>
                    { Boolean( isLoading )
                        ? (
                            <>
                                {
                                    [ 1, 2, 3, 4, 5 ].map( item => (
                                        <TableRow key={ item }>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                        </TableRow>
                                    ) )
                                }
                            </>
                        )
                        : (
                            <>
                                { registries.length > 0
                                    ?
                                    <>
                                        { registries.map( ( registry ) => (
                                            <TableRow key={ registry.id }>
                                                <TableCell>{ moment( registry?.displayingDate ).format( "DD.MM.YYYY HH:mm" ) || "-" }</TableCell>
                                                <TableCell>{ registry.id }</TableCell>
                                                <TableCell align="left">
                                                    { Boolean( registry?.user )
                                                        ? (
                                                            <Box>
                                                                <Box className={ classes.rowContent }>
                                                                    <Typography>Имя:</Typography>
                                                                    <Button
                                                                        className={ classes.userNameButton }
                                                                        variant="text"

                                                                        onClick={ onOpenUserCart.bind( this, registry?.user || {}, false ) }
                                                                    >
                                                                        { registry.user.firstName || "-" } { registry.user.lastName || "-" }
                                                                    </Button>
                                                                </Box>
                                                                <Box className={ classes.rowContent }>
                                                                    <Typography>Телефон:</Typography>
                                                                    <Link href={ `tel:+${ registry?.user?.phone }` }>
                                                                        <Typography className={ classes.value }>
                                                                            { formatPhone( registry.user.phone ) }
                                                                        </Typography>
                                                                    </Link>
                                                                </Box>
                                                            </Box>

                                                        )
                                                        : "-"
                                                    }
                                                </TableCell>
                                                <TableCell
                                                    align="right">{ convertorNumber( registry?.amount || 0, 2, '.' ) } ₽
                                                </TableCell>
                                                <TableCell align="right">
                                                    { filter.type !== 'archive' && (
                                                        <>
                                                            <Tooltip title="Провести выплату (выплата попадет в архив)">
                                                                <IconButton
                                                                    color="primary"
                                                                    variant="text"
                                                                    onClick={ onManualPayout.bind( this, registry.id, false ) }
                                                                >
                                                                    <CheckCircleIcon color="primary" fontSize="large"/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip
                                                                classes={ {
                                                                    popper: classes.deleteTooltip,
                                                                } }
                                                                title="Отменить выплату (выплата попадет в архив)"
                                                            >
                                                                <IconButton
                                                                    color="error"
                                                                    variant="text"
                                                                    onClick={ onCancelPayout.bind( this, registry.id, false ) }
                                                                >
                                                                    <CancelIcon color="error" fontSize="large"/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>
                                                    ) }
                                                </TableCell>
                                            </TableRow>
                                        ) ) }
                                    </>
                                    :
                                    <>
                                        <TableRow>
                                            <TableCell align="center" colSpan={ 5 }>Нет выплат</TableCell>
                                        </TableRow>
                                    </>
                                }
                            </>
                        )
                    }
                </TableBody>
            </Table>

            <Box mt={ 3 }/>

            <Pagination
                page={ Number( pagination.page ) }
                count={ Number( pagination.totalPage ) }

                onChange={ handleChangePage }
            />
        </>
    );
};

const EnhancedTableHead = ( props ) => {
    const {
        visibleColumns,
        filter,

        handleChangeFilter
    } = props;
    const classes = useStyles();

    const handleChangeSort = ( col ) => {
        if (filter.sortInfo === `-${ col }`) {
            handleChangeFilter( "sortInfo", col );
        } else {
            handleChangeFilter( "sortInfo", `-${ col }` );
        }
    };

    return (
        <>
            { visibleColumns.map( column => (
                <TableCell
                    key={ column.id }
                    width={ column.width }
                    { ...column }
                >
                    { column.sortable
                        ? (
                            <TableSortLabel
                                classes={ {
                                    active: classes.activeSort,
                                    root: classes.sort,
                                } }
                                active={ filter.sortInfo === column.id || filter.sortInfo === `-${ column.id }` }
                                direction={ filter.sortInfo === column.id ? "desc" : "asc" }

                                onClick={ handleChangeSort.bind( this, column.id ) }
                            >
                                { column.label }
                            </TableSortLabel>
                        )
                        : column.label
                    }
                </TableCell>
            ) ) }
        </>
    );
};

const useStyles = makeStyles( {
    rowContent: {
        display: "flex",
    },

    value: {
        color: "#84b92c",
        marginLeft: 4,
    },

    userNameButton: {
        '&.MuiButton-root': {
            padding: 0,
            margin: "0 0 0 4px",
            color: "#84b92c",
            textTransform: "none",
            height: "auto",
            fontSize: 14,
            letterSpacing: "-0.05px",
            lineHeight: "21px",
            fontWeight: 400,
        },
    },

    sort: {
        "&.MuiButtonBase-root": {
            transition: "all .2s linear",
            "&:hover": {
                color: "rgba(255,255,255, .5)",
                "& .MuiSvgIcon-root": {
                    fill: "#FFFFFF",
                }
            }
        }
    },
    activeSort: {
        '&.MuiButtonBase-root.Mui-active': {
            color: "#FFFFFF",

            "& .MuiSvgIcon-root": {
                "& path": {
                    fill: "#FFFFFF",
                }
            }
        },
    },

    deleteTooltip: {
        "&.MuiTooltip-popper": {
            '& .MuiTooltip-tooltip': {
                background: "#e53935",
            }
        }
    },
} );

export default TableComponent;