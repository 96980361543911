import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { Formik } from "formik";
import organizationTypes from "../../../../../../constants/organizationTypes";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import regexp from "../../../../../../constants/regexp";
import { phoneFormat } from "../../../../../../common/Formater";

const initialValues = {
    type: "",
    name: "",
    inn: "",
    kpp: "",
    ogrn: "",
    email: "",
    phone: "",
    address: "",
    ceoName: "",
};

const DialogEditOrganization = (props) => {
    const {
        organization,
        isOpen,
        onClose,
        onEdit,
    } = props;

    const refFormik = React.useRef(null);

    const onSubmit = (form) => {
        onEdit(form);
        handleClose();
    };

    const handleChangeForm = ({ target }) => {
        const { value, name } = target;

        const newForm = refFormik.current.values;
        newForm[name] = value;

        refFormik.current.setValues(newForm);
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            open={ isOpen }
            fullWidth
            maxWidth="md"
            scroll="body"
            onClose={ handleClose }
        >
            <DialogTitle>
                <Typography variant="h3">Редактирование организации</Typography>
            </DialogTitle>
            <DialogContent>
                <Formik
                    innerRef={ refFormik }
                    initialValues={ organization
                        ? JSON.parse(JSON.stringify({
                            ...organization,
                            phone: phoneFormat(organization.phone),
                        }))
                        : initialValues }
                    validationSchema={ ValidationSchema }
                    onSubmit={ onSubmit }
                >
                    { (props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box pt={ 1 }>
                                    <Box mb={ 2 }>
                                        <FormControl fullWidth error={ touched.type && Boolean(errors.type) }>
                                            <InputLabel>Тип</InputLabel>
                                            <Select
                                                name="type"
                                                label="Тип"
                                                value={ values.type }
                                                onChange={ handleChangeForm }
                                            >
                                                { organizationTypes.map((type) => (<MenuItem value={ type.value }>
                                                    { type.label }
                                                </MenuItem>)) }
                                            </Select>
                                            { Boolean(touched.type && Boolean(errors.type)) && (
                                                <FormHelperText error variant="filled">
                                                    { touched.type && errors.type }
                                                </FormHelperText>) }
                                        </FormControl>
                                    </Box>
                                    <Box mb={ 2 }>
                                        <TextField
                                            fullWidth
                                            name="name"
                                            label="Название"
                                            value={ values.name }
                                            onChange={ handleChangeForm }
                                            error={ touched.name && Boolean(errors.name) }
                                            helperText={ touched.name && errors.name }
                                        />
                                    </Box>
                                    <Box mb={ 2 }>
                                        <TextField
                                            fullWidth
                                            name="inn"
                                            label="ИНН"
                                            value={ values.inn }
                                            onChange={ handleChangeForm }
                                            error={ touched.inn && Boolean(errors.inn) }
                                            helperText={ touched.inn && errors.inn }
                                        />
                                    </Box>
                                    <Box mb={ 2 }>
                                        <TextField
                                            fullWidth
                                            name="kpp"
                                            label="КПП"
                                            value={ values.kpp }
                                            onChange={ handleChangeForm }
                                            error={ touched.kpp && Boolean(errors.kpp) }
                                            helperText={ touched.kpp && errors.kpp }
                                        />
                                    </Box>
                                    <Box mb={ 2 }>
                                        <TextField
                                            fullWidth
                                            name="ogrn"
                                            label="ОГРН"
                                            value={ values.ogrn }
                                            onChange={ handleChangeForm }
                                            error={ touched.ogrn && Boolean(errors.ogrn) }
                                            helperText={ touched.ogrn && errors.ogrn }
                                        />
                                    </Box>
                                    <Box mb={ 2 }>
                                        <TextField
                                            fullWidth
                                            name="email"
                                            label="Почта"
                                            value={ values.email }
                                            onChange={ handleChangeForm }
                                            error={ touched.email && Boolean(errors.email) }
                                            helperText={ touched.email && errors.email }
                                        />
                                    </Box>
                                    <Box mb={ 2 }>
                                        <InputMask
                                            mask="+7 (099) 999 - 99 - 99"
                                            value={ values.phone }
                                            maskChar=" "
                                            onChange={ handleChangeForm }
                                            formatChars={ {
                                                0: "[489]", 9: "[0-9]",
                                            } }
                                        >
                                            { () => (<TextField
                                                fullWidth
                                                name="phone"
                                                label="Номер телефона"
                                                error={ touched.phone && Boolean(errors.phone) }
                                                helperText={ touched.phone && errors.phone }
                                            />) }
                                        </InputMask>
                                    </Box>
                                    <Box mb={ 2 }>
                                        <TextField
                                            fullWidth
                                            name="address"
                                            label="Адрес"
                                            value={ values.address }
                                            onChange={ handleChangeForm }
                                            error={ touched.address && Boolean(errors.address) }
                                            helperText={ touched.address && errors.address }
                                        />
                                    </Box>
                                    <Box mb={ 2 }>
                                        <TextField
                                            fullWidth
                                            name="ceoName"
                                            label="Директор"
                                            value={ values.ceoName }
                                            onChange={ handleChangeForm }
                                            error={ touched.ceoName && Boolean(errors.ceoName) }
                                            helperText={ touched.ceoName && errors.ceoName }
                                        />
                                    </Box>
                                </Box>
                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        onClick={ handleClose }
                                        sx={ {
                                            textTransform: "initial", borderRadius: "4px", padding: "4px 24px",
                                        } }
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={ {
                                            textTransform: "initial", borderRadius: "4px", padding: "4px 24px",
                                        } }

                                        onClick={ handleSubmit }
                                    >
                                        Редактировать
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    } }
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const ValidationSchema = Yup.object().shape({
    type: Yup.string()
        .required("Обязательное поле")
        .matches(regexp.organizationType, "Невалидный тип"),
    name: Yup.string().required("Обязательное поле"),
    inn: Yup.string()
        .required("Обязательное поле")
        .min(9, "Минимальная длина 9 символов")
        .max(12, "Максимальная длина 12 символов"),
    kpp: Yup.string().when([ "type" ], {
        is: (type) => type === 'jp',
        then: Yup.string()
            .required("Обязательное поле")
            .min(9, "Минимальная длина 9 символов")
            .max(9, "Максимальная длина 9 символов"),
    }),
    ogrn: Yup.string()
        .required("Обязательное поле")
        .min(13, "Минимальная длина 13 символов")
        .max(15, "Максимальная длина 15 символов"),
    email: Yup.string()
        .required("Обязательное поле")
        .email("Невалидный адрес почты"),
    phone: Yup.string()
        .required("Обязательное поле")
        .matches(regexp.phone, "Введите номер телефона в формате - +7 (999) 999 - 99 - 99"),
    address: Yup.string().required("Обязательное поле"),
    ceoName: Yup.string().required("Обязательное поле"),
});

export default React.memo(DialogEditOrganization);