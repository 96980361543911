import React, {useRef, useState} from 'react';
import {
    Autocomplete,
    Box, Button,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {Formik} from "formik";
import {
    Search as SearchIcon
} from "@mui/icons-material";
import agent from "../../../../../../agent/agent";
import * as Yup from "yup";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";

const initialValues = {
    user: '',
}

let timeout = null;

const DialogFormAddEmployee = (props) => {
    const {
        isOpen,
        onClose,
        onAddEmployee
    } = props;
    const classes = useStyles();
    const refFormik = useRef(null);
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenAutocomplete, setIsOpenAutocomplete] = useState(false);
    const [searchField, setSearchField] = useState('name');

    const onSubmit = (form) => {
        onAddEmployee(form.user.id);
        handleCloseModal();
    };

    const handleCloseModal = () => {
        refFormik.current.resetForm();
        onClose();
    };

    const handleChangeAutocomplete = (value) => {
        const newForm = refFormik.current.values;
        newForm.user = value;
        setSearchField('name');

        refFormik.current.setValues(newForm);
    };

    const onInputAutocomplete = (e, value) => {
        clearTimeout(timeout);
        timeout = null;
        if (value.length >= 3) {
            timeout = setTimeout(async () => {
                setUsers([]);
                setIsLoading(true);
                await getUsers(value);
                setIsLoading(false);
            }, 1000);
        }
    };

    const handleChangeSelect = ({target}) => {
        const {value} = target;
        setSearchField(value);
    };

    const resetUsers = () => {
        setUsers([]);
    };

    const getUsers = async (value) => {
        const response = await agent.get(`/users?${searchField}=${value}&limit=0&roles=admin,provider,courier,driver`)
            .then(res => res.data.users)
            .catch(err => []);
        setUsers(response);
    };

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={isOpen}
            onClose={handleCloseModal}
        >
            <DialogTitle>
                Добавление участника в организацию
            </DialogTitle>
            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box sx={{paddingTop: "10px"}}>
                                    <FormControl fullWidth>
                                        <InputLabel id="add-employee-select-label">Поиск по полю:</InputLabel>
                                        <Select
                                            labelId="add-employee-select-label"
                                            id="add-employee-select"
                                            value={searchField}
                                            label="Поиск по полю:"

                                            onChange={handleChangeSelect}
                                        >
                                            <MenuItem value="name">Имя</MenuItem>
                                            <MenuItem value="phone">Телефон</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Box mt={2} mb={2}>
                                        <Autocomplete
                                            fullWidth
                                            disableClearable
                                            open={isOpenAutocomplete}
                                            noOptionsText='Нет пользователей'
                                            loadingText='Загрузка...'
                                            value={values.user}
                                            name='user'
                                            options={users}
                                            filterOptions={(x) => x}
                                            getOptionLabel={(option) => option.firstName || option}
                                            renderOption={(props, option) => (
                                                <li {...props} className={clsx({
                                                    [classes.listItem]: true,
                                                    [classes.addedUser]: option.organization,
                                                })}>№{option.id} | {option.firstName} {option.phone}</li>
                                            )}
                                            placeholder={searchField === 'name' ? "Введите имя пользователя" : "Введите телефон пользователя"}
                                            loading={isLoading}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={searchField === 'name' ? "Имя пользователя" : "Телефон пользователя"}
                                                    placeholder={searchField === 'name' ? "Введите имя пользователя" : "Введите телефон пользователя"}
                                                    error={Boolean(touched.user && errors.user)}
                                                    helperText={touched.user && errors.user}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: <SearchIcon
                                                            color={Boolean(touched.user && errors.user) ? "error" : "primary"}
                                                            sx={{fontSize: 20}}/>,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {isLoading ? <CircularProgress color="primary"
                                                                                               size={20}/> : params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}

                                            onInputChange={onInputAutocomplete}
                                            onChange={(e, value) => handleChangeAutocomplete(value)}
                                            onClose={() => {
                                                resetUsers();
                                                setIsOpenAutocomplete(false)
                                            }}
                                            onOpen={() => setIsOpenAutocomplete(true)}
                                        />
                                    </Box>
                                </Box>
                                <DialogActions>
                                    <Box>
                                        <Grid container justifyContent="flex-end" spacing={1}>
                                            <Grid item>
                                                <Button variant="outlined" onClick={handleCloseModal}
                                                        sx={{
                                                            textTransform: "initial",
                                                            borderRadius: "4px",
                                                            padding: "4px 24px"
                                                        }}>
                                                    Отменить
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" onClick={handleSubmit}>
                                                    Добавить
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const validationSchema = Yup.object().shape({
    user: Yup.object().required('Выберите пользователя'),
})

const useStyles = makeStyles({
    listItem: {
        padding: "6px 16px",
        fontSize: 14,
        lineHeight: "21px",
        letterSpacing: "0.05px",
        cursor: 'pointer',
        "&:hover": {
            background: "rgba(0, 0, 0, .1)",
        },
    },
    addedUser: {
        textDecoration: "line-through",
        color: "red",
        pointerEvents: "none",
    }
});

export default DialogFormAddEmployee;