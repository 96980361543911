import React from "react";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    Popover,
    Typography
} from "@mui/material";
import {
    KeyboardArrowDown as KeyboardArrowDownIcon
} from "@mui/icons-material";
import {
    withStyles
} from "@mui/styles";
import PopupState, {
    bindPopover,
    bindTrigger
} from "material-ui-popup-state";
import {
    DialogConfirmAction,
    DialogCreateOrder
} from '../../../../components';
import {
    Link,
    withRouter,
} from "react-router-dom";
import {
    Notification,
    NotificationTypes
} from '../../../../common/Notification'
import clsx from "clsx";
import agent from "../../../../agent/agent";

class Header extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isOpenCreateOrder: false,
            isShowBackdrop: false,
        }

        this.refDialogConfirmation = React.createRef();
    }

    _menu = () => {
        const mathPath = this.props?.match?.path || "";

        return [
            {
                iconPath: require("../../../../assets/svg/header/orders.svg").default,
                label: "Заказы",
                path: "",
                isActive: Boolean(mathPath.indexOf("/orders") > -1),
                items: [
                    {
                        path: '/orders',
                        search: "?page=1&type=current",
                        label: "Текущие",
                    },
                    {
                        path: "/orders",
                        search: "?page=1&type=new",
                        label: "Неоплаченные",
                    },
                    {
                        path: "/orders",
                        search: "?page=1&type=archive",
                        label: "Архив",
                    }
                ]
            },
            {
                iconPath: require("../../../../assets/svg/header/wallet.svg").default,
                label: "Финансы",
                path: "",
                isActive: Boolean(mathPath.indexOf("/wallet") > -1) || Boolean(mathPath.indexOf("/registry") > -1) || Boolean(mathPath.indexOf("/invoices") > -1),
                items: [
                    {
                        path: '/wallet',
                        search: "",
                        label: "Баланс",
                    },
                    {
                        path: "/invoices",
                        search: "",
                        label: "Счета на оплату",
                    },
                    {
                        path: "/registry/withdrawal/physical",
                        search: "",
                        label: "Выплаты ФЛ",
                    },
                    {
                        path: "/registry/withdrawal/legal",
                        search: "",
                        label: "Выплаты ЮЛ",
                    },
                    {
                        path: "/wallets",
                        search: "",
                        label: "Кошельки",
                    },
                ],
            },
            {
                iconPath: require("../../../../assets/svg/header/users.svg").default,
                label: "Пользователи",
                path: "/users",
                isActive: Boolean(mathPath.indexOf("/users") > -1)
            },
            {
                iconPath: require("../../../../assets/svg/header/products.svg").default,
                label: "Товары",
                path: "/products",
                isActive: Boolean(mathPath.indexOf("/products") > -1)
            },
            {
                iconPath: require("../../../../assets/svg/header/categories.svg").default,
                label: "Категории",
                path: "/categories",
                isActive: Boolean(mathPath.indexOf("/categories") > -1)
            },
            {
                iconPath: require("../../../../assets/svg/header/organization.svg").default,
                label: "Организации",
                path: "/organizations",
                isActive: Boolean(mathPath.indexOf("/organizations") > -1)
            },
            {
                iconPath: require("../../../../assets/svg/header/store.svg").default,
                label: "Склады",
                path: "/stores",
                isActive: Boolean(mathPath.indexOf("/stores") > -1)
            },
            {
                iconPath: require("../../../../assets/svg/header/settings.svg").default,
                label: "Настройки",
                path: "/settings",
                isActive: Boolean(mathPath.indexOf("/settings") > -1)
            },




            // {
            //     iconPath: require("../../../../assets/svg/header/accreditation.svg").default,
            //     label: "Аккредитации",
            //     path: "/accreditation",
            //     isActive: Boolean(mathPath.indexOf("/accreditation") > -1)
            // },
        ]
    }

    createOrder = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.open({
                title: "Изменение состава заказа",
                message: `Вы действительно хотите создать заказ?"`,
                labelSuccess: "Да, заказ",
                onSuccess: this.createOrder.bind(this, form, true)
            });

            return null
        }

        this.setState({isShowBackdrop: true});

        const body = {
            purchaser: {
                phone: form.purchaser.phone,
                firstName: form.purchaser.firstName,
                lastName: form.purchaser.lastName,
                username: form.purchaser.username || "",
                email: form.purchaser.email || "",
            },
            order: {
                items: form.items.map(item => {
                    return {
                        productId: item.productId,
                        quantity: item.quantityCbm,
                        unit: "cbm",
                    }
                }),
            },
            delivery: {
                coords: form.coords,
                method: form.method,
                deliveryTimespan: form.deliveryTimespan,
                deliveryDate: form.deliveryDate,
            },
            sourceOfCreation: "site",
        };

        const response = await agent.post(`/orders`, body)
            .then(res => res.data.orders)
            .catch(err => {
                return {error: err.response}
            });


        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при создании заказа",
                type: NotificationTypes.error,
            });

            return null;
        }

        const orderIds = response.map(item => `№${item.id}`);
        const notificationMessage = `${orderIds.length > 1 ? "Заказы" : "Заказ"} ${orderIds.join(', ')} успешно ${orderIds.length > 1 ? "созданы" : "создан"}`;

        this.setState({isShowBackdrop: false});

        Notification({
            message: notificationMessage,
            type: NotificationTypes.success,
        });
    };

    setIsOpenCreateOrder = (isOpen) => {
        this.setState({isOpenCreateOrder: isOpen});
    };

    handleCloseCreateOrder = () => {
        this.setIsOpenCreateOrder(false);
    };

    render() {
        const {
            classes
        } = this.props;
        const {
            isOpenCreateOrder,
            isShowBackdrop
        } = this.state;

        return (
            <>
                <Box className={classes.root}>
                    <Container maxWidth="xl">
                        <Grid container justifyContent="space-between">

                            <Grid item>
                                <Box className={classes.logo}>
                                    <Box className={classes.logoLabel}>admin</Box>
                                </Box>
                            </Grid>

                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Box className={classes.addButtonWrapper}>
                                            <Button
                                                className={classes.addButton}
                                                variant="contained"
                                                size="small"

                                                onClick={this.setIsOpenCreateOrder.bind(this, true)}
                                            >
                                                Новый заказ
                                            </Button>
                                        </Box>
                                    </Grid>
                                    {
                                        this._menu().map((item, index) => (
                                            <Grid item key={`menu-item-${index}`}>

                                                {Boolean((item.items || []).length <= 0) && (
                                                    <Link to={item.path}>
                                                        <Box className={clsx({
                                                            [classes.menuItem]: true,
                                                            [classes.menuItemDisable]: Boolean(item.path === "#"),
                                                        })}>
                                                            <Box className={classes.menuItemIcon}>
                                                                <img src={item.iconPath}/>
                                                            </Box>
                                                            <Typography
                                                                className={classes.menuItemLabel}>{item.label}</Typography>

                                                            {Boolean(item.isActive) && (
                                                                <Box className={classes.menuItemIndicator}/>
                                                            )}
                                                        </Box>
                                                    </Link>
                                                )}

                                                {Boolean((item.items || []).length > 0) && (
                                                    <PopupState variant="popover" popupId="demo-popup-popover">
                                                        {(popupState) => (
                                                            <div>
                                                                <Box {...bindTrigger(popupState)}>
                                                                    <Box className={clsx({
                                                                        [classes.menuItem]: true,
                                                                        [classes.menuItemDropDown]: true,
                                                                        [classes.menuItemDisable]: Boolean(item.path === "#"),
                                                                    })}>
                                                                        <Box className={classes.menuItemIcon}>
                                                                            <img src={item.iconPath}/>
                                                                        </Box>
                                                                        <Typography
                                                                            className={classes.menuItemLabel}>{item.label}</Typography>
                                                                        <Box className={classes.menuItemIconDropDown}>
                                                                            <KeyboardArrowDownIcon color="primary"/>
                                                                        </Box>

                                                                        {Boolean(item.isActive) && (
                                                                            <Box className={classes.menuItemIndicator}/>
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                                <Popover
                                                                    {...bindPopover(popupState)}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'right',
                                                                    }}
                                                                >
                                                                    {item.items.map((item, indexItem) => (
                                                                        <Link to={{
                                                                            pathname: item.path,
                                                                            search: item.search,
                                                                            replace: true
                                                                        }}>
                                                                            <Box className={clsx({
                                                                                [classes.dropDownItem]: true
                                                                            })}>
                                                                                <Typography
                                                                                    className={classes.menuItemLabel}>{item.label}</Typography>
                                                                            </Box>
                                                                        </Link>
                                                                    ))}
                                                                </Popover>
                                                            </div>
                                                        )}
                                                    </PopupState>
                                                )}

                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>

                        </Grid>
                    </Container>
                </Box>

                <DialogCreateOrder
                    isOpen={isOpenCreateOrder}
                    onClose={this.handleCloseCreateOrder}
                    onCreate={this.createOrder}
                />

                <DialogConfirmAction
                    ref={this.refDialogConfirmation}
                />

                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="white"/>
                </Backdrop>
            </>
        );
    }
}

const styles = {
    root: {
        backgroundColor: "white",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)"
    },

    logo: {
        display: "flex"
    },
    logoLabel: {
        height: 64,
        backgroundColor: "#84b92c",
        padding: "0 8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 20,

        fontSize: 24,
        lineHeight: "24px",
        color: "white",
        fontWeight: "600"
    },
    logoIcon: {},

    menuItem: {
        display: "flex",
        alignItems: "center",
        height: 64,
        position: "relative"
    },
    menuItemDropDown: {
        cursor: "pointer"
    },
    menuItemDisable: {
        opacity: 0.4,
        cursor: "not-allowed"
    },
    menuItemIcon: {
        width: 24,
        height: 24
    },
    menuItemIconDropDown: {
        width: 24,
        height: 24,
        marginLeft: 12
    },
    menuItemLabel: {
        fontSize: 16,
        lineHeight: "26px",
        fontWeight: "600",
        color: "#22252D",
        marginLeft: 8
    },
    menuItemIndicator: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#84b92c",
        height: 5
    },

    dropDownItem: {
        padding: "8px 16px"
    },

    addButtonWrapper: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        marginRight: 8,
    },
    addButton: {
        '&.MuiButton-root': {
            textTransform: "initial",
            fontSize: 12,
            height: "auto",
            borderRadius: 1
        }
    }
}

const headerStyles = withStyles(styles)(Header);
export default withRouter(headerStyles)
