import React from 'react';
import {
    Grid,
    Typography
} from "@mui/material";
import {
    convertorNumber
} from "../../../../../../helper/convertor";

const MainInformation = (props) => {
    const {
        totalSum
    } = props;
    return (
        <>
            <Typography variant="h2">Выплаты юридическим лицам</Typography>
            <Grid container justifyContent="space-between" mt={5} alignItems="center">
                <Grid item xs>
                    <Grid container mb={1} alignItems="center">
                        <Grid item xs={2}>
                            <Typography variant="h4">
                                Общая сумма выплат:
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="h4" textAlign="right">
                                {convertorNumber(totalSum, 2, '.')} ₽
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default MainInformation;