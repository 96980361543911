import React from 'react';
import { Box, Button, Checkbox, FormControlLabel, Tooltip, Typography } from "@mui/material";
import { convertorNumber } from "../../../../../../helper/convertor";
import { makeStyles } from "@mui/styles";

const MainInformation = (props) => {
    const {
        wallet,
        walletsInfo,
        isRegularRequest,
        onOpenWithdrawalFunds,
        onCanceledRegularFunds
    } = props;

    const classes = useStyles();

    const getClientsAssets = () => {
        return (+walletsInfo.balancesSum + +walletsInfo.frozenSum) - +wallet.balanceFrozen;
    };

    const handleChangeBoolean = ({ target }) => {
        const { checked } = target;

        if (checked) {
            onOpenWithdrawalFunds(true, 'regular');

            return null;
        }

        onCanceledRegularFunds();
    };

    return (
        <>
            <Typography variant="h2">Баланс</Typography>
            <Box className={classes.rowContent} justifyContent="space-between" mt={ 5 } alignItems="flex-start">
                <Box className={ classes.columnContent }>
                    <Box className={ classes.columnContent } p={ 1 } sx={ {
                        border: "1px solid #84b92c",
                        background: "rgba(132, 185, 44, 0.04)",
                        borderRadius: "4px"
                    } }>
                        <Typography variant="h3" mb={ 1 }>Общий баланс</Typography>
                        <Box className={ classes.rowContent } mb={ 1 } alignItems="center"
                             justifyContent="space-between">
                            <Typography variant="h4">свободные:</Typography>
                            <Typography variant="h4" textAlign="right">
                                { convertorNumber(walletsInfo.balancesSum, 2, '.') } ₽
                            </Typography>
                        </Box>
                        <Box className={ classes.rowContent } mb={ 1 } alignItems="center" justifyContent="space-between">
                            <Typography variant="h4">заблокированные:</Typography>
                            <Typography variant="h4" textAlign="right">
                                { convertorNumber(walletsInfo.frozenSum, 2, '.') } ₽
                            </Typography>
                        </Box>
                        <Box className={classes.rowContent} alignItems="center" justifyContent="space-between">
                            <Typography variant="h4" fontWeight="bold">итого:</Typography>
                            <Typography variant="h4" fontWeight="bold" textAlign="right">
                                { convertorNumber(+walletsInfo.frozenSum + +walletsInfo.balancesSum, 2, '.') } ₽
                            </Typography>
                        </Box>
                    </Box>

                    <Box className={classes.rowContent} px={ 1 } mt={ 4 } alignItems="center" justifyContent="space-between">
                        <Typography variant="h4">захолдированные:</Typography>
                        <Typography variant="h4" textAlign="right">
                            { convertorNumber(walletsInfo.holdSum, 2, '.') } ₽
                        </Typography>
                    </Box>

                    <Box className={classes.rowContent} px={ 1 } mt={ 1 } alignItems="center" justifyContent="space-between">
                        <Typography variant="h4">активы клиентов:</Typography>
                        <Typography variant="h4" textAlign="right">
                            { convertorNumber(getClientsAssets(), 2, '.') } ₽
                        </Typography>
                    </Box>

                    <Box className={classes.rowContent} px={ 1 } mt={ 1 } alignItems="center" justifyContent="space-between">
                        <Typography variant="h4">свободные деньги системы:</Typography>
                        <Typography variant="h4" textAlign="right">
                            { convertorNumber(wallet.balance, 2, '.') } ₽
                        </Typography>
                    </Box>

                    <Box className={classes.rowContent} px={ 1 } mt={ 1 } alignItems="center" justifyContent="space-between">
                        <Typography variant="h4">заблокированные деньги системы:</Typography>
                        <Typography variant="h4" textAlign="right">
                            { convertorNumber(wallet.balanceFrozen, 2, '.') } ₽
                        </Typography>
                    </Box>
                </Box>
                <Box className={classes.columnContent}>
                    <Box>
                        <Button
                            sx={ {
                                borderRadius: "6px",
                            } }
                            variant="contained"
                            disabled={ isRegularRequest }

                            onClick={ onOpenWithdrawalFunds.bind(this, true, 'oneTime') }
                        >
                            Получить оплату
                        </Button>
                    </Box>
                    <Box>
                        <Tooltip
                            title="Выводить все свободные средства на банковский счет в день их поступления в кошелек">
                            <FormControlLabel
                                label="получать автоматически"
                                disabled={ isRegularRequest }
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={ isRegularRequest }

                                        onChange={ handleChangeBoolean }
                                    />
                                }
                            />
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

const useStyles = makeStyles({
    rowContent: {
        display: "flex",
        flexWrap: "wrap",
        gap: "10px 20px",
    },

    columnContent: {
        display: "flex",
        flexDirection: "column",
    },
});

export default MainInformation;