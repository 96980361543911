import React from 'react';
import {IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import {Delete as DeleteIcon} from "@mui/icons-material";
import userRoles from "../../../../../../constants/userRoles";
import {formatPhone} from "../../../../../../helper/formatPhone";

const TableEmployees = (props) => {
    const {
        employees,
        onDeleteEmployee
    } = props;

    const isEmpty = !employees.length;

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Фамилия</TableCell>
                        <TableCell>Имя</TableCell>
                        <TableCell>Телефон</TableCell>
                        <TableCell>Роль</TableCell>
                        <TableCell width="40px"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isEmpty ? (
                        <TableRow>
                            <TableCell
                                style={{borderRadius: "0 0 10px 10px"}}
                                align="center"
                                colspan={6}
                            >
                                Нет участников организации
                            </TableCell>
                        </TableRow>
                    ) : (
                        employees.map((row, index) => (
                            <TableRow
                                key={`row-item-employees-${index}`}
                                hover
                            >
                                <TableCell>{row.id || "—"}</TableCell>
                                <TableCell>{row.lastName || "—"}</TableCell>
                                <TableCell>{row.firstName || "—"}</TableCell>
                                <TableCell>{formatPhone(row.phone) || "—"}</TableCell>
                                <TableCell>{userRoles.find(role => role.value === row.role).label || "-"}</TableCell>
                                <TableCell>
                                    <Tooltip title="Удалить" arrow>
                                        <IconButton
                                            onClick={onDeleteEmployee.bind(this, row.id, false)}
                                        >
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </>
    );
};

export default TableEmployees;