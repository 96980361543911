import React from 'react';
import {Box, Tab, Tabs} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import {TableComponent} from "../index";
import {makeStyles} from "@mui/styles";

const TableInformation = (props) => {
    const {
        wallet,
        activeTab,
        transactions,
        filter,
        pagination,
        allTransactions,
        isLoading,

        onChangePagination,
        onChangeFilter,
        onChangeTab
    } = props;
    const classes = useStyles();

    const handleChangeTab = (e, value) => {
        onChangeTab(value);
    };
    return (
        <Box>
            <TabContext value={activeTab}>
                <Tabs
                    value={activeTab}
                    onChange={handleChangeTab}
                >
                    <Tab value="all" label="Все операции"/>
                    <Tab value="my" label="Личные операции"/>
                </Tabs>
                <TabPanel className={classes.tabPanel} value="all">
                    <TableComponent
                        wallet={wallet}
                        activeTab={activeTab}
                        title="История операций всех кошельков"
                        filter={filter}
                        pagination={pagination}
                        transactions={allTransactions}
                        isLoading={isLoading}
                        onChangePagination={onChangePagination}
                        onChangeFilter={onChangeFilter}
                    />
                </TabPanel>
                <TabPanel className={classes.tabPanel} value="my">
                    <TableComponent
                        wallet={wallet}
                        activeTab={activeTab}
                        title="История операций личного кошелька"
                        filter={filter}
                        pagination={pagination}
                        transactions={transactions}
                        isLoading={isLoading}
                        onChangePagination={onChangePagination}
                        onChangeFilter={onChangeFilter}
                    />
                </TabPanel>
            </TabContext>
        </Box>
    );
};

const useStyles = makeStyles({
    tabPanel: {
        "&.MuiTabPanel-root": {
            padding: 0,
            marginTop: 24,
        }
    }
})

export default TableInformation;