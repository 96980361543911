const UPDATE_USER = 'app/UPDATE_USER';
const SET_SETTINGS = 'app/SET_SETTINGS';

const initialState = {
    user: null,
    settings: null,
};

export function setUser(user) {
    return {
        type: UPDATE_USER,
        user
    }
}

export function setSettings(settings) {
    return {
        type: SET_SETTINGS,
        settings,
    }
}

export default function AppState(state = initialState, action = {}) {
    switch (action.type) {
        case UPDATE_USER: {
            let user = action.user

            return {
                ...state,
                user
            }
        }
        case SET_SETTINGS: {
            let settings = action.settings;

            return {
                ...state,
                settings,
            };
        }
        default:
            return state;
    }
}
