import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@mui/material";
import {
    Formik
} from "formik";
import {
    AutocompleteUser_1
} from "../../../../../../components";
import * as Yup from 'yup';

const DialogFormSetExpeditor = (props) => {
    const {
        isOpen,
        onClose,
        onSet
    } = props;

    const refFormik = React.useRef(null);
    const [initialValues, setInitialValues] = React.useState({
        expeditor: "",
    });

    React.useEffect(() => {
        if (isOpen) {
            setInitialValues({
                expeditor: "",
            });
        }
    }, [isOpen]);

    const onSubmit = (form) => {
        const newForm = {
            expeditorId: form.expeditor.id,
        };
        onSet(newForm);
        handleClose();
    };

    const handleClose = () => {
        onClose();
    };

    const handleChangeAutocomplete = (value) => {
        const newForm = refFormik.current.values;

        newForm.expeditor = value;

        refFormik.current.setValues(newForm);
    };

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={isOpen}
            onClose={handleClose}
        >
            <DialogTitle>
                Назначение экспедитора
            </DialogTitle>
            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box pt={1}>
                                    <Box>
                                        <AutocompleteUser_1
                                            name='expeditor'
                                            value={values.expeditor}
                                            error={errors.expeditor}
                                            touched={touched.expeditor}
                                            limit="0"
                                            roles="courier"
                                            title="Экспедитор"
                                            showSearchFields
                                            onChangeAutocomplete={handleChangeAutocomplete}
                                        />
                                    </Box>
                                </Box>

                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            borderRadius: "5px",
                                            textTransform: "none",
                                        }}

                                        onClick={handleClose}
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            borderRadius: "5px",
                                            textTransform: "none",
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        Назначить
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const validationSchema = Yup.object().shape({
    expeditor: Yup.object().required('Выберите заказчика'),
});

export default DialogFormSetExpeditor;