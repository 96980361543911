import React from "react";
import {
    Box,
    Grid,
    Pagination,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, TableSortLabel,
    Typography,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import moment from "moment";
import paymentStatus from "../../../../../../constants/paymentStatus";
import {deliveryTime} from "../../../../../../helper/deliveryTime";

const TableCustom = (props) => {
    const {
        data,
        filter,
        pagination,
        visibleColumns,
        settings,

        isLoading,

        onChangePagination,
        onChangeFilter,
        onChangeStatusPayment,

        routeOrderPage
    } = props;
    const classes = useStyles();

    const handleClickRow = (row, event) => {
        const isDisableRoute = Boolean(event.target.closest(".disable-route"));
        if (isDisableRoute) {
            return
        }

        routeOrderPage(row.id);
    }

    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination)
    }

    const handleChangeFilter = (name, value) => {
        const newFilter = {...filter};
        newFilter[name] = value;

        onChangeFilter(newFilter, true);
    };

    const _getStoreInfo = (row) => {
        if (row.store !== null) {
            return row.store.reserve ? "Резервный" : "Обычный";
        } else {
            return "-";
        }
    }

    const _getDeliveryAddress = (row) => {
        if (row.deliveryMethod === 'delivery') {
            return row?.deliveryAddress || "Милицейский адрес не найден";
        } else {
            return "Самовывоз";
        }
    };

    const _getDeliveryDate = (row) => {
        const date = moment(row?.deliveryDate).format("DD.MM.YYYY");
        const timesDelivery = deliveryTime(settings);
        const timespan = timesDelivery.filter(time => time.value === row?.deliveryTimespan);

        return `${date} (${timespan[0]?.label || "Время не указано"})`;
    }

    const _getStatusOrderReady = (row) => {
        switch (row.deliveryStatus) {
            case "new":
                return "Новый";
            case "inProgress":
                return "В работе";
            case "partiallyReady":
                return "Частично готов";
            case "readyForShipment":
            case 'courierInstalled':
            case 'transferringToDelivery':
            case "loaded":
            case "enRoute":
            case 'transferringToClient':
            case "received":
            case "partialReturn":
            case "fullReturn":
            case "canceled":
                return "Готов";
            default:
                return "Статус не определен";
        }
    }

    const _getStatusOrderDelivery = (row) => {
        if (row.deliveryMethod === "pickup") {
            return "Самовывоз";
        } else {
            switch (row.deliveryStatus) {
                case "new":
                case "partiallyReady":
                case "inProgress":
                case "readyForShipment":
                    return "Новый";
                case "loaded":
                case 'courierInstalled':
                case 'transferringToDelivery':
                    return "Ожидает погрузки";
                case "enRoute":
                    return "Загружен";
                case "received":
                case "transferringToClient":
                case "partialReturn":
                case "fullReturn":
                case "canceled":
                    return "Доставлен";
                default:
                    return "Статус не определен";
            }
        }
    }

    const _getStatusOrderReceived = (row) => {
        switch (row.deliveryStatus) {
            case "new":
            case "partiallyReady":
            case "inProgress":
            case "readyForShipment":
            case "loaded":
            case "enRoute":
            case 'courierInstalled':
            case 'transferringToDelivery':
            case "transferringToClient":
                return "Новый";
            case "received":
                return "Выполнен";
            case "partialReturn":
                return "Частичный возврат";
            case "fullReturn":
                return "Полный возврат";
            case "canceled":
                return "Отменен";
            default:
                return "Статус не определен";
        }
    }

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>
                        <EnhancedTableHead
                            visibleColumns={visibleColumns}
                            filter={filter}
                            handleChangeFilter={handleChangeFilter}
                        />
                    </TableRow>
                </TableHead>

                <TableBody>

                    {

                        Boolean(isLoading) ? (
                            <>

                                {
                                    [0, 1, 2, 3, 4, 5].map((item) => (

                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                        </TableRow>

                                    ))
                                }

                            </>
                        ) : (
                            <>
                                {
                                    data.map((row, index) => (
                                        <TableRow
                                            key={`row-item-${index}`}
                                            hover={true}

                                            onClick={(event) => handleClickRow(row, event)}
                                        >
                                            <TableCell>{row.id}</TableCell>
                                            <TableCell
                                                align="center">{_getDeliveryDate(row)}</TableCell>
                                            <TableCell align="center">{_getDeliveryAddress(row)}</TableCell>
                                            <TableCell align="center">{_getStatusOrderReady(row)}</TableCell>
                                            <TableCell align="center">{_getStatusOrderDelivery(row)}</TableCell>
                                            <TableCell align="center">{_getStatusOrderReceived(row)}</TableCell>
                                            <TableCell
                                                align="center">{paymentStatus[row.paymentStatus] || "—"}</TableCell>
                                            <TableCell align="center">{_getStoreInfo(row)}</TableCell>
                                            <TableCell align="left">
                                                {row.providerUser !== null
                                                    ? (
                                                        <>
                                                            <Typography>Имя: {row?.providerUser?.firstName}</Typography>
                                                            <Typography>Тел: <a className={classes.userPhone}
                                                                                href={`tel: +${row?.providerUser?.phone}`}
                                                                                onClick={e => e.stopPropagation()}>+{row?.providerUser?.phone}</a></Typography>
                                                        </>
                                                    )
                                                    : (
                                                        <Typography>-</Typography>
                                                    )
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </>
                        )
                    }
                </TableBody>
            </Table>
            <Box mt={3}/>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Pagination
                        page={Number(pagination.page)}
                        count={Number(pagination.totalPage)}

                        onChange={handleChangePage}
                    />
                </Grid>
                <Grid item></Grid>
            </Grid>

        </>
    );
};

const EnhancedTableHead = (props) => {
    const {
        visibleColumns,
        filter,

        handleChangeFilter
    } = props;
    const classes = useStyles();

    const handleChangeSort = (col) => {
        if (filter.sortInfo === `-${col}`) {
            handleChangeFilter("sortInfo", col);
        } else {
            handleChangeFilter("sortInfo", `-${col}`);
        }
    };
    return (
        <>
            {visibleColumns.map(column => (
                <TableCell
                    key={column.id}
                    width={column.width}
                    {...column}
                >
                    {column.sortable
                        ? (
                            <TableSortLabel
                                classes={{
                                    active: classes.activeSort,
                                    root: classes.sort,
                                }}
                                active={filter.sortInfo === column.id || filter.sortInfo === `-${column.id}`}
                                direction={filter.sortInfo === column.id ? "desc" : "asc"}

                                onClick={handleChangeSort.bind(this, column.id)}
                            >
                                {column.label}
                            </TableSortLabel>
                        )
                        : column.label
                    }
                </TableCell>
            ))}
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    buttonAction: {},
    userPhone: {
        fontWeight: 500,
        color: "#84b92c",
    },

    tableSmall: {
        "& tr td": {
            padding: "2px 20px",
            "&:first-child": {
                paddingLeft: 0,
            },
            "&:last-child": {
                paddingRight: 0,
            }
        }
    },

    sort: {
        "&.MuiButtonBase-root": {
            transition: "all .2s linear",
            "&:hover": {
                color: "rgba(255,255,255, .5)",
                "& .MuiSvgIcon-root": {
                    fill: "#FFFFFF",
                }
            }
        }
    },
    activeSort: {
        '&.MuiButtonBase-root.Mui-active': {
            color: "#FFFFFF",

            "& .MuiSvgIcon-root": {
                "& path": {
                    fill: "#FFFFFF",
                }
            }
        },
    },
}));

export default TableCustom
