import React, {useEffect, useState} from 'react';
import agent from "../../agent/agent";
import {
    Autocomplete,
    CircularProgress,
    TextField
} from "@mui/material";
import {
    Search as SearchIcon
} from '@mui/icons-material'

let timeout = null;

const AutocompleteOrganization = (props) => {
    const {
        organizations,
        value,
        name,
        touched,
        errors,
        onChangeValue,
        getOrganizations,
        resetOrganizations,
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleChange = (e, value) => {
        clearTimeout(timeout);
        timeout = null;
        if (value > 5) {
            timeout = setTimeout(async () => {
                setIsLoading(true);
                await getOrganizations(value);
                setIsLoading(false);
            }, 1000);
        }
    };

    return (
        <Autocomplete
            fullWidth
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => {
                resetOrganizations();
                setIsOpen(false)
            }}
            noOptionsText='Нет организаций'
            loadingText='Загрузка...'
            value={value}
            name='organization'
            onChange={(e, value) => onChangeValue(value)}
            onInputChange={handleChange}
            options={organizations}
            filterOptions={(x) => x}
            getOptionLabel={(option) => option.inn || option}
            renderOption={(props, option) => (
                <li {...props}>{option.id} | {option.name} {option.inn}</li>
            )}
            placeholder="Введите ИНН организации"
            loading={isLoading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="ИНН организации"
                    placeholder="Введите ИНН организации"
                    error={Boolean(touched.organization && errors.organization)}
                    helperText={touched.organization && errors.organization}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <SearchIcon
                            color={Boolean(touched.organization && errors.organization) ? "error" : "primary"}
                            sx={{fontSize: 20}}/>,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ?
                                    <CircularProgress color="primary" size={20}/> : params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default AutocompleteOrganization;