import {numberFormat} from "../common/Formater";

const convertorNumber = (value, decimalCount = 0, decimal = ",", thousands = " ") => {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = value < 0 ? "-" : "";

    let i = parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(value - i).toFixed(decimalCount).slice(2) : "");
};

const convertorToPiece = (value, coefficient) => {
    const piece = value * coefficient;
    if (Math.floor(piece) + 0.9 <= piece) {
        return Math.round(piece);
    } else {
        return Math.floor(piece);
    }
};

const convertorToCbm = (value, coefficient) => {
    const pieceToCbm = 1 / coefficient;
    return numberFormat(Number(value) * pieceToCbm, 4, '.');
};

const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};

export {
    convertorNumber,
    convertorToPiece,
    convertorToCbm,
    getBase64,
};