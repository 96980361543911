import React from "react";
import {
    Box,
    Grid,

    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

    Typography,
    Pagination,
    Skeleton,
    Checkbox,

    Tooltip,
    IconButton,
    Avatar
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
    Edit as EditIcon,
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    VerifiedUser as VerifiedUserIcon,
    Delete as DeleteIcon
} from "@mui/icons-material";
import {
    TableHeadSortCell
} from "../../../../../../components";
import moment from "moment";
import userRoles from '../../../../../../constants/userRoles';

const TableCustom = (props) => {
    const {
        data,
        filter,
        pagination,

        isLoading,

        onChangePagination,
        onChangeFilter,
        onEditUser,
        onEditAccreditation,
        onSetActive,
        onDeleteUser,
        onOpenUserCart
    } = props;

    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination)
    }
    const handleChangeSort = (sort) => {
        let newFilter = {...filter};
        newFilter.sort = sort;

        onChangeFilter(newFilter, true);
    }

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Фамилия</TableCell>
                        <TableCell>Имя</TableCell>
                        <TableCell>Логин</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Телефон</TableCell>
                        <TableCell>Роль</TableCell>
                        <TableCell>Активирован</TableCell>
                        {/*<TableCell>Организация</TableCell>*/}
                        {/*<TableCell>Дата создания</TableCell>*/}
                        {/*<TableCell>Дата редактирования</TableCell>*/}
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {

                        Boolean(isLoading) ? (

                            <>

                                {
                                    [0, 1, 2, 3, 4, 5].map((item) => (

                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            {/*<TableCell><Skeleton/></TableCell>*/}
                                            {/*<TableCell><Skeleton/></TableCell>*/}
                                            {/*<TableCell><Skeleton/></TableCell>*/}
                                            <TableCell>
                                                <Grid container justifyContent="flex-end" spacing={1}>
                                                    <Grid item><Skeleton width={24} height={40}/></Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>

                                    ))
                                }

                            </>

                        ) : (

                            <>

                                {

                                    data.map((row, index) => (

                                        <TableRow key={`row-item-${index}`} onClick={() => onOpenUserCart(row)}>
                                            <TableCell>{row.id}</TableCell>
                                            <TableCell>{row.lastName}</TableCell>
                                            <TableCell>{row.firstName}</TableCell>
                                            <TableCell>{row.username}</TableCell>
                                            <TableCell>{row.email || "—"}</TableCell>
                                            <TableCell>{row.phone || "—"}</TableCell>
                                            <TableCell>{userRoles.map(role => role.value === row.role ? role.label : null).join('') || '-'}</TableCell>
                                            <TableCell><Checkbox disabled checked={row.active}/></TableCell>
                                            {/*<TableCell>*/}
                                            {/*    Наименование: {row.organizationName || "—"}<br/>*/}
                                            {/*    Адрес: {row.organizationAddress || "—"}<br/>*/}
                                            {/*    ИНН: {row.organizationInn || "—"}<br/>*/}
                                            {/*    КПП: {row.organizationName || "—"}*/}
                                            {/*</TableCell>*/}
                                            {/*<TableCell>*/}
                                            {/*    {moment(row.createdAt).format("DD.MM.YYYY")}<br/>*/}
                                            {/*    {moment(row.createdAt).format("HH:mm:ss")}*/}
                                            {/*</TableCell>*/}
                                            {/*<TableCell>*/}
                                            {/*    {moment(row.updatedAt).format("DD.MM.YYYY")}<br/>*/}
                                            {/*    {moment(row.updatedAt).format("HH:mm:ss")}*/}
                                            {/*</TableCell>*/}
                                            <TableCell>
                                                <Grid container justifyContent="flex-end" spacing={1} wrap="nowrap">
                                                    <Grid item>
                                                        <Tooltip title="Активировать пользователя" arrow>
                                                            <IconButton
                                                                disabled={row.active}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    onSetActive(row);
                                                                }}
                                                            >
                                                                <VerifiedUserIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title="Редактировать аккредитации пользователя" arrow>
                                                            <IconButton
                                                                disabled={row.role !== "provider"}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    onEditAccreditation(row)
                                                                }}
                                                            >
                                                                <AssignmentTurnedInIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title="Редактировать пользователя" arrow>
                                                            <IconButton onClick={(e) => {
                                                                e.stopPropagation();
                                                                onEditUser(row)
                                                            }}>
                                                                <EditIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title="Удалить пользователя" arrow>
                                                            <IconButton onClick={(e) => {
                                                                e.stopPropagation();
                                                                onDeleteUser(row)
                                                            }}>
                                                                <DeleteIcon sx={{ color: "red" }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>

                                    ))

                                }

                            </>

                        )

                    }

                </TableBody>

            </Table>

            <Box mt={3}/>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Pagination
                        page={Number(pagination.page)}
                        count={Number(pagination.totalPage)}

                        onChange={handleChangePage}
                    />
                </Grid>
                <Grid item></Grid>
            </Grid>

        </>
    )
}

const useStyles = makeStyles((theme) => ({

    buttonAction: {}

}));

export default TableCustom
