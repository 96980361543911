// @flow
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Organization from './Organization';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(Organization);
