import React, {useState} from 'react';
import {
    Box,
    Button,
    Collapse,
    Grid,

    Table,
    TableBody,
    TableCell,
    TableRow,

    Typography
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import Map from "../../../../../../components/Map";

const StoreInformation = (props) => {
    const {
        store
    } = props;
    const classes = useStyles();
    const [isShowMap, setIsShowMap] = useState(false);
    return (
        <>
            <Box className={classes.root}>
                <Box mb={2}>
                    <Typography variant="h3">Информация о складе</Typography>
                </Box>

                <Table className={classes.table}>
                    <TableBody>
                        <TableRow>
                            <TableCell width="20%">Адрес</TableCell>
                            <TableCell className={classes.tableValue}>
                                <Grid container alignItems='center' spacing={2}>
                                    <Grid item>
                                        {store?.address || 'Милицейский адрес не найден'}
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            className={classes.tableButton}
                                            onClick={() => setIsShowMap(!isShowMap)}
                                        >
                                            {isShowMap ? "Свернуть карту" : "Развернуть карту"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </TableCell>
                            <TableCell width={150} align="right"/>
                        </TableRow>
                        <TableRow>
                            <TableCell width="20%">Широта</TableCell>
                            <TableCell className={classes.tableValue}>
                                {store.addressLat}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width="20%">Долгота</TableCell>
                            <TableCell className={classes.tableValue}>
                                {store.addressLong}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width="20%">Название</TableCell>
                            <TableCell className={classes.tableValue}>
                                {store.name}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width="20%">Описание</TableCell>
                            <TableCell className={classes.tableValue}>
                                {store.description}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Collapse in={isShowMap} timeout="auto">
                    <Box height={420} mt={2}>
                        <Map
                            center={[store.addressLat, store.addressLong]}
                            coords={[store.addressLat, store.addressLong]}
                        />
                    </Box>
                </Collapse>
            </Box>

        </>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        borderRadius: 10,
        backgroundColor: "rgba(132, 185, 44, 0.04)",

        padding: "24px 32px"
    },

    table: {
        "&.MuiTable-root": {
            boxShadow: "none"
        },
        "& .MuiTableCell-root": {
            padding: "8px 0 0 0",
            backgroundColor: "transparent!important"
        },
    },
    tableValue: {
        fontSize: 14,
        fontWeight: "600"
    },
    tableButton: {
        height: 30,
        fontSize: 12,
        padding: "0 12px",
        borderRadius: 4
    },

    delivery: {
        display: "flex",
        alignItems: "center",

        "& > img": {
            marginRight: 8
        }
    }
}));

export default StoreInformation;