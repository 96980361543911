import React, {createRef} from 'react';
import {
    Box,
    Button,
    Collapse,

    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,

    IconButton,
    Link,

    Typography,
} from "@mui/material";
import {
    DeleteRounded as DeleteIcon,
    ExpandLessRounded as LessIcon,
    ExpandMoreRounded as MoreIcon
} from '@mui/icons-material';
import {
    Formik
} from "formik";
import {
    withStyles
} from "@mui/styles";
import {
    LoadingButton
} from "@mui/lab";
import {
    Notification,
    NotificationTypes
} from "../../common/Notification";
import {
    phoneFormat
} from "../../common/Formater";
import {
    AutocompleteUser_1
} from "../index";
import userRoles from "../../constants/userRoles";
import * as Yup from "yup";
import agent from "../../agent/agent";
import {userIsLegal} from "../../helper/userType";

const initialValues = {
    expeditor: "",
}

class DialogUserCart extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            favoritesExpeditors: [],
            expeditor: {},
            isOpen: false,
            isOpenCollapseFavExpeditors: false,
            isLoadingFavExpeditors: false,
        }

        this.refFormik = createRef();
    }

    open = (user, open) => {
        this.setState({
            user: user,
            isOpen: open,
            favoritesExpeditors: [],
            isOpenCollapseFavExpeditors: false,
        });

        if (user.expeditorId) {
            (async () => {
                await this.getExpeditorForDriver(user.expeditorId);
            })();
        }
    }

    close = () => {
        this.setState({
            user: {},
            isOpen: false,
        })
    }

    onSubmit = async (form) => {
        await this.setFavoriteExpeditor(form.expeditor.id);
    };

    handleChange = ({target}) => {
        const {name, value} = target;
        const newForm = this.refFormik.current.values;
        newForm[name] = value;

        this.refFormik.current.setValues(newForm);
    };

    handleChangeAutocomplete = (value) => {
        const newForm = this.refFormik.current.values;

        newForm.expeditor = value;

        this.refFormik.current.setValues(newForm);
    };

    getFavoritesExpeditors = async (isRefresh = false) => {
        const {
            user,
            isOpenCollapseFavExpeditors,
        } = this.state;

        this.setState({
            isLoadingFavExpeditors: true,
        })

        if (!isOpenCollapseFavExpeditors || isRefresh) {
            const response = await agent.get(`/favorite-expeditor/get-by-provider?providerId=${user.id}`)
                .then(res => res.data.expeditors)
                .catch(err => []);

            const favExpeditors = response.map(item => item.expeditor);

            await this.setState({
                favoritesExpeditors: favExpeditors,
                isLoadingFavExpeditors: false,
            });
        }

        if (!isRefresh) {
            this.setState({
                isOpenCollapseFavExpeditors: !isOpenCollapseFavExpeditors,
                isLoadingFavExpeditors: false,
            });
        }
    };

    setFavoriteExpeditor = async (expeditorId, isConfirm) => {
        const {
            refDialogConfirmation
        } = this.props;

        if (!isConfirm) {
            refDialogConfirmation.current.open({
                title: "Подтверждения",
                message: `Вы действительно хотите добавить экспедитора в список избранных?`,
                labelSuccess: "Да, добавить",
                onSuccess: this.setFavoriteExpeditor.bind(this, expeditorId, true),
            });

            return null
        }

        const body = {
            providerId: this.state.user.id,
            expeditorId,
        };

        const response = await agent.post(`/favorite-expeditor`, body)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.statusCode && response.statusCode === 400) {
            Notification({
                message: response?.message || "Ошибка при добавлении экспедитора в список избранных",
                type: NotificationTypes.error,
            });

            return null;
        }

        if (response.error) {
            Notification({
                message: response.error?.data?.message || "Ошибка при добавлении экспедитора в список избранных",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getFavoritesExpeditors(true);
        this.refFormik.current.setFieldValue('expeditorId', '');
        Notification({
            message: "Экспедитор успешно добавлен в список избранных",
            type: NotificationTypes.success,
        })
    };

    deleteFavoriteExpeditor = async (expeditorId, isConfirm) => {
        const {
            refDialogConfirmation
        } = this.props;

        if (!isConfirm) {
            refDialogConfirmation.current.open({
                title: "Подтверждения",
                message: `Вы действительно хотите удалить экспедитора из списка избранных?`,
                labelSuccess: "Да, удалить",
                onSuccess: this.deleteFavoriteExpeditor.bind(this, expeditorId, true),
            });

            return null
        }

        const response = await agent.delete(`/favorite-expeditor/${expeditorId}`, {
            data: {
                providerId: this.state.user.id,
                expeditorId,
            }
        })
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });
        if (response.error) {
            Notification({
                message: response.error?.data?.message || "Ошибка при удалении экспедитора из списка избранных",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getFavoritesExpeditors(true);
        Notification({
            message: "Экспедитор успешно удален из списка избранных",
            type: NotificationTypes.success,
        });
    };

    getExpeditorForDriver = async (expeditorId) => {
        const response = await agent.get(`/users/${expeditorId}`)
            .then(res => res.data.user)
            .catch(err => []);

        this.setState({expeditor: response});
    };

    _routeOrganization = (organizationId) => {
        this.props.history.replace(`/organizations/${organizationId}`);
    };

    render() {
        const {
            user,
            favoritesExpeditors,
            expeditor,
            isOpen,
            isOpenCollapseFavExpeditors,
            isLoadingFavExpeditors,
        } = this.state;
        const {
            classes,
        } = this.props;

        return (
            <Dialog
                fullWidth
                maxWidth="md"
                open={isOpen}

                onClose={this.close}
            >
                <DialogTitle sx={{backgroundColor: "#84b92c"}}>
                    <Typography variant="h3" sx={{color: "white"}}>
                        Карточка пользователя
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Box pt={2}>
                        <Box className={classes.rowContent}>
                            <Typography className={classes.label}>Имя</Typography>
                            <Typography className={classes.value}>{user?.firstName || '-'}</Typography>
                        </Box>

                        <Box className={classes.rowContent}>
                            <Typography className={classes.label}>Фамилия</Typography>
                            <Typography className={classes.value}>{user?.lastName || '-'}</Typography>
                        </Box>

                        <Box className={classes.rowContent}>
                            <Typography className={classes.label}>Никнейм</Typography>
                            <Typography className={classes.value}>{user?.username || '-'}</Typography>
                        </Box>

                        <Box className={classes.rowContent}>
                            <Typography className={classes.label}>Email</Typography>
                            <Typography className={classes.value}>{user?.email || '-'}</Typography>
                        </Box>

                        <Box className={classes.rowContent}>
                            <Typography className={classes.label}>Телефон</Typography>
                            <Box className={classes.value}>
                                <Link
                                    className={classes.linkValue}
                                    href={`tel:+${user?.phone || '-'}`}
                                >
                                    {phoneFormat(user.phone) || '-'}
                                </Link>
                            </Box>
                        </Box>

                        <Box className={classes.rowContent}>
                            <Typography className={classes.label}>Роль</Typography>
                            <Typography
                                className={classes.value}>{userRoles.map(role => role.value === user.role ? role.label : null).join('') || '-'}</Typography>
                        </Box>

                        {userIsLegal(user) && (
                            <>
                                <Typography variant="h3" mt={3} mb={1}>Организация</Typography>
                                <Box className={classes.rowContent}>
                                    <Typography className={classes.label}>Наименование</Typography>
                                    <Box className={classes.value}>
                                        <Link
                                            className={classes.linkValue}
                                            href="#"

                                            onClick={this._routeOrganization.bind(this, user.organization.id)}
                                        >
                                            {user?.organization?.name || "-"}
                                        </Link>
                                    </Box>
                                </Box>

                                <Box className={classes.rowContent}>
                                    <Typography className={classes.label}>Роль пользователя</Typography>
                                    <Typography
                                        className={classes.value}
                                    >
                                        {userIsLegal(user) ? "Владелец" : "Участник"}
                                    </Typography>
                                </Box>

                                <Box className={classes.rowContent}>
                                    <Typography className={classes.label}>ИНН</Typography>
                                    <Typography
                                        className={classes.value}>{user?.organization?.inn || "-"}</Typography>
                                </Box>
                            </>
                        )}

                        {user.expeditorId && (
                            <>
                                <Typography variant="h3" mt={3} mb={1}>Экспедитор</Typography>

                                <Box className={classes.rowContent}>
                                    <Typography className={classes.label}>Имя</Typography>
                                    <Typography className={classes.value}>{expeditor?.firstName || '-'}</Typography>
                                </Box>

                                <Box className={classes.rowContent}>
                                    <Typography className={classes.label}>Фамилия</Typography>
                                    <Typography className={classes.value}>{expeditor?.lastname || '-'}</Typography>
                                </Box>

                                <Box className={classes.rowContent}>
                                    <Typography className={classes.label}>Телефон</Typography>
                                    <Box className={classes.value}>
                                        <Link
                                            className={classes.linkValue}
                                            href={`tel:+${expeditor?.phone || '-'}`}
                                        >
                                            {phoneFormat(expeditor.phone) || '-'}
                                        </Link>
                                    </Box>
                                </Box>
                            </>
                        )}

                        {user.role === 'provider' && (
                            <Box mt={3}>
                                <Box>
                                    <LoadingButton
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        sx={{
                                            justifyContent: "space-between",
                                            fontWeight: 500,
                                            fontSize: '16px',
                                            letterSpacing: '0.02em',
                                            lineHeight: 'normal',
                                            textTransform: "none",
                                            borderRadius: "5px",
                                        }}
                                        loading={isLoadingFavExpeditors}
                                        endIcon={isOpenCollapseFavExpeditors ? <LessIcon/> : <MoreIcon/>}

                                        onClick={this.getFavoritesExpeditors.bind(this, false)}
                                    >
                                        Избранные экспедиторы
                                    </LoadingButton>
                                    <Collapse in={isOpenCollapseFavExpeditors} timeout="auto">
                                        <Box p={1}>
                                            {favoritesExpeditors.length > 0 && (
                                                <Box>
                                                    {favoritesExpeditors.map(expeditor => (
                                                        <Box
                                                            className={classes.favoriteExpeditorCard}
                                                            key={`favorite-expeditor-${expeditor.id}`}
                                                        >
                                                            <Typography>№{expeditor.id} | {expeditor.lastName} {expeditor.firstName}</Typography>
                                                            <IconButton
                                                                color="error"
                                                                onClick={this.deleteFavoriteExpeditor.bind(this, expeditor.id, false)}
                                                            >
                                                                <DeleteIcon color="error"/>
                                                            </IconButton>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            )}
                                            <Formik
                                                innerRef={this.refFormik}
                                                initialValues={initialValues}
                                                validationSchema={validationSchema}
                                                onSubmit={this.onSubmit}
                                            >
                                                {(props) => {
                                                    const {
                                                        values,
                                                        touched,
                                                        errors,
                                                        handleSubmit
                                                    } = props;

                                                    return (
                                                        <>
                                                            <Box mt={2}>
                                                                <Box>
                                                                    <AutocompleteUser_1
                                                                        value={values.expeditor}
                                                                        name="expeditor"
                                                                        title="Экспедитор"
                                                                        error={errors.expeditor}
                                                                        touched={touched.expeditor}
                                                                        onChangeAutocomplete={this.handleChangeAutocomplete}
                                                                        roles='courier'
                                                                        addedUsers={favoritesExpeditors}
                                                                    />
                                                                </Box>
                                                                <DialogActions>
                                                                    <Button
                                                                        size="small"
                                                                        variant="contained"
                                                                        sx={{
                                                                            borderRadius: "5px",
                                                                            fontWeight: "400",
                                                                            fontSize: '16px',
                                                                            letterSpacing: '0.02em',
                                                                            lineHeight: 'normal',
                                                                            textTransform: "none",
                                                                        }}
                                                                        onClick={handleSubmit}
                                                                    >
                                                                        Добавить экспедитора
                                                                    </Button>
                                                                </DialogActions>
                                                            </Box>
                                                        </>
                                                    );
                                                }}
                                            </Formik>
                                        </Box>
                                    </Collapse>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }
}

const validationSchema = Yup.object().shape({
    expeditor: Yup.object().required('Выберите экспедитора'),
});

const styles = {
    rowContent: {
        display: 'flex',
        alignItems: "flex-start",
        marginTop: 8,
        gap: 24,

        "&:first-child": {
            marginTop: 0,
        },
    },

    label: {
        flex: 3,
        fontSize: 18,
        lineHeight: "normal",
        color: "#22252D",
        letterSpacing: "0.02px",
    },

    value: {
        flex: 9,
        fontWeight: 600,
        fontSize: 20,
        lineHeight: "normal",
        color: "#22252D",
        letterSpacing: "0.02px",
        textAlign: "left",
    },

    linkValue: {
        width: "max-content",
        color: "#84b92c",
    },

    favoriteExpeditorCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
        marginTop: 8,
        borderRadius: "5px",
        background: "#84b92c1a",
        padding: "5px 10px",
        "&:first-child": {
            marginTop: 0,
        },
    },
};

export default withStyles(styles)(DialogUserCart);