import React from "react";
import {
    Box,
    Grid,
    Button,
    TextField,
    Typography, IconButton
} from "@mui/material";
import {
    Delete as DeleteIcon
} from "@mui/icons-material";
import {
    Map
} from "../../../../../../components";

const tariffDeliveryItem = {
    from: null,
    to: null,
    price: null
};

const timespanItem = {
    from: "",
    to: "",
}

class Form extends React.PureComponent {
    changeCoords = (coords) => {
        let data = {...this.props.data};
        data.centerLat = coords[0];
        data.centerLong = coords[1];

        this.props.onChange(data);
    }
    changeData = ({target}) => {
        const {value, name} = target;

        let data = {...this.props.data};
        data[name] = value;

        this.props.onChange(data);
    }

    //Логика работы с временными интервалами
    changeTimeInterval = ({target}, index) => {
        const {value, name} = target;

        let data = {...this.props.data};
        data.deliveryTimespans[index][name] = value;

        this.props.onChange(data);
    };
    addTimeInterval = () => {
        let data = {...this.props.data};
        data.deliveryTimespans.push({
            ...timespanItem
        });

        this.props.onChange(data);
    }
    deleteTimeInterval = (index) => {
        let data = {...this.props.data};

        data.deliveryTimespans.splice(index, 1);

        this.props.onChange(data);
    }


    // Логика радактирование тарифов
    changeTariff = ({target}, tariff, index) => {
        const {name, value} = target;

        let data = {...this.props.data};
        data[tariff][index][name] = value;


        this.props.onChange(data);
    }
    addTariff = (tariff) => {
        let data = {...this.props.data};
        data[tariff].push({
            ...tariffDeliveryItem
        });

        this.props.onChange(data);
    }
    removeTariff = (tariff, index) => {
        let data = {...this.props.data};

        data[tariff].splice(index, 1)

        this.props.onChange(data);
    }

    render() {
        const {
            data,

            onSave
        } = this.props;


        return (
            <>

                <Box mb={4} px={4} py={2} bgcolor="#f8f8f8" borderRadius={4}>
                    <Typography variant="h3" sx={{marginBottom: 2}}>Срок службы заказа</Typography>
                    <TextField
                        value={data.newOrderLifetime}
                        name="newOrderLifetime"
                        placeholder="Введите систему измерения"
                        type="number"
                        onChange={this.changeData}

                        fullWidth
                        helperText="Система измерения — ЧАСЫ"
                    />
                </Box>

                <Box mb={4} px={4} py={2} bgcolor="#f8f8f8" borderRadius={4}>
                    <Typography variant="h3" sx={{marginBottom: 2}}>Комиссия системы за реализацию товаров</Typography>
                    <TextField
                        value={data.systemPercent}
                        name="systemPercent"
                        placeholder="Введите процент"
                        type="number"
                        onChange={this.changeData}

                        fullWidth
                    />
                </Box>

                <Box mb={4} px={4} py={2} bgcolor="#f8f8f8" borderRadius={4}>
                    <Typography variant="h3" sx={{marginBottom: 2}}>Минимальный срок готовности заказа</Typography>
                    <TextField
                        value={data.deliveryMinDays}
                        name="deliveryMinDays"
                        placeholder="Введите срок"
                        type="number"
                        onChange={this.changeData}

                        fullWidth
                    />
                </Box>

                <Box mb={4} px={4} py={2} bgcolor="#f8f8f8" borderRadius={4}>
                    <Typography variant="h3" sx={{marginBottom: 2}}>Максимальный срок готовности заказа</Typography>
                    <TextField
                        value={data.deliveryMaxDays}
                        name="deliveryMaxDays"
                        placeholder="Введите срок"
                        type="number"
                        onChange={this.changeData}

                        fullWidth
                    />
                </Box>

                <Box mb={4} px={4} py={2} bgcolor="#f8f8f8" borderRadius={4}>
                    <Typography variant="h3" sx={{marginBottom: 2}}>Максимальный объем заказа (м³)</Typography>
                    <TextField
                        value={data.countCbmInOneOrder}
                        name="countCbmInOneOrder"
                        placeholder="Введите максимальный объем"
                        type="number"
                        onChange={this.changeData}

                        fullWidth
                    />
                </Box>

                <Box mb={4} px={4} py={2} bgcolor="#f8f8f8" borderRadius={4}>
                    <Typography variant="h3" sx={{marginBottom: 2}}>Размер бонуса водителя</Typography>
                    <TextField
                        value={data.driverBonus}
                        name="driverBonus"
                        placeholder="Введите размер бонуса"
                        type="number"
                        onChange={this.changeData}

                        fullWidth
                    />
                </Box>

                <Box mb={4} px={4} py={2} bgcolor="#f8f8f8" borderRadius={4}>
                    <Typography variant="h3" sx={{marginBottom: 2}}>Временные интервалы</Typography>
                    {data.deliveryTimespans.map((time, index) => (
                        <Box mb={3} sx={{
                            borderBottom: "1px solid #E6E6E6",
                            paddingBottom: "20px",
                        }}>
                            <Grid container alignItems="center">
                                <Grid item xs={1}>
                                    <Typography variant="h4">{index + 1}</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                value={time.from}
                                                label="от"
                                                type="time"
                                                name="from"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}

                                                onChange={(event) => this.changeTimeInterval(event, index)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h4">-</Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                value={time.to}
                                                label="до"
                                                type="time"
                                                name="to"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}

                                                onChange={(event) => this.changeTimeInterval(event, index)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <IconButton
                                                color="error"

                                                onClick={() => this.deleteTimeInterval(index)}
                                            >
                                                <DeleteIcon color="error"/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                    <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        sx={{fontSize: 16, borderRadius: "4px", textTransform: "initial", marginTop: 2}}

                        onClick={this.addTimeInterval}
                    >
                        Добавить интервал
                    </Button>
                </Box>

                <Box mb={4} px={4} py={2} bgcolor="#f8f8f8" borderRadius={4}>
                    <Typography variant="h3" sx={{marginBottom: 2}}>Тарифы для доставки (клиент)</Typography>
                    <Grid container spacing={2}>
                        {(data.deliveryTariffs || []).map((tariffDelivery, index) => (
                            <Grid item xs={12}>
                                <TariffDelivery
                                    key={`TariffDelivery-${index}`}

                                    tariffDelivery={tariffDelivery}
                                    tariffName="deliveryTariffs"
                                    index={index}

                                    onDelete={this.removeTariff}
                                    onChange={this.changeTariff}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        sx={{fontSize: 16, borderRadius: "4px", textTransform: "initial", marginTop: 2}}
                        onClick={this.addTariff.bind(this, "deliveryTariffs")}
                    >
                        Добавить тариф
                    </Button>
                </Box>

                <Box mb={4} px={4} py={2} bgcolor="#f8f8f8" borderRadius={4}>
                    <Typography variant="h3" sx={{marginBottom: 2}}>Тарифы для доставки (экспедитор)</Typography>
                    <Grid container spacing={2}>
                        {(data.expeditorDeliveryTariffs || []).map((tariffExpeditorDelivery, index) => (
                            <Grid item xs={12}>
                                <TariffDelivery
                                    key={`TariffExpeditorDelivery-${index}`}

                                    tariffDelivery={tariffExpeditorDelivery}
                                    tariffName="expeditorDeliveryTariffs"
                                    index={index}

                                    onDelete={this.removeTariff}
                                    onChange={this.changeTariff}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        sx={{fontSize: 16, borderRadius: "4px", textTransform: "initial", marginTop: 2}}
                        onClick={this.addTariff.bind(this, "expeditorDeliveryTariffs")}
                    >
                        Добавить тариф
                    </Button>
                </Box>

                <Box mb={4} px={4} py={2} bgcolor="#f8f8f8" borderRadius={4} height={460} display="flex"
                     flexDirection="column">
                    <Typography variant="h3" sx={{marginBottom: 2}}>
                        Центр
                    </Typography>
                    <Map
                        center={[data.centerLat, data.centerLong]}
                        coords={[data.centerLat, data.centerLong]}
                        onChangeCoords={this.changeCoords}
                    />
                </Box>

                <Button
                    variant="contained"
                    fullWidth
                    sx={{
                        height: 56,
                        borderRadius: "4px"
                    }}

                    onClick={onSave}
                >
                    Сохранить
                </Button>

            </>
        );
    }
}

const TariffDelivery = (props) => {
    const {
        index,
        tariffDelivery,
        tariffName,

        onDelete,
        onChange
    } = props;

    return (
        <Grid container spacing={2} wrap="nowrap">
            <Grid item xs={4}>
                <TextField
                    value={tariffDelivery.from}
                    label="Дистанция от (м)"
                    fullWidth
                    name="from"
                    size="small"
                    type="number"
                    onChange={(event) => onChange(event, tariffName, index)}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    value={tariffDelivery.to}
                    label="Дистанция до (м)"
                    fullWidth
                    name="to"
                    size="small"
                    type="number"
                    onChange={(event) => onChange(event, tariffName, index)}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    value={tariffDelivery.price}
                    label="Стоимость"
                    fullWidth
                    name="price"
                    size="small"
                    type="number"
                    onChange={(event) => onChange(event, tariffName, index)}
                />
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="error"
                    sx={{
                        height: 38,
                        textTransform: "initial",
                        fontSize: 16,
                        borderRadius: "4px"
                    }}
                    onClick={() => onDelete(tariffName, index)}
                >
                    Удалить
                </Button>
            </Grid>
        </Grid>
    );
}

export default Form
