import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,

    Box,
    Grid,
    Button,
    TextField,
    Typography
} from "@mui/material";
import {
    AutocompleteUser,
    AutocompleteCategory
} from "../../../../../../components";
import {
    Formik
} from "formik";
import * as Yup from "yup";


const initForm = {
    userId: "",
    categoryId: ""
};

class DialogFormAccreditation extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initUserName: "",

            isOpen: false,
            isCreated: false,


            onEvent: null
        };

        this.refFormik = React.createRef();
    }


    open = ({ form, event }) => {

        this.setState({
            isOpen: true,
            isCreated: Boolean(Object.keys(form).length <= 0),
            initUserName: form.name,
            onEvent: event
        }, () => {
            setTimeout(() => {
                this.refFormik.current.setValues(
                    Boolean(Object.keys(form).length <= 0) ? {...initForm} : form
                );
            }, 100);
        });

    }
    close = () => {
        this.refFormik.current.setValues({});
        this.setState({
            isOpen: false
        })
    }

    // Обработчик формы
    onSubmit = (form) => {
        this.state.onEvent(form, true);
    }

    // Изменение формы
    changeForm = ({target}) => {
        const {
            value,
            name
        } = target;

        let newValues = this.refFormik.current.values;
        newValues[name] = value;

        this.refFormik.current.setValues(newValues);
    }

    // Клик по кнопке в управлении
    clickCreate = () => {
        this.refFormik.current.handleSubmit();
    }

    render() {
        const {
            initUserName,

            isOpen,
            isCreated
        } = this.state;

        return (
            <>

                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={isOpen}

                    onClose={this.close}
                >

                    <DialogTitle>
                        <Typography variant="h3">
                            {Boolean(isCreated) ? "Создание аккредитации" : `Редактирование аккредитации: "${initUserName}"`}
                        </Typography>
                    </DialogTitle>

                    <DialogContent>
                        <Formik
                            innerRef={this.refFormik}
                            initialValues={{}}
                            validationSchema={validationSchema}
                            onSubmit={this.onSubmit}
                        >
                            {(props) => {
                                const {
                                    values,
                                    errors,
                                    touched
                                } = props;

                                if (Boolean(!isCreated && Object.keys(values || {}).length <= 0)) {
                                    return null
                                }

                                return (
                                    <Box pt={1}>
                                        <Box mb={2}>
                                            <AutocompleteUser
                                                value={values.userId}
                                                error={Boolean(touched.userId && errors.userId)}
                                                helperText={touched.userId && errors.userId}
                                                name="userId"
                                                label="Пользователь"
                                                placeholder="Выберите пользователя"
                                                variant="outlined"
                                                fullWidth

                                                onChange={this.changeForm}
                                            />
                                        </Box>
                                        <Box mb={2}>
                                            <AutocompleteCategory
                                                value={values.categoryId}
                                                error={Boolean(touched.categoryId && errors.categoryId)}
                                                helperText={touched.categoryId && errors.categoryId}
                                                name="categoryId"
                                                label="Категория"
                                                placeholder="Выберите категорию"
                                                variant="outlined"
                                                fullWidth

                                                onChange={this.changeForm}
                                            />
                                        </Box>
                                    </Box>
                                )
                            }}
                        </Formik>
                    </DialogContent>

                    <DialogActions>
                        <Box px={2}>
                            <Grid container justifyContent="flex-end" spacing={1}>
                                <Grid item>
                                    <Button variant="outlined" onClick={this.close}
                                            sx={{textTransform: "initial", borderRadius: "4px", padding: "4px 24px"}}>
                                        Отменить
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={this.clickCreate}
                                            sx={{textTransform: "initial", borderRadius: "4px", padding: "4px 24px"}}>
                                        {Boolean(isCreated) ? "Создать" : "Редактировать"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogActions>

                </Dialog>

            </>
        );
    }
}


const validationSchema = Yup.object().shape({
    userId: Yup.string().required("Выберите пользователя").nullable(),
    categoryId: Yup.string().required("Выберите категорию").nullable(),
});

export default DialogFormAccreditation
